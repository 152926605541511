import React, { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import classnames from "classnames";
import ReactModal from "react-modal"


import SvgButtonBarSubmit from "./../../svg/ButtonBarSubmit";
import BaseButton from "components/common/BaseButton";
import CircleCloseFilterSvg from "components/svg/CircleCloseFilter";
import submitCase from "./../ButtonActions/submitCase";
import { saveAllAnswersAndValidate } from "redux/thunks/answerThunk";
import { setValidationErrors } from "redux/actions/saveComponentValueAction";

const ButtonSubmit = ({ buttonSize, currentFormTemplateID, setCaseStatus, userCanEdit }) => { 

    const currentCaseStatus = useSelector(state => state.currentCase.case.status, shallowEqual);
    const currentCaseId = useSelector(state => state.currentCase.case.id, shallowEqual);
    const currentUser = useSelector(state => state.currentUser, shallowEqual);
    const connection = useSelector(state => state.connection, shallowEqual);
    const answers = useSelector(state => state.saveComponentValue.components, shallowEqual);
    const isSaving = useSelector(state => state.isSaving);
    
    const intl = useIntl();
    const dispatch = useDispatch();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openConfirmationModal = () => {
        if (!isSubmitting) { 
            setIsSubmitting(true);
            dispatch(saveAllAnswersAndValidate(result => { 
                console.log("openConfirmationModal", result);
                dispatch(setValidationErrors(result.requiredList));
                if (!result.validationErrors) {
                    setIsModalOpen(true);
                } else { 
                    setIsSubmitting(false);
                }
            }));
        }
    }

    const closeConformationModal = () => {
        setIsModalOpen(false);
        setIsSubmitting(false);
    }
    
    const onSubmitCase = () => {
        
        setIsModalOpen(false);      
        
        submitCase(currentCaseId, answers, currentFormTemplateID, currentUser, intl, dispatch).then(response => {
            setIsSubmitting(false);
            setCaseStatus(response.caseStatus);
        });
    }

    const isVisible = currentCaseStatus === "draft";
    const isDisabled = !connection || !userCanEdit || isSaving;

    return isVisible && <>
    <button
        id="buttonSubmit"
        type="button"
        onClick={openConfirmationModal}
        className={classnames("button", "button--icon-only", { "button--icon-saving": isSubmitting }, { "button--icon-disabled": isDisabled })}
        disabled={isDisabled}>
        <SvgButtonBarSubmit size={buttonSize} title={intl.formatMessage({ id: "formRenderer.submitForApproval" })} />
    </button>
    <ReactModal isOpen={isModalOpen} className="react-modal" overlayClassName="react-modal-overlay" onRequestClose={closeConformationModal}>
        <div className="react-modal__header">
            <button className="react-modal__close-button" onClick={closeConformationModal}>
                <CircleCloseFilterSvg />
            </button>
        </div>
        <div className="react-modal__body">
            <div className="react-modal__text">
                <FormattedMessage id="formRenderer.submitMessage" />
            </div>
            <div className="react-modal__actions-container">
                <BaseButton handleSubmit={onSubmitCase} text={intl.formatMessage({ id: "formRenderer.submitButton" })} backgroundColor="gray" className="react-modal__action-button button--wide" />
                <BaseButton handleSubmit={closeConformationModal} text={intl.formatMessage({ id: "formRenderer.edit.cancel" })} backgroundColor="green" className="react-modal__action-button" />
            </div>
        </div>
    </ReactModal>
    </>
}

export default ButtonSubmit;