import React from 'react';

const ButtonBarConvert = ({ title, size }) => (
  <svg height={size} width={size} id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 20">
    <title>{title}</title>
    <defs>
      <style>{`
      .cls-1 {
        fill: #fff;
        stroke-width: 0px;
      }
    `}
      </style>
    </defs>
    <path class="cls-1" d="M10,0H2C1.45,0,.98.2.59.59s-.59.86-.59,1.41v16c0,.55.2,1.02.59,1.41s.86.59,1.41.59h12c.55,0,1.02-.2,1.41-.59s.59-.86.59-1.41V6L10,0ZM6.27,16.65h-.76v-6.77l-2.19,2.03-.52-.55,3.06-2.88,3.1,2.88-.52.55-2.18-2.03v6.77ZM10.13,16.65l-3.1-2.88.52-.55,2.18,2.03v-6.77h.76v6.77l2.19-2.03.52.55-3.06,2.88ZM9,7V2l5,5h-5Z" />
  </svg>
);

export default ButtonBarConvert;
