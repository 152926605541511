import React, { useState, useRef, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import ArrowDown from "components/svg/ArrowDown";
import CircleCloseFilter from "components/svg/CircleCloseFilter";
import classnames from "classnames";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { toggleNavigation } from "redux/actions/uiActions";
import { FormattedMessage } from "react-intl";
import config from "config";
import uuid from "uuid";
import { FeatureToggle, FeatureOn } from "components/_FeatureToggle/FeatureToggle";
import { addCase } from "redux/thunks/caseThunks";
import ReactModal from "react-modal";
import { isUserAdmin } from "components/formcomponents/utils/userUtils";

const TheNavigation = (props) => {
  // state
  const [activeSubNavigation, setActiveSubNavigation] = useState("");
  const [createModalOpen, setCreateModalOpen] = useState(false);
  // methods
  const toggleSubItems = (parentName) => {
    setActiveSubNavigation(
      activeSubNavigation === parentName ? "" : parentName
    );
  };

  const closeSubItemIfOpen = (parentName) => {
    if (activeSubNavigation.includes(parentName)) {
      toggleSubItems(parentName);
    }
  };
  const toggleNavigation = () => props.toggleNavigation();

  const addOneCase = (id, confidential = false) => {
    setCreateModalOpen(true);
    const lang = localStorage.getItem('currentLang');
    props.addCase(id, confidential, lang, props.user, (caseData) => props.history.push("/case/" + caseData.id));
    toggleSubItems("Create new report");
  };

  function generateOfflineCaseId(id) {
    return id + "-" + uuid.v4() + "-offlineCase";
  }

  const navigationWrapperRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      navigationWrapperRef.current &&
      !navigationWrapperRef.current.contains(event.target)
    ) {
      if (activeSubNavigation !== "") {
        setActiveSubNavigation("");
        props.toggleNavigation();
      }
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  const isInternalUser = user => !checkFuzzyBoolean(user["custom:external"])
  const isAdministrator = user => isUserAdmin(user["custom:administrator"]);

  const isNAMUser = user => {
    if (user["custom:region"]) {
      return user["custom:region"] === "NAM";
    }
    return false;
  }

  const checkFuzzyBoolean = value => {
    if (value === undefined || value === null) {
      return false;
    }

    if (typeof value === "boolean") {
      return value;
    }

    if (typeof value === "string") {
      return String(value).toLowerCase() === 'true';
    }
  }

  return (
    <>
      <nav
        className={classnames("navigation", {
          "navigation--is-open": props.isNavigationOpen,
        })}
        ref={navigationWrapperRef}
      >
        <div className="navigation__container">
          <div className="navigation__header">
            <FormattedMessage id="navigation.menuLabel" />
            <button
              className="navigation__header-close-button"
              onClick={toggleNavigation}
            >
              <CircleCloseFilter />
            </button>
          </div>

          <div className="navigation__sub-navigation-container">
            <button
              id="nav__button__toggler"
              className={classnames(
                "navigation__action navigation__action--toggler",
                {
                  "navigation__action--active":
                    activeSubNavigation === "Create new report",
                }
              )}
              onClick={() => toggleSubItems("Create new report")}
            >
              <FormattedMessage id="navigation.createNewReport" />
              <ArrowDown className="navigation__action-svg-arrow" />
            </button>

            {activeSubNavigation === "Create new report" && (
              <div className="navigation__sub-navigation-items">
                {isInternalUser(props.user) ? (
                  <a
                    id="create__bda__button"
                    className="navigation__sub-link"
                    onClick={() => {
                      addOneCase(config.BART_TEMPLATE_ID);
                      toggleSubItems("Create new report");
                    }}
                  >
                    <FormattedMessage id="navigation.bearingDamageAnalysis" />
                  </a>
                ) : null}
                {(isInternalUser(props.user) || (!isInternalUser(props.user) && !isNAMUser(props.user))) ?
                  <a
                    to="/"
                    id="create__bi__button"
                    className="navigation__sub-link"
                    onClick={() => {
                      toggleSubItems("Create new report");
                      addOneCase(config.INSPECTION_TEMPLATE_ID);
                    }}
                  >
                    <FormattedMessage id="navigation.bearingInspection" />
                  </a> :
                  null}
                {isNAMUser(props.user) || isAdministrator(props.user) ?
                  <FeatureToggle feature="usinspection">
                    <FeatureOn>
                      <a
                        to="/"
                        id="create__nam__button"
                        className="navigation__sub-link"
                        onClick={() => {
                          toggleSubItems("Create new report");
                          addOneCase(config.US_INSPECTION_TEMPLATE_ID);
                        }}
                      >
                        <FormattedMessage id="navigation.usBearingInspection" />
                      </a>
                    </FeatureOn>
                  </FeatureToggle>
                  : null}

                {isInternalUser(props.user) ? (
                  <a
                    id="create__wind__button"
                    className="navigation__sub-link"
                    onClick={() => {
                      toggleSubItems("Create new report");
                      addOneCase(config.WIND_TEMPLATE_ID, true);
                    }}
                  >
                    <FormattedMessage id="navigation.wind" />
                  </a>
                ) : null}
                {isInternalUser(props.user) ? (
                  <a
                    id="create__gbi__button"
                    className="navigation__sub-link"
                    onClick={() => {
                      addOneCase(config.GBI_TEMPLATE_ID);
                      toggleSubItems("Create new report");
                    }}
                  >
                    <FormattedMessage id="navigation.gbi" />
                  </a>
                ) : null}
              </div>
            )}
          </div>

          {props.connection ? (
            <React.Fragment>
              <Link
                to={"/my-reports"}
                className="navigation__action"
                onClick={() => {
                  closeSubItemIfOpen("Create new report");
                  props.toggleNavigation();
                }}
              >
                <FormattedMessage id="pageName.myReports" />
              </Link>

              <Link
                to={"/all-reports"}
                className="navigation__action"
                onClick={() => {
                  closeSubItemIfOpen("Create new report");
                  props.toggleNavigation();
                }}
              >
                <FormattedMessage id="pageName.allReports" />
              </Link>
              {isInternalUser(props.user) ? (
                <a
                  href="https://skfgroup.sharepoint.com/:f:/r/sites/BART-Public/Shared%20Documents/Analytics?csf=1&e=6gA1TI"
                  className="navigation__action"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage id="pageName.analytics" />
                </a>
              ) : null}
              {isNAMUser(props.user) || isAdministrator(props.user) ? (
                <FeatureToggle feature="usinspection">
                  <FeatureOn>
                    <Link
                      to={"/legacyinspectionreports"}
                      className="navigation__action"
                      onClick={() => {
                        closeSubItemIfOpen("Create new report");
                        props.toggleNavigation();
                      }}
                    >
                      <FormattedMessage id="pageName.legacyUSInspectionReports" />
                    </Link>
                  </FeatureOn>
                </FeatureToggle>
              ) : null}
            </React.Fragment>
          ) : null}
        </div>
      </nav>
      <ReactModal
        isOpen={createModalOpen}
        className="react-modal approveai-container"
        overlayClassName="react-modal-overlay">
        <div className="react-modal__body update-modal__body">
          <h2><FormattedMessage id="navigation.creatingReport" /></h2>
        </div>
      </ReactModal>
    </>
  );
};

TheNavigation.propTypes = {
  isNavigationOpen: PropTypes.bool.isRequired,
  toggleNavigation: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleNavigation: () => dispatch(toggleNavigation()),
    addCase: (id, confidential, lang, user, callback) => dispatch(addCase(id, confidential, lang, user, callback))
  };
};

const mapStateToProps = (state) => {
  return {
    isNavigationOpen: state.ui.isNavigationOpen,
    connection: state.connection,
    user: state.currentUser
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TheNavigation)
);
