import React from "react";

const SvgInfo = props => (
    <svg width={26} height={26} viewBox="0 0 26 26" {...props}>
        <defs>
            <path
                d="M12 24C5.373 24 0 18.627 0 12S5.373 0 12 0s12 5.373 12 12-5.373 12-12 12z"
                id="info_svg__a"/>
        </defs>
        <g transform="matrix(1 0 0 -1 1 25) rotate(180 12 12)" fill="none" fillRule="evenodd">
            <use stroke="#485A64" xlinkHref="#info_svg__a"/>
            <path
                d="M13.412 18.78v-7.94c0-.74-.6-1.36-1.341-1.36-.76 0-1.36.62-1.36 1.36v7.94c0 .74.6 1.34 1.36 1.34.74 0 1.34-.6 1.34-1.34zM13.772 6.08a1.7 1.7 0 00-3.173-.85 1.7 1.7 0 103.173.85z"
                fill="#485A64"/>
        </g>
    </svg>
);

export default SvgInfo;
