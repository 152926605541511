import React from "react";

const SvgWarning = ({ className, color = "#485A64" }) => (
  <svg width={26} height={26} viewBox="0 0 26 26" className={ className }>
    <defs>
      <path
        d="M12 24C5.373 24 0 18.627 0 12S5.373 0 12 0s12 5.373 12 12-5.373 12-12 12z"
        id="warning_svg__a"
      />
    </defs>
    <g transform="matrix(1 0 0 -1 1 25)" fill="none" fillRule="evenodd">
      <use stroke={ color } xlinkHref="#warning_svg__a" />
      <path
        d="M13.412 18.78v-7.94c0-.74-.6-1.36-1.341-1.36-.76 0-1.36.62-1.36 1.36v7.94c0 .74.6 1.34 1.36 1.34.74 0 1.34-.6 1.34-1.34zm.36-12.7a1.7 1.7 0 00-3.173-.85 1.7 1.7 0 103.173.85z"
        fill={ color }
      />
    </g>
  </svg>
);

export default SvgWarning;
