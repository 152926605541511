import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

function PageHeader(props) {
  return (
    <div className={classnames('page-header', props.className)}>
      <h2 className="page-header__element">
        { props.header }
      </h2>

      { props.additional }
    </div>
  )
}

PageHeader.propTypes = {
  additional: PropTypes.object
}

export default PageHeader
