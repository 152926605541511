
import config from "config";
import { setCurrentCaseStatus } from "redux/actions/currentCaseActions";
import { isIOS, isIPad13, isMobileSafari } from "react-device-detect";
import { submitCase as submitCaseThunk } from "redux/thunks/caseThunks";

export default function submitCase(caseId, answers, currentFormTemplateID, user, intl, dispatchF) {
    return new Promise((resolve, reject) => {
        const approver = getApprover(answers, currentFormTemplateID);
        const isApprover = approver !== null && user.email === approver;
    
        dispatchF(submitCaseThunk(caseId, (result) => {
            console.log(result);
            dispatchF(setCurrentCaseStatus(result.data));
            resolve({ isApprover, caseStatus: result.data });
            openEmail(caseId, approver, intl);
        }));
    });
}

function openEmail(caseId, approver, intl) {
    const subject = `${intl.formatMessage({ id: "formRenderer.bartReportNumber", })}:${caseId} ${intl.formatMessage({ id: "formRenderer.hasBeenSubmittedForApproval", })}`;
    let body = `${intl.formatMessage({ id: "formRenderer.availableReport", })}
        ${window.location.origin.toString()}/case/${caseId}
        %0DLINK ${intl.formatMessage({ id: "formRenderer.mustBeOpenedInChrome", })}`;
    
    if (isIOS || isIPad13 || isMobileSafari) {
        window.location.href = `ms-outlook://compose?to=${approver}&subject=${subject}&body=${body}`;
    } else {
        window.location.href = `mailto:${approver}?subject=${subject}&body=${body}`;
    }
}


function getApprover(answers, currentFormTemplateID) {
    if (currentFormTemplateID === config.US_INSPECTION_TEMPLATE_ID) {
        const approver = answers.find(a => a.name === "reportDetails.reportApprovedBy.email");
        if (approver) {
            let approverParsed = JSON.parse(approver.value);
            if (approverParsed.hasOwnProperty("value")) {
                approverParsed = approverParsed.value;
            }
            return approverParsed;
        } else {
            return null;
        }
    } else {
        const approver = answers.find(a => a.name === "skfDetails.reportNeedsApproval");
        if (approver) {
            let approverParsed = JSON.parse(approver.value).approversName;
            if (approverParsed.hasOwnProperty("value")) {
                approverParsed = approverParsed.value;
            }
            return approverParsed;
        } else {
            return null;
        }
    }
    
}