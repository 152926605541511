const reactSelectStyles = {
  control: () => ({
    borderRadius: 0,
    backgroundColor: '#ffffff',
    display: 'flex',
    alignItems: 'center',
    minHeight: '2.75rem',
    height: 'auto',
    border: '1px solid #DCE0E1',
    padding: '0.25rem 0.9375rem',
    
    '> div': {
      padding: 0
    }
  }),
  menu: (provided) => ({
    ...provided,
    margin: 0,
    boxShadow: 'none',
    border: '1px solid #DCE0E1',
    borderTop: 0,
    borderRadius: 0,
    zIndex: 11
  }),
  menuList: (provided) => ({
    ...provided,
    borderRadius: 0,
    backgroundColor: '#ffffff',
    padding: 0
  }),
  option: (provided, state) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    minHeight: '2.75rem',
    padding: '0.25rem 0.9375rem',
    borderBottom: '1px solid #DCE0E1',
    backgroundColor: state.isSelected ? '#0F58D6' : state.isFocused ? '#DCE0E1' : 'transparent',
    '&:last-child': {
      borderBottom: 0
    },
    '&:hover': {
      cursor: 'pointer'
    }
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: 0
  })
}

export default reactSelectStyles
