import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import classnames from "classnames";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import ReactModal from "react-modal"

import SvgButtonBarDelete from "../../svg/ButtonBarDelete"
import BaseButton from "components/common/BaseButton";
import CircleCloseFilterSvg from "components/svg/CircleCloseFilter";
import { deleteCase, loadCaseAndData } from "redux/thunks/caseThunks";


const ButtonDelete = ({ buttonSize, userCanEdit }) => { 
    const connection = useSelector(state => state.connection, shallowEqual);
    const currentCaseStatus = useSelector(state => state.currentCase.case.status, shallowEqual);
    const caseId = useSelector(state => state.currentCase.case.id, shallowEqual);

    const intl = useIntl();
    const dispatch = useDispatch();

    const [isModalOpen, setIsModalOpen] = useState(false);

    const isVisible = () => currentCaseStatus !== "approved";
    const isDisabled = () => !connection || !userCanEdit;
    const onDeleteCase = () => { 
        setIsModalOpen(true);
    }
    const closeConfirmDelete = () => { 
        setIsModalOpen(false);
    }
    const deleteCurrentCase = () => { 
        dispatch(deleteCase(caseId, () => {
            dispatch(loadCaseAndData(caseId));
        }));
    }

    return isVisible() && <>
        <button
            id="buttonDelete"
            type="button"
            onClick={onDeleteCase}
            className={classnames("button", "button--icon-only", { "button--icon-disabled": isDisabled() })}
            disabled={isDisabled()}>
            <SvgButtonBarDelete size={buttonSize} title={intl.formatMessage({ id: "formRenderer.delete" })} />
        </button>
        <ReactModal isOpen={isModalOpen} className="react-modal" overlayClassName="react-modal-overlay" onRequestClose={closeConfirmDelete}>
            <div className="react-modal__header">
                <button className="react-modal__close-button" onClick={() => { closeConfirmDelete(); }}>
                    <CircleCloseFilterSvg />
                </button>
            </div>

            <div className="react-modal__body">
                <div className="react-modal__text">
                    <div className="icon-message">
                        <div className="icon-message__text">
                            {<FormattedMessage id="formRenderer.doYouWantToDeleteReport" />}{" "}{caseId}?
                        </div>
                    </div>
                </div>        
                <div className="react-modal__actions-container">
                    <BaseButton handleSubmit={ deleteCurrentCase } text={intl.formatMessage({ id: "formRenderer.yes" })} backgroundColor="gray" className="react-modal__action-button" />
                    <BaseButton handleSubmit={ closeConfirmDelete } text={intl.formatMessage({ id: "formRenderer.no" })} backgroundColor="green" className="react-modal__action-button" />
                </div>
            </div>
        </ReactModal>
    </>;

}

export default ButtonDelete;