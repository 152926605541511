import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import {
  STATUS_APPROVED,
  STATUS_NOT_APPROVED,
  STATUS_SUBMITTED_FOR_APPROVAL,
  STATUS_SUBMITTED_FOR_APPROVAL_EDITED
} from 'core/ui/status-indicator-types.js'

const LighthouseStatus = ({ label, value, color }) => {
  const indicatorClass = () => {
    if (color === undefined) {
      const text = lower(value)

      if (text === lower(STATUS_APPROVED)) {
        color = 'green'
      }

      if (text === lower(STATUS_SUBMITTED_FOR_APPROVAL) || text === lower(STATUS_SUBMITTED_FOR_APPROVAL_EDITED)) {
        color = 'yellow'
      }

      if (text === lower(STATUS_NOT_APPROVED)) {
        color = 'red'
      }
    } else {
      color = color === undefined ? 'black' : color
    }

    return color !== undefined ? `table-cell-status--${color}` : 'table-cell-status--no-status'
  }

  const lower = (cellValue) => {
    if (cellValue !== undefined) {
      return cellValue.toLowerCase()
    }
  }

  return (
    <div className={ classnames('table-cell-status', indicatorClass()) }>
      { label }
    </div>
  )
}

LighthouseStatus.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  color: PropTypes.string
}

export default LighthouseStatus
