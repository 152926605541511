import React, { Component } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import classnames from "classnames";
import { connect } from "react-redux";
import { TO_MEDIUM, MEDIUM } from "core/ui/breakpoints";

import { setActiveTab } from "redux/actions/activeTabActions";
import SvgButtonBarZoomIn from "../../svg/ButtonBarZoomIn"
import SvgButtonBarZoomOut from "../../svg/ButtonBarZoomOut";

class ButtonExpandForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: 0,
            previousTab: 0,
            case: {},
            caseStatus: ""
        }
    }
    
    componentDidMount() {
        this.setStateFromProps();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.currentCase != this.props.currentCase) {
            this.setStateFromProps();
        }
        if (prevProps.currentCase || this.props.currentCase) {
            if (prevProps.currentCase.status != this.props.currentCase.status) {
                this.setStateFromProps();
            }
        }
    }

    setStateFromProps() {
        this.setState({
            case: this.props.currentCase,
            caseStatus: this.props.currentCase.status,
        });
    }

    onExpand = () => {
        if (this.props.activeTab !== 2) {
            this.props.dispatch(setActiveTab(2));
        } else {
            this.props.dispatch(setActiveTab(0));
        }
    }

    isMedium() {
        const mq = window.matchMedia(MEDIUM);
        return mq.matches;
    }
    
    getIcon = () => {
        if (this.props.activeTab === 2) {
            return <SvgButtonBarZoomOut size={ this.props.buttonSize } title={this.props.intl.formatMessage({ id: "formRenderer.collapse" })} />
        } else {
            return <SvgButtonBarZoomIn size={ this.props.buttonSize } title={this.props.intl.formatMessage({ id: "formRenderer.expand" })} />
        }
    }

    isVisible = () => this.state.caseStatus !== "rejected" && this.state.caseStatus !== "approved" && this.state.caseStatus !== "submitted" && this.isMedium();

    render() {
        return this.isVisible() && <button
            id="buttonCopyUrl"
            type="button"
            onClick={() => this.onExpand()}
            className={classnames("button", "button--icon-only", "hide-for-small")}>
            { this.getIcon() }
        </button>;
    }
}
function mapStateToProps(state) {
    return {
        activeTab: state.activeTab,
        currentCase: state.currentCase.case,
    };
}
  
export default injectIntl(connect(mapStateToProps)(ButtonExpandForm));