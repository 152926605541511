import React from 'react';
import TableCellBasic from '../../common/TableCellBasic';
import classnames from 'classnames';

const ConvertBearingsTable = ({ headers, data, onCheckboxChange }) => {
  return (
    <table className='table'>
      <thead>
        <tr className='table__header-row'>
          {headers.map((header, index) => (
            <th key={index} className='table__header-cell'>
              <div className={classnames('table__header-inset', { 'table__header-inset--align-right': header.alignRight })}>
                {header.displayName}
              </div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row, i) => (
          <tr className='table__body-row' key={i}>
            <TableCellBasic cellValue={i + 1} />
            <TableCellBasic cellValue={row.designation} />
            <TableCellBasic cellValue={row.recommendation} />
            <td className='table__body-cell'>
              <input
                onChange={() => onCheckboxChange(i)}
                type='checkbox'
                checked={row.select}
                className='convertion__table__checkbox'
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default ConvertBearingsTable;
