import React from "react"
import { Switch } from "react-router-dom"
import AppliedRoute from "./Routes/AppliedRoute.js"
import AuthenticatedRoute from "./Routes/AuthenticatedRoute"
import Case from 'components/_Case/Case.js'
import Dashboard from "components/_Dashboard/Dashboard.js"
import Login from "components/_Login/Login.js"
import AllReports from "components/_AllReports/AllReports.js"
import MyReports from "components/_MyReports/MyReports.js"
import About from "components/_About/About.js"
import ResetPassword from "components/_ResetPassword/ResetPassword.js"
import DataExport from "components/_DataExport/DataExport.js"
import LegacyReportSearch from "components/_LegacyInspectionReports/LegacyReportSearch"
import LicenseAgreementPage from "components/_LicenseAgreement/LicenseAgreementPage.js"

export default ({ childProps }) =>
  <Switch>
    <AppliedRoute exact path="/" component={Login} props={childProps} />
    <AppliedRoute path="/login" component={Login} props={childProps} />
    <AppliedRoute path="/reset-password" component={ResetPassword} props={childProps}/>
    <AppliedRoute path="/license-agreement" component={LicenseAgreementPage} props={childProps}/>
    <AuthenticatedRoute path="/case/:id" component={Case} props={childProps}/>
    <AuthenticatedRoute path="/about" component={About} props={childProps}/>
    <AuthenticatedRoute path="/dashboard" component={Dashboard} props={childProps}/>
    <AuthenticatedRoute path="/all-reports" component={AllReports} props={childProps}/>
    <AuthenticatedRoute path="/my-reports" component={MyReports} props={childProps}/>
    <AuthenticatedRoute path="/dataexport" component={DataExport} props={childProps} />
    <AuthenticatedRoute path="/legacyinspectionreports" component={LegacyReportSearch} props={childProps}/>
  </Switch>
