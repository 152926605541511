import React from 'react';

const HeaderComponent = props => {
    const HeaderType = props.formComponentData.subtype;
    return (
        <div className="formHeader">
            <HeaderType>{props.formComponentData.label}</HeaderType>
        </div>
    );
};

export default HeaderComponent;