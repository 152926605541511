import React, { useState, useEffect } from 'react'
import { Amplify, Auth } from 'aws-amplify'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import Routes from './Routes'
import config from './config'
import TheHeader from 'components/common/TheHeader'
import TheEnvironmentBar from 'components/common/TheEnvironmentBar'
import TheUserBar from 'components/common/TheUserBar'
import TheFooter from 'components/common/TheFooter'
import LicenseAgreementModal from 'components/_LicenseAgreement/LicenseAgreementModal'
import TheToTop from 'components/common/TheToTop'
import ServiceWorkerUpdateModal from './ServiceWorkerUpdateModal'
import languageTranslations from './language_translation.json'
import { IntlProvider } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import ReactGA from 'react-ga'
import CacheBuster from './CacheBuster'
import { setCurrentUser } from './redux/actions/currentUserActions'
import Offline from 'components/_Offline/Offline'
import OfflineBar from 'components/_Offline/OfflineBar'
import { checkFuzzyBoolean, isInternalUser, isUserAdmin } from 'components/formcomponents/utils/userUtils'
import _ from 'lodash'

const AWS_REGION = 'eu-west-1'

const App = () => {
  const dispatch = useDispatch()

  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [isUserExternal, setIsUserExternal] = useState(false)
  const [user, setUser] = useState(null)
  const [isLicenseAgreementAccepted, setIsLicenseAgreementAccepted] = useState(false)

  const currentLanguage = useSelector(state => state.language)
  const connection = useSelector(state => state.connection)
  const currentUser = useSelector(state => state.currentUser)

  useEffect(() => {
    Amplify.configure({
      Auth: config.COGNITO_AUTH,
      Storage: {
        AWSS3: {
          bucket: config.BUCKET,
          region: AWS_REGION,
        },
      },
    })

    const initializeGA = () => {
      const environment = process.env.REACT_APP_STAGE
      if (environment === 'stage') {
        console.log('Initialized Google analytics for stage')
        ReactGA.initialize('UA-30046188-41')
      } else if (environment === 'prod') {
        console.log('Initialized Google analytics for prod')
        ReactGA.initialize('UA-30046188-41')
      } else if (!environment) {
        console.log('Initialized Google analytics for dev')
        ReactGA.initialize('UA-113741941-2')
      }
      ReactGA.pageview('/dashboard')
    }

    const fetchCurrentUser = async () => {
      try {
        await Auth.currentSession()
        setIsAuthenticated(true)
        const userAuth = await Auth.currentAuthenticatedUser()
        const isExternal = userAuth.attributes['custom:external']?.toLocaleLowerCase() === 'true'
        setIsUserExternal(isExternal)
        setUser(userAuth.attributes)
        dispatch(setCurrentUser(userAuth.attributes))
      } catch (e) {
        if (e !== 'No current user') {
          console.log(e)
        }
      }
    }

    initializeGA()
    fetchCurrentUser()
  }, [dispatch, isAuthenticated])

  useEffect(() => {
    if (!currentUser) return

    if (_.isEmpty(currentUser)) return

    if (isInternalUser(currentUser['custom:external']) || isUserAdmin(currentUser['custom:administrator'])) {
      setIsLicenseAgreementAccepted(true)
      return
    }

    getCurrentSessionUser()
  }, [currentUser])

  async function getCurrentSessionUser() {
    try {
      await Auth.currentSession()
      const userAuth = await Auth.currentAuthenticatedUser()
      const userAttributes = userAuth.attributes

      const hasAcceptedLicense = checkFuzzyBoolean(userAttributes['custom:LA_accepted'])
      setIsLicenseAgreementAccepted(hasAcceptedLicense)
    } catch (e) {
      console.log(e)
    }
  }

  const getMessages = () => (
    Object.assign(
      {},
      languageTranslations['en'],
      languageTranslations[currentLanguage],
    )
  )

  const childProps = {
    isAuthenticated,
    userHasAuthenticated: setIsAuthenticated,
    setUserExternal: setIsUserExternal,
    setUser,
    isUserExternal,
    user,
  }

  const notcase = /^(?!.*(\/case)).*$/

  const showLicenseAgreementModal = !_.props?.currentUser.isEmpty() && isUserExternal && !isLicenseAgreementAccepted && connection

  return (
    <>
      <CacheBuster isconnected={connection}>
        <Offline />
        {showLicenseAgreementModal &&
          <LicenseAgreementModal
            showModal={showLicenseAgreementModal}
          />
        }
        <Router exact>
          <IntlProvider
            locale={currentLanguage}
            defaultLocale={'en'}
            messages={getMessages()}
          >
            <ServiceWorkerUpdateModal />
            <div id='app' className='App page-layout'>
              <OfflineBar />
              <TheEnvironmentBar />
              <Route
                path={notcase}
                render={() => <TheUserBar childProps={childProps} />}
              />
              <Route
                path={notcase}
                render={() => (
                  <TheHeader
                    childProps={childProps}
                    isDisconnected={!connection}
                  />
                )}
              />
              <Routes childProps={childProps} />
              <Route
                path={notcase}
                render={() => (
                  <TheFooter isUserExternal={isUserExternal} />
                )}
              />
              <TheToTop />
            </div>
          </IntlProvider>
        </Router>
      </CacheBuster>
    </>
  )
}

export default App
