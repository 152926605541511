import React, { useState, useEffect } from "react";
import MainContainer from "components/common/MainContainer";
import PageHeader from "components/common/PageHeader";
import ContentContainer from "components/common/ContentContainer";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import BaseInput from 'components/common/BaseInput'
import BaseButton from 'components/common/BaseButton'
import SearchSvg from 'components/svg/Search'
import CircleClearSearchSvg from 'components/svg/CircleClearSearch'
import SvgDownloadPdf from 'components/svg/DownloadPdf';
import ArrowDownSorting from 'components/svg/ArrowDownSorting'
import classnames from 'classnames'
import TableCellBasic from 'components/common/TableCellBasic'
import { injectIntl } from "react-intl";
import httpService from "core/http-service";

const LegacyReportSearch = ({ loading, connection, ...props }) => {
  const headers = [
    { displayName: props.intl.formatMessage({ id: "legacyreports.id" }), sortable: false },
    { displayName: props.intl.formatMessage({ id: "legacyreports.created" }), sortable: false },
    { displayName: props.intl.formatMessage({ id: "legacyreports.title" }), sortable: false },
    { displayName: props.intl.formatMessage({ id: "legacyreports.download" }), sortable: false }
  ]

  const [searchTerm, setSearchTerm] = useState("");
  const [resultEmpty, setResultEmpty] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    
  }, []);

  const search = () => {
    setIsLoading(true);
    setResultEmpty(false);
    httpService.get("/us-inspectiondata/documents", { q: searchTerm }).then(response => {
      setTableData(response.data);
      setResultEmpty(response.data.length === 0);
      setIsLoading(false)
    }).catch(err => {
      setIsLoading(false)
      console.log(err);
    })
  }

  const checkIfMissing = url => {
    return !(url.includes("com.intricateapplications.skf.prod") || url.includes("bart-usinpsection-legacypdfs"))
  }

  const checkIfMigrated = url => {
    return !url.includes("bart-usinpsection-legacypdfs");
  }

  return (
    <MainContainer>
      <PageHeader header={<FormattedMessage id="legacyreports.header" />} />
      
      <ContentContainer useFrame={true}>
        <div className="case-search-box__container">
          <div className="case-search-box__search-with-results--wrapper">
            <div className="case-search-box__search-with-results">
              <BaseInput
                className="case-search-box__search_input input"
                value={searchTerm}
                handleChange={event => setSearchTerm(event.target.value)}
                placeholder={props.intl.formatMessage({ id: "legacyreports.searchplaceholder" })}
                textToLeft={true}
                iconToRight={true}
                iconComponent={searchTerm.length > 0 ?
                  <CircleClearSearchSvg onClick={ () => setSearchTerm("") }
                    className="input-shell__svg input-shell__svg--right case-search-box__clear-svg"
                    color="#86989C" /> : null
                }
              />
            </div>
            <BaseButton
              text="Search"
              className={classnames("case-search-box__submit-button button--has-icon button--icon-to-left", { "element--is-loading element--is-loading-after": isLoading })}
              textToLeft={false}
              iconToRight={false}
              handleSubmit={search}
              iconComponent={<SearchSvg />}
            />
          </div>
        </div>
        <div className="table-wrapper">
          {tableData && tableData.length > 0 ? (
            <table className={classnames('table', { 'table--layout-fixed': props.fixedLayout }, props.className)}>
              <thead>
                <tr className="table__header-row">
                  {headers.map((header, index) =>
                    <th key={index} className="table__header-cell">
                      <div className={classnames('table__header-inset', { 'table__header-inset--button': header.sortable }, { 'table__header-inset--align-right': header.alignRight })}>{header.displayName}</div>
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {tableData.map((row, i) =>
                  <tr className="table__body-row" key={row.id} data-row-id={row.id}>
                    <TableCellBasic cellValue={String(row.id)} />
                    <TableCellBasic cellValue={new Date(row.created).toLocaleDateString()} noWrapClass={true} />
                    <TableCellBasic cellValue={row.title} />
                    <TableCellPdfLink intl={ props.intl } url={row.url} isMissing={ checkIfMissing(row.url) } isNotMigrated={ checkIfMigrated(row.url) } />
                  </tr>
                )}
              </tbody>
            </table>
          ) : null}
          {resultEmpty ? (
            <div><FormattedMessage id="legacyreports.noresult" /></div>
          ) : null }
        </div>
      </ContentContainer>
    </MainContainer>
  );
};


const TableCellPdfLink = ({ url, intl, isMissing = false, isNotMigrated = false }) => {
  return (
    <td className="table__body-cell">
      <div className="table__body-inset table__body-cell-pdf">
        <a href={url} download target="_blank"><SvgDownloadPdf
          title={isMissing ? intl.formatMessage({ id: "legacyreports.download.missing" }) : isNotMigrated ? intl.formatMessage({ id: "legacyreports.download.notmigrated" }) : intl.formatMessage({ id: "legacyreports.download.ok" })}
          color={isMissing ? "#EB202A" : isNotMigrated ? "#FF8004" : "#88C008"} /></a>
      </div>
    </td>
  )
}

const mapStateToProps = (state) => ({
  loading: state.caseRelated.loading,
  connection: state.connection
});

export default connect(mapStateToProps)(injectIntl(LegacyReportSearch));
