import { useIntl } from "react-intl";
import React from "react";
import { useSelector } from "react-redux";
import fieldConfig from "./../../../Configs/USInspection/USInspection-Recommendations.json";
import SvgCircleCheck from "components/svg/CircleCheck";

const UsRecommendations = ({ config, data }) => {
    const intl = useIntl()
    const scrollTarget = useSelector(state =>state.saveComponentValue.scrollTarget)
    const caseWasSaved = useSelector(state =>state.saveComponentValue.caseWasSaved)

    const findConfigFor = (section) => config.find(k => k.key.endsWith(section))

    const recommendationConfig = findConfigFor('recommendations')
    const recommendationData = data[recommendationConfig.key];

    const options = fieldConfig.components[0].args[0].options;

    const hasScrollTarget = key => {
        if (!scrollTarget) return false

        if (scrollTarget.fieldTarget || scrollTarget.scrollTarget) {
            return (scrollTarget.fieldTarget === key || scrollTarget.scrollTarget.includes(key)) && !caseWasSaved
        }
    }

    const currentBearingIndex = config && config[0]?.index;

    const skfEquivalentConfig = findConfigFor('skfEquivalent')
    const bearingDesignationConfig = `bearingInvestigations.bearingInvestigation[${currentBearingIndex}].bearingType.BearingDesignationComponent`;
    const skfEquivalentValue = data[bearingDesignationConfig]?.skfEquivalent;

    const renderRecommendations = recommendationData => (
        <>
            {(recommendationData || hasScrollTarget(recommendationConfig.key)) && (
                <div className="live-preview-list-structure">
                    <div className="live-preview-key-value">
                        <div className="live-preview-key-value__header__without_background_color">{intl.formatMessage({ id: "recommendations" })}</div>
                    </div>
                    <div className="live-preview-images">
                        <div className="live-preview-key-value__row">
                            <div className="live-preview-key-value__cell" data-key-id={recommendationConfig.key}>
                                <div className="live-preview__recommendations">
                                    {options.map((option, index) => (
                                        <div key={index} className="live-preview-key-value__key recommendation-option">
                                            <SvgCircleCheck checked={recommendationData && option.value === recommendationData.value ? true : false} size="28px" />
                                            <span>{intl.formatMessage({ id: option.label })}</span>
                                        </div>
                                    ))}
                                </div>
                                {recommendationData && recommendationData.value && (
                                    <div className="live-preview-key-value__value">
                                        {intl.formatMessage({ id: recommendationData.value + ".description" })}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {(skfEquivalentValue || hasScrollTarget(`${bearingDesignationConfig}.skfEquivalent`)) && (
                <div className="live-preview-key-value">
                    <div className="live-preview-key-value__row">
                        <div
                            className="live-preview-key-value__cell"
                            data-key-id={skfEquivalentConfig.key}
                        >
                            <div className="live-preview-key-value__key">
                                {intl.formatMessage({ id: "skfEquivalent" })}:
                            </div>
                            <div className="live-preview-key-value__value">
                                {skfEquivalentValue}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )

    return renderRecommendations(recommendationData)
}

export default UsRecommendations