import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter} from 'react-router-dom'
import classnames from 'classnames'
import queryString from 'query-string'
import BaseButton from './BaseButton'
import { useMediaQuery } from 'react-responsive'
import { TO_MEDIUM } from 'core/ui/breakpoints'

export const pageSize = 10 // Set pagination page size

const getPageCount = (total, limit) =>
  Math.ceil(total/limit)

const Pagination = ({
  className,
  currentPage,
  pageCount,
  history
}) => {

  const toMedium = useMediaQuery({ 
    query: TO_MEDIUM
  })
  
  if (pageCount <= 1) { return null }
  
  const handlePageChange = newPage => {
    let URLSearch = queryString.parse(history.location.search)
    if (newPage === 1) {
      delete URLSearch['page']
      history.push(Object.keys(URLSearch).length > 0
      ? '?' + queryString.stringify(URLSearch)
      : history.location.pathname)
    } else {
      URLSearch = { ...URLSearch, page: String(newPage) }
      history.push('?' + queryString.stringify(URLSearch))
    }
  }
  
  const getPageRangeToDisplay = (pageCount, currentPage, maxPages) => {
    let first = 1, last = maxPages
    
    if (pageCount < maxPages) { 
      last = pageCount
      return { first, last }
    }
    
    const halfOfMax = Math.floor(maxPages/2)
    if (currentPage > halfOfMax) {
      first = currentPage - halfOfMax
      last = pageCount < currentPage + halfOfMax ? pageCount : currentPage + halfOfMax 
      return { first, last }
    }
    
    return { first, last }
  }

  const pageRange = getPageRangeToDisplay(pageCount,
    currentPage, toMedium ? 5 : 9) // 9 instead of 10 (in wireframes) to prevent unneccesary complicated logic
  
  const getPages = () => {
    const pages = []
    for (let i = pageRange.first; i <= pageRange.last; i++) {
      pages.push(i)
    }
    
    return pages
  }

  return (
    <div className={classnames('pagination', className)}>
      <BaseButton
        className="pagination__prev--button button--has-icon"
        disabled={pageRange.first === currentPage}
        handleSubmit={() => handlePageChange(currentPage - 1)}
        iconComponent={(
          <svg width="9" height="14" viewBox="0 0 9 14" xmlns="http://www.w3.org/2000/svg">
            <path strokeWidth="2" d="M8 1L2 7l6 6" stroke="#0F58D6" fill="none" fillRule="evenodd"/>
          </svg>
        )} />

      <div className="pagination__filler" />
      <div className="pagination__pages-container">
      { getPages().map(page => (
        <BaseButton
          className={classnames("pagination__page--button", page === currentPage ? "current-page" : "")}
          key={`pagination-page-button-${page}`}
          text={String(page)}
          disabled={page === currentPage}
          handleSubmit={() => handlePageChange(page)} />
        ))
      }
      </div>
      <div className="pagination__filler" />

      <BaseButton
        className="pagination__next--button button--has-icon"
        disabled={pageRange.last === currentPage}
        handleSubmit={() => handlePageChange(currentPage + 1)}
        iconComponent={(
          <svg width="9" height="14" viewBox="0 0 9 14" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 13l6-6-6-6" fillRule="nonzero" stroke="#0F58D6" strokeWidth="2" fill="none"/>
          </svg>
        )} />
    </div>
  )
}

Pagination.propTypes = {
  className: PropTypes.string,
  currentPage: PropTypes.number,
  pageCount: PropTypes.number,
  history: PropTypes.object
}

const mapStateToProps = state => ({
  pageCount: getPageCount(state.caseRelated.total, pageSize)
})

export default withRouter(connect(mapStateToProps)(Pagination))