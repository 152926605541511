import getHttpRepository from "./HttpRepository";

export class CaseDataRepository { 

    constructor(offlineRepository) { 
        this.offlineRepository = offlineRepository;
    }

    async syncCase(caseId, events, imageUrls) { 
        return getHttpRepository(true).post(`offlinesync/case/${caseId}`, { events, imageUrls });
    }

    async getCase(caseId) { 
        const caseData = await getHttpRepository(true).get(`case/${caseId}`);
        await this.offlineRepository.addOrUpdateCase(caseId, caseData.data);
        return caseData;
    }

    async addCase(templateId, answers, confidential = false, language = 'en', domain) {
        const caseData = await getHttpRepository(true).put(
            'case',
            {
                case: {
                    template: templateId,
                    confidential: confidential,
                    language: language,
                    domain,
                },
                answers,
                template: templateId
            }
        )
        await this.offlineRepository.addOrUpdateCase(caseData.data.id, caseData.data, );
        return caseData;
    }

    async cloneCase(caseId) { 
        return getHttpRepository(true).get(`case/${caseId}/clone`);
    }

    async deleteCase(caseId, updatedAt) { 
        await getHttpRepository(true).delete(`case/${caseId}`);
        await this.offlineRepository.deleteCase(caseId, updatedAt);
    }

    async submitCase(caseId) { 
        return getHttpRepository(true).post(`case/${caseId}/status/submit`);
    }

    async rejectCase(caseId) { 
        return getHttpRepository(true).post(`case/${caseId}/status/reject`);
    }

    async editCase(caseId) { 
        return getHttpRepository(true).post(`case/${caseId}/status/draft`);
    }

    async approveCase(caseId) { 
        return getHttpRepository(true).post(`case/${caseId}/status/approve`);
    }

    async approvalEditCase(caseId) { 
        return getHttpRepository(true).post(`case/${caseId}/status/approvalEdit`);
    }
    
    
    async restoreCase(caseId) { 
        return getHttpRepository(true).post(`case/${caseId}/undodelete`);
    }

    async getCaseData(caseId) { 
        const answers = await getHttpRepository(true).get(`case/${caseId}/answer`);
        await this.offlineRepository.setCaseData(caseId, answers);
        return answers;
    }

    async getCaseHistory(caseId) { 
        return getHttpRepository(true).get(`history/case/${caseId}`);
    }
    
    async getCaseAccess(caseId) {
        const access = await getHttpRepository(true).get(`case/${caseId}/access`);
        await this.offlineRepository.setCaseAccess(caseId, access);
        return access;
    }
    
    async addAnswer(caseId, answer) { 
        const savedAnswer = await getHttpRepository(true).put(`case/${caseId}/answer`, answer);
        await this.offlineRepository.addOrUpdateAnswer(caseId, savedAnswer.data.id, savedAnswer.data);
        return savedAnswer;
    }

    async updateAnswer(caseId, answerId, answer) { 
        const updatedAnswer = await getHttpRepository(true).post(`case/${caseId}/answer/${answerId}`, answer);
        await this.offlineRepository.addOrUpdateAnswer(caseId, updatedAnswer.data.id, updatedAnswer.data);
        return updatedAnswer;
    }

    async updateMultipleAnswers(caseId, answersToSave) { 
        const updatedAnswers = await getHttpRepository(true).post(`case/${caseId}/answers`, answersToSave);
        await this.offlineRepository.updateMultipleAnswers(caseId, { answersToUpdate: updatedAnswers.data, answersToAdd: []});
        return updatedAnswers;
    }

    async updateCaseData(caseId, caseData) { 
        const updatedCasedata = await getHttpRepository(true).post(`case/${caseId}`, caseData);
        await this.offlineRepository.addOrUpdateCase(updatedCasedata.data.id, updatedCasedata.data);
        return updatedCasedata; 
    }

    getCasesDraftForUser(numberOfCases) { 
        return getHttpRepository(true).get(`case/mycasesindraft/${numberOfCases}`);
    }

    getFormTemplate(templateId) { 
        return getHttpRepository(true).get(`formtemplate/${templateId}`);
    }

    getFormTemplates(templateIds) { 
        return getHttpRepository(true).get(`formtemplate/formtemplates/${templateIds}`);
    }

    async moveBearing(caseId, currentIndex, newIndex, count) { 
        const result = await getHttpRepository(true).post(`case/${caseId}/answer/bearing/move/${currentIndex}/${newIndex}`);
        await this.offlineRepository.moveBearing(caseId, currentIndex, newIndex, count);
        return result;
    }

    async removeBearing(caseId, bearingIndex, newCount) { 
        const result = await getHttpRepository(true).post(`case/${caseId}/answer/bearing/remove/${bearingIndex}`);
        await this.offlineRepository.removeBearing(caseId, bearingIndex, newCount);
        return result;
    }

    async duplicateBearing(caseId, bearingIndex, newCount) { 
        const result = await getHttpRepository(true).post(`case/${caseId}/answer/bearing/duplicate/${bearingIndex}`);
        await this.offlineRepository.duplicateBearing(caseId, bearingIndex, newCount);
        return result;
    }

    postImage(id, file) {
        return getHttpRepository(false).post(`case/${id}/image`, { file });
    }

    getList(endpoint) { 
        return getHttpRepository(false).get(endpoint);
    }    

    async convertInspectionToBDA(caseId, formtemplateId, bearingsToMove) {
        return getHttpRepository(true).post(`case/${caseId}/convert`, {
            formtemplateId,
            bearingsToMove
        });
    }
}
