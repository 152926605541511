import React, { useState } from "react"
import getComponent from "components/_Case/ComponentsList"

// using this eslint blockers to opt out translation messages for numbers
// eslint-disable-next-line
const consoleError = console.error.bind(console)
// eslint-disable-next-line
console.error = (message, ...args) => {
  if (typeof message === 'string' && message.startsWith('[React Intl]')) return
  // consoleError(message, ...args)
}

export default function MachineNumberComponent(props) {
  const [machineNumber, setMachineNumber] = useState(null)

  const changeHandler = (value, name) => {
    setMachineNumber(value ? { ...value } : null)
    saveValues(value, props.myName)
  }

  const saveValues = (value, name) => {
    if (props.parentHandleSave) {
      props.parentHandleSave(value, name)
    } else {
      props.setValue(value, name)
    }
  }

  const renderMachineNumber = () => {
    const machineNumberOptions = []
    for (let i = 1; i <= 50; i++) {
      machineNumberOptions.push({
        label: i.toString(),
        value: i.toString(),
      })
    }
    const machineNumberConfigData = {
      "key": "machineNumber",
      "type": "AutoCompleteCreatable",
      "description": "Machine number",
      "args": [{ "options": machineNumberOptions }]
    }

    return getComponent(
      machineNumberConfigData,
      props.myName,
      changeHandler,
      machineNumber,
    )
  }

  return <>{renderMachineNumber()}</>
}