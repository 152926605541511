import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { useMediaQuery } from "react-responsive";
import { selectQuickLink, setSelectedBearing } from "./../../redux/actions/saveComponentValueAction";
import { TO_MEDIUM } from "core/ui/breakpoints";

import classnames from "classnames";
import ItemsCarousel from 'react-items-carousel';
import BaseButton from "../common/BaseButton";



const BearingQuickLinks = ({ formTemplateId }) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const isDevice = useMediaQuery({
        query: TO_MEDIUM,
    });
    
    const [bearingCount, setBearingCount] = useState(0);
    const [showQuicklinks, setShowQuicklinks] = useState(false);
    const [quicklinks, setQuicklinks] = useState([]);
    const [numberOfCards, setNumberOfCards] = useState(7);
    const [activeItemIndex, setActiveItemIndex] = useState(0);

    const bearingCountAnswer = useSelector(state => state.saveComponentValue.components.find(a => a.name === "bearingInvestigations.count"), shallowEqual);
    const quickLinksState = useSelector(state => state.saveComponentValue.quicklinks.target, shallowEqual)
    const selectedBearing = useSelector(state => state.saveComponentValue.selectedBearing)

    useEffect(() => {
        if (bearingCountAnswer) {
            const bearingCount = JSON.parse(bearingCountAnswer.value);
            setBearingCount(bearingCount);
        }
    }, [bearingCountAnswer]);

    useEffect(() => {
        if (isDevice) {
            setNumberOfCards(13);
        }
    }, [isDevice]);

    useEffect(() => {
        if (bearingCount > 2) {
            setShowQuicklinks(true);
            setQuicklinks(Array.apply(null, Array(bearingCount)).map((i, index) => ({
                index: index,
                isSelected: selectedBearing >= 0 ? index === selectedBearing : false,
            })))
        } else {
            setShowQuicklinks(false);
        }
    }, [bearingCount, selectedBearing]);
    
    const handleQuicklink = quicklink => {
        dispatch(setSelectedBearing(quicklink.index));
        dispatch(selectQuickLink({
            ...quicklink,
            offset: window.scrollY, 
            formTemplateId: formTemplateId,
            accordionTarget: quickLinksState.accordionTarget,
        }));
    }

    const renderQuicklink = quicklink => {
        return (
            <BaseButton
                key={quicklink.index}
                text={String(quicklink.index + 1)}
                className={classnames("button--background-green", { 'button-selected': quicklink.isSelected })}
                handleSubmit={event => handleQuicklink(quicklink)}
            />
        );
    }

    return showQuicklinks ? (
        <div className="case-bearingquicklinks-actions">
            <h4>{intl.formatMessage({ id: "bearingquicklinks" })}</h4>
            <ItemsCarousel
                requestToChangeActive={index => setActiveItemIndex(index)}
                activeItemIndex={activeItemIndex}
                numberOfCards={numberOfCards}
                gutter={10}
                leftChevron={<button>{'<'}</button>}
                rightChevron={<button>{'>'}</button>}
                outsideChevron={false}
                classes={{ rightChevronWrapper: "chevron-right", leftChevronWrapper: "chevron-left" }}
            >
                {showQuicklinks && quicklinks.map(q => renderQuicklink(q))}
            </ItemsCarousel>
        </div>
    ) : null;
}

export default BearingQuickLinks;