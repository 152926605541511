import uuid from "uuid";

export const getBearingIndexFromName = name => {
    const firstSplit = name.split("[");
    if (firstSplit.length > 1) { 
        return firstSplit[1].split("]")[0];
    }
    return -1;
}


export const getBearingAnswerObjFromName = name => {
    const firstSplit = name.split("[");
    if (firstSplit.length > 1) {
        const secondSplit = firstSplit[1].split("]");
        if (secondSplit.length > 1) {
            return {
                part1: firstSplit[0],
                part2: secondSplit[1],
                index: parseInt(secondSplit[0])
            }
        }
    }
}

const getBearingAnswerNameFromObj = obj => {
    return `${obj.part1}[${obj.index}]${obj.part2}`;
}

export const removeBearingFromAnswers = (answers, indexToRemove, newCount) => {
    answers = answers.filter(answer => !answer.name.includes(`bearingInvestigations.bearingInvestigation[${indexToRemove}]`) && answer.name !== "bearingInvestigations.count");
    answers.forEach(a => {
        const nameObj = getBearingAnswerObjFromName(a.name);
        if (nameObj && nameObj.index > indexToRemove) {
            nameObj.index = nameObj.index - 1;
            a.name = getBearingAnswerNameFromObj(nameObj);
        }
    })
    answers.push({ name: "bearingInvestigations.count", value: String(newCount), index: -1 });
    return answers;
}

export const duplicateBearingAnswers = (answers, indexToDuplicate, newCount) => {
    const newAnswers = [];
    answers = answers.reduce((acc, a) => {
        if (a.name.includes(`bearingInvestigations.bearingInvestigation[${indexToDuplicate}]`)) {
            acc.push(a);
            const newAnswer = { value: removeImages(a.value), name: a.name.replace(`${indexToDuplicate}`, `${newCount - 1}`), id: uuid.v4(), index: newCount - 1 };
            acc.push(newAnswer);
            newAnswers.push(newAnswer);
        } else if (a.name !== "bearingInvestigations.count") {
            acc.push(a);
        }
        return acc;
    }, []);
    answers.push({ name: "bearingInvestigations.count", value: String(newCount), index: -1 });
    return { updatedAnswers: answers, newAnswers };
}

const removeImages = value => {
    if (value?.includes("imageUrls")) {
        const obj = JSON.parse(value);
        obj.imageUrls = [];
        return JSON.stringify(obj);
    }
    return value;
}

export const moveBearingAnswers = (answers, currentPosition, newPosition, count) => {
    if (newPosition > -1 && newPosition < count) {
        return answers.map(a => {
            if (a.name.includes(`bearingInvestigations.bearingInvestigation[${currentPosition}]`)) {
                a.name = a.name.replace(`${currentPosition}`, `${newPosition}`)
                a.index = newPosition;
            } else if (a.name.includes(`bearingInvestigations.bearingInvestigation[${newPosition}]`)) {
                a.name = a.name.replace(`${newPosition}`, `${currentPosition}`);
                a.index = currentPosition;
            }
            return a;
        });
    } else {
        return answers;
    }
}

export const getBearingsForRemanAndReplacement = answers => {
    const recommendationAnswersPattern = /bearingInvestigations\.bearingInvestigation\[(\d+)\]\.recommendations\.recommendations/;

    return answers.filter(answer => {
        const regexResult = recommendationAnswersPattern.test(answer.name)
        if (regexResult) {
            const value = JSON.parse(answer.value);
            return value?.label === 'Replace bearing' || value?.label === 'Potential for remanufacturing';
        }
        return false;
    });
}
