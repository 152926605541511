import React, { useState } from 'react'
import PropTypes from 'prop-types'
import TableCellOverlay from "components/common/TableCellOverlay"
import TableMobileRow from "components/common/TableMobileRow"
import _ from 'lodash'
import classnames from 'classnames'
import { injectIntl } from "react-intl"; 

const BaseTableMobile = ({tableData, intl}) => {
  const [openAccordions, setOpenAccordions] = useState([])

  const toggleAccordion = (id) => {
    setOpenAccordions(_.xor([id], openAccordions))
  }

  return (
    <div className="table-mobile">
      {tableData.map((row, i) => 
        <div key={row.id} className="accordion accordion--mobile-table">
          <button className={classnames('accordion__trigger', 'table-mobile-accordion__toggler', { 'accordion__trigger--is-open': openAccordions.includes(`table-mobile-row-${i}`)})} onClick={() => toggleAccordion(`table-mobile-row-${i}`)}>
            <div className="table-mobile-accordion__toggle-report-id">{row.id}</div>
            <div className="table-mobile-accordion__toggle-title">{row.title}</div>
          </button>

          <div className={classnames('accordion__content', {'accordion__content--is-open': openAccordions.includes(`table-mobile-row-${i}`) })}>
            <TableMobileRow translationKey="table.header.date" value={row.updated} />
            <TableMobileRow translationKey="table.header.customer" value={row.customer} />
            <TableMobileRow translationKey="table.header.reportTitle" value={row.title} />
            <TableMobileRow translationKey="table.header.author" value={row.author} />
            <TableMobileRow translationKey="table.header.status" value={intl.formatMessage({ id: "preview." + row.status })} originalValue={ row.status } isStatus={true} />
            <TableMobileRow translationKey="table.header.confidential" isConfidential={row.confidential} />

            <div className="table-mobile-accordion__actions">
              <TableCellOverlay caseId={row.id} status={row.status}/>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

BaseTableMobile.propTypes = {
  tableData: PropTypes.array
}

export default injectIntl(BaseTableMobile)
