import React from "react";

const SvgAccount = props => (
  <svg
    width={14}
    height={14}
    viewBox="0 0 14 14"
    className={`svg ${props.className}`}
  >
    <path
      d="M9.94 8.432s-.466-.046-.612 0c-.777.25-1.466.486-2.348.486-.883 0-1.572-.236-2.349-.486-.157-.05-.665 0-.665 0C1.785 8.432 0 10.147 0 12.243v.323c0 .711.606 1.293 1.346 1.293H12.56c.74 0 1.345-.582 1.345-1.293v-.323c0-2.096-1.784-3.81-3.965-3.81m.776-4.67a3.763 3.763 0 11-7.525 0 3.763 3.763 0 017.525 0"
      fill="#0F58D6"
      fillRule="evenodd"
    />
  </svg>
);

export default SvgAccount;
