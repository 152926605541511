import { Auth } from "aws-amplify";
import axios from "axios";
import _ from "lodash";
import config from "config";
import localforage from "localforage";
import { store } from "../index.js";

const baseURL = config.API_URL;
const apiKey = config.API_KEY;

function get(endpoint, params) {
  return xhr("get", endpoint, { params });
}

function deleteRequest(endpoint, params) {
  return xhr("delete", endpoint, { params });
}

function getEndpoint(endpoint) {
  return xhr("get", endpoint, null, true);
}

function post(endpoint, data) {
  return xhr("post", endpoint, { data });
}

function put(endpoint, data, params = {}) {
  return xhr("put", endpoint, { data, params });
}

async function xhr(method, endpoint, settings = {}, otherEndpoint) {
  if (store.getState().connection) {
    if (!otherEndpoint) {
      var user = await Auth.currentAuthenticatedUser();
      const defaultSettings = {
        headers: {
          Authorization: user.signInUserSession.idToken.jwtToken,
          "x-api-key": apiKey,
        },
        method,
        url: `${baseURL}/${endpoint}`
      };

      const requestConfig = _.assignIn(defaultSettings, settings);

      var response = await axios(requestConfig);
      var responseData = {
        data: {}
      };
      responseData.data = response.data;

      if (method === "get") {
        localforage.setItem(endpoint, responseData);
      }
      return response;
    } else {
      return axios.get(endpoint);
    }
  } else {
    let offlineResponse = await localforage.getItem(endpoint);

    // bugfix for empty endusers dropdown in offline
    if (offlineResponse === null && endpoint && endpoint[0] === '/') {
      offlineResponse = await localforage.getItem(endpoint.substring(1));
    }
    return offlineResponse;
  }
  
}

export default { get, getEndpoint, post, put, deleteRequest };
