import React from "react";

const SvgPlus = props => (
    <svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">  
        <g id="Icons/Add" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path d="M6,12 C6.64788732,12 7.15492958,11.4929577 7.15492958,10.8450704 L7.15492958,7.15492958 L10.8450704,7.15492958 C11.4929577,7.15492958 12,6.64788732 12,6 C12,5.35211268 11.4929577,4.84507042 10.8450704,4.84507042 L7.15492958,4.84507042 L7.15492958,1.15492958 C7.15492958,0.507042254 6.64788732,0 6,0 C5.35211268,0 4.84507042,0.507042254 4.84507042,1.15492958 L4.84507042,4.84507042 L1.15492958,4.84507042 C0.507042254,4.84507042 0,5.35211268 0,6 C0,6.64788732 0.507042254,7.15492958 1.15492958,7.15492958 L4.84507042,7.15492958 L4.84507042,10.8450704 C4.84507042,11.4929577 5.35211268,12 6,12 Z" id="+" fill="#FFFFFF" fillRule="nonzero"></path>
        </g>
    </svg>
);

export default SvgPlus;