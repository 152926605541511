import FeatureUserController from "./FeatureUserController";

export default class FeatureDetector { 
    constructor(toggleConfig) { 
        this.toggleConfig = toggleConfig;
    }

    isFeatureEnabled = (feature) => { 
        const featureDef = this.toggleConfig.features.find(f => f.name === feature);
        if (featureDef) {
            if (this.isBoolean(featureDef.featureEnabled)) {
                return new Promise(resolve => resolve([featureDef.featureEnabled]));
            } else { 
                return Promise.all(featureDef.featureEnabled.map(fe => this.getController(fe)).map(c => c.isFeatureEnabled()))
            }
        } else { 
            return new Promise(resolve => resolve(false));
        }
    }

    getController = (definition) => { 
        switch (definition.type) {
            case "user":
                return new FeatureUserController(definition);
            default:
                return new NopeController(definition);
        }
    }

    isBoolean = (value) => value !== null && value.constructor.name === "Boolean";   
}

class NopeController { 
    isFeatureEnabled = (feature) => false
}
