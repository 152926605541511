import {
  SAVE_COMPONENT_VALUE,
  SET_ALL_ANSWERS,
  SET_ALL_ANSWERS_BEFORE_SAVE,
  REMOVE_COMPONENT_VALUE,
  USE_AUTOMATIC_FIGURENUMBERING,
  SELECT_QUICKLINK,
  RECORD_QUICKLINK_POSITION,
  SET_SCROLL_TARGET,
  SET_DISABLE_AUTOSCROLL,
  SET_VALIDATION_ERRORS,
  REMOVE_VALIDATION_ERRORS_BEARING,
  REMOVE_VALIDATION_ERROR,
  ADD_VALIDATION_ERROR,
  SET_CURRENTCASE_FORMTEMPLATE,
  DISMISS_ANSWER_ERROR,
  SAVE_COMPONENT_VALUES,
  SET_ANSWER_TO_SAVE,
  REMOVE_ANSWER_TO_SAVE,
  REMOVE_ANSWERS_TO_SAVE,
  CLEAR_ANSWERS_TO_SAVE,
  TRIGGER_SAVE_ON_ACCORDIAN,
  SET_LIVEPREVIEW_TARGET,
  SET_SELECTED_BEARING,
  SET_IMAGE_TO_DELETE,
  SET_CONVERTED_TO_BDA_CASEID,
  SET_MOVED_BEARINGS,
} from 'redux/types'
import initialState from 'redux/initialState'

import getNameFromScrollTarget from "./../effects/saveComponentValueEffects/scrollTargetEffects";
import { getQuicklinkPositions, getQuickLinkTarget } from "./../effects/saveComponentValueEffects/quicklinksEffects";
import { getImageUrlsFromState, addImageNumbering, combineImagesFromComponentTypSave, isAutomaticFigureNumbering, addImageNumberingToComponentsToSave } from "./../effects/saveComponentValueEffects/imageNumberingEffects";
import { addUpdateComponent, clearComponentErrorById } from "./../effects/saveComponentValueEffects/addUpdateComponentsEffect";
import { validateComponents, removeValidationErrorsForBearing, removeValidationError, addValidationError } from "./../effects/saveComponentValueEffects/handleValidationEffect";
import { setReportTitle } from "./../effects/saveComponentValueEffects/setReportTitle";
import { addAnswerToSave, removeAnswerToSave, removeAnswersToSave, triggerSaveOnAccordian } from "redux/effects/saveComponentValueEffects/answersToSaveEffects";
import { setApprover } from 'redux/effects/saveComponentValueEffects/setApprover';
import { setSystemTypeMachineNumber } from 'redux/effects/saveComponentValueEffects/setSystemNameMachineNumber';

export default function saveComponentValueReducers(state = initialState.saveComponentValue, action) {

  switch (action.type) {
    case SAVE_COMPONENT_VALUE:
      if (action.payload.value !== undefined && action.payload.value !== null) {
        let saveComponentComponents = addUpdateComponent(state.components, action.payload);
        
        if (saveComponentComponents.length === state.components.length) {
          return {
            ...state,
            components: saveComponentComponents,
            images: action.payload.value.includes("imageUrls") ? getImageUrlsFromState(saveComponentComponents) : state.images,
            validationErrors: validateComponents(state.validationErrors, saveComponentComponents)
          }
        } else {
          return {
            ...state,
            components: saveComponentComponents,
            images: getImageUrlsFromState(saveComponentComponents),
            validationErrors: validateComponents(state.validationErrors, saveComponentComponents)
          }
        }
      }
      break;
    case SAVE_COMPONENT_VALUES:
      let saveComponentsComponents = action.payload.reduce((components, component) => [...addUpdateComponent(components, component)], state.components);
      return {
        ...state,
        components: saveComponentsComponents,
        images: getImageUrlsFromState(saveComponentsComponents),
        validationErrors: validateComponents(state.validationErrors, saveComponentsComponents)
      }
    case SET_ALL_ANSWERS_BEFORE_SAVE:
      return {...state, componentsBeforeSave: action.payload.answers}
    case SET_ALL_ANSWERS:
      return Object.assign({}, state, {
        components: action.payload.answers,
        caseId: action.payload.caseId,
        images: getImageUrlsFromState(action.payload.answers),
        useAutomaticFigureNumbering: isAutomaticFigureNumbering(action.payload.answers),
        quicklinks: {
          target: {},
          positions: []
        },
        scrollTarget: {}
      })
    case REMOVE_COMPONENT_VALUE:
      let index = state.components.findIndex(component => component.name === action.payload);
      if (index !== -1) {
        let updated = JSON.parse(JSON.stringify(state.components))
        updated.splice(index, 1);
        return {
          ...state,
          components: updated,
          images: getImageUrlsFromState(updated)
        }
      }
      break
    case USE_AUTOMATIC_FIGURENUMBERING:
      if (!state.useAutomaticFigureNumbering && action.payload) {
        //Add numbering
        return {
          ...state,
          componentsToSave: addImageNumberingToComponentsToSave(state.components, state.componentsToSave, { answer: { value: "imageUrls" } }),
          useAutomaticFigureNumbering: action.payload
        }
      } else {
        return {
          ...state,
          useAutomaticFigureNumbering: action.payload
        }
      }
    case SELECT_QUICKLINK:
      const newTarget = getQuickLinkTarget(action.payload, state.quicklinks.positions);
      return {
        ...state,
        quicklinks: {
          target: newTarget,
          positions: getQuicklinkPositions(state.quicklinks.positions, newTarget),
        },
        scrollTarget: getNameFromScrollTarget(newTarget.name, action.payload.formTemplateId, state.scrollTarget)
      };
    case RECORD_QUICKLINK_POSITION:
      return { ...state, quicklinks: { ...state.quicklinks, positions: getQuicklinkPositions(state.quicklinks.positions, action.payload) } };
    case SET_SCROLL_TARGET:
      return {
        ...state,
        scrollTarget: getNameFromScrollTarget(action.payload.id, action.payload.formTemplateId, state.scrollTarget),
        caseWasSaved: false,
      };
    case SET_LIVEPREVIEW_TARGET:
      return { ...state, livePreviewTarget: action.payload };
    case SET_DISABLE_AUTOSCROLL:
      return { ...state, disableAutoScroll: action.payload };
    case SET_VALIDATION_ERRORS:
      return { ...state, validationErrors: action.payload };
    case REMOVE_VALIDATION_ERRORS_BEARING:
      return { ...state, validationErrors: removeValidationErrorsForBearing(state.validationErrors, action.payload) };
    case REMOVE_VALIDATION_ERROR:
      return { ...state, validationErrors: removeValidationError(state.validationErrors, action.payload) };
    case ADD_VALIDATION_ERROR:
      return { ...state, validationErrors: addValidationError(state.validationErrors, action.payload) };
    case SET_CURRENTCASE_FORMTEMPLATE:
      return { ...state, formtemplateId: action.payload };
    case DISMISS_ANSWER_ERROR: 
      let updateComponents = clearComponentErrorById(state.components, action.payload);
      return { ...state, components: updateComponents };
    case SET_ANSWER_TO_SAVE:
      let componentsToSave = addAnswerToSave(state.componentsToSave, action.payload.answer);
      componentsToSave = setApprover(state.formtemplateId, state.components, componentsToSave, action.payload.answer);
      componentsToSave = setSystemTypeMachineNumber(state.formtemplateId, state.components, componentsToSave);
      componentsToSave = setReportTitle(state.formtemplateId, state.components, componentsToSave, action.payload.answer, action.payload.intl);
      componentsToSave = addImageNumberingToComponentsToSave(state.components, componentsToSave, action.payload);
      const images = combineImagesFromComponentTypSave(state.components, componentsToSave, state.images, action.payload);
      return {
        ...state,
        componentsToSave: componentsToSave,
        validationErrors: validateComponents(state.validationErrors, [action.payload.answer]),
        images: images,
        caseWasSaved: false,
      };
    case REMOVE_ANSWER_TO_SAVE:
      return { ...state, componentsToSave: removeAnswerToSave(state.componentsToSave, action.payload) };
    case REMOVE_ANSWERS_TO_SAVE:
      return {
        ...state,
        componentsToSave: removeAnswersToSave(state.componentsToSave, action.payload),
        caseWasSaved: true
      };
    case CLEAR_ANSWERS_TO_SAVE:
      return { ...state, componentsToSave: [] };
    case TRIGGER_SAVE_ON_ACCORDIAN:
      return { ...state, componentsToSave: triggerSaveOnAccordian(state.componentsToSave, action.payload) };
    case SET_SELECTED_BEARING:
      return { ...state, selectedBearing: action.payload }
    case SET_IMAGE_TO_DELETE:
      return { ...state, imageForDeletion: action.payload }
    case SET_CONVERTED_TO_BDA_CASEID:
      return { ...state, convertedToBDACaseId: action.payload }
    case SET_MOVED_BEARINGS:
      return { ...state, movedBearings: action.payload }
    default:
      return state
  }
}