import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from "react-intl"

const ReportNumber = ({data, revisionNumber, intl}) => {

  const formatRevisionNumber = (revNumber) => {
    if ( revNumber === 0 ) {
      return "00"
    } else {
      var n = revNumber.toString();
      var stringValue = n.split(".")
      stringValue =  stringValue[0] + stringValue[1]
      return  stringValue
    }
  }

  return (
    <div className="live-preview__report-number-container">
      <h3 className="live-preview__report-number">
        {intl.formatMessage({ id: 'case.livePreview.reportNumber' })}: {data}
      </h3>

      {/* <h3 className="live-preview__report-revision-number">
        {intl.formatMessage({ id: 'case.livePreview.reportStatus' })}: {reportStatus}
      </h3> */}

      <h4 className="live-preview__report-revision-number">
        {intl.formatMessage({ id: 'case.livePreview.revisionNumber' })}: {formatRevisionNumber(revisionNumber)}
      </h4>
    </div>
  )
}

ReportNumber.propTypes = {
  reportNumber: PropTypes.string,
  revisionNumber: PropTypes.number
}

export default injectIntl(ReportNumber)
