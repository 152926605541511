import React, { Component } from "react";
import CreatableSelect from "react-select/creatable";
import httpService from "core/http-service";
import classnames from "classnames";
import ReactSelectStyles from "components/formcomponents/ReactSelectStyles";
import ReactSelectSearchIndicator from "components/formcomponents/ReactSelectSearchIndicator";
import _ from "lodash";
import { injectIntl, FormattedMessage } from "react-intl";
import { connect } from "react-redux";


class AutoCompleteCreatableComponent extends Component {
  state = {
    value: "",
    options: [],
    newOption: ""
  };

  findValueInArray = (array, value) => {
    if (array !== undefined && value !== undefined) {
      return _.find(array, value);
    }
  };

  loadSavedDataAndOptions = () => {
    let savedValue;
    let hasApi = false;
    let hasConfigOptions = false;
    let hasValueLabelParams = false;

    if (
      this.props.parentSetValue !== undefined &&
      this.props.parentSetValue !== null
    ) {
      savedValue = this.props.parentSetValue;
    } else {
      savedValue = this.props.getValue(this.props.myName);
    }

    if (this.props.myName.includes('disposition')) {
      const dispositionComponent = this.props.components.find(component =>
        component.name === this.props.name || this.props.myName.includes(component.name)
      )

      if (dispositionComponent?.value) {
        const { disposition } = JSON.parse(dispositionComponent.value)
        this.setState({ value: { label: disposition.label, value: disposition.value } })
      }
    }

    if (savedValue) {
      this.setState({ value: savedValue });
    }

    if (!_.some(savedValue, e => e)) {
      this.setState({ value: null })
    }

    let apiPath = this.findValueInArray(
      this.props.formComponentData.args,
      "apiPath"
    );
    let valueLabelParams = this.findValueInArray(
      this.props.formComponentData.args,
      "valueLabelParams"
    );

    let configOptions = this.findValueInArray(
      this.props.formComponentData.args,
      "options"
    );

    let translationNeeded = this.props.formComponentData.translate

    if (apiPath !== undefined) {
      apiPath = apiPath.apiPath;
      hasApi = true;
    } else {
      hasApi = false;
    }

    if (valueLabelParams !== undefined) {
      valueLabelParams = valueLabelParams.valueLabelParams;
      hasValueLabelParams = true;
    } else {
      hasValueLabelParams = false;
    }

    if (configOptions !== undefined) {
      configOptions = configOptions.options;
      hasConfigOptions = true;
    } else {
      hasConfigOptions = false;
    }

    if (hasApi) {
      this.getOptionsFromAPI(apiPath, { "lang": this.props.language }).then(response => {
        if (response.data !== undefined) {
          let result = response.data.map(option => {
            if (hasValueLabelParams) {
              return {
                value: option[valueLabelParams.value],
                label: option[valueLabelParams.label]
              };
            } else {
              return { value: option, label: option };
            }
          });
          this.setState({ options: result }, () => {
            if (savedValue !== undefined) {
              if (!this.checkIfExistsInArray(savedValue, this.state.options)) {
                this.handleCreateNewOption(savedValue.value);
              } else {
                return null;
              }
            }
          });
        }
      });
    } else if (!hasApi) {
      if (hasConfigOptions) {
        let options = configOptions.map(option => {
          if (hasValueLabelParams) {
            return {
              value: option[valueLabelParams.value],
              label: this.props.intl.formatMessage({
                id: `${option[valueLabelParams.value]}`
              })
            };
          } else {
            if (translationNeeded === undefined) {
              return {
                value: option.value,
                label: this.props.intl.formatMessage({ id: `${option.value}` })
              };
            } else {
              return {
                value: option.value,
                label: option.label
              };
            }
          }
        });
        this.setState({ options: options }, () => {
          if (savedValue) {
            if (!this.checkIfExistsInArray(savedValue, this.state.options)) {
              this.handleCreateNewOption(savedValue.value);
            } else {
              return null;
            }
          }
        }
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  componentDidMount() {
    this.loadSavedDataAndOptions();
  }

  componentDidUpdate(prevProps) {
    if (prevProps && this.props.parentSetValue) {
      if (!this.props.parentSetValue && prevProps.parentSetValue) {
        this.loadSavedDataAndOptions();
      } else {
        if (prevProps.parentSetValue && prevProps.parentSetValue.value != this.props.parentSetValue.value) {
          this.loadSavedDataAndOptions();
        }
      }
    }
    if (prevProps && this.props.formComponentData.args) {
      if (!_.isEqual(this.props.formComponentData.args,
        prevProps.formComponentData.args)) {
        this.loadSavedDataAndOptions();
      }
    }

    // fix for dropdown not updating when reloading answers from server
    const answer = this.props.getValue(this.props.myName);
    if (answer && answer.value) {
      if (!_.isEqual(this.state.value, answer)) {
        this.setState({ value: answer });
      }
    }
  }

  checkIfExistsInArray(value, array) {
    let isFound = false;
    if (value !== undefined && array !== undefined) {
      for (let i = 0; i < array.length; i++) {
        if (array[i].value === value.value) {
          isFound = true;
          break
        }
      }
    }
    return isFound;
  }

  getOptionsFromAPI = (apiPath, params) => {
    if (apiPath !== undefined) {
      return httpService
        .get(apiPath, params)
        .then(response => {
          return response;
        })
        .catch(error => {
          return error;
        });
    }
  };

  handleChange = value => {
    if (_.isEqual(value, this.state.value)) return
    if (this.props.parentHandleSave !== undefined) {
      this.props.parentHandleSave(value, this.props.myName);
    } else {
      this.props.setValue(value, this.props.myName);
    }
    this.setState({ value: value });
  };

  handleCreateNewOption = inputValue => {
    // If the user creates their own option, it's added to the options-list.
    if (inputValue !== undefined && inputValue !== "") {
      const { options } = this.state;
      const newOption = { label: inputValue, value: inputValue };
      this.setState({
        options: [...options, newOption],
        newOption: newOption
      });
      this.handleChange(newOption);
    }
  };

  getCurrentValue = (value, options) => {
    if (options !== undefined && value !== undefined) {
      let currentValue = options.filter(option => {
        return option.value === value;
      });

      if (currentValue.length === 0) {
        currentValue = this.state.newOption;
      }
      return currentValue;
    }
  };

  getPlaceholder = () => {
    return this.props.formComponentData.placeholder
      ? this.props.formComponentData.placeholder
      : this.props.intl.formatMessage({ id: "formComponents.selectOrFreeText" })
  }

  handleChangeWithoutValue = () => {
    if (this.props.parentHandleSave !== undefined) {
      this.props.parentHandleSave("", this.props.myName);
    } else {
      this.props.setValue("", this.props.myName);
    }
    this.setState({
      value: "",
      newOption: ""
    });
  }

  render() {
    return (
      <div className="input-shell">
        {this.props.formComponentData.description && (
          <label
            htmlFor={this.props.formComponentData.name}
            className={classnames(
              "input-shell__label",
              "input-shell__label--dark",
              {
                "input-shell__label--required": this.props.formComponentData
                  .required
              }
            )}
          >
            {this.props.formComponentData.description}
          </label>
        )}
        <div className="input-shell__container" data-id={this.props.myName}>
          <CreatableSelect
            isMulti={false}
            value={this.state.value}
            options={this.state.options}
            className="select__element"
            placeholder={this.getPlaceholder()}
            onChange={e => e ? this.handleChange(e) : this.handleChangeWithoutValue()}
            onCreateOption={value => this.handleCreateNewOption(value)}
            styles={ReactSelectStyles}
            components={{ DropdownIndicator: ReactSelectSearchIndicator }}
            isClearable={true}
          />
        </div>

        {this.props.formComponentData.required && (
          <div className="input-shell__message">
            <div className="input-shell__message-hint">
              {" "}
              <FormattedMessage id="formComponents.required" />
            </div>
          </div>
        )}
      </div>
    );
  }
}


function mapStateToProps(state) {
  return {
    language: state.language,
    components: state.saveComponentValue.components,
  };
}

export default injectIntl(connect(mapStateToProps)(AutoCompleteCreatableComponent))
