import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import ArrowDownSorting from 'components/svg/ArrowDownSorting'
import TableCellBasic from 'components/common/TableCellBasic'
import TableCellStatus from 'components/common/TableCellStatus'
import TableCellConfidential from 'components/common/TableCellConfidential'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'

function BaseTable(props) {
  const intl = useIntl()

  // state
  const [sortedKey, setSortedKey] = useState('')
  const [sortedOrder, setSortedOrder] = useState('')

  const isSaving = useSelector(state => state.isSaving)

  const headers = [
    { displayName: intl.formatMessage({ id: "baseTable.reportNumber" }), sortable: true, sortKey: 'report_number' },
    { displayName: intl.formatMessage({ id: "baseTable.date" }), sortable: true, sortKey: 'updated' },
    { displayName: intl.formatMessage({ id: "baseTable.customer" }), sortable: true, sortKey: 'customer' },
    { displayName: intl.formatMessage({ id: "baseTable.reportTitle" }), sortable: true, sortKey: 'title' },
    { displayName: intl.formatMessage({ id: "baseTable.author" }), sortable: true, sortKey: 'author' },
    { displayName: intl.formatMessage({ id: "baseTable.status" }), sortable: true, sortKey: 'status' },
    { displayName: intl.formatMessage({ id: "baseTable.confidential" }), sortable: false, sortKey: 'confidential' }
  ]

  const getSortedKey = () => {
    return props.sortedKey ? props.sortedKey : sortedKey
  }
  
  const getSortedOrder = () => {
    return props.sortedOrder ? props.sortedOrder : sortedOrder
  }

  // methods
  const sortTable = sortKey => {
    if (props.onSortTable) {
      props.onSortTable(sortKey)
      return
    }

    setSortedOrder(sortedKey !== sortKey ? '' : sortedOrder)
    setSortedKey(sortedKey !== sortKey ? sortKey : sortedKey)

    setSortedOrder(sortedOrder === 'asc' ? 'desc' : 'asc')
    
    return _.orderBy(props.tableData, naturalSorting(sortedKey), [sortedOrder])
  }

  const naturalSorting = (sortKey) => {
    return function(o) {
      if (Number.isInteger(o[sortKey])) {
        const v = parseInt(o[sortKey], 10)
        return isNaN(v) ? o[sortKey] : v
      } else {
        let value = o[sortKey]
        return value != null ? value.toString().toLowerCase() : ''
      }
    }
  }

  const handleClick = (id) => {
    const rowId = id.toString();

    if (!rowId.includes("offline") || !props.disableActionForOffline) { 
      const row = document.querySelector(`[data-row-id="${rowId}"]`)
    
      if (!row.classList.contains('table__body-row--is-active')) {
        const allTableRows = document.querySelectorAll('.table__body-row')
        allTableRows.forEach(row => {
          row.classList.remove('table__body-row--is-active')
        })
  
        row.classList.add('table__body-row--is-active')
  
        const rowOverlay = row.querySelector('.table__body-overlay-element')
  
        if (rowOverlay !== undefined) {
          const lastCell = row.querySelector('.table__body-cell:last-child')
          const leftMargin = `-${row.offsetWidth - lastCell.offsetWidth}px`
          rowOverlay.style.marginLeft = leftMargin
        }
      }
    }
    
  }

  const getRowId = (id) => { 
    return !props.rowPrefix ? id : (props.rowPrefix + id)
  }

  return (
    <div className="table-wrapper">
      <table className={classnames('table', { 'table--layout-fixed': props.fixedLayout }, props.className)}>
        <thead>
          <tr className="table__header-row">
            {headers.map((header, index) =>
              <th key={index} className="table__header-cell">
                {header.sortable ? (
                  <button
                    className={classnames('table__header-inset', { 'table__header-inset--button': header.sortable }, { 'table__header-inset--align-right': header.alignRight })}
                    onClick={() => sortTable(header.sortKey)}
                  >
                    {header.displayName}
                    <div className="table__header-sort-actions">
                      <ArrowDownSorting className={classnames('table__header-sort svg--180', { 'table__header-sort--is-active': getSortedKey() === header.sortKey && getSortedOrder() === 'asc' })} />
                      <ArrowDownSorting className={classnames('table__header-sort', { 'table__header-sort--is-active': getSortedKey() === header.sortKey && getSortedOrder() === 'desc' })} />
                    </div>
                  </button>
                ) : (
                  <div className={classnames('table__header-inset', { 'table__header-inset--button': header.sortable }, { 'table__header-inset--align-right': header.alignRight })}>{header.displayName}</div>
                )}
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {isSaving && <div className="element--is-loading-after" />}
          {props.tableData && (
            props.tableData
              .sort((data1, data2) => data2.created - data1.created)
              .map((row, i) =>
                <tr className="table__body-row" key={row.id} onClick={() => handleClick(getRowId(row.id))} data-row-id={getRowId(row.id)}>
                  <TableCellBasic cellValue={String(row.report_number)} />
                  <TableCellBasic cellValue={row.updated} noWrapClass={true} />
                  <TableCellBasic cellValue={row.customer} />
                  <TableCellBasic cellValue={row.title} useTruncate={true} />
                  <TableCellBasic cellValue={row.author} />
                  <TableCellStatus label={intl.formatMessage({ id: "preview." + row.status })} value={row.status} />
                  <TableCellConfidential cellValue={row.confidential} caseId={row.id} status={row.status} rowPrefix={props.rowPrefix} />
                </tr>
              )
          )}
        </tbody>
      </table>
    </div>
  )
}

BaseTable.propTypes = {
  tableData: PropTypes.array,
  tableName: PropTypes.string,
  fixedLayout: PropTypes.bool,
  defaultSortedKey: PropTypes.string,
  onClick: PropTypes.func
}

export default BaseTable
