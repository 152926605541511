import _ from "lodash";

function getConfigMap() {
  
  const files = require.context("../Configs", true, /[A-Z]\w+\.(json)$/);

  return _.reduce(
    files.keys(),
    (acc, fileName) => {

      const cfg = files(fileName);
      
      const name = fileName
        .split("/")
        .pop()
        .split(".")[0];
      acc[name] = cfg;
      return acc;
    },
    {}
  );
}

const configMap = getConfigMap();
const apiPaths = getApiPaths(configMap);

function getApiPaths(configMap) {
  const toPairsDeep = (o) => _.flatMap(_.toPairs(o), ([k, v]) => _.isObjectLike(v) ? toPairsDeep(v) : [[k, v]])
  return _.chain(toPairsDeep(configMap)).filter(([k, v]) => k === 'apiPath').map(1).uniq().value()
}

function createConfiguration(configuration) {
  return _.reduce(
    configuration.components,
    (result, data) => {
      const component = data.$ref ? getComponentByReference(data) : data;
      result.push(
        component.components
          ? { ...component, components: createConfiguration(component) }
          : component
      );
      return result;
    },
    []
  );
}

function getComponentByReference(data) {
  const component = _.merge({}, configMap[data.$ref], data);
  const result = component.$extends
    ? _.merge({}, configMap[component.$extends], component)
    : component;
  return _.omitBy(result, (value, key) => _.startsWith(key, "$"));
}

export { configMap, createConfiguration, apiPaths };
