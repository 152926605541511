import React, { useEffect, useState, useCallback, useRef } from "react";
import CreatableSelect from "react-select/creatable";
import ReactSelectStyles from "components/formcomponents/ReactSelectStyles";
import { useIntl } from "react-intl";
import classnames from "classnames";

const ManufacturingDateCode = (props) => {
  const [localValue, setLocalValue] = useState("");
  const [newOption, setNewOption] = useState("");
  const [options, setOptions] = useState([]);
  const intl = useIntl();
  const { myName, getValue, setValue, parentSetValue, parentHandleSave, formComponentData } = props;


  useEffect(() => {
    let valueToSet = "";
    if (parentSetValue !== undefined && parentSetValue !== null) {
      valueToSet = parentSetValue;
    } else {
      valueToSet = getValue(myName);
    }
    if (valueToSet && !valueToSet.value) {
      handleCreateNewOption(valueToSet);
    } else {
      setLocalValue(valueToSet);
    }
    
  }, [parentSetValue, props]);
    
  useEffect(() => {
    setOptions(createOptions().reverse());
  }, []);

  const getAlphabet = () => {
    const alpha = Array.from(Array(26)).map((e, i) => i + 65);
    return alpha.map((x) => String.fromCharCode(x)).filter(c => c !== "Q" && c !== "V" && c !== "I");
  }

  const yearStartingPoints = [1981, 2004]; //The date code can only start on certain years, like 1981 = A and 2004 = A.

  const createOptions = () => {
    return yearStartingPoints.reduce((options, yearStart) => [...options, ...getAlphabet().map((character, index) => ({
      value: `${character}${index + yearStart}`,
      label: `${character} (${index + yearStart})`
    }))], []);
  };

  const handleCreateNewOption = inputValue => {
    // If the user creates their own option, it's added to the options-list.
    if (inputValue !== undefined && inputValue !== "") {
      const newOption = { label: inputValue, value: inputValue };
      setOptions([...options, newOption]);
      setNewOption(newOption);
      handleChange(newOption);
    }
  };

  const handleChange = value => {
    if (parentHandleSave !== undefined) {
      parentHandleSave(value, myName);
    } else {
      setValue(value, myName);
    }
    setLocalValue(value)
  };

  const selectRef = useRef(null)

  const onMenuOpen = useCallback(() => {
    // Calling an initial prop if it has been passed
    const option = selectRef.current?.select?.state?.selectValue?.[0]
    if (option) {
      setTimeout(() => {
        // Setting a focused value as a selected one
        // References:
        // - https://github.com/JedWatson/react-select/blob/master/packages/react-select/src/Select.js#L503
        // - https://github.com/JedWatson/react-select/blob/master/packages/react-select/src/Select.js#L802
        if (selectRef.current?.select) {
          const optionToSet = selectRef.current.select.state.menuOptions.focusable.find(o => option.value === o.value);
          if (optionToSet) {
            // Focusing selected option only if it exists
            selectRef.current.select.scrollToFocusedOptionOnUpdate = true
            selectRef.current.select.inputIsHiddenAfterUpdate = false
            selectRef.current.select.setState({
              focusedValue: null,
              focusedOption: optionToSet,
            })
          }
        }
      })
    }
  }, [selectRef.current])

  return (
    <div className="input-shell">
      {formComponentData.description && (
        <label
          htmlFor={formComponentData.description}
          className={classnames(
            "input-shell__label",
            "input-shell__label--dark",
            { "input-shell__label--required": formComponentData.required }
          )}
        >
          {formComponentData.description}
        </label>
      )}
    
      <div
        className="input-shell__container"
        data-id={formComponentData.myName}
      >
        <CreatableSelect
          isMulti={false}
          value={localValue}
          options={options}
          className="select__element"
          placeholder={formComponentData.placeholder ? formComponentData.placeholder : intl.formatMessage({ id: "formComponents.selectOrFreeText" })}
          onChange={event => {
            if (event !== undefined && event !== null) {
              handleChange(event);
            } else {
              // is cleared
              if (parentHandleSave !== undefined) {
                parentHandleSave("", myName);
              } else {
                setValue("", myName);
                setLocalValue("");
                setNewOption("");
              }
            }
          }}
          ref={selectRef}
          onCreateOption={value => handleCreateNewOption(value)}
          onMenuOpen={onMenuOpen}
          styles={ReactSelectStyles}
          isClearable={true}
        />
      </div>
    
      {formComponentData.required || formComponentData.showrequired && (
        <div className="input-shell__message">
          <div className="input-shell__message-hint">{intl.formatMessage({ id: "formComponents.required" })}</div>
        </div>
      )}
    </div>
  );
};

export default ManufacturingDateCode;