import { getCaseDataRepository } from "core/caseRepository/caseRepositoryFactory";
import { saveComponentValues, saveComponentValue } from "redux/actions/saveComponentValueAction";
import { handleAnswerError, loadAnswers } from "./answerThunk";
import { removeRequiredListForBearing } from "redux/actions/requiredListActions";
import { removeValidationErrorsForBearing } from "redux/actions/saveComponentValueAction";
import { setBearingsIsSaving } from "redux/actions/isBearingsExpandedActions";

export const removeBearing = (caseId, bearingIndex, newCount) => async (dispatch, getState) => {
    dispatch(saveComponentValue({ name: "bearingInvestigations.count", value: newCount.toString() }));

    try {
        await getCaseDataRepository(getState()).removeBearing(caseId, bearingIndex, newCount);
        await loadAnswers(caseId)(dispatch, getState);
    } catch (error) {
        handleAnswerError(error.response.data, dispatch);
    }

    dispatch(removeRequiredListForBearing(bearingIndex));
    dispatch(removeValidationErrorsForBearing(bearingIndex));
}

export const moveBearing = (caseId, currentIndex, newIndex, count) => async (dispatch, getState) => {
    dispatch(setBearingsIsSaving(true));
    dispatch(saveComponentValue({ name: "bearingInvestigations.count", value: "0" }));

    try {
        const result = await getCaseDataRepository(getState()).moveBearing(caseId, currentIndex, newIndex, count);
        dispatch(saveComponentValues(result.data));
        dispatch(saveComponentValue({ name: "bearingInvestigations.count", value: count.toString() }));
    } catch (error) {
        handleAnswerError(error.response.data, dispatch);
    }

    dispatch(setBearingsIsSaving(false));
}

export const duplicateBearing = (caseId, bearingIndex, count) => async (dispatch, getState) => {
    dispatch(setBearingsIsSaving(true));
    dispatch(saveComponentValue({ name: "bearingInvestigations.count", value: "0" }));

    try {
        const result = await getCaseDataRepository(getState()).duplicateBearing(caseId, bearingIndex, count + 1);
        dispatch(saveComponentValues(result.data));
        dispatch(saveComponentValue({ name: "bearingInvestigations.count", value: (count + 1).toString() }));
    } catch (error) {
        handleAnswerError(error.response.data, dispatch);
    }

    dispatch(setBearingsIsSaving(false));
}