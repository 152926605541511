import React, { useState } from 'react';
import ReactModal from 'react-modal';
import CircleCloseFilterSvg from 'components/svg/CircleCloseFilter';
import BaseButton from 'components/common/BaseButton';
import ConvertBearingsTable from './ConvertBearingsTable';
import classnames from 'classnames';

const TABLE_HEADERS = [
  { displayName: 'No.' },
  { displayName: 'Bearing Designation' },
  { displayName: 'Recommendation' },
  { displayName: 'Select' },
];

const ConvertToBDAModal = ({
  showModal,
  setShowModal,
  isConverting,
  bearingsData,
  handleCheckboxChange,
  selectedBearingsCount,
  handleConvert,
  setBearingsData,
  isAlreadyConverted,
  setIsAlreadyConverted,
  hasJustConverted,
  setHasJustConverted,
  correspondingBDAId,
  error,
  setError,
}) => {
  const [noBearingsSelected, setNoBearingsSelected] = useState(false);

  const closeModal = () => setShowModal(false);

  const showBearingsToConvert = () => {
    if (isAlreadyConverted || hasJustConverted || noBearingsSelected || (correspondingBDAId) || error) {
      return null;
    }
    return (
      <div className='react-modal__body'>
        <div className='react-modal__text'>
          <h3 className='convertion__title'>
            Are you sure you want to convert bearings from this Inspection Report to a Bearing Damage Analysis Report?
          </h3>
          <p className='convertion__description'>
            This action will generate a new Bearing Damage Analysis (BDA) Report linked to the current Inspection Report.
            Please select the bearing(s) you would like to have in a BDA Report.
          </p>
          <ConvertBearingsTable headers={TABLE_HEADERS} data={bearingsData} onCheckboxChange={handleCheckboxChange} />
          <div className='convertion__selected__bearings'>
            <BaseButton
              handleSubmit={() => setBearingsData(bearingsData.map(item => ({ ...item, select: true })))}
              text='Select all'
              backgroundColor='orange'
              className='react-modal__action-button--wide'
              disabled={bearingsData.every(item => item.select) || isConverting}
            />
            <p>Selected: {`${selectedBearingsCount}/${bearingsData.length}`}</p>
          </div>
        </div>
        <div className='react-modal__actions-container' style={{ gap: '2rem' }}>
          <BaseButton
            handleSubmit={() => {
              if (selectedBearingsCount === 0) {
                setNoBearingsSelected(true);
                return;
              }
              handleConvert()
            }}
            text='Confirm'
            backgroundColor='green'
            className='button--wide'
            disabled={isConverting}
          />
          <BaseButton
            handleSubmit={closeModal}
            text='Cancel'
            backgroundColor='gray'
            className='button--wide'
            disabled={isConverting}
          />
        </div>
      </div>
    )
  }

  const showSelectAtLeastOneBearing = () => {
    if (!noBearingsSelected || error) return null;

    return (
      <div className='react-modal__body'>
        <div className='convertion__successful__dialog'>
          <h3 style={{ marginTop: '0' }}>
            It is necessary to select at least one bearing to create a new BDA report and complete the conversion process.
          </h3>
          <BaseButton
            handleSubmit={() => setNoBearingsSelected(false)}
            text='OK'
            backgroundColor='green'
            className='react-modal__action-button'
          />
        </div>
      </div>
    )
  }

  const showAlreadyConverted = () => {
    if ((isAlreadyConverted || correspondingBDAId) && !isConverting && !hasJustConverted && !error) {
      return (
        <div className='react-modal__body'>
          <div className='convertion__successful__dialog'>
            <h3>
              {`This Inspection Report already has a linked BDA Report Nr ${correspondingBDAId}. A second conversion cannot be made.`}
            </h3>
            <BaseButton
              handleSubmit={closeModal}
              text='OK'
              backgroundColor='gray'
              className='react-modal__action-button'
            />
          </div>
        </div>
      )
    }
    return null;
  }

  const showSuccessfullyConverted = () => {
    if (hasJustConverted && !isConverting && !error) {
      return (
        <div className='react-modal__body'>
          <div className='convertion__successful__dialog'>
            <h3>The bearings you've selected from your Inspection report were successfully converted into a Bearing Damage Analysis report.</h3>
            <BaseButton
              handleSubmit={() => {
                closeModal();
                setHasJustConverted(false);
              }}
              text='OK'
              backgroundColor='green'
              className='react-modal__action-button'
            />
          </div>
        </div>
      );
    }
    return null;
  }

  const showErrorModal = () => {
    if (!error) return null;

    return (
      <div className='react-modal__body'>
        <div className='convertion__successful__dialog'>
          <h3>The conversion process could not be completed. Please try again later. Initiate an IT ticket to report an incident if the problem persists.</h3>
          <BaseButton
            handleSubmit={() => {
              setIsAlreadyConverted(false);
              setHasJustConverted(false);
              setError(false);
            }}
            text='OK'
            backgroundColor='green'
            className='react-modal__action-button'
          />
        </div>
      </div>
    )
  }

  return (
    <ReactModal
      isOpen={showModal}
      className={classnames(
        'react-modal-convert-table',
        { 'element--is-loading element--is-loading-after': isConverting },
      )}
      overlayClassName='react-modal-overlay'
      onRequestClose={closeModal}
    >
      <div className='react-modal__header'>
        <button className='react-modal__close-button' onClick={closeModal}>
          <CircleCloseFilterSvg />
        </button>
      </div>

      {showSelectAtLeastOneBearing()}
      {showBearingsToConvert()}
      {showAlreadyConverted()}
      {showSuccessfullyConverted()}
      {showErrorModal()}

    </ReactModal>
  );
}

export default ConvertToBDAModal;
