import React, { Component } from "react";
import getComponent from "../../_Case/ComponentsList";
import _ from "lodash";

const mainShaftData = {
  key: "mainShaft",
  type: "AutoComplete",
  description: "Main Shaft Type",
  args: [
    {
      options: [
        {
          label: "msRsRotorSideMainShaftBearing",
          value: "msRsRotorSideMainShaftBearing"
        },
        {
          label: "msGsGeneratorSideMainShaftBearing",
          value: "msGsGeneratorSideMainShaftBearing"
        }
      ]
    }
  ]
};

const sealMainShaftData = {
  key: "sealMainShaft",
  type: "AutoComplete",
  description: "Seal Main Shaft Type",
  args: [
    {
      options: [
        {
          label: "sMsRsSealMainShaft",
          value: "sMsRsSealMainShaft"
        },
        {
          label: "sMsGsGeneratorSideMainShaftSeal",
          value: "sMsGsGeneratorSideMainShaftSeal"
        }
      ]
    }
  ]
};

const gearBoxData = {
  key: "gearBox",
  type: "AutoComplete",
  description: "Gearbox Type",
  args: [
    {
      options: [
        {
          label: "hsSRs",
          value: "hsSRs"
        },
        {
          label: "hsSRsI",
          value: "hsSRsI"
        },
        {
          label: "hsSRsO",
          value: "hsSRsO"
        },
        {
          label: "hsSRsA",
          value: "hsSRsA"
        },
        {
          label: "hsSRsR",
          value: "hsSRsR"
        },
        {
          label: "hsS2Rs",
          value: "hsS2Rs"
        },
        {
          label: "hsS3Rs",
          value: "hsS3Rs"
        },
        {
          label: "hsSGs",
          value: "hsSGs"
        },
        {
          label: "hsSGsI",
          value: "hsSGsI"
        },
        {
          label: "hsSGsO",
          value: "hsSGsO"
        },
        {
          label: "hsSGsA",
          value: "hsSGsA"
        },
        {
          label: "hsSGsR",
          value: "hsSGsR"
        },
        {
          label: "hsS2Gs",
          value: "hsS2Gs"
        },
        {
          label: "hsS3Gs",
          value: "hsS3Gs"
        },
        {
          label: "hisSRs",
          value: "hisSRs"
        },
        {
          label: "hisSRsI",
          value: "hisSRsI"
        },
        {
          label: "hisSRsO",
          value: "hisSRsO"
        },
        {
          label: "hisSGs",
          value: "hisSGs"
        },
        {
          label: "hisSGsI",
          value: "hisSGsI"
        },
        {
          label: "hisSGsO",
          value: "hisSGsO"
        },
        {
          label: "hisP",
          value: "hisP"
        },
        {
          label: "hisC",
          value: "hisC"
        },       
        {
          label: "hisCRs",
          value: "hisCRs"
        },
        {
          label: "hisCGs",
          value: "hisCGs"
        },
        {
          label: "isSRs",
          value: "isSRs"
        },
        {
          label: "isSRsI",
          value: "isSRsI"
        },
        {
          label: "isSRsO",
          value: "isSRsO"
        },
        {
          label: "isSGs",
          value: "isSGs"
        },
        {
          label: "isSGsI",
          value: "isSGsI"
        },
        {
          label: "isSGsO",
          value: "isSGsO"
        },
        {
          label: "isP",
          value: "isP"
        },
        {
          label: "isC",
          value: "isC"
        },
        {
          label: "isCRs",
          value: "isCRs"
        },
        {
          label: "isCGs",
          value: "isCGs"
        },
        {
          label: "isR",
          value: "isR"
        },
        {
          label: "isRGs",
          value: "isRGs"
        },
        {
          label: "isRRs",
          value: "isRRs"
        },
        {
          label: "lisSRs",
          value: "lisSRs"
        },
        {
          label: "lisSRsI",
          value: "lisSRsI"
        },
        {
          label: "lisSRsO",
          value: "lisSRsO"
        },
        {
          label: "lisSGs",
          value: "lisSGs"
        },
        {
          label: "lisSGsI",
          value: "lisSGsI"
        },
        {
          label: "lisSGsO",
          value: "lisSGsO"
        },
        {
          label: "lisP",
          value: "lisP"
        },
        {
          label: "lisC",
          value: "lisC"
        },
        {
          label: "lisCGs",
          value: "lisCGs"
        },
        {
          label: "lisCRs",
          value: "lisCRs"
        },
        {
          label: "lsSRs",
          value: "lsSRs"
        },
        {
          label: "lsSGs",
          value: "lsSGs"
        },
        {
          label: "lsP",
          value: "lsP"
        },
        {
          label: "lsCRs",
          value: "lsCRs"
        },
        {
          label: "lsCGs",
          value: "lsCGs"
        }
      ]
    }
  ]
};

const generatorData = {
  key: "generator",
  type: "AutoComplete",
  description: "Generator Type",
  args: [
    {
      options: [
        {
          label: "driveEndDe",
          value: "driveEndDe"
        },
        {
          label: "nonDriveEndNde",
          value: "nonDriveEndNde"
        }
      ]
    }
  ]
};

class PositionOfBearingInWindMachineComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPositionOfBearingInWindMachine: "",
      mainShaft: "",
      gearBox: "",
      generator: "",
      sealMainShaft: ""
    };
  }

  componentDidMount() {
    let values;
    if (
      this.props.parentSetValue !== undefined &&
      this.props.parentSetValue !== null
    ) {
      values = this.props.parentSetValue;
    } else {
      values = this.props.getValue(this.props.myName);
    }
    if (values !== undefined) {
      this.setState({
        selectedPositionOfBearingInWindMachine:
          values.selectedPositionOfBearingInWindMachine,
        mainShaft: values.mainShaft,
        gearBox: values.gearBox,
        generator: values.generator,
        sealMainShaft: values.sealMainShaft
      });
    }
  }

  saveToStore(objectToSave, name) {
    if (this.props.parentHandleSave !== undefined) {
      this.props.parentHandleSave(objectToSave, name);
    } else {
      this.props.setValue(objectToSave, name);
    }
  }

  resetObjectToSave = (objectToSave, valueToKeep) => {
    return _.mapValues(objectToSave, function(key) {
      if (key !== valueToKeep) {
        key = "";
      }
      return key;
    });
  };

  handleChange = (value, name) => {
    let parts = name.split(".");
    let resultName = parts[parts.length - 1];

    let objectToSave = {
      selectedPositionOfBearingInWindMachine: this.state
        .selectedPositionOfBearingInWindMachine,
      mainShaft: this.state.mainShaft,
      gearBox: this.state.gearBox,
      generator: this.state.generator,
      sealMainShaft: this.state.sealMainShaft
    };

    if (resultName === "selectedPositionOfBearingInWindMachine") {
      objectToSave = this.resetObjectToSave(objectToSave, value);

      this.setState({
        selectedPositionOfBearingInWindMachine: value
      });
      objectToSave.selectedPositionOfBearingInWindMachine = value;

      this.saveToStore(objectToSave, this.props.myName);
    } else if (resultName === "mainShaft") {
      this.setState({
        mainShaft: value
      });
      objectToSave.mainShaft = value;
      this.saveToStore(objectToSave, this.props.myName);
    } else if (resultName === "gearBox") {
      this.setState({
        gearBox: value
      });
      objectToSave.gearBox = value;
      this.saveToStore(objectToSave, this.props.myName);
    } else if (resultName === "generator") {
      this.setState({
        generator: value
      });
      objectToSave.generator = value;
      this.saveToStore(objectToSave, this.props.myName);
    } else if (resultName === "sealMainShaft") {
      this.setState({
        sealMainShaft: value
      });
      objectToSave.sealMainShaft = value;
      this.saveToStore(objectToSave, this.props.myName);
    }
  };

  renderSelectPositionInWindMachine = (formComponent, index) => {
    if (formComponent !== undefined) {
      return (
        <div key={index}>
          {getComponent(
            formComponent,
            `${this.props.myName}.${formComponent.key}`,
            this.handleChange,
            this.state.selectedPositionOfBearingInWindMachine
          )}
        </div>
      );
    }
  };

  switchBetweenSubAutoComplete = subAutoComplete => {
    switch (subAutoComplete.value) {
      case "mainShaft":
        return this.renderSubAutoComplete(mainShaftData);
      case "gearBox":
        return this.renderSubAutoComplete(gearBoxData);
      case "generatorDriveEndOrNonDriveEnd":
        return this.renderSubAutoComplete(generatorData);
      case "sealMainShaft":
        return this.renderSubAutoComplete(sealMainShaftData);
      default:
        return null;
    }
  };

  renderSubAutoComplete = data => {
    return (
      <React.Fragment key={data.key}>
        {getComponent(
          data,
          `${this.props.myName}.${data.key}`,
          this.handleChange,
          this.state[data.key]
        )}
      </React.Fragment>
    );
  };

  render() {
    return (
      <div>
        {this.props.formComponentData.args.map((formComponent, index) => {
          return this.renderSelectPositionInWindMachine(formComponent, index);
        })}
        {this.switchBetweenSubAutoComplete(
          this.state.selectedPositionOfBearingInWindMachine
        )}
      </div>
    );
  }
}

export default PositionOfBearingInWindMachineComponent;
