import { SET_IS_SAVING } from 'redux/types'
import initialState from 'redux/initialState'

export default function isSavingReducer(state = initialState.isSaving, action) {
  let newState = '';
  switch (action.type) {
      case SET_IS_SAVING:
        return newState = action.payload;
    default:
      return state
  }
}