import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import classnames from "classnames";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { saveAllAnswersAndValidate } from "redux/thunks/answerThunk";
import { loadCaseAndData } from "redux/thunks/caseThunks";
import { setScrollTarget } from "redux/actions/saveComponentValueAction"
import {setCurrentCase} from "redux/actions/currentCaseActions";
import SvgSavingIndicator from "../../svg/SavingIndicator"
import SvgButtonBarSave from "./../../svg/ButtonBarSave";

const ButtonReload = ({ buttonSize }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState();

    const currentCase = useSelector(state => state.currentCase.case, shallowEqual);
    const connection = useSelector(state => state.connection, shallowEqual);
    const isSaving = useSelector(state => state.isSaving, shallowEqual);
    const hasUnsavedValues = useSelector(state => state.saveComponentValue.componentsToSave ? state.saveComponentValue.componentsToSave.length > 0 : false, shallowEqual);
    const isApprovalEdit = useSelector(state => state.currentCase.case.status === "approvalEdited", shallowEqual);
    const formtemplateId = useSelector(state => state.currentCase.case.formtemplateId)

    useEffect(() => {
        setIsLoading(currentCase.isLoading);
    }, [currentCase]);

    useEffect(() => { 
        setIsLoading(isSaving);
    }, [isSaving]);
    
    const onReload = () => {
        if (hasUnsavedValues) {
            dispatch(setScrollTarget({ id: '', formTemplateId: formtemplateId }))
            setIsLoading(true);
            dispatch(saveAllAnswersAndValidate(result => { 
                setIsLoading(false);
            }));
        } else { 
            dispatch(setCurrentCase({...currentCase, isLoading: true}))
            dispatch(loadCaseAndData(currentCase.id, connection, []));
        }
    }

    return <button
        id="buttonReload"
        type="button"
        onClick={() => onReload()}
        className={classnames("button", "button--icon-only", { "button--icon-saving": isLoading }, { "button--icon-disabled": !connection })}
        disabled={!connection}>
        {hasUnsavedValues ?
            <SvgButtonBarSave size={buttonSize} title={intl.formatMessage({ id: isApprovalEdit ? "formRenderer.saveAsFinalDraft" : "formRenderer.save" })} /> :
            <SvgSavingIndicator color="#F8F8F8" title={intl.formatMessage({ id: "formRenderer.reload" })} />}
    </button>;
};

export default ButtonReload;