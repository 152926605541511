import React from "react";

const SubHeaderComponent = props => {
  return (
    <React.Fragment>
      {props.formComponentData.description ? (
        <h3 className="page-layout__sub-header">{props.formComponentData.description}</h3>
      ) : (
        "[INSERT LABEL]"
      )}
    </React.Fragment>
  );
};

export default SubHeaderComponent;
