import React from "react";
import {injectIntl} from "react-intl";
import SvgInfo from "../svg/Info";

const InfoMessageComponent = props => {
    return (
        <div className="info-message-container" >
            <SvgInfo width={18} height={18} />&nbsp;
            <span className="info-message-text">{props.intl.formatMessage({'id': props.textId})}</span>
        </div>
    )
};

export default injectIntl(InfoMessageComponent);
