import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ReportHeader from 'components/_LivePreview/Shared/ReportHeader'
import ReportNumber from 'components/_LivePreview/Shared/ReportNumber'
import ReportGeneralText from 'components/_LivePreview/Shared/ReportGeneralText'
import GbiReportInformation from 'components/_LivePreview/Gbi/GbiReportInformation'
import GbiBearingInvestigation from 'components/_LivePreview/Gbi/GbiBearingInvestigation'
import StatusConfidential from 'components/_LivePreview/Shared/StatusConfidential'
import KeyValueTable from 'components/_LivePreview/Shared/KeyValueTable'
import LivePreviewHeader from 'components/_LivePreview/Shared/LivePreviewHeader'
import GbiConfig from 'components/_LivePreview/Gbi/GbiConfig'
import { injectIntl } from "react-intl"
import {
  isConfidential, extractConfigFor, transformSummaryData,
  formatSectorSegment, transformPersonalInfo, getOtherSKFdetailsData
} from "../Shared/LivepreviewHelperFunctions"
import NewKeyValueTable from 'components/_LivePreview/Shared/NewKeyValueTable'

const Gbi = (props) => {
  
  const translated = {
    assetDetailsHeader: props.intl.formatMessage({ id: 'assetDetails.livePreview.header' }),
    skfDetailsHeader: props.intl.formatMessage({ id: 'skfDetails.livePreview.header' }),
    summaryHeader: props.intl.formatMessage({ id: 'summary.livePreview.header' }),
    firstSummaryHeader: props.intl.formatMessage({ id: 'summary.livePreview.firstHeader' }),
    investigationDetails: props.intl.formatMessage({ id: 'skfDetails.livePreview.purpose.header' }),
    gbiHeader: props.intl.formatMessage({ id: 'navigation.gbi' }),
  }

  const bearingTableObject = {isGBI: true, data: props.data, isVisible: checkIfOneBearingHasDesignationOrBrand(props.data)}
    
  function checkIfInvestigationDetailsExits(config, data) {
    let exists = false
    if (config !== undefined && data !== undefined) {
      config.forEach(element => {
        if (data[element.key]) {
          exists = true
        }
      })
      return exists
    }
    return exists
  }

  function checkIfOneBearingHasDesignationOrBrand(data) {
    for (var i = 0; i < data["bearingInvestigations.count"]; i++) {
      if (data["TERorAMFRorOther"]) {
        if (data["TERorAMFRorOther"].GBIReportType.value === "Other") {
          let bearingDesignationComponent = data[`bearingInvestigations.bearingInvestigation[${i}].otherBearingInvestigation`]
          if (bearingDesignationComponent && bearingDesignationComponent.bearingDesignation) {
            if (bearingDesignationComponent.bearingDesignation.skfOrCompetitor.value == "skf") {
              if (bearingDesignationComponent.bearingDesignation.skfBearingDesignation !== "" || bearingDesignationComponent.bearingDesignation.SKFManufacturer !== "") {
                return true
              }
            } else if (bearingDesignationComponent.bearingDesignation.skfOrCompetitor.value == "competitor") {
              if (bearingDesignationComponent.bearingDesignation.manufacturer !== "" || bearingDesignationComponent.bearingDesignation.competitorDesignationAndMarkings !== "") {
                return true
              }
            }
          }
        } else if (data["TERorAMFRorOther"].GBIReportType.value === "AMFR") {
          let bearingDesignationComponent = data["bearingInvestigations.bearingInvestigation[" + i + "].amfrBearingInvestigation"]
          if (bearingDesignationComponent && bearingDesignationComponent.skfBearingDesignation) {
            return true
          }
        }
      }
    }
    return false
  }

  function extractConfigForSkfDetails(config){
    return GbiConfig.filter(item => {
      if ( item.key.includes("skfDetails")) {
        return true
      } else {
        return false
      }
    })
  }

  const terOrAmfrNumber = () => {
    let answer = props.data["TERorAMFRorOther"]
    let typeAndNumber = {}
    if (answer !== undefined) {
      if (answer.hasOwnProperty("GBIReportType")) {
        typeAndNumber.type = answer.GBIReportType
      }
      if (answer.hasOwnProperty("searchText")) {
        typeAndNumber.number = answer.searchText
      }
    }
    return typeAndNumber
  }

  const cutomerDetails = (data) => {
    let answerObject = {}
    let otherInvestigationDetails = data["investigationDetails.OtherInvestigationDetails"]
    if (otherInvestigationDetails) {
      if (otherInvestigationDetails.customer) {
        answerObject["customerDetails.OtherInvestigationDetails.customer"] = otherInvestigationDetails.customer.companyName.label
        answerObject["customerDetails.OtherInvestigationDetails.customer.addressValue"] = otherInvestigationDetails.customer.addressValue
        answerObject["customerDetails.OtherInvestigationDetails.customer.cityAndStateOrTerritoryValue"] = otherInvestigationDetails.customer.cityAndStateOrTerritoryValue
        answerObject["customerDetails.OtherInvestigationDetails.customer.postcodeValue"] = otherInvestigationDetails.customer.postcodeValue
        answerObject["customerDetails.OtherInvestigationDetails.customer.countryValue"] = otherInvestigationDetails.customer.countryValue
        answerObject["customerDetails.OtherInvestigationDetails.site"] = otherInvestigationDetails.customer.site
      }

      answerObject["customerDetails.OtherInvestigationDetails.plantOrTrainOrShipName"] = otherInvestigationDetails.plantOrTrainOrShipName
      answerObject["customerDetails.OtherInvestigationDetails.customerContactName"] = otherInvestigationDetails.customerContactName
  
      answerObject["customerDetails.OtherInvestigationDetails.customerContactJobTitle"] = otherInvestigationDetails.customerContactJobTitle
      answerObject["customerDetails.OtherInvestigationDetails.customerContactEmail"] = otherInvestigationDetails.customerContactEmail
      answerObject["customerDetails.OtherInvestigationDetails.customerContactPhone"] = otherInvestigationDetails.customerContactPhone
    }

    return answerObject
  }

  const transformSKFDetailsData = () => {
    let answerObject = {}
    let answer = props.data["skfDetails.reportNeedsApproval"]
    let config = extractConfigFor('skfDetails', GbiConfig)
    config = config.filter(item => {
      if ( !item.key.toLowerCase().includes("reportauthor")) {
        return true
      } else {
        return false
      }
    })

    if (config !== undefined) {
      config.forEach(element => {
        if (typeof (answer) === "object") {
          
        } else {
          
        }
        answerObject[element.key] = props.data[element.key]
      });
      if ( answer !== undefined ) { 
        if (answer.approversName.hasOwnProperty("value") ) {
          answerObject["skfDetails.reportNeedsApproval"] = answer.approversName.label
        } else {
          answerObject["skfDetails.reportNeedsApproval"] = answer.approversName
        }
      }
    }

    const authorPersonalInfo = transformPersonalInfo("skfDetails.reportAuthor", props.data)
    const coAuthorPersonalInfo = transformPersonalInfo("skfDetails.coReportAuthor", props.data)
    const coAuthorTwoPersonalInfo = transformPersonalInfo("skfDetails.coReportAuthorTwo", props.data)
    const coAuthorThreePersonalInfo = transformPersonalInfo("skfDetails.coReportAuthorThree", props.data)
    const coAuthorFourPersonalInfo = transformPersonalInfo("skfDetails.coReportAuthorFour", props.data)

    answerObject["skfDetails.reportAuthorPersonalInfo"] = authorPersonalInfo.data["skfDetails.reportAuthor.personalInfo"]
    answerObject["skfDetails.coReportAuthorPersonalInfo"] = coAuthorPersonalInfo.data["skfDetails.coReportAuthor.personalInfo"]
    answerObject["skfDetails.coReportAuthorTwo"] = coAuthorTwoPersonalInfo.data["skfDetails.coReportAuthorTwo.personalInfo"]
    answerObject["skfDetails.coReportAuthorThree"] = coAuthorThreePersonalInfo.data["skfDetails.coReportAuthorThree.personalInfo"]
    answerObject["skfDetails.coReportAuthorFour"] = coAuthorFourPersonalInfo.data["skfDetails.coReportAuthorFour.personalInfo"]

    const mergedObject = {...answerObject, ...getOtherSKFdetailsData(config, answerObject)}
    return mergedObject
  }

  const checkIfDataExsists = (data, config) => {
    let cleanedData = {}
    config.forEach(configElement => {
       let answer = data[configElement.key]
       if (answer !== "" && answer !== undefined && answer !== null){
        cleanedData[configElement.key] = answer
       }
    });
    return cleanedData
  }

  function transformInvestigationConfig() {
    const config = extractConfigFor("investigationDetails", GbiConfig)
    let terOramfrOrOther = props.data["TERorAMFRorOther"]
    if (terOramfrOrOther !== undefined) {
      terOramfrOrOther = terOramfrOrOther.GBIReportType.value
      if (terOramfrOrOther && terOramfrOrOther !== 'Other') {
        return config.filter(c => c.key.includes(terOramfrOrOther.toLowerCase()))
      }
      return config.filter(c => c.key.includes(terOramfrOrOther))
    }  
    return config
  }

  function transformInvestigationDetailsData() {
    let answerObject = {}
    let config
    let answer
    let terOramfrOrOther = props.data["TERorAMFRorOther"]
    if ( terOramfrOrOther !== undefined) {
      terOramfrOrOther = terOramfrOrOther.GBIReportType.value
      if(terOramfrOrOther === "TER") {
        config = extractConfigFor("investigationDetails.terInvestigationDetails",GbiConfig)
        answer = props.data["investigationDetails.terInvestigationDetails"]
      } else if (terOramfrOrOther === "AMFR") {
        config = extractConfigFor('investigationDetails.amfrInvestigationDetails',GbiConfig)
        answer = props.data["investigationDetails.amfrInvestigationDetails"]
      } else if (terOramfrOrOther === "Other") {
        config = extractConfigFor('investigationDetails.OtherInvestigationDetails',GbiConfig)
        answer = props.data["investigationDetails.OtherInvestigationDetails"]
      }
      if (config !== undefined && answer !== undefined) {
        config.forEach(element => {
          let nameParts = element.key.split(".")
          let name = nameParts[nameParts.length - 1 ]

          if (terOramfrOrOther === "TER") {
            if ( name === "localReferenceNumber" ) {
              answerObject[`${element.key}`] = answer.localReferenceNumber
            } else if ( name === "customerReference" ) {
              answerObject[`${element.key}`] = answer.customerReference
            } else if ( name === "customerName" ) {
              answerObject[`${element.key}`] = answer.customerName
            } else if ( name === "customerIDReference" ) {
              answerObject[`${element.key}`] = answer.customerIDReference
            } else if ( name === "customerRequestedDate" ) {
              answerObject[`${element.key}`] = answer.customerRequestedDate
            } else if ( name === "X1Coordinator" ) {
              answerObject[`${element.key}`] = answer.X1Coordinator
            } else if ( name === "investigationDetailsText" ) {
              answerObject[`${element.key}`] = answer.investigationDetailsText
            } else if ( name === "investigationDetailsFigure" ) {
              answerObject[`${element.key}`] = answer.investigationDetailsFigure
            } else if ( name === "productLine" ) {
              answerObject[`${element.key}`] = answer.productLine
            } else if ( name === "GBICenter" ) {
              answerObject[`${element.key}`] = answer.GBICenter
            } else if ( name === "GBIDistributionList" ) {
              answerObject[`${element.key}`] = answer.GBIDistributionList
            } else if ( name === "peopleInvolved" ) {
              answerObject[`${element.key}`] = answer.peopleInvolved
            } else if ( name === "quantity" ) {
              answerObject[`${element.key}`] = answer.quantity
            }
          } else if (terOramfrOrOther === "AMFR" ) {
            if ( name === "localReferenceNumber" ) {
              answerObject[`${element.key}`] = answer.localReferenceNumber
            } else if ( name === "customerReference" ) {
              answerObject[`${element.key}`] = answer.customerReference
            } else if ( name === "customerName" ) {
              answerObject[`${element.key}`] = answer.customerName
            } else if ( name === "investigationDetailsText" ) {
              answerObject[`${element.key}`] = answer.investigationDetailsText
            } else if ( name === "investigationDetailsFigure" ) {
              answerObject[`${element.key}`] = answer.investigationDetailsFigure
            } else if ( name === "typeOfReturn" ) {
              answerObject[`${element.key}`] = answer.typeOfReturn
            } else if ( name === "customerRequestedDate" ) {
              answerObject[`${element.key}`] = answer.customerRequestedDate
            } else if ( name === "X1Coordinator" ) {
              answerObject[`${element.key}`] = answer.X1Coordinator
            } else if ( name === "productLine" ) {
              answerObject[`${element.key}`] = answer.productLine
            } else if ( name === "GBICenter" ) {
              answerObject[`${element.key}`] = answer.GBICenter
            } else if ( name === "GBIDistributionList" ) {
              answerObject[`${element.key}`] = answer.GBIDistributionList
            } else if ( name === "peopleInvolved" ) {
              answerObject[`${element.key}`] = answer.peopleInvolved
            }
          } else if (terOramfrOrOther === "Other" ) {
            if ( name === "skfCaseNumber" ) {
              answerObject[`${element.key}`] = answer.skfCaseNumber
            } else if ( name === "purpose" ) {
              answerObject[`${element.key}`] = answer.purpose.label
            } else if ( name === "scopeAndPurpose" ) {
              answerObject[`${element.key}`] = answer.scopeAndPurpose.label
            } else if ( name === "investigationDetailsText" ) {
              answerObject[`${element.key}`] = answer.investigationDetailsText
            } else if ( name === "investigationDetailsFigure" ) {
              answerObject[`${element.key}`] = answer.investigationDetailsFigure
            } else if ( name === "customerRequestedDate" ) {
              if (answer.customerRequestedDate !== "") answerObject[`${element.key}`] = answer.customerRequestedDate.date
            } else if ( name === "describePurposeOfBearing" ) {
              answerObject[`${element.key}`] = answer.describePurposeOfBearing
            } else if (name === "country") {
              answerObject[`${element.key}`] = answer.country
            } else if (name === "site") {
              answerObject[`${element.key}`] = answer.site
            } else if (name === "plantOrTrainOrShipName") {
              answerObject[`${element.key}`] = answer.plantOrTrainOrShipName
            } else if (name === "customerType") {
              answerObject[`${element.key}`] = answer.customerType ? answer.customerType.label : ""
            } else if (name === "customerContactName") {
              answerObject[`${element.key}`] = answer.customerContactName
            } else if (name === "customerContactJobTitle") {
              answerObject[`${element.key}`] = answer.customerContactJobTitle
            } else if (name === "customerContactEmail") {
              answerObject[`${element.key}`] = answer.customerContactEmail
            } else if (name === "customerContactPhone") {
              answerObject[`${element.key}`] = answer.customerContactPhone
            } else if (name === "customer") {
              if (answer.customer.companyName !== undefined && answer.customer.companyName.value !== undefined) answerObject[`${element.key}`] = answer.customer.companyName.value.AddressLine2
              answerObject[`${element.key}.addressValue`] = answer.customer.addressValue
              answerObject[`${element.key}.cityAndStateOrTerritoryValue`] = answer.customer.cityAndStateOrTerritoryValue
              answerObject[`${element.key}.postcodeValue`] = answer.customer.postcodeValue
              answerObject[`${element.key}.countryValue`] = answer.customer.countryValue
              if (answer.customer.sectorSegment !== undefined && answer.customer.sectorSegment !== null) {
                formatSectorSegment(answer.customer.sectorSegment.customPath, 1, answer.customer.sectorSegment.sector, answerObject, `${element.key}.sectorSegment.sector`);
                formatSectorSegment(answer.customer.sectorSegment.customPath, 2, answer.customer.sectorSegment.segment, answerObject, `${element.key}.sectorSegment.segment`);
                formatSectorSegment(answer.customer.sectorSegment.customPath, 3, answer.customer.sectorSegment.industry, answerObject, `${element.key}.sectorSegment.industry`);
              }
            }
          }
        });
        return answerObject
      }
    } else {
      return answerObject
    }
  }

  return (
    <div className="live-preview">
      <LivePreviewHeader />

      <ReportHeader data={translated.gbiHeader} />

      <StatusConfidential caseStatus={props.caseStatus} isConfidential ={isConfidential(props.data)}/>

      <ReportNumber data={props.reportNumber} revisionNumber={props.revisionNumber} />

      <GbiReportInformation data={props.data} terOrAmfrNumber={terOrAmfrNumber()}/>

      <NewKeyValueTable header={translated.investigationDetails} config={transformInvestigationConfig()} sectionId="investigationDetails" data={transformInvestigationDetailsData()} bearingTableObject={bearingTableObject} />

      <KeyValueTable header={props.intl.formatMessage({ id: "customerDetails.livePreview.header"})} config={extractConfigFor('customerDetails', GbiConfig)} sectionId="customerDetails" data={cutomerDetails(props.data)} />

      <KeyValueTable header={translated.skfDetailsHeader} config={extractConfigForSkfDetails()} sectionId="skfDetails" data={transformSKFDetailsData()} />

      {props.showSummaryAtTop && <KeyValueTable header={translated.firstSummaryHeader} sectionId="summary" config={extractConfigFor('summary.conclusions', GbiConfig)} data={transformSummaryData(props.data, GbiConfig)} />}
      
      <GbiBearingInvestigation config={extractConfigFor('bearingInvestigations',GbiConfig)} data={props.data} bearingsCount={props.data['bearingInvestigations.count']} />

      <NewKeyValueTable header={translated.summaryHeader} config={extractConfigFor('summary',GbiConfig)} sectionId="summary" data={transformSummaryData(props.data, GbiConfig)} />
      
      <ReportGeneralText/>

    </div>
  )
}

Gbi.propTypes = {
  formData: PropTypes.object,
  caseId: PropTypes.any,
  revisionNumber: PropTypes.any
}

const mapStateToProps = (state) => {
  return {
    showSummaryAtTop: state.showSummaryAtTop
  };
}

export default connect(mapStateToProps)(injectIntl(Gbi))
