export default function compareUserEmail(useremail1, useremail2) {
    if (!useremail1 || !useremail2) return false;
    return useremail1.toUpperCase() === useremail2.toUpperCase();
}

export function isUserApprover(answers, currentUserEmail, currentCaseApprover, currentCaseDomain) {
    try {
        let approverAnswer;
        let approverEmail;

        if (currentCaseDomain === 'NAMInspection') {
            approverAnswer = answers.find(a => a.name === 'reportDetails.reportApprovedBy.email');
            if (!approverAnswer) {
                return false;
            }
            approverEmail = JSON.parse(approverAnswer.value);
        } else {
            approverAnswer = answers.find(a => a.name === 'skfDetails.reportNeedsApproval');
            if (!approverAnswer) {
                return false
            }
            const parsedValue = JSON.parse(approverAnswer.value);

            approverEmail = currentCaseDomain === 'Inspection'
                ? parsedValue.approversName.value
                : typeof parsedValue.approversName === 'object'
                    ? parsedValue.approversName.value
                    : parsedValue.approversName;
        }

        const isCurrentUserApprover =
            compareUserEmail(approverEmail, currentUserEmail)
            || compareUserEmail(currentCaseApprover, currentUserEmail);

        return isCurrentUserApprover;
    } catch (e) {
        console.log(e);
    }
    return false;
}

export function isUserAdmin(role) {
    if (!role) return false;
    return role.toString().toLowerCase() === 'true';
}

export function isInternalUser(userRole) {
    if (typeof userRole === 'string') {
        return userRole.toString().toLowerCase() === 'false'
    }
    if (typeof userRole === 'boolean') {
        return !userRole
    }
    return true
}

export function checkFuzzyBoolean(value) {
    if (typeof value === 'string') {
        return value.toString().toLowerCase() === 'true'
    }
    if (typeof value === 'boolean') {
        return value
    }
    return false
}
