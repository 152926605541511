import { SET_CURRENT_FORMTEMPLATE } from 'redux/types'
import initialState from 'redux/initialState'

export default function formTemplateReducer(state = initialState.formTemplate, action) {
  let newState = '';
  switch (action.type) {
      case SET_CURRENT_FORMTEMPLATE:
        return newState = action.payload;
    default:
      return state
  }
}