import React, { useState } from "react";
import BaseButton from "components/common/BaseButton";
import classnames from "classnames";
import ReactModal from "react-modal";
import CircleCloseFilterSvg from "components/svg/CircleCloseFilter";
import { useIntl } from "react-intl";
import SvgPlus from "components/svg/Plus";
import getComponent from "components/_Case/ComponentsList";
import RemoveSvg from "../../svg/Remove"

const TableComponent = ({ myName, getValue, setValue, parentSetValue, parentHandleSave, formComponentData }) => { 

    const intl = useIntl();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [tableData, setTableData] = useState([]);

    const openTableModel = () => { 
        let valueFromStore = []
        if (parentSetValue !== undefined && parentSetValue !== null) {
            valueFromStore = parentSetValue;
        } else {
            valueFromStore = getValue(myName);
        }
        if (!valueFromStore) { 
            valueFromStore = [];
        }
        setTableData(valueFromStore);
        setIsModalOpen(true);
    }

    const cancel = () => { 
        setIsModalOpen(false);
    }

    const save = () => { 
        setIsModalOpen(false);
        const tableToSave = tableData.filter(row => formComponentData.args.some(arg => row[arg.component.key]));
        if (parentHandleSave !== undefined) {
            parentHandleSave(tableToSave, myName);
        } else {
            setValue(tableToSave, myName);
        }
    }

    const addRow = () => { 
        setTableData([...tableData, createEmptyRow()])
    }

    const removeRow = (index) => { 
        setTableData(tableData.filter((row, i) => i != index ));
    }

    const handleChange = (value, name, index) => { 
        const newTableData = [...tableData];
        newTableData[index][name] = value;
        setTableData(newTableData);
    }

    const createEmptyRow = () => {
        return formComponentData.args.reduce((row, arg) => {
            row[arg.component.key] = '';
            return row;
        }, {});
    }

    const renderTable = () => { 
        return <table>
            <thead>
                <tr>
                    {formComponentData.args.map((column, index) => renderHeader(column, index))}
                    <th></th>
                </tr>
                {renderTableRows()}
            </thead>
        </table>
    }

    const renderHeader = (column, index) => { 
        return <th key={ index }>{intl.formatMessage({id: column.header}) }</th>
    }

    const renderTableRows = () => { 
        return tableData.map((data, rowindex) => <tr key={rowindex}>
            {formComponentData.args.map((column, colindex) => renderTableColumn(column.component, data, colindex, rowindex))}
            <td><div className="remove" onClick={() => removeRow(rowindex)}><RemoveSvg title="Remove row" /></div></td>
        </tr>);
    }

    const renderTableColumn = (component, data, colindex, rowindex) => { 
        if (component.type === "TextArea") {
            return <td key={colindex }>
                {
                    getComponent(
                        {
                            key: component.key,
                            type: "Text",
                            required: false,
                            isTextArea: true
                        },
                        component.key,
                        (value, name) => handleChange(value.replace(/(?:\r\n|\r|\n)/g, '<br/>'), name, rowindex),
                        data[component.key].split("<br/>").join("\n")
                    )
                }
            </td>;
        } else { 
            return <td key={colindex }>
                {
                    getComponent(
                        {
                            key: component.key,
                            type: component.type,
                            required: false
                        },
                        component.key,
                        (value, name)  => handleChange(value, name, rowindex),
                        data[component.key]
                    )
                }
            </td>;
        }
    }

    return <>
        <BaseButton text={formComponentData.description} className="button--background-green button--full" handleSubmit={openTableModel}></BaseButton>
        <ReactModal isOpen={isModalOpen}
            className="react-modal ai-modal-container table-component"
            overlayClassName="react-modal-overlay">
            <div className="react-modal__header table-component__header">
                <h2>{formComponentData.description}</h2>
            </div>
            <div className="react-modal__body table-component__body">
                {renderTable()}
                <BaseButton handleSubmit={addRow} iconComponent={ <SvgPlus /> } id="add" text="Add row" backgroundColor="green"></BaseButton>
            </div>
            <div className="table-component__footer">
                <BaseButton handleSubmit={cancel} id="cancel" text="Cancel" className="react-modal__action-button" backgroundColor="gray"></BaseButton>
                <BaseButton handleSubmit={save} id="save" text="Save" className="react-modal__action-button react-modal__action-button--wide"></BaseButton>
            </div>
        </ReactModal>
    </>;
    
}

export default TableComponent;