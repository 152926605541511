import React from "react";

const ReportDateInspectionDate = ({ config, data }) => {

    const fixDate = (answer) => { 
        if (typeof (answer) === "object") {
            if (answer.hasOwnProperty("date")) {
                return answer.date
            }
        }
        return "";
    }
    return data ? <div className="valueWithSpace">
        {[fixDate(data.reportDate), fixDate(data.inspectionDate)].filter(d => d).map((d, i) => <span key={ i }>{d}</span>)}
    </div> : null;
}

export default ReportDateInspectionDate;