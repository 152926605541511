import { SET_IS_RAILWAY_WHEELSET_BEARING,RESET_IS_RAILWAY_WHEELSET_BEARING } from 'redux/types'

const setIsRailwayWheelsetBearing = (payload) => {
  return { type: SET_IS_RAILWAY_WHEELSET_BEARING, payload }
}

const resetIsRailwayWheelsetBearing = () => {
  return { type: RESET_IS_RAILWAY_WHEELSET_BEARING, payload: [] }
}

export {
    setIsRailwayWheelsetBearing, resetIsRailwayWheelsetBearing
}