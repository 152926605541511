import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import BaseInput from 'components/common/BaseInput'
import BaseButton from 'components/common/BaseButton'
import SearchSvg from 'components/svg/Search'
import CircleClearSearchSvg from 'components/svg/CircleClearSearch'
import { injectIntl } from "react-intl"
import repository from 'core/repository'
import { useMediaQuery } from 'react-responsive'
import { TO_MEDIUM, MEDIUM } from 'core/ui/breakpoints'
import { withRouter } from 'react-router-dom'
import useDebounce from 'customHooks/useDebounce'
import queryString from 'query-string'

const searchPath = '/all-reports' // Change to search page once implemented

const CaseSearchBox = ({ intl, history, isUserExternal }) => {
  const [startPhrase, setStartPhrase] = useState(null)
  const [searchPhrase, setSearchPhrase] = useState('')
  const [loading, setLoading] = useState(false)
  const [results, setResults] = useState(null)

  const wrapperRef = useRef(null) // to be used by handleClickOutside
  
  const handleClickOutside = event => {
    /**
     * Alert if clicked on outside of element
     */
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setResults(null)
    }
  }

  const URLSearch = queryString.parse(history.location.search)

  useEffect(() => {
    if (URLSearch && URLSearch.q && URLSearch.q.trim().length > 0 && !startPhrase) {
      setStartPhrase(URLSearch.q)
    }
  }, [URLSearch, URLSearch.q, startPhrase])

  useEffect(() => {
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside)
    }
  })

  const debouncedSearchTerm = useDebounce(searchPhrase, 500)

  useEffect(() => {
    // Make sure we have a value (user has entered something in input)
    if (debouncedSearchTerm) {
      // Set isSearching state
      setLoading(true)
      // Fire off our API call
      handleSearch(debouncedSearchTerm)
    } else {
      setResults(null)
    }
  }, [debouncedSearchTerm]);

  const translated = {
    search: intl.formatMessage({ id: 'dashboard.searchPlaceholder' }),
    searchSubmit: intl.formatMessage({ id: 'dashboard.searchSubmit' }),
    searchResultZeroHits: intl.formatMessage({ id: 'dashboard.searchResultZeroHits' }),
    searchError: intl.formatMessage({ id: 'dashboard.searchError' }),
    tryAgain: intl.formatMessage({ id: 'dashboard.searchErrorTryAgain' })
  }

  // methods
  const toMedium = useMediaQuery({ 
    query: TO_MEDIUM
  })

  const medium = useMediaQuery({
    query: MEDIUM
  })

  const clearSearchPhrase = () => {
    if (startPhrase && startPhrase.trim().length > 0) {
      setStartPhrase('')
    }
    setSearchPhrase('')
    setResults(null)

    const params = { ...URLSearch }
    delete params['page']
    delete params['q']

    if (history.location.pathname === searchPath) {
      history.push('?' + queryString.stringify(params))
    }
  }

  const handleSearchInputKeyUp = event => {
    const value = event.target.value

    if (startPhrase && startPhrase.trim().length > 0) {
      setStartPhrase('')
    }
    setSearchPhrase(value)
  }

  const handleSearch = (q) => {
    if (!q.trim()) return

    setLoading(true)
    repository.getCaseQCSearch({ q })
      .then(response => {
        const data = response.data
        const filteredData = isUserExternal
          ? data.hits.filter(hit => hit[Object.keys(hit)[0]] !== "user_company")
          : data.hits

        setLoading(false)

        if (filteredData.length === 0) {
          setResults([{
            [translated.searchResultZeroHits]: translated.tryAgain,
            unClickable: true,
          }])
          return
        }

        const transformedData = filteredData.reduce((acc, hit) => {
          const key = Object.keys(hit)[0]
          // if (!key.includes(',')) {
            acc.push({ [hit[key]]: key })
          // }
          return acc
        }, [])

        setResults(transformedData);
      })
      .catch((e) => {
        console.log(e)
        setLoading(false)
        setResults([{
          [translated.searchError]: translated.tryAgain,
          unClickable: true,
        }])
      })
  }

  const goToFilterResult = hit => {
    const hitVal = Object.keys(hit)[0]
    const hitKey = hit[hitVal]
    
    let newParams = {
      filters: `${hitVal}:${hitKey}`
    }
    
    /*
      Uncomment below if desired logic is to append to
      existing filters rather than resetting it
    */
    // let filters = getQueryFiltersFromURL()
    // if (Object.keys(filters).length > 0) {
    //   filters = {
    //     ...filters,
    //     [hitKey]: hitVal
    //   }
    
    //   const filterQuery = getFilterQuery(filters)
    //   newParams = { filters: filterQuery }
    // }
        
    // const params = { ...URLSearch }
    // if (params.order) {
    //   newParams['order'] = params.order
    // }

    setResults(null)
    history.push(searchPath + '?' + queryString.stringify(newParams))
  }

  const goToQueryResult = () => {
    if (searchPhrase.trim().length > 0) { 
      const params = { ...URLSearch }
      let newParams = {
        q: searchPhrase
      }
      
      if (params.order) {
        newParams['order'] = params.order
      }
      
      setResults(null)
      history.push(searchPath + '?' + queryString.stringify(newParams))
    }
  }

  const handleListItemKeyUp = (event, hit) => {
    if (event.keyCode === 13) { // Enter key
      goToFilterResult(hit)
    }
  }

  const renderResults = results => (
    results?.length > 0 && (
      <div className="case-search-box__result-list-container">
        <ul className="case-search-box__result-list">
          {results.map((hit) => {
            const { keyVal, elKey, label } = extractHitData(hit)

            return (
              <li
                className={`case-search-box__result-item ${hit.unClickable ? 'unclickable' : ''}`}
                key={elKey}
                tabIndex="0"
                onKeyUp={hit.unClickable ? null : (event) => handleListItemKeyUp(event, hit)}
                onClick={() => goToFilterResult(hit)}
              >
                <span className="case-search-box__result-item--result">
                  {/* Must be same line below or capitalization will break */}
                  {label}</span> <span className="case-search-box__result-item--group-name">
                  {keyVal}
                </span>
              </li>
            )
          })}
        </ul>
      </div>
    )
  );

  const extractHitData = (hit) => {
    const keyVal = Object.keys(hit)[0]
    const elKey = `${hit[keyVal]}:${keyVal}`
    const label = hit[keyVal].replace("_", " ")
    return { keyVal, elKey, label }
  }

  const getLoadingClass = () =>
    (loading ? 'element--is-loading element--is-loading-after ' : '')

  return (
    <div className={"case-search-box__container"} ref={wrapperRef}>
      <div className="case-search-box__search-with-results--wrapper">
        <div className="case-search-box__search-with-results">
          <BaseInput
            className="case-search-box__search_input input"
            value={startPhrase && startPhrase.trim().length > 0 ? startPhrase : searchPhrase}
            placeholder={translated.search}
            handleChange={handleSearchInputKeyUp}
            textToLeft={true}
            iconToRight={true}
            iconComponent={
              (startPhrase && startPhrase.trim().length > 0)
              || searchPhrase.length > 0
              ? <CircleClearSearchSvg
              className="input-shell__svg input-shell__svg--right case-search-box__clear-svg"
              color="#86989C"
              onClick={clearSearchPhrase} />
              : null
            }
          />
          {toMedium && renderResults(results)}
        </div>
        <BaseButton
          text={translated.searchSubmit}
          className={getLoadingClass() + "case-search-box__submit-button button--has-icon button--icon-to-left"}
          textToLeft={false}
          iconToRight={false}
          handleSubmit={goToQueryResult}
          iconComponent={<SearchSvg />}
        />
      </div>
      {medium && renderResults(results)}
    </div>
  )
}

CaseSearchBox.propTypes = {
  intl: PropTypes.object
}
 
export default withRouter(injectIntl(CaseSearchBox))