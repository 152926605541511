import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import SunEditor from 'suneditor-react';
import classnames from 'classnames';
import 'suneditor/dist/css/suneditor.min.css';

//It seems that SunEditor doesn't support functional components
class RichTextComponent extends Component { 
    constructor(props) {
        super(props);
        this.state = {
            value: "",
            initialValue: ""
        };
    }

    editorOptions = {
        buttonList: [["undo", "redo"], ["bold", "underline", "italic", "strike", "list"], ["fontColor"], ["removeFormat"]],
        colorList: ["#6E90F7", "#1AFF1A", "#C83B7D", "#EA6B2D", "#F2B33E"],
        defaultStyle: "font-family: 'skf_sans_light', sans-serif; font-size: .75rem;",
        height: "150px",
        resizingBar: true,
        showPathLabel: false,
        pasteTagsWhitelist: 'tagdoesntexists' //It is not possible to remove formatting when passing, but using nonexisting html tag we can turn it of.
    };

    componentDidUpdate(prevProps) {
        if (prevProps.parentSetValue != this.props.parentSetValue) {
            this.setState({ value: this.props.parentSetValue, initialValue: this.props.parentSetValue });
        }

        const answer = this.props.getValue(this.props.myName);
        if (answer && this.state.value !== answer) {
            this.setState({ value: answer, initialValue: this.state.initialValue });
        }
    }

    componentDidMount() {
        let currentValue = '';
         if (this.props.parentSetValue !== undefined) {
            currentValue = this.props.parentSetValue;
        } else {
            currentValue =this. props.getValue(this.props.myName);
        }
        if (currentValue && currentValue !== '') {
            this.setState({ value: currentValue, initialValue: currentValue });
        }

        this.interval = setInterval(() => {
            if (this.state.initialValue !== this.state.value) {
                this.saveValue(this.state.value)
                clearInterval(this.interval);
            }
        }, 60 * 1000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    saveValue = (value) => {
        const regex = /(&nbsp;|<([^>]+)>)/ig;
        const strippedValue = value.replace(regex, "");
        if (strippedValue.trim() === "") {
            value = "";
        }
        // remove newlines when pasting
        const newValue = value.replaceAll(/[\n\r]/g, "");

        if (this.props.parentSetValue !== undefined) {
            this.props.parentHandleSave(newValue, this.props.myName)
        } else {
            this.props.setValue(newValue, this.props.myName);
        }
        this.setState({ value: newValue })
    }

    onBlur = (content) => { 
        this.saveValue(content);
    }

    onChange = (content) => {
        // this.setState({value: content})
    }

    fixLanguage = lang => {
        return lang === "pt" ? "pt_br" : lang === "zh" ? "zh_cn" : lang === "zh-HANT" ? "zh_cn" : lang;
    }

    render() {
        return (
            <div className={classnames('input-shell', this.props.className)}>
                {this.props.formComponentData.description && (<label
                    htmlFor={this.props.formComponentData.name}
                    className={
                        classnames('input-shell__label', 'input-shell__label--dark', 
                        { 'input-shell__label--required': this.props.formComponentData.required })}>
                    {this.props.formComponentData.description}
                </label>)}
                <div className="input-shell__container rich-text" data-id={this.props.myName}>
                    <SunEditor
                        setOptions={this.editorOptions}
                        setContents={this.state.value}
                        lang={this.fixLanguage(this.props.language)}
                        placeholder={this.props.formComponentData.placeholder ? this.props.formComponentData.placeholder : ""}
                        onBlur={(event, content) => this.onBlur(content)}
                        onChange={content => this.onChange(content)}
                    />
                </div>
                {this.props.formComponentData.required &&
                    <div style={{ marginTop: '.5rem' }} className="input-shell__message-hint">
                        <FormattedMessage id='formComponents.required' />
                    </div>
                }
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        language: state.language,
        componentAnswers: state.saveComponentValue.components
    };
}
  
export default connect(mapStateToProps)(RichTextComponent);