import React,{ useEffect, useState }  from "react";
import BaseInput from '../common/BaseInput'
import { useIntl, injectIntl } from 'react-intl';
import SvgButtonBarEdit from './../svg/ButtonBarEdit';
import SvgUndo from './../svg/Undo';

const UnlockableTextFieldComponent = (props) => {
    const [value, setValue] = useState('');
    const [originalValue, setOriginalValue] = useState('');
    const [isLocked, setIsLocked] = useState(true);
    const intl = useIntl();

    const handleChange = (event) => {
        setValue(event.target.value)
    }
    
    const _onBlur = () => {
        const valueToSet = {
            isLocked: isLocked,
            value: isLocked ? originalValue : value
        };
        setParentValue(valueToSet);
    }

    const setParentValue = value => {
        if (props.parentSetValue !== undefined) {
            props.parentHandleSave(value, props.myName)
        } else {
            props.setValue(value, props.myName)
        }
    }
    
    const lockClicked = () => {
        if (isLocked) {
            setValue("");
            setIsLocked(false);
            setParentValue({
                isLocked: false,
                value: ""
            });
        } else {
            setValue(originalValue);
            setIsLocked(true);
            setParentValue({
                isLocked: true,
                value: originalValue
            });
        }
    }

    useEffect(() => {
        if (props.parentSetValue !== undefined) {
            if (props.parentSetValue !== null) {
                setValue(props.parentSetValue.value)
                setIsLocked(props.parentSetValue.isLocked)
                setOriginalValue(props.parentSetValue.value);    
            }
        } else {
            const value = props.getValue(props.myName);
            if (value) {
                setIsLocked(value.isLocked)
                setValue(value.value)
                setOriginalValue(value.value);    
            }
            
        }

    }, [props, props.parentSetValue])
      
     
    
    return (
        <div className="lockablefield">
            <BaseInput
                onBlur={_onBlur}
                type={props.formComponentData.isNumeric ? 'number' : 'text'}
                id={props.formComponentData.name}
                placeholder={props.formComponentData.placeholder ? props.formComponentData.placeholder : ""}
                labelText={props.formComponentData.description}
                labelTextDark={true}
                hintMessage={props.formComponentData.required && props.intl.formatMessage({ id: 'formComponents.required' })}
                isTextArea={props.formComponentData.isTextArea ? props.formComponentData.isTextArea : false}
                handleChange={handleChange}
                isRequired={props.formComponentData.required}
                isDisabled={isLocked}
                value={value ? value : ''}
                myName={props.myName}
            />
            <div onClick={ lockClicked } className="icon">
                {isLocked ?
                    <SvgButtonBarEdit fill="#485A64" title={props.formComponentData.editTitle ? props.formComponentData.editTitle : "Click to edit"} /> :
                    <SvgUndo fill="#485A64" title={props.formComponentData.revertTitle ? props.formComponentData.revertTitle : "Revert to pre-filled data"} />}
            </div>
            
        </div>
    )
}

export default injectIntl(UnlockableTextFieldComponent);