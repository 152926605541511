import React, { Component } from "react";
import getComponent from "../../_Case/ComponentsList";
import _ from "lodash";

const nacelleData = {
  key: "assetType",
  type: "AutoComplete",
  description: "Asset type",
  showrequired : true,
  args: [
    {
      options: [
        {
          label: "mainShaftArrangementSrb",
          value: "mainShaftArrangementSrb"
        },
        {
          label: "mainShaftArrangementSrbSrb",
          value: "mainShaftArrangementSrbSrb"
        },
        {
          label: "mainShaftArrangementSrbCarb",
          value: "mainShaftArrangementSrbCarb"
        },
        {
          label: "mainShaftArrangementMomentBearing",
          value: "mainShaftArrangementMomentBearing"
        },
        {
          label: "mainShaftArrangementTrbTrb",
          value: "mainShaftArrangementTrbTrb"
        },
        {
          label: "mainShaftArrangementDrtrbCrb",
          value: "mainShaftArrangementDrtrbCrb"
        },
        {
          label: "gearBox",
          value: "gearBox"
        },
        {
          label: "generator",
          value: "generator"
        },
        {
          label: "lubricationSystem",
          value: "lubricationSystem"
        }
      ]
    }
  ]
};

const rotorData = {
  key: "assetType",
  type: "AutoComplete",
  description: "Asset type",
  showrequired : true,
  args: [
    {
      options: [
        {
          label: "pitchDriveSystem",
          value: "pitchDriveSystem"
        }
      ]
    }
  ]
};

const towerData = {
  key: "assetType",
  type: "AutoComplete",
  description: "Asset type",
  showrequired : true,
  args: [
    {
      options: [
        {
          label: "yawDriveSystem",
          value: "yawDriveSystem"
        }
      ]
    }
  ]
};

const yawDriveSystemData = {
  key: "positionInAsset",
  type: "AutoCompleteCreatable",
  description: "Position In Asset",
  showrequired : true,
  args: [
    {
      options: [
        {
          label: "yawBearing",
          value: "yawBearing"
        }
      ]
    }
  ]
};

const pitchDriveSystemData = {
  key: "positionInAsset",
  type: "AutoCompleteCreatable",
  description: "Position In Asset",
  showrequired : true,
  args: [
    {
      options: [
        {
          label: "pitchBearing",
          value: "pitchBearing"
        }
      ]
    }
  ]
};

const lubricationSystemData = {
  key: "positionInAsset",
  type: "AutoCompleteCreatable",
  showrequired : true,
  description: "Position In Asset"
};

const generatorData = {
  key: "positionInAsset",
  type: "AutoCompleteCreatable",
  description: "Position In Asset",
  showrequired : true,
  args: [
    {
      options: [
        {
          label: "driveEndDe",
          value: "driveEndDe"
        },
        {
          label: "nonDriveEndNde",
          value: "nonDriveEndNde"
        }
      ]
    }
  ]
};

const gearBoxData = {
  key: "positionInAsset",
  type: "AutoComplete",
  showrequired : true,
  description: "Position In Asset",
  args: [
    {
      options: [
        {
          label: "hsSRs",
          value: "hsSRs"
        },
        {
          label: "hsSRsI",
          value: "hsSRsI"
        },
        {
          label: "hsSRsO",
          value: "hsSRsO"
        },
        {
          label: "hsSRsA",
          value: "hsSRsA"
        },
        {
          label: "hsSRsR",
          value: "hsSRsR"
        },
        {
          label: "hsS2Rs",
          value: "hsS2Rs"
        },
        {
          label: "hsS3Rs",
          value: "hsS3Rs"
        },
        {
          label: "hsSGs",
          value: "hsSGs"
        },
        {
          label: "hsSGsI",
          value: "hsSGsI"
        },
        {
          label: "hsSGsO",
          value: "hsSGsO"
        },
        {
          label: "hsSGsA",
          value: "hsSGsA"
        },
        {
          label: "hsSGsR",
          value: "hsSGsR"
        },
        {
          label: "hsS2Gs",
          value: "hsS2Gs"
        },
        {
          label: "hsS3Gs",
          value: "hsS3Gs"
        },
        {
          label: "hisSRs",
          value: "hisSRs"
        },
        {
          label: "hisSRsI",
          value: "hisSRsI"
        },
        {
          label: "hisSRsO",
          value: "hisSRsO"
        },
        {
          label: "hisSGs",
          value: "hisSGs"
        },
        {
          label: "hisSGsI",
          value: "hisSGsI"
        },
        {
          label: "hisSGsO",
          value: "hisSGsO"
        },
        {
          label: "hisP",
          value: "hisP"
        },
        {
          label: "hisC",
          value: "hisC"
        },       
        {
          label: "hisCRs",
          value: "hisCRs"
        },
        {
          label: "hisCGs",
          value: "hisCGs"
        },
        {
          label: "isSRs",
          value: "isSRs"
        },
        {
          label: "isSRsI",
          value: "isSRsI"
        },
        {
          label: "isSRsO",
          value: "isSRsO"
        },
        {
          label: "isSGs",
          value: "isSGs"
        },
        {
          label: "isSGsI",
          value: "isSGsI"
        },
        {
          label: "isSGsO",
          value: "isSGsO"
        },
        {
          label: "isP",
          value: "isP"
        },
        {
          label: "isC",
          value: "isC"
        },
        {
          label: "isCRs",
          value: "isCRs"
        },
        {
          label: "isCGs",
          value: "isCGs"
        },
        {
          label: "isR",
          value: "isR"
        },
        {
          label: "isRGs",
          value: "isRGs"
        },
        {
          label: "isRRs",
          value: "isRRs"
        },
        {
          label: "lisSRs",
          value: "lisSRs"
        },
        {
          label: "lisSRsI",
          value: "lisSRsI"
        },
        {
          label: "lisSRsO",
          value: "lisSRsO"
        },
        {
          label: "lisSGs",
          value: "lisSGs"
        },
        {
          label: "lisSGsI",
          value: "lisSGsI"
        },
        {
          label: "lisSGsO",
          value: "lisSGsO"
        },
        {
          label: "lisP",
          value: "lisP"
        },
        {
          label: "lisC",
          value: "lisC"
        },
        {
          label: "lisCGs",
          value: "lisCGs"
        },
        {
          label: "lisCRs",
          value: "lisCRs"
        },
        {
          label: "lsSRs",
          value: "lsSRs"
        },
        {
          label: "lsSGs",
          value: "lsSGs"
        },
        {
          label: "lsP",
          value: "lsP"
        },
        {
          label: "lsCRs",
          value: "lsCRs"
        },
        {
          label: "lsCGs",
          value: "lsCGs"
        }
      ]
    }
  ]
};

const mainShaftArrangementData = {
  key: "positionInAsset",
  type: "AutoComplete",
  showrequired : true,
  description: "Position In Asset",
  args: [
    {
      options: [
        {
          label: "msRsRotorSideMainShaftBearing",
          value: "msRsRotorSideMainShaftBearing"
        },
        {
          label: "msGsGeneratorSideMainShaftBearing",
          value: "msGsGeneratorSideMainShaftBearing"
        }
      ]
    }
  ]
};

class WindFunctionalLocationComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      functionalLocation: "",
      assetType: "",
      positionInAsset: ''
    };
  }

  componentDidMount() {
    let values;
    if (
      this.props.parentSetValue !== undefined &&
      this.props.parentSetValue !== null
    ) {
      values = this.props.parentSetValue;
    } else {
      values = this.props.getValue(this.props.myName);
    }
    if (values !== undefined) {
      this.setState({
        functionalLocation: values.functionalLocation ? values.functionalLocation : "",
        assetType: values.assetType ? values.assetType : "",
        positionInAsset: values.positionInAsset ? values.positionInAsset : ""
      });
    }
  }

  saveToStore(objectToSave, name) {
    if (this.props.parentHandleSave !== undefined) {
      this.props.parentHandleSave(objectToSave, name);
    } else {
      this.props.setValue(objectToSave, name);
    }
  }

  handleChange = (value, name) => {
    let resultName = this.getResultName(name);

    if (resultName === "functionalLocation") {
      this.setState({ functionalLocation: value, assetType: '', positionInAsset: '' });
      let objectToSave = {
        functionalLocation: value
      };
      this.saveHandleChangeValue(objectToSave, resultName, value);
    } else if (resultName === "assetType") {
      this.setState({ assetType: value, positionInAsset: '' });
      let objectToSave = {
        functionalLocation: this.state.functionalLocation,
        assetType: value
      };
      this.saveHandleChangeValue(objectToSave, resultName, value);
    } else if (resultName === "positionInAsset") {
      this.setState({ positionInAsset: value });
      let objectToSave = {
        functionalLocation: this.state.functionalLocation,
        assetType: this.state.assetType,
        positionInAsset: value
      };
      this.saveHandleChangeValue(objectToSave, resultName, value);
    } 
    
    
    else {
      return null;
    }
  };

  getResultName = name => {
    let parts = name.split(".");
    let resultName = parts[parts.length - 1];
    return resultName;
  };

  saveHandleChangeValue = (objectToSave, resultName, value) => {
    this.setState({
      [resultName]: value
    });
    objectToSave[resultName] = value;
    this.saveToStore(objectToSave, this.props.myName);
  };

  renderSelectWindFunctionalLocation = (formComponent, index) => {
    if (formComponent !== undefined) {
      return (
        <div key={index}>
          {getComponent(
            formComponent,
            `${this.props.myName}.${formComponent.key}`,
            this.handleChange,
            this.state.functionalLocation
          )}
        </div>
      );
    }
  };

  switchBetweenSubAutoComplete = subAutoComplete => {
    let value = subAutoComplete.value;

    if (value !== undefined && value.includes('mainShaftArrangement')) {
      value = 'mainShaftArrangement';
    }

    switch (value) {
      case "nacelle":
        return this.renderSubAutoComplete(nacelleData, value);
      case "rotor":
        return this.renderSubAutoComplete(rotorData, value);
      case "tower":
        return this.renderSubAutoComplete(towerData, value);
      case "yawDriveSystem":
        return this.renderSubAutoComplete(yawDriveSystemData, value);
      case "pitchDriveSystem":
        return this.renderSubAutoComplete(pitchDriveSystemData, value);
      case "lubricationSystem":
        return this.renderSubAutoComplete(lubricationSystemData, value);
      case "generator":
        return this.renderSubAutoComplete(generatorData, value);
      case "gearBox":
        return this.renderSubAutoComplete(gearBoxData, value);
      case "mainShaftArrangement":
        return this.renderSubAutoComplete(mainShaftArrangementData, value);
      default:
        return null;
    }
  };

  renderSubAutoComplete = (data, value) => {
    return (
      <React.Fragment key={value}>
        {getComponent(
          data,
          `${this.props.myName}.${data.key}`,
          this.handleChange,
          this.state[data.key]
        )}
      </React.Fragment>
    );
  };

  render() {
    return (
     <React.Fragment>
        {this.props.formComponentData.args.map((formComponent, index) => {
          return this.renderSelectWindFunctionalLocation(formComponent, index);
        })}
        {this.switchBetweenSubAutoComplete(this.state.functionalLocation)}
        {this.switchBetweenSubAutoComplete(this.state.assetType)}
      </React.Fragment>
    );
  }
}

export default WindFunctionalLocationComponent;
