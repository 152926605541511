import React from 'react'
import { connect } from 'react-redux'
import { toggleNavigation } from 'redux/actions/uiActions'
import PropTypes from 'prop-types';
import classnames from 'classnames'

const TheHeaderBurger = (props) => {
  // methods
  const toggleNavigation = () => props.toggleNavigation()

  return (
    <button className={classnames('navigation-toggler', props.className)} onClick={toggleNavigation}>
      <div className="burger">
        <span className="burger__row burger__row--1" />
        <span className="burger__row burger__row--2" />
        <span className="burger__row burger__row--3" />
      </div>
    </button>
  )
}

TheHeaderBurger.propTypes = {
  toggleNavigation: PropTypes.func.isRequired,
  className: PropTypes.string
}

const mapDispatchToProps = (dispatch) => {
  return {
    toggleNavigation: () => dispatch(toggleNavigation())
  }
}

export default connect(null, mapDispatchToProps)(TheHeaderBurger)
