
import { injectIntl } from "react-intl";
import React from "react";
import BearingTable from 'components/_LivePreview/Shared/BearingTable'
import { checkIfAnyBearingHasDesignationBrandOrRecommendations, } from "../Shared/GetAndRenderBearingDesignation";

const UsInspectionSummary = ({ data, intl }) => {
    return checkIfAnyBearingHasDesignationBrandOrRecommendations(data) ? <div className="live-preview__ussummary">
        <div className="live-preview-key-value__header">{intl.formatMessage({ id: "summaryInspection" })}</div>
        <BearingTable
            data={data}
            numberOfBearings={data["bearingInvestigations.count"]}
            isGBI={false}
            isRecommendationShown={true}
            isUSInspection={true}
        />
    </div> : null;
}

export default injectIntl(UsInspectionSummary)