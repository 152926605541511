import React from "react";
import { components } from "react-select";
import SearchSvg from 'components/svg/Search'

const ReactSelectIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <SearchSvg fill="#86989C" />
      </components.DropdownIndicator>
    )
  }

export default ReactSelectIndicator
