import React from "react";

const SvgAdd = props => (
    <svg width="28px" height="28px" viewBox="0 0 28 28" version="1.1" style={{ background: "transparent" }} {...props}>
        <defs>
            <rect id="path-1" x="0" y="0" width="18.5221233" height="21.1681423"></rect>
        </defs>
        <g id="icon_add" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <rect fill="transparent" x="0" y="0" width="28" height="28"></rect>
            <g id="icon-add">
                <path d="M5,0 L23.2241879,0 C25.9856116,-5.07265313e-16 28.2241879,2.23857625 28.2241879,5 L28.2241879,23.2241879 C28.2241879,25.9856116 25.9856116,28.2241879 23.2241879,28.2241879 L5,28.2241879 C2.23857625,28.2241879 3.38176876e-16,25.9856116 0,23.2241879 L0,5 C-3.38176876e-16,2.23857625 2.23857625,5.07265313e-16 5,0 Z" id="Rectangle-5" fill="#88C008"></path>
                <g id="plus-solid-1" transform="translate(4.409912, 3.528046)">
                    <mask id="mask-2" fill="white">
                            
                    </mask>
                    <g id="plus-solid-1-(Background/Mask)"></g>
                    <path d="M11.2455749,8.5995465 L11.2455749,2.64600686 C11.2455749,1.91545793 10.653115,1.32299805 9.92256607,1.32299805 L8.59955726,1.32299805 C7.86900833,1.32299805 7.27654845,1.91545793 7.27654845,2.64600686 L7.27654845,8.5995465 L1.32300881,8.5995465 C0.592459879,8.5995465 0,9.19200638 0,9.92255531 L0,11.2455641 C0,11.9761131 0.592459879,12.5685729 1.32300881,12.5685729 L7.27654845,12.5685729 L7.27654845,18.5221126 C7.27654845,19.2526615 7.86900833,19.8451214 8.59955726,19.8451214 L9.92256607,19.8451214 C10.653115,19.8451214 11.2455749,19.2526615 11.2455749,18.5221126 L11.2455749,12.5685729 L17.1991145,12.5685729 C17.9296635,12.5685729 18.5221233,11.9761131 18.5221233,11.2455641 L18.5221233,9.92255531 C18.5221233,9.19200638 17.9296635,8.5995465 17.1991145,8.5995465 L11.2455749,8.5995465 Z" id="Vector" fill="#FFFFFF" fillRule="nonzero" mask="url(#mask-2)"></path>
                </g>
            </g>
        </g>
    </svg>
);

export default SvgAdd;