import React, { useState, useEffect } from "react";
import { injectIntl } from "react-intl";
import ImageContainer from "../Shared/ImageContainer";
import { connect } from "react-redux";
import KeyValueTable from "../Shared/KeyValueTable";
import {
    getImageObjects,
    getImageLayoutObject,
} from "../Shared/BIHelperFunctions";
  
const BackgroundInformation = ({ header, config, data, scrollTarget }) => {
    const [selectedValues, setSelectedValues] = useState([]);
    const [image, setImage] = useState({});
    const [imageRealKey, setImageRealKey] = useState("");

    const part1 = ["backgroundInformationText"];
    const part2 = ["recurringFailure", "lengthOfBearingOperated", "operation", "expectedLife", "costOfFailure", "reasonWasRemoved"];
    const imageKey = "backgroundInformationImages";

    useEffect(() => {
        setSelectedValues(requiredFieldsAndFilledArray(config, data));
        const key = Object.keys(data).find(k => k.includes(imageKey));
        if (key) {
            setImage(data[key]);
            setImageRealKey(key);
        }
        
    }, [data]);

    const hasScrollTarget = key => {
        if (scrollTarget) {
          if (scrollTarget.fieldTarget || scrollTarget.scrollTarget) {
            return scrollTarget.fieldTarget === key || scrollTarget.scrollTarget.includes(key);
          }
        }
        return false;
    }
    

    const filterConfig = (config, filter) => {
        return config.filter(c => filter.find(f => c.key.includes(f)));
    }

    const requiredFieldsAndFilledArray = (config, data) => {
        let hasFields = [];
    
        if (data !== undefined) {
            config.forEach((c) => {
                if (data[c.key] !== undefined && data[c.key] !== null && data[c.key] !== "" || hasScrollTarget(c.key)) {
                    hasFields.push(c);
                }
            });
        }
    
        return hasFields;
    };
    
    return (
        <>
            {selectedValues.length > 0 && (
                <>
                    <KeyValueTable config={filterConfig(config, part1)} data={data} />
                    {image !== null && image !== undefined && <ImageContainer datakey={ imageRealKey} imageLayout={getImageLayoutObject(image)} imageObjects={getImageObjects(imageRealKey, data)} />}
                    <KeyValueTable config={filterConfig(config, part2)} data={data} />
                </>
            )}
            
        </>
    );
}

const mapStateToProps = (state) => {
    return {
      scrollTarget: state.saveComponentValue.scrollTarget
    };
  };
  
export default connect(mapStateToProps)(injectIntl(BackgroundInformation));