import { Auth } from "aws-amplify";
import React, { Component, useCallback, useEffect, useState } from "react";
import BartLogo from "components/svg/BartLogo";
import BaseInput from "components/common/BaseInput";
import BaseButton from "components/common/BaseButton";
import { connect, useDispatch } from "react-redux";
import { Link, useLocation, useHistory } from "react-router-dom";
import { FormattedMessage, injectIntl, useIntl } from "react-intl";
import classnames from "classnames";
import jwt_decode from "jwt-decode";
import { setCurrentUser } from "../../redux/actions/currentUserActions";

const Login = ({ isAuthenticated, setUser, setUserExternal, userHasAuthenticated }) => { 

  const [loginErrors, setLoginErrors] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState();
  const [user, setUserInternal] = useState();

  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  let { from } = location.state || { from: { pathname: "/dashboard" } };

  useEffect(() => { 
    if (isAuthenticated) { 
      history.replace(from);
    }
  }, [isAuthenticated])

  const onAuthenticate = (username, password) => { 
    setIsLoading(true);
    Auth.signIn(username, password)
      .then((user) => {
        setIsLoading(false);
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          setStatus(user.challengeName);
          setUserInternal({ ...user, external: false });
        } else {
          var jwtToken = user.signInUserSession.idToken.jwtToken;
          var decodedToken = jwt_decode(jwtToken);
          console.log("JWT_TOKEN: " + jwtToken);
          setUserInternal({ ...user, external: decodedToken["custom:external"] });
          dispatch(setCurrentUser(user));
          setUser(decodedToken);
          if (decodedToken["custom:external"]) {
            setUserExternal(
              decodedToken["custom:external"].toLowerCase() == "true"
            );
          }
          userHasAuthenticated(true);
          history.replace(from);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setLoginErrors(error.message);
      });
  }

  const onResetPassword = (newPassword) => { 
    setIsLoading(true);
    Auth.completeNewPassword(user, newPassword)
      .then(() => {
        alert(this.props.intl.formatMessage({ id: "login.passwordChangeSuccess" }));
        setStatus("NOT_LOGGED_IN");
        setIsLoading(false);
      })
      .catch((error) => {
        alert(error.message);
        setIsLoading(false);
      });
  }

  return status === "NEW_PASSWORD_REQUIRED" ? <LoginNewPassword isLoading={isLoading} onResetPassword={ onResetPassword } /> : !isAuthenticated ?
    <LoginUsernameAndPassword loginErrors={loginErrors} isLoading={isLoading} onAuthenticate={onAuthenticate} /> : null;
}

const LoginUsernameAndPassword = ({ loginErrors, isLoading, onAuthenticate }) => { 

  const intl = useIntl();
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();

  const [usernameErrors, setUsernameErrors] = useState();
  const [passwordErrors, setPasswordErrors] = useState();

  useEffect(() => { 
    const listner = event => {
      if (event.code === "Enter") { 
        event.preventDefault();
        handleSubmit();
      }
    }

    document.addEventListener("keydown", listner);
    return () => { 
      document.removeEventListener("keydown", listner);
    }
  }, []);

  const handleSubmit = useCallback(() => {
    if (username !== null && password !== null) {
      onAuthenticate(username.toLowerCase(), password);
    } else { 
      setUsernameErrors(intl.formatMessage({ id: "login.mustEnterEmail" }))
      setPasswordErrors(intl.formatMessage({id: "login.mustEnterPassword"}))
    }
  }, [username, password, onAuthenticate]);

  return <div className="login">
    <div className="login__form">
      <BartLogo className="login__bart-logo" />
      <BaseInput id="email" errorMessage={usernameErrors ? usernameErrors : ""} handleChange={(event) => setUsername(event.target.value)} placeholder={intl.formatMessage({ id: "login.emailPlaceholder" })} labelText={intl.formatMessage({ id: "login.enterEmail" })} labelTextDark={true} className="login__input-containers" />
      <BaseInput type="password" id="password" errorMessage={passwordErrors ? passwordErrors : ""} handleChange={event => setPassword(event.target.value)} placeholder={intl.formatMessage({ id: "login.passwordPlaceholder" })} labelText={intl.formatMessage({ id: "login.enterPassword" })} labelTextDark={true} className="login__input-containers login__input-containers--last"
      />
      <BaseButton
        handleSubmit={handleSubmit}
        text={intl.formatMessage({ id: "login.loginButtonLabel" })}
        className={classnames("login__button", {
          "element--is-loading element--is-loading-after": isLoading,
        })}
      />
      {loginErrors && (
        <div className="login__errors"> {loginErrors} </div>
      )}
      <Link to={"/reset-password"}>
        <FormattedMessage id="login.forgotPassword" />
      </Link>
    </div>
  </div>;
}

const LoginNewPassword = (isLoading, onResetPassword) => { 

  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [newPassword, setNewPassword] = useState();

  const intl = useIntl();

  const onChangeNewPassword = (event) => { 
    setNewPassword(event.target.value);
    setIsPasswordValid(event.target.value.length > 7);
  }

  const handleResetPassword = () => { 
    if (isPasswordValid) { 
      onResetPassword(newPassword);
    }
  }

  return <div className="login login--set-password">
    <div className="login__form">
      <BartLogo className="login__bart-logo" />
      <p className="login__text">
        <FormattedMessage id="login.firstTimeLogin" />
      </p>
      <BaseInput
        type="password"
        id="newPassword"
        className="login__input-containers login__input-containers--last"
        placeholder={intl.formatMessage({id : "login.newPasswordPlaceholder" })}
        labelText={intl.formatMessage({id : "login.enterPassword" })}
        labelTextDark={true}
        errorMessage={
          newPassword !== null && !isPasswordValid ? intl.formatMessage({id: "login.invalidPassword"}) : ("") }
        handleChange={onChangeNewPassword}
      />
      <BaseButton
        text={<FormattedMessage id="login.changePassword" />}
        className={classnames("login__button", {
          "element--is-loading element--is-loading-after": isLoading,
        })}
        handleSubmit={handleResetPassword}
      />
    </div>
  </div>;
}

export default Login;