import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { setSelectedBearing, triggerSaveOnAccordian, setLivePreviewTarget } from "redux/actions/saveComponentValueAction";
import { setIsBearingsExpanded, setBearingsExpandedState } from "redux/actions/isBearingsExpandedActions";
import { createBIFormConfig } from "redux/thunks/formTemplateThunks";
import { addAnswer } from "../../../redux/thunks/answerThunk";

import _ from "lodash";
import classnames from "classnames";
import config from "config";

import SvgPlus from "./../../svg/Plus";
import BearingInvestigationComponent from "./BearingInvestigationComponent";
import AccordionTrigger from "./../../_Case/Accordion/AccordionTrigger";

const BearingInvestigationsComponent = props => {
  const { formComponentData, myName, setValue } = props
  const intl = useIntl();
  const dispatch = useDispatch();

  const [bearingConfig, setBearingConfig] = useState([]);
  const [bearingCount, setBearingCount] = useState(0);
  const [openAccordions, setOpenAccordions] = useState([]);

  const isBearingsExpanded = useSelector(state => state.bearingsExpanded.isBearingsExpanded, shallowEqual);
  const disableAutoScroll = useSelector(state => state.saveComponentValue.disableAutoScroll, shallowEqual);
  const firstBIAnyValue = useSelector(state => state.saveComponentValue.componentsToSave.some(c =>
    c.answer.name.includes('bearingInvestigation[0]')))
  const firstBIanySavedValue = useSelector(state => state.saveComponentValue.components.some(c =>
    c.name.includes('bearingInvestigation[0]')))
  const bearingCountAnswer = useSelector(state => state.saveComponentValue.components.find(a =>
    a.name === "bearingInvestigations.count"), shallowEqual);
  const formTemplate = useSelector(state => state.formTemplate, shallowEqual);
  const isBearingsSaving = useSelector(state => state.bearingsExpanded.isBearingsSaving, shallowEqual);
  const quicklinksTarget = useSelector(state => state.saveComponentValue.quicklinks.target, shallowEqual);
  const currentCaseId = useSelector((state) => state.currentCase.case.id, shallowEqual);
  const livePreviewTarget = useSelector(state => state.saveComponentValue.livePreviewTarget)

  useEffect(() => {
    if (bearingCountAnswer) {
      const bearingCount = JSON.parse(bearingCountAnswer.value);
      setBearingCount(bearingCount);
    }
  }, [bearingCountAnswer]);

  useEffect(() => {
    if (isBearingsSaving) {
      setOpenAccordions(['bearingInvestigations']);
    }
  }, [isBearingsSaving]);

  useEffect(() => {
    const compiledConfig = createBIFormConfig(formTemplate);
    const configArray = [];
    for (let i = 0; i < bearingCount; i++) {
      configArray.push(compiledConfig);
    }
    setBearingConfig(configArray);
  }, [bearingCount, formTemplate]);

  useEffect(() => {
    if (!quicklinksTarget.name || quicklinksTarget.name.includes('[-1]')) return

    const parts = quicklinksTarget.name.split(".");
    if (parts.length > 1) {
      const accordions = [parts[1]];
      setOpenAccordions(accordions);
      dispatch(setIsBearingsExpanded(true));
      //Scroll to if only a bearing being opened
      if (parts.length === 2) {
        scrollToTarget(parts[1])
      }
    }
  }, [quicklinksTarget]);

  const toggleBearingInvestigations = () => {
    dispatch(triggerSaveOnAccordian("bearingInvestigations"));
    isBearingsExpanded ? scrollToSection('bearingInvestigations') : scrollToTarget('bearingInvestigations');

    dispatch(setIsBearingsExpanded(!isBearingsExpanded));
    dispatch(setSelectedBearing(''))
  }

  const addInvestigation = () => {
    setBearingCount(bearingCount + 1);
    setValue(bearingCount + 1, `${myName}.count`);

    // Checks if the report is GBI
    if (formTemplate === config?.GBI_TEMPLATE_ID) {
      // Adds default value to a new bearing investigation because if the bearing investigation is 
      // empty and after it we have another bearing investigation with values in it causes a lot of bugs.
      dispatch(addAnswer(currentCaseId,
        JSON.stringify(''),
        `bearingInvestigations.bearingInvestigation[${bearingCount}].bearingSkfCustomerDescription`,
        bearingCount,
        intl
      ))

      // Adds default value to the first bearing investigation because if the bearing investigation 
      // is empty and after it we have another bearing investigation with values in it
      // and the users saves the report it causes a lot of bugs.
      if (!firstBIanySavedValue && !firstBIAnyValue) {
        dispatch(addAnswer(currentCaseId,
          JSON.stringify(''),
          'bearingInvestigations.bearingInvestigation[0].bearingSkfCustomerDescription',
          0,
          intl
        ))
      }
    } else {
      // Adds default value to a new bearing investigation because if the bearing investigation is 
      // empty and after it we have another bearing investigation with values in it causes a lot of bugs.
      dispatch(addAnswer(currentCaseId,
        JSON.stringify(''),
        `bearingInvestigations.bearingInvestigation[${bearingCount}].bearingType.bearingSkfCustomerDescription`,
        bearingCount,
        intl
      ))

      // Adds default value to the first bearing investigation because if the bearing investigation 
      // is empty and after it we have another bearing investigation with values in it
      // and the users saves the report it causes a lot of bugs.
      if (!firstBIanySavedValue && !firstBIAnyValue) {
        dispatch(addAnswer(currentCaseId,
          JSON.stringify(''),
          'bearingInvestigations.bearingInvestigation[0].bearingType.bearingSkfCustomerDescription',
          0,
          intl
        ))
      }
    }
  };

  const toggleAccordion = (id, index) => {
    dispatch(triggerSaveOnAccordian(id));
    if (openAccordions.includes(id)) {
      dispatch(setSelectedBearing(''))
      setOpenAccordions(['bearingInvestigations']);
      dispatch(setBearingsExpandedState("opened"));
      dispatch(setLivePreviewTarget(''))
    } else {
      setOpenAccordions(['bearingInvestigations', id]);
      livePreviewTarget
        ? dispatch(setLivePreviewTarget(generateNewLivePreviewTarget(index)))
        : dispatch(setLivePreviewTarget(`bearingInvestigation[${index}]`))
      dispatch(setSelectedBearing(index))
      scrollToSection(id);
      scrollToTarget(id)
      if (index == bearingCount - 1) {
        dispatch(setBearingsExpandedState("lastChildOpened"));
      } else {
        dispatch(setBearingsExpandedState("childOpened"));
      }
    }
  }

  const getIndexValue = value => {
    const match = value?.match(/\[(.*?)\]/)
    return match ? match[1] : '-1'
  }

  const generateNewLivePreviewTarget = (newIndex) => {
    const firstIndex = livePreviewTarget?.indexOf('[')
    const secondIndex = livePreviewTarget?.lastIndexOf(']')
    const substring = livePreviewTarget?.substring(firstIndex + 1, secondIndex)

    return livePreviewTarget?.replace(substring, newIndex)
  }

  const scrollToSection = target => {
    const nextTarget = livePreviewTarget ? generateNewLivePreviewTarget(getIndexValue(target)) : target

    const allTargetElements = document.querySelectorAll(`[data-section-id*="${nextTarget}"]`).length > 0
      ? Array.from(document.querySelectorAll(`[data-section-id*="${nextTarget}"]`))
      : Array.from(document.querySelectorAll(`[data-section-id*="${target}"]`))
    const element = allTargetElements?.find(el => el.offsetTop > 0)

    const livePreviewContainer = document.querySelector('.page-layout__content--live-preview')

    if (element) {
      livePreviewContainer.scrollTo({
        top: element.offsetTop,
        behavior: 'smooth',
      })
    }
  }

  const scrollToTarget = target => {
    scrollTo(target, 'data-parent-id', window);
  }

  const scrollTo = (target, key, scrollContainer) => {
    if (disableAutoScroll) return

    const { accordionTarget } = quicklinksTarget

    const allElements = Array.from(document.querySelectorAll(`[${key}*="${target}"]`)) || document.querySelectorAll(`[${key}*="${accordionTarget}"]`)
    const element = allElements.find(el => el.offsetTop > 0)

    if (element) {
      scrollContainer.scrollTo({
        top: element.offsetTop,
        behavior: "smooth"
      })
    }
  }

  return (
    <div className="accordion accordion-menu-container" data-parent-id="bearingInvestigations">
      <div className="accordion-trigger-container">
        <AccordionTrigger
          isRoot={false}
          isOpened={isBearingsExpanded}
          isExpanded={false}
          isLast={false}
          isFirst={false}
          isPreviousExpanded={formComponentData.isPreviousExpanded}
          isPreviousOpened={formComponentData.isPreviousOpened}
          toggleAccordion={() => toggleBearingInvestigations()}
          label={intl.formatMessage({ id: "customComponents.bearingInvestigations" })}
          fieldName={myName}
        />
        <div className="accordion-menu">
          <button id="addBearingButton" onClick={addInvestigation} type="button" className="button bearing-add-button">
            <SvgPlus />
          </button>
        </div>
      </div>
      <div className={classnames("accordion__content", { "accordion__content--is-open": isBearingsExpanded },)}>
        {bearingConfig.map((bearing, index) =>
          <BearingInvestigationComponent
            formConfig={bearing}
            key={index}
            index={index}
            openAccordions={openAccordions}
            toggleAccordion={toggleAccordion}
            myName={`${myName}.bearingInvestigation[${index}]`}
          />
        )}
        <BearingSaving />
      </div>
    </div>
  );
}

const BearingSaving = () => {
  const isBearingsSaving = useSelector(state => state.bearingsExpanded.isBearingsSaving, shallowEqual);
  return isBearingsSaving ? <div className="loader-container">
    <div className="element--is-loading element--is-loading-after"></div>
  </div> : null;
}

export default BearingInvestigationsComponent;
