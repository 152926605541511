import { SET_SUMMARY_ATTOP } from "redux/types";
import initialState from "redux/initialState";

export default function showSummaryAtTopReducers(
  state = initialState.showSummaryAtTop,
  action
) {
  switch (action.type) {
      case SET_SUMMARY_ATTOP:
          return action.payload || action.payload === false ? action.payload : state;
      default:
          return state;
  }
}
