import React, { useState } from "react";
import { Auth } from "aws-amplify";
import BartLogo from "components/svg/BartLogo";
import BaseInput from "components/common/BaseInput";
import BaseButton from "components/common/BaseButton";
import { injectIntl, FormattedMessage } from "react-intl";

const ResetPassword = props => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [code, setCode] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [nextStep, setNextStep] = useState(false);

  const requestPassword = event => {
    event.preventDefault();
    Auth.forgotPassword(username.toLowerCase())
      .then(data => {
        setNextStep(true);
        console.log(data);
      })
      .catch(err => alert("Contact BART@skf.com " + err.message));
  };

  const confirmPassword = event => {
    event.preventDefault();
    let continueWithConfirm = true;

    if (
      (!hasLowerCase(password) && hasUpperCase(password)) ||
      (hasLowerCase(password) && !hasUpperCase(password))
    ) {
      setPasswordError(
        "Password has to contain both uppercase and lowercase characters."
      );
      continueWithConfirm = false;
    }

    if (password.length < 8) {
      setPasswordError("Password has to be atleast 8 characters.");
      continueWithConfirm = false;
    }

    if (!/\d/.test(password)) {
      setPasswordError("Password has to contain numbers.");
      continueWithConfirm = false;
    }

    if (continueWithConfirm) {
      Auth.forgotPasswordSubmit(username.toLowerCase(), code, password)
        .then(() => {
          props.history.push("/login");
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  const hasLowerCase = str => {
    return /[a-z]/.test(str);
  };

  const hasUpperCase = str => {
    return /[A-Z]/.test(str);
  };

  const setUserNameText = event => {
    setUsername(event.target.value);
  };

  return (
    <div className="login">
      {!nextStep && (
        <form onSubmit={requestPassword} className="login__form">
          <BartLogo className="login__bart-logo" />

          <h2 className="login__header">
            <FormattedMessage id="resetPassword.presetPasswordStep1" />
          </h2>

          <p>
            <FormattedMessage id="resetPassword.pleaseEnterYourUsernameYouWillThenGetACodeInYourEmailThisCodeHasToBeEnteredOnTheNextPage" />
          </p>

          <BaseInput
            id="login-username"
            value={username}
            handleChange={event => setUserNameText(event)}
            className="login__input-containers"
            placeholder={props.intl.formatMessage({ id: "resetPassword.userNamePlaceholder"})} 
            labelText={props.intl.formatMessage({ id: "resetPassword.userNameLabelText"})} 
            labelTextDark={true}
          />

          <BaseButton
            text={props.intl.formatMessage({ id: "resetPassword.buttonTextSend"})} 
            handleSubmit={requestPassword}
            className="login__button-full-width"
          />
        </form>
      )}

      {nextStep && (
        <form className="login__form">
          <BartLogo className="login__bart-logo" />

          <h2 className="login__header">
            <FormattedMessage id="resetPassword.resetPasswordStep2" />
          </h2>

          <p>
            <FormattedMessage id="resetPassword.youShouldRecieveACodeInYourRegisteredEmailAddressPleaseCheckYourSpamFolderIfYouCantFindIt" />
          </p>

          <p>
            <FormattedMessage id="resetPassword.enterTheCodeBelowAndChooseANewPassword" />
          </p>

          <BaseInput
            id="login-code"
            value={code}
            handleChange={event => setCode(event.target.value)}
            className="login__input-containers"
            placeholder={props.intl.formatMessage({ id: "resetPassword.codePlaceholder"})} 
            labelText={props.intl.formatMessage({ id: "resetPassword.codeLabelText"})}
            labelTextDark={true}
          />

          <BaseInput
            id="login-password"
            value={password}
            handleChange={event => setPassword(event.target.value)}
            className="login__input-containers"
            placeholder={props.intl.formatMessage({ id: "resetPassword.passwordPlaceholder"})}
            labelText={props.intl.formatMessage({ id: "resetPassword.passwordLabelText"})}
            type="password"
            errorMessage={passwordError}
            labelTextDark={true}
          />

          <p>
            <FormattedMessage id="resetPassword.thePasswordHasToContainNumbersUppercaseCharactersAndLowercaseCharacters" />
          </p>

          <BaseButton
            text={props.intl.formatMessage({ id: "resetPassword.buttonTextConfirm"})}
            handleSubmit={confirmPassword}
            className="login__button-full-width"
          />
        </form>
      )}
    </div>
  );
};

export default injectIntl(ResetPassword);
