import React from "react";


const SvgButtonBarZoomOut = ({ title, size = "26px" }) => (
    <svg width={ size } height={ size } viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <title>{title}</title>
        <g id="Icons/icon_zoomout" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path d="M1.05162647,8.97424445 C0.513412709,4.51068252 4.2598026,0.754115034 8.72381083,1.30282714 C11.6787099,1.66160044 14.1270548,4.00417904 14.6230558,6.93767837 C14.9818649,9.07976601 14.3275658,11.0952278 13.0611805,12.5725296 L13.0611805,12.5725296 L13.3566704,12.8574378 L14.1903741,12.8574378 L18.6754888,17.3526562 C19.1081704,17.7852946 19.1081704,18.4817369 18.6754888,18.9143753 L18.6754888,18.9143753 L18.6649356,18.9249274 C18.2322539,19.3575658 17.535742,19.3575658 17.1030603,18.9249274 L17.1030603,18.9249274 L12.6073925,14.4402612 L12.6073925,13.6066409 L12.3224558,13.3111805 C10.8450062,14.5774392 8.82934294,15.2316729 6.68704112,14.8728996 C3.75324847,14.3769483 1.41043564,11.9288481 1.05162647,8.97424445 Z M7.85844753,3.36049753 C5.23069801,3.36049753 3.1095026,5.48148086 3.1095026,8.10896767 C3.1095026,10.7364545 5.23069801,12.8574378 7.85844753,12.8574378 C10.4861971,12.8574378 12.6073925,10.7364545 12.6073925,8.10896767 C12.6073925,5.48148086 10.4861971,3.36049753 7.85844753,3.36049753 Z M11,7 C11.5522847,7 12,7.44771525 12,8 C12,8.55228475 11.5522847,9 11,9 L5,9 C4.44771525,9 4,8.55228475 4,8 C4,7.44771525 4.44771525,7 5,7 L11,7 Z" id="Combined-Shape" fill="#F8F8F8"></path>
        </g>
    </svg>
);

export default SvgButtonBarZoomOut;
