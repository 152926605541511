
import { setCurrentCaseStatus } from "redux/actions/currentCaseActions";
import { approvalEditCase } from "redux/thunks/caseThunks";

export default function approveWithEdits(caseId, dispatchF) {
    return new Promise((resolve, reject) => {
        dispatchF(approvalEditCase(caseId, response => { 
            dispatchF(setCurrentCaseStatus(response.data));
            resolve({caseStatus: response.data})
        }));
    });
}