import React, { Component } from "react";
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from "react-intl";
import AsyncCreatableSelect from "react-select/async-creatable";
import { FixedSizeList as List } from "react-window";
import httpService from "core/http-service";
import classnames from "classnames";
import ReactSelectStyles from "components/formcomponents/ReactSelectStyles";
import _ from "lodash";

const height = 44;

class MenuList extends Component {
  render() {
    const { options, children, maxHeight, getValue } = this.props;
    const [value] = getValue();
    const initialOffset = options.indexOf(value) * height;
    let correctHeight = 0;
    if (height * options.length > maxHeight) {
      correctHeight = maxHeight;
    } else {
      correctHeight = height * options.length;
    }

    return (
      <List
        height={correctHeight}
        itemCount={children.length}
        itemSize={height}
        initialScrollOffset={initialOffset}
      >
        {({ index, style }) => <div style={style}>{children[index]}</div>}
      </List>
    );
  }
}

class AutoCompleteCreatableBigListComponent extends Component {
  state = {
    value: "",
    options: [],
    newOption: ""
  };

  findValueInArray = (array, value) => {
    if (array !== undefined && value !== undefined) {
      return _.find(array, value);
    }
  };

  loadSavedDataAndOptions = () => {
    let savedValue;
    let hasApi = false;
    let hasConfigOptions = false;
    let hasValueLabelParams = false;

    if (
      this.props.parentSetValue !== undefined &&
      this.props.parentSetValue !== null
    ) {
      savedValue = this.props.parentSetValue;
    } else {
      savedValue = this.props.getValue(this.props.myName);
    }

    if (savedValue !== undefined) {
      this.setState({ value: savedValue });
    }

    let apiPath = this.findValueInArray(
      this.props.formComponentData.args,
      "apiPath"
    );
    let valueLabelParams = this.findValueInArray(
      this.props.formComponentData.args,
      "valueLabelParams"
    );

    let configOptions = this.findValueInArray(
      this.props.formComponentData.args,
      "options"
    );

    if (apiPath !== undefined) {
      apiPath = apiPath.apiPath;
      hasApi = true;
    } else {
      hasApi = false;
    }

    if (valueLabelParams !== undefined) {
      valueLabelParams = valueLabelParams.valueLabelParams;
      hasValueLabelParams = true;
    } else {
      hasValueLabelParams = false;
    }

    if (configOptions !== undefined) {
      configOptions = configOptions.options;
      hasConfigOptions = true;
    } else {
      hasConfigOptions = false;
    }

    if (hasApi) {
      this.getOptionsFromAPI(apiPath, { "lang": this.props.language }).then(response => {
        if (response.data !== undefined) {
          let result = response.data.map(option => {
            if (hasValueLabelParams) {
              return {
                value: option[valueLabelParams.value],
                label: option[valueLabelParams.label],
                searchable: option[valueLabelParams.label.toLowerCase()]
              };
            } else {
              return {
                value: option,
                label: option,
                searchable: option.toLowerCase()
              };
            }
          });
          this.setState({ options: result }, () => {
            if (!this.checkIfExistsInArray(savedValue, this.state.options)) {
              if (savedValue !== undefined)
                this.handleCreateNewOption(savedValue.label, true);
            } else {
              return null;
            }
          });
        }
      });
    } else if (!hasApi) {
      if (hasConfigOptions) {
        let options = configOptions.map(option => {
          if (hasValueLabelParams) {
            return {
              value: option[valueLabelParams.value],
              label: option[valueLabelParams.label],
              searchable: option[valueLabelParams.label.toLowerCase()]
            };
          } else {
            return {
              value: option.value,
              label: option.label,
              searchable: option.label.toLowerCase()
            };
          }
        });
        this.setState(
          {
            options: options
          },
          () => {
            if (!this.checkIfExistsInArray(savedValue, this.state.options)) {
              if (savedValue !== undefined)
                this.handleCreateNewOption(savedValue.label, true);
            } else {
              return null;
            }
          }
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  componentDidMount() {
    this.loadSavedDataAndOptions();
  }

  checkIfExistsInArray(value, array) {
    let isFound = false;
    if (value !== undefined && array !== undefined) {
      for (let i = 0; i < array.length; i++) {
        if (array[i].value === value) {
          isFound = true;
        }
      }
    }
    return isFound;
  }

  getOptionsFromAPI = (apiPath, params) => {
    if (apiPath !== undefined) {
      return httpService
        .get(apiPath, params)
        .then(response => {
          return response;
        })
        .catch(error => {
          return error;
        });
    }
  };

  handleChange = value => {
    if (this.props.parentHandleSave !== undefined) {
      this.props.parentHandleSave(value, this.props.myName);
    } else {
      this.props.setValue(value, this.props.myName);
    }
    this.setState({ value: value });
  };

  handleCreateNewOption = (inputValue, skipSave) => {
    // If the user creates their own option, it's added to the options-list.
    if (inputValue !== undefined && inputValue !== "") {
      const { options } = this.state;
      const newOption = {
        label: this.props.formComponentData.key === "skfBearingDesignation" ? inputValue?.toUpperCase() : inputValue,
        value: this.props.formComponentData.key === "skfBearingDesignation" ? inputValue?.toUpperCase() : inputValue,
        searchable: inputValue.toLowerCase()
      };
      this.setState({
        options: [...options, newOption],
        value: newOption,
        newOption: newOption
      });
      if (!skipSave) { 
        if (this.props.parentHandleSave !== undefined) {
          this.props.parentHandleSave(newOption, this.props.myName);
        } else {
          this.props.setValue(newOption, this.props.myName);
        }
      }
    }
  };

  promiseOptions = inputValue => {
    return new Promise((resolve, reject) => {
      let optionsArray = [];
      inputValue = inputValue.toLowerCase();
      if (inputValue !== undefined && inputValue !== "") {
        if (inputValue.length > 0) {
          for (let index = 0; index < this.state.options.length; index++) {
            const option = this.state.options[index];
            if (option.searchable.startsWith(inputValue)) {
              optionsArray.push(option);
            }

            if (optionsArray.length > 50) {
              break;
            }
          }
          resolve(optionsArray);
        }
      } else {
        resolve([]);
      }
    });
  };

  render() {
    return (
      <div className="input-shell">
        {this.props.formComponentData.description && (
          <label
            htmlFor={this.props.formComponentData.name}
            className={classnames(
              "input-shell__label",
              "input-shell__label--dark",
              {
                "input-shell__label--required": this.props.formComponentData
                  .required
              }
            )}
          >
            {this.props.formComponentData.description}
          </label>
        )}
        <div className="input-shell__container" data-id={this.props.myName}>
          <AsyncCreatableSelect
            onChange={event => {
              if (event !== null) {
                // SAVE CORRECTLY
                this.handleChange(event);
              } else {
                //is cleared
                this.handleChange("");
              }
            }}
            placeholder={this.props.intl.formatMessage({
              id: "customComponents.startTypingToSearch"
            })}
            className="select__element"
            value={this.state.value}
            styles={ReactSelectStyles}
            cacheOptions
            defaultOptions
            loadOptions={this.promiseOptions}
            onCreateOption={this.handleCreateNewOption}
            isClearable={true}
          />
        </div>

        {this.props.formComponentData.required && (
          <div className="input-shell__message">
            <div className="input-shell__message-hint">
              {" "}
              <FormattedMessage id="formComponents.required" />
            </div>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    language: state.language
  };
}

export default injectIntl(connect(mapStateToProps)(AutoCompleteCreatableBigListComponent))
