import React, { useState, useEffect } from "react";
import getComponent from '../../_Case/ComponentsList'
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { removeRequiredListKeys } from "redux/actions/requiredListActions";

const ApprovedByComponent = ({ myName, getValue, setValue, formComponentData }) => { 
  const dispatch = useDispatch();

  const [needsApproval, setNeedsApproval] = useState("");
  const [approversName, setApproversName] = useState("");

  const user = useSelector(state => state.currentUser, shallowEqual);

  useEffect(() => { 
    let values = getValue(myName);
    if (values) {
      setNeedsApproval(values.needsApproval);
      setApproversName(values.approversName);
    }
  }, [myName, getValue]);

  const handleChange = (value, name) => { 
    const resultName = name.split(".").pop();
    let objectToSave = { needsApproval, approversName };
    if (resultName === "needsApproval") {
      setNeedsApproval(value);

      if (value === "false") {
        dispatch(removeRequiredListKeys(["skfDetails.reportNeedsApproval.approversName"]));
        objectToSave = { ...objectToSave, approversName: user.email, needsApproval: value };
      } else {
        objectToSave = { ...objectToSave, needsApproval: value };
      }

    } else if (resultName === "approversName") {
      setApproversName(value);
      objectToSave = { ...objectToSave, approversName: value };
    }
    setValue(objectToSave, myName)
  };

  const renderNeedsApproval = (formComponent, index) => {
    if ( formComponent !== undefined) {
      if (formComponent.key === "needsApproval" && needsApproval !== " ") {
        return <div key={index}>{getComponent(formComponent, `${myName}.${formComponent.key}`, handleChange, needsApproval)}</div>
      } else if (formComponent.key === "approversName") {
        if (needsApproval !== undefined && needsApproval === "true") {
          return <div key={index}>{getComponent(formComponent, `${myName}.${formComponent.key}`, handleChange, approversName)}</div>
        }
      }
    }
  }
  
  return (
    <div>
      {formComponentData.args.map( (formComponent, index) => {
        return renderNeedsApproval(formComponent, index)
      })}
    </div>
  );
 
}

export default ApprovedByComponent;