import { SET_CONFIDENTIAL } from "redux/types";
import initialState from "redux/initialState";

export default function isConfidentialReducer(
  state = initialState.isConfidential,
  action
) {
  switch (action.type) {
    case SET_CONFIDENTIAL:
      return action.payload;
    default:
      return state;
  }
}
