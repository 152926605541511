import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import DatePickerComponent from 'components/formcomponents/DatePickerComponent'

const DateRangeFilter = ({ onDateChange, appliedFilters }) => {
  const [fromDate, setFromDate] = useState(new Date())
  const [toDate, setToDate] = useState(new Date())

  useEffect(() => {
    const appliedFrom = appliedFilters[appliedFilters.findIndex(item => item.groupName === 'from')]
    const appliedTo = appliedFilters[appliedFilters.findIndex(item => item.groupName === 'to')]

    setFromDate(appliedFrom ? appliedFrom.date : new Date())
    setToDate(appliedTo ? appliedTo.date : new Date())
  }, [appliedFilters])

  const getDate = name => (
    name === 'from' ? fromDate : toDate
  )

  const datePickerProps = name => ({
    myName: name,
    formComponentData: {
      description: name.charAt(0).toUpperCase() + name.slice(1),
      name
    },
    getValue: getDate,
    setValue: onDateChange
  })

  return (
    <>
      <DatePickerComponent {...datePickerProps('from')} labelClassName="date-range__date-picker_label" isDateRangeFilter />
      <DatePickerComponent {...datePickerProps('to')} labelClassName="date-range__date-picker_label" isDateRangeFilter />
    </>
  )
}

DateRangeFilter.propTypes = {
  onDateChange: PropTypes.func,
  appliedFilters: PropTypes.array
}
 
export default DateRangeFilter
