import React from "react";

const SvgButtonBarApprove = ({ title, size = "26px" }) => (
    <svg width={size} height={size} viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <title>{ title }</title>
        <g id="Icons/icon_approve" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path d="M10,0 C4.48,0 0,4.48 0,10 C0,15.52 4.48,20 10,20 C15.52,20 20,15.52 20,10 C20,4.48 15.52,0 10,0 Z M8,15 L3,10 L4.41,8.59 L8,12.17 L15.59,4.58 L17,6 L8,15 Z" id="-↳Color" fill="#F8F8F8"></path>
        </g>
    </svg>
);

export default SvgButtonBarApprove;
