import React, { useState } from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import classnames from "classnames";

import SvgButtonBarDuplicate from "./../../svg/ButtonBarDuplicate"
import duplicateCase from "./../ButtonActions/duplicateCase";
import DuplicateCaseModal from "components/common/DuplicateCaseModal";

const ButtonDuplicate = ({ buttonSize }) => {

    const connection = useSelector(state => state.connection, shallowEqual);
    const currentCaseStatus = useSelector(state => state.currentCase.case.status, shallowEqual);
    const currentCaseId = useSelector(state => state.currentCase.case.id, shallowEqual);
    const currentCaseAccess = useSelector(state => state.currentCaseAccess, shallowEqual);

    const dispatch = useDispatch();

    const intl = useIntl();
    const [isDuplicating, setIsDuplicating] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [duplicatedCaseId, setDuplicatedCaseId] = useState(null);

    const replaceCaseId = (url, clonedCaseId) => url.replace(/\/case\/\d+$/, `/case/${clonedCaseId}`);

    const onDuplicate = async () => {
        setIsDuplicating(true);
        try {
            const clonedCaseId = await duplicateCase(
                currentCaseId,
                currentCaseStatus,
                currentCaseAccess.edit,
                dispatch,
                intl
            );
            setDuplicatedCaseId(clonedCaseId);
            setShowModal(true);
        } catch (e) {
            console.log('There was an error duplicating case ' + currentCaseId, e);
        } finally {
            setIsDuplicating(false);
        }
    }

    return (
        <>
            <button
                id="buttonDuplicate"
                type="button"
                onClick={onDuplicate}
                className={classnames("button", "button--icon-only", { "button--icon-saving": isDuplicating }, { "button--icon-disabled": !connection })}
                disabled={!connection}
            >
                <SvgButtonBarDuplicate size={buttonSize} title={intl.formatMessage({ id: "formRenderer.duplicate" })} />
            </button>
            {showModal && duplicatedCaseId && (
                <DuplicateCaseModal
                    showModal={showModal}
                    setShowModal={setShowModal}
                    duplicatedCaseId={duplicatedCaseId}
                    formatURL={replaceCaseId}
                />
            )}
        </>
    );
}

export default ButtonDuplicate;