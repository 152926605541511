import React, { memo } from 'react';
import getComponent from 'components/_Case/ComponentsList';

const OtherManufacturersDesignations = memo(({
  manufacturer,
  handleChange,
  competitorDesignationAndMarkings,
  myName,
}) => (
  <>
    {getComponent(
      {
        key: 'competitorDesignationAndMarkings',
        type: 'AutoCompleteCreatable',
        description: 'Competitor designation and markings',
        args: formatOtherManufacturer(manufacturer) ? [
          { apiPath: 'lists/otherManufacturersDesignations/' + formatOtherManufacturer(manufacturer) },
          {
            valueLabelParams: {
              value: 'Designation',
              label: 'Designation',
            },
          }
        ] : []
      },
      `${myName}.competitorDesignationAndMarkings`,
      handleChange,
      competitorDesignationAndMarkings
    )}
  </>
));

const formatOtherManufacturer = (manufacturerValue) => {
  if (manufacturerValue === 'FAG / Schaeffler') {
    return 'FAG';
  } else if (manufacturerValue === 'NSK Ltd') {
    return 'NSK';
  } else if (manufacturerValue === 'Timken') {
    return 'TIMKEN';
  }
  return undefined;
}

export default OtherManufacturersDesignations;
