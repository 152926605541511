import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import classnames from 'classnames'
import TableCellBasic from 'components/common/TableCellBasic'
import LighthouseStatus from 'components/common/LighthouseStatus'

const CasesToSyncTable = ({ casesToSync, linkReports }) => { 
    const intl = useIntl();

    const headers = [
        { displayName: intl.formatMessage({ id: "baseTable.reportNumber" }) },
        { displayName: intl.formatMessage({ id: "baseTable.date" }) },
        { displayName: intl.formatMessage({ id: "baseTable.reportTitle" }) },
        { displayName: intl.formatMessage({ id: "baseTable.syncStatus" }), sortable: false, alignRight: true },
    ];

    const handleClick = (rowId) => { 
        if (linkReports) {
            window.open(`${window.location.protocol}//${window.location.host}/case/${rowId}`, '_blank', 'noopener,noreferrer');
        }
    }

    return (
        <table className="table">
            <thead>
                <tr className="table__header-row">
                    {headers.map((header, index) =>
                        <th key={index} className="table__header-cell">
                            <div className={classnames('table__header-inset', { 'table__header-inset--align-right': header.alignRight })}>{header.displayName}</div>
                        </th>
                    )}
                </tr>
            </thead>
            <tbody>
                {casesToSync?.map((row, i) =>
                    <tr className={classnames("table__body-row", {'showpointer': linkReports})} key={row.id} onClick={() => handleClick(row.id)} data-row-id={row.id}>
                        <TableCellBasic cellValue={String(row.id)} />
                        <TableCellBasic cellValue={row.updated} noWrapClass={true} />
                        <TableCellBasic cellValue={row.title} useTruncate={true} />
                        <TableCellSyncStatus 
                            isSyncing={row.isSyncing} 
                            isComplete={row.isComplete} 
                            isError={row.isSyncError} 
                            isConcurrencyError={row.isConcurrencyError}
                            syncAttempts={row.syncAttempts}
                        />
                    </tr>
                )}
            </tbody>
        </table>
    );
}

const TableCellSyncStatus = ({
    isSyncing,
    isComplete,
    isError,
    isConcurrencyError,
    syncAttempts,
}) => {
    const [label, setLabel] = useState("");
    const [color, setColor] = useState("");

    useEffect(() => {
        if (isSyncing) {
            setColor("yellow");
            setLabel("Syncing");
        } else if (syncAttempts >= 5) {
            setColor("yellow");
            setLabel("Please, contact an administrator");
        } else if (isComplete && !isError) {
            setColor("green");
            setLabel("Complete");
        } else if (isComplete && isError && isConcurrencyError) {
            setColor("red");
            setLabel("Concurrency Error");
        } else if (isComplete && isError) {
            setColor("red");
            setLabel("Error");
        } else { 
            setColor("");
            setLabel("Ready to sync");
        }
    }, [isSyncing, isComplete, isError]);

    return (
        <td className="table__body-cell">
            <div className="table__body-inset table__header-inset--align-right">
                <LighthouseStatus label={label} color={color} />
            </div>
        </td>
    )
}

export default CasesToSyncTable;