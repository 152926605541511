import { SET_CURRENTCASE_ACCESS } from "redux/types";
import initialState from "redux/initialState";

export default function currentCaseAcessReducer(
  state = initialState.currentCaseAccess,
  action
) {
  switch (action.type) {
    case SET_CURRENTCASE_ACCESS:
      return action.payload;
    default:
      return state;
  }
}
