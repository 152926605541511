import React from "react";
import { Link } from "react-router-dom";
import TheNavigation from "components/common/TheNavigation";
import TheHeaderSettings from "components/common/TheHeaderSettings";
import TheApplicationFeedback from "components/common/TheApplicationFeedback";
import TheHeaderBurger from "components/common/TheHeaderBurger";
import SkfLogo from "components/svg/SkfLogo";
import BartLogo from "components/svg/BartLogo";
import PropTypes from "prop-types";
import classnames from "classnames";

const TheHeader = (props) => {
  return (
    <header
      className={classnames("header", {
        "header--is-offline": props.isDisconnected,
      })}
    >
      <div className="header__container">
        <Link
          to={props.childProps.isAuthenticated ? "/dashboard" : "/login"}
          className="header__action header__action--logos-container"
        >
          <SkfLogo className="header__action-skf-logo" />
          {props.isDisconnected}
          <BartLogo className="header__action--bart-logo" />
        </Link>
        {/* <div className="header__action header__action--bart">
          <BartLogo />
        </div> */}

        {props.childProps.isAuthenticated && (
          <React.Fragment>
            <TheNavigation />

            <TheHeaderBurger
              isOpen={false}
              className="header__navigation-burger"
            />

            <TheApplicationFeedback {...props} />

            <TheHeaderSettings />

            {/* <TheHeaderSearch /> */}
          </React.Fragment>
        )}
      </div>
    </header>
  );
};

TheHeader.propTypes = {
  childProps: PropTypes.object,
  isDisconnected: PropTypes.bool,
};

export default TheHeader;
