import React from "react";

const SvgCalendar = props => (
  <svg width={22} height={22} viewBox="0 0 22 22" {...props}>
    <defs>
      <path
        d="M6.821 0c.25 0 .454.204.454.455v1.364h4.547V.455c0-.251.204-.455.455-.455h2.729c.251 0 .454.204.454.455v1.364h3.183c.251 0 .455.204.455.455v4.093H0V2.274c0-.251.203-.455.454-.455h3.183V.455c0-.251.204-.455.455-.455zm7.73.91h-1.819v2.728h1.819V.91zm-8.185 0H4.547v2.728h1.819V.91z"
        id="calendar_svg__a"
      />
    </defs>
    <g fill="#C1C9CC" fillRule="evenodd">
      <path d="M9.094 15.916a6.828 6.828 0 016.821-6.821 6.78 6.78 0 013.183.793V7.276H0v9.549c0 .252.203.455.454.455h8.777a6.815 6.815 0 01-.137-1.364" />
      <path d="M18.396 16.37H16.37v2.026a.455.455 0 01-.91 0V16.37h-2.025a.455.455 0 010-.909h2.025v-2.026a.455.455 0 01.91 0v2.026h2.026a.455.455 0 110 .91m-2.481-6.367a5.918 5.918 0 00-5.911 5.911 5.918 5.918 0 005.911 5.912 5.918 5.918 0 005.911-5.912 5.918 5.918 0 00-5.911-5.91" />
      <use xlinkHref="#calendar_svg__a" />
    </g>
  </svg>
);

export default SvgCalendar;
