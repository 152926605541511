import React from "react";

const SvgEllipsis = props => (
    <svg width="38px" height="20px" viewBox="0 0 38 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g id="icon_ellipsis" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <rect fill="transparent" x="0" y="0" width="38" height="20"></rect>
            <g id="Group" transform="translate(0.000000, 0.000000)" fillRule="nonzero">
                <rect id="Rectangle" fill={props.background ? props.background : "#88C008"} x="0" y="0" width="37.6470588" height="20" rx="10"></rect>
                <circle id="Oval" fill="#FFFFFF" cx="12.5708235" cy="10.2178706" r="1.98257647"></circle>
                <circle id="Oval" fill="#FFFFFF" cx="18.2354118" cy="10.2178706" r="1.98257647"></circle>
                <circle id="Oval" fill="#FFFFFF" cx="23.8995294" cy="10.2178706" r="1.98257647"></circle>
            </g>
        </g>
    </svg>
);

export default SvgEllipsis;