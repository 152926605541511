import React from 'react';

const IconSevere = ({ title = "" }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		fill="currentColor"
		focusable="false"
		height="100%"
		width="100%"
	>
		 <title>{title}</title>
		<g fill="none">
			<path
				d="m11.433.586 8.019 8.019a2 2 0 0 1 0 2.828l-8.019 8.019a2 2 0 0 1-2.828 0l-8.02-8.019a2 2 0 0 1 0-2.828l8.02-8.02a2 2 0 0 1 2.828 0Z"
				fill="#C00"
			/>
			<path
				d="m9.26 10.686-.22-3.274a24.374 24.374 0 0 1-.061-1.374c0-.379.099-.674.297-.886.198-.211.46-.317.783-.317.392 0 .654.135.786.406s.198.662.198 1.173c0 .3-.016.606-.048.916l-.294 3.37c-.031.4-.1.708-.205.923a.537.537 0 0 1-.52.321c-.245 0-.416-.104-.512-.311-.096-.207-.164-.523-.205-.947ZM10.006 15.184c-.275 0-.515-.09-.72-.27-.205-.18-.307-.432-.307-.755 0-.283.098-.523.294-.721a.972.972 0 0 1 .719-.298c.284 0 .526.1.726.298.2.198.3.438.3.72 0 .32-.1.57-.303.753-.203.182-.44.273-.71.273Z"
				fill="#FFF"
			/>
		</g>
	</svg>
);

export default IconSevere;