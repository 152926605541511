import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { useIntl } from "react-intl";


const RadioCheckboxComponent = ({ myName, parentSetValue, getValue, parentHandleSave, setValue, formComponentData }) => { 
  const intl = useIntl();

  const [checked, setChecked] = useState(undefined);

  useEffect(() => {
    let value = undefined;
    if (parentSetValue !== undefined && parentSetValue !== null) {
      value = parentSetValue;
    } else {
      value = getValue(myName);
    }

    if (value !== undefined && value !== "") {
      setChecked(JSON.parse(value));
    } else {
      setChecked(undefined);
    }
  }, [myName, parentSetValue, getValue]);

  const isChecked = (index, checked) => {
    if ( checked === undefined) {
      return false;
    } else {
      if ( checked) {
        if (index === 0) {
          return true
        } else if (index === 1) {
          return false
        }
      } else {
        if (index === 0) {
          return false
        } else if (index === 1) {
          return true
        }
      }
    }
  }

  const handleChange = (e, index) => {
    let targetChecked = e.target.checked
    let finalChecked

    if (checked !== undefined) {
      if (checked ) {
        if ( targetChecked ) {
          finalChecked = false
        } else {
          finalChecked = undefined
        }
      } else if (!checked) {
        if ( targetChecked ) {
          finalChecked = true
        } else {
          finalChecked = undefined
        }
      } 
    } else {
      if (index === 0) {
        finalChecked = true
      } else {
        finalChecked = false
      }
    }
    setChecked(finalChecked);

    if (parentHandleSave !== undefined) {
        parentHandleSave(JSON.stringify(finalChecked), myName);
      } else {
        if (finalChecked !== undefined) {
          setValue(JSON.stringify(finalChecked), myName);
        } else {
          setValue("", myName);
        }
      }
  };

  return (
    <div className="input-shell">
      {formComponentData.description && (<label
        htmlFor={formComponentData.description}
        className={classnames('input-shell__label', 'input-shell__label--dark', { 'input-shell__label--required': formComponentData.required })}>
        {formComponentData.description}
      </label>)}
      <div className="input-shell checkbox">
        <input
          id={`${myName}.yes`}
          type="checkbox"
          checked={isChecked(0, checked)}
          onChange={e => handleChange(e, 0)}
          className="checkbox__hidden"
        />
        <label
          htmlFor={`${myName}.yes`}
          className="checkbox__label checkbox__label--dark"
          tabIndex="0"
        >
          <span className="checkbox__label-span">{intl.formatMessage({ id: "customComponents.yes" })}</span>
        </label>
        <input
          id={`${myName}.no`}
          type="checkbox"
          checked={isChecked(1, checked)}
          onChange={e => handleChange(e, 1)}
          className="checkbox__hidden"
        />
        <label
          htmlFor={`${myName}.no`}
          className="checkbox__label checkbox__label--dark"
          tabIndex="1"
        >
          <span className="checkbox__label-span">{intl.formatMessage({ id: "customComponents.no" })}</span>
        </label>
      </div>
      {formComponentData.required && (<div className="input-shell__message">
        <div className="input-shell__message-hint">
          *Required
        </div>
      </div>)}
    </div>
  );

}

export default RadioCheckboxComponent;
