import config from "./../../../config";
import { getCaseDataRepositoryWithoutOffline } from "core/caseRepository/caseRepositoryFactory";
import localforage from "localforage";
import { apiPaths } from "Configs/config-builder";

let formTemplateIds = [config.BART_TEMPLATE_ID, config.GBI_TEMPLATE_ID, config.INSPECTION_TEMPLATE_ID, config.US_INSPECTION_TEMPLATE_ID, config.WIND_TEMPLATE_ID, config.BI_BART_TEMPLATE_ID, config.BI_GBI_TEMPLATE_ID, config.BI_INSPECTION_TEMPLATE_ID, config.BI_US_INSPECTION_TEMPLATE_ID, config.BI_WIND_TEMPLATE_ID];

const syncDataForOffline = () => { 
     return Promise.all([getFormTemplates(), getApiPaths(), getCasesInDraft()])
}

export const getCasesInDraft = async () => { 
    // await clearCases()
    const repository = getCaseDataRepositoryWithoutOffline()
    
    const result = await repository.getCasesDraftForUser(20);
    await Promise.all(result.data.reduce((alladditions, caseWithAnswer) =>
        [
            ...alladditions,
            localforage.setItem(`case/${caseWithAnswer.case.id}`, { data: caseWithAnswer.case }),
            localforage.setItem(`casedata/${caseWithAnswer.case.id}`, { data: caseWithAnswer.answers }),
            localforage.setItem(`caseaccess/${caseWithAnswer.case.id}`, { data: caseWithAnswer.access })
        ]
        , []));
    
    return result;
}

const clearCases = async () => {
    const itemsToRemove = [];
    await localforage.iterate((value, key) => {
        if (key.includes("case/")) {
            itemsToRemove.push(key);
        }
    })
    await Promise.all(itemsToRemove.map(key => localforage.removeItem(key)));
}

const getApiPaths = () => { 
    const repository = getCaseDataRepositoryWithoutOffline()
    return Promise.all(apiPaths.map(apiPath =>
        repository.getList(apiPath).then(response => localforage.setItem(apiPath, { data: response.data }))));
}

const getFormTemplates = async () => { 
    const repository = getCaseDataRepositoryWithoutOffline()
    const response =  await repository.getFormTemplates(formTemplateIds);
    await Promise.all(response.data.map(ft => localforage.setItem(`formtemplate/${ft.id}`, { data: ft })));
}

export default syncDataForOffline;