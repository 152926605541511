import React from "react";

const SvgCanvasRotate = props => (
    <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g id="Icons/icon_rotate" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path d="M9.84184583,2.12662076 L9.84184583,0.38732715 C9.84184583,-0.049925433 9.27934583,-0.263693362 8.95642917,0.0472418076 L6.0397625,2.75820782 C5.83142917,2.9525423 5.83142917,3.25376075 6.0397625,3.44809523 L8.9460125,6.15906124 C9.27934583,6.46027968 9.84184583,6.24651176 9.84184583,5.80925917 L9.84184583,4.06996557 C13.2897625,4.06996557 16.0918458,6.68376434 16.0918458,9.9 C16.0918458,10.6676212 15.9355958,11.415809 15.6335125,12.0862629 C15.4772625,12.436065 15.5918458,12.8344507 15.8730958,13.0968022 C16.4043458,13.5923551 17.3001792,13.4174541 17.5814292,12.7664336 C17.9668458,11.8822117 18.1751792,10.9105393 18.1751792,9.9 C18.1751792,5.60520797 14.4460125,2.12662076 9.84184583,2.12662076 Z M9.84184583,15.7300344 C6.39392917,15.7300344 3.59184583,13.1162357 3.59184583,9.9 C3.59184583,9.1323788 3.74809583,8.38419105 4.05017917,7.71373709 C4.20642917,7.36393502 4.09184583,6.96554934 3.81059583,6.70319779 C3.27934583,6.20764486 2.3835125,6.38254589 2.1022625,7.0335664 C1.71684583,7.91779541 1.5085125,8.8894607 1.5085125,9.9 C1.5085125,14.194792 5.23767917,17.6733792 9.84184583,17.6733792 L9.84184583,19.4126729 C9.84184583,19.8499254 10.4043458,20.0636934 10.7272625,19.7527582 L13.6335125,17.0417922 C13.8418458,16.8474577 13.8418458,16.5462393 13.6335125,16.3519048 L10.7272625,13.6409388 C10.4043458,13.3397203 9.84184583,13.5534882 9.84184583,13.9907408 L9.84184583,15.7300344 Z" id="Shape" fill="#F8F8F8" transform="translate(9.841846, 9.900000) rotate(-360.000000) translate(-9.841846, -9.900000) "></path>
            <circle id="Oval" fill="#F8F8F8" cx="10" cy="10" r="2"></circle>
        </g>
    </svg>
);

export default SvgCanvasRotate;
