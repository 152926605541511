function rootReducer(state = {}, action) {
    switch (action.type) {
      case 'SERVICE_WORKER_INIT':
        return {
          ...state,
          serviceWorkerInitialized: !state.serviceWorkerInitialized,
        };
      case 'SERVICE_WORKER_UPDATE':
        console.log(action.payload)
        return {
          ...state,
          serviceWorkerUpdated: !state.serviceWorkerUpdated,
          serviceWorkerRegistration: action.payload,
        };
      default:
        return state;
    }
  }
  
  export default rootReducer;