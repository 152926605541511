import React, { Component } from "react";
import PropTypes from "prop-types";

const EnvironmentBar = (props) => { 
    const messageText = process.env.REACT_APP_STAGE == "stage" ?
        "BART Staging Environment. For production please go to" :
        "BART Dev Environment. For production please go to";
    
    return (
        process.env.REACT_APP_STAGE !== "prod" ? (<div className="environment-bar">
            <div className="environment-bar__container">
                {messageText}&nbsp;<a href="https://bart.skf.com" >bart.skf.com</a>
            </div>
        </div>) : null
    );
}

EnvironmentBar.propTypes = {
    
};

export default EnvironmentBar;