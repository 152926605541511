import React, { Component, useEffect, useRef, useState } from "react";

import arrayMove from "array-move";
import classnames from "classnames";
import repository from "core/repository";
import localforage from "localforage";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { setImageToDelete } from "redux/actions/saveComponentValueAction";
import { SortableContainer, SortableElement } from "react-sortable-hoc";

import _ from 'lodash';
import SvgAdd from "../../svg/Add";
import SvgImageEdit from "../../svg/ImageEdit";
import FileUploadMultiple from "./../multiupload/FileUploadMultiple";
import ImageLayoutSelectComponent from "./ImageLayoutSelectComponent";
import FigureModalWrapper from "../figuretool/FigureModalWrapper";

const maxImageSize = 6999999;

class FileImageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      urlItems: [],
      imageLayout: {},
      isModalOpen: false,
      indexModal: 0,
      isLoading: false,
    };
  }

  componentDidMount() {
    let savedValue;
    if (
      this.props.parentSetValue !== undefined &&
      this.props.parentSetValue !== null
    ) {
      savedValue = this.props.parentSetValue;
    } else {
      savedValue = this.props.getValue(this.props.myName);
    }
    if (savedValue !== undefined) {
      this.setState({
        urlItems: savedValue.urlItems,
        imageLayout: savedValue.imageLayout,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.parentSetValue !== this.props.parentSetValue) {
      let savedValue;
      if (
        this.props.parentSetValue !== undefined &&
        this.props.parentSetValue !== null
      ) {
        savedValue = this.props.parentSetValue;
      } else {
        savedValue = this.props.getValue(this.props.myName);
      }
      if (savedValue !== undefined) {
        // if condition is a bugfix for disappearing images when dropping connection during upload
        if (this.state.urlItems.length <= savedValue.urlItems?.length) {
          this.setState({
            urlItems: savedValue.urlItems,
            imageLayout: savedValue.imageLayout,
          });
        }
      }
    }
  }

  layoutChange = (value) => {
    this.setState({ imageLayout: value });
    if (this.props.parentHandleSave !== undefined) {
      this.props.parentHandleSave(value, this.props.myName + ".imageLayout");
    } else {
      this.props.setValue(value, this.props.myName + ".imageLayout");
    }
  };

  fileAdded = async (res) => {
    if (this.props.connection) {
      this.setState({
        isLoading: true,
      });
      try {
        const response = await repository.postImage(this.props.caseId, res);
        this.setState({
          isLoading: false,
        });
        if (response !== undefined) {
          this.addImageURLtoUrlItems(response.data);
        }
      } catch (error) {
        alert(error);
        await this.addOfflineImage(res);
        this.setState({
          isLoading: false,
        });
      }
    } else {
      await this.addOfflineImage(res);
    }
  };

  addOfflineImage = async (res) => {
    this.setState({
      isLoading: true,
    });
    const res2 = res.split(",")[1];
    const blob = this.b64toBlob(res2, "");
    const imageURI = URL.createObjectURL(blob);

    let offlineImageId = this.generateOfflineImgId(imageURI);
    await localforage.setItem(offlineImageId, res);
    this.setState({
      isLoading: false,
    });
    if (imageURI !== undefined) {
      this.addImageURLtoUrlItems({
        url: imageURI,
        originalUrl: null,
        hasOriginal: false,
      });
    }
  };

  generateOfflineImgId(imageURI) {
    return "offline-img-" + imageURI;
  }

  addImageURLtoUrlItems = (imageData) => {
    let allUrls = [...this.state.urlItems];
    allUrls.push({
      url: imageData.url,
      originalUrl: imageData.originalUrl,
      hasOriginal: imageData.hasOriginal,
      number: "",
      caption: "",
    });

    this.props.parentHandleSave(allUrls, this.props.myName);
    this.setState({
      urlItems: allUrls,
      isLoading: false,
    });
  };

  onClosedUpload = () => {
    this.saveImageList(this.state.urlItems);
  };

  b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  saveImageList = (urls) => {
    if (Array.isArray(urls) && urls.length > 0) {
      if (this.props.parentHandleSave !== undefined) {
        this.props.parentHandleSave(urls, this.props.myName);
      } else {
        this.props.setValue(urls, this.props.myName);
      }
    }
  };

  setItems = (items) => {
    this.setState({
      urlItems: items,
    });
    if (this.props.parentHandleSave !== undefined) {
      this.props.parentHandleSave(items, this.props.myName);
    } else {
      this.props.setValue(items, this.props.myName);
    }
  };

  setItem = (item) => {
    let urlItems = JSON.parse(JSON.stringify(this.state.urlItems));
    let itemToChange = urlItems[this.state.indexModal];

    // copy of itemToChange
    const originalItem = JSON.parse(JSON.stringify(itemToChange));

    if (!itemToChange) return;

    itemToChange = {
      ...itemToChange,
      caption: item.caption || item.caption === '' ? item.caption : itemToChange.caption,
      number: item.number || item.number === '' ? item.number : itemToChange.number,
      url: item.url ? item.url : itemToChange.url,
      uneditedUrl: item.uneditedUrl ? item.uneditedUrl : itemToChange.uneditedUrl,
      shapes: item.shapes ? item.shapes : itemToChange.shapes,
      effects: item.effects ? item.effects : itemToChange.effects,
    };

    urlItems[this.state.indexModal] = itemToChange;

    this.setState({ urlItems });

    if (_.isEqual(originalItem, itemToChange)) return;

    this.props.parentHandleSave
      ? this.props.parentHandleSave(urlItems, this.props.myName)
      : this.props.setValue(urlItems, this.props.myName);
  };

  deleteImage = (index) => {
    let urlItems = JSON.parse(JSON.stringify(this.state.urlItems));
    this.props.setImageToDelete(urlItems[index]);
    urlItems.splice(index, 1);

    this.setState({
      urlItems: urlItems,
    });
    if (this.props.parentHandleSave !== undefined) {
      this.props.parentHandleSave(urlItems, this.props.myName);
    } else {
      this.props.setValue(urlItems, this.props.myName);
    }
  };

  shouldShowModal = (index) => {
    this.setState({
      indexModal: index,
      isModalOpen: true,
    });
  };

  closeModal = () => {
    this.setState({
      isModalOpen: false,
    });
  };

  render() {
    return (
      <div className="input-shell">
        {this.props.formComponentData.description && (
          <div className="fileimage-header">
            <h3 className="page-layout__sub-header" htmlFor={this.props.myName}>
              {this.props.formComponentData.description}
            </h3>
            <ImageLayoutSelectComponent
              onChange={this.layoutChange}
              selectedValue={this.state.imageLayout}
            />
          </div>
        )}

        <SortableComponent
          items={this.state.urlItems}
          setItems={this.setItems}
          shouldShowModal={this.shouldShowModal}
          connection={this.props.connection}
          isLoading={this.state.isLoading}
          fileAdded={this.fileAdded}
          onClosed={this.onClosedUpload}
        />

        <FigureModalWrapper
          onChange={this.setItem}
          onDeleteImage={this.deleteImage}
          imageItems={this.state.urlItems}
          caseId={this.props.caseId}
          currentIndex={this.state.indexModal}
          onModalClose={this.closeModal}
          showModal={this.state.isModalOpen}
          myName={this.props.myName}
        />
      </div>
    );
  }
}

const SortableItem = SortableElement(
  ({ value, index2, shouldShowModal, connection }) => {
    const itemRef = useRef({});

    useEffect(() => {
      if (connection) {
        itemRef.current.style.backgroundImage = `url(${value.url}?${Date.now()})`;
      }
    }, [connection]);

    const itemStyles = {
      backgroundPosition: "50% 50%",
      backgroundImage: `url(${value.url})`,
    };

    return (
      <React.Fragment>
        <div
          ref={itemRef}
          style={itemStyles}
          className={"image-selector__item"}
          onClick={() => (connection ? shouldShowModal(index2) : null)}
          key={index2}
        >
          <div className="image-selector__overlay" />
          <SvgImageEdit className="image-selector__button image-selector__editbutton" />
        </div>
      </React.Fragment>
    );
  }
);

const SortableList = SortableContainer(
  ({ items, shouldShowModal, connection, fileAdded, isLoading, onClosed }) => {
    const isMobile = window.matchMedia("( max-width:  766px )").matches;
    const [showUpload, setShowUpload] = useState(false);

    const addImages = () => {
      setShowUpload(true);
    };

    const closeModal = () => {
      setShowUpload(false);
      onClosed();
    };

    return (
      <div className="image-selector">
        {items.map((value, index) => (
          <SortableItem
            key={`item-${index}`}
            index={index}
            value={value}
            index2={index}
            shouldShowModal={shouldShowModal}
            connection={connection}
            disabled={isMobile}
          />
        ))}
        <label
          onClick={() => addImages()}
          className={classnames(
            "image-selector__item",
            "image-selector__item-add",
            { "element--is-loading element--is-loading-after": isLoading }
          )}
          key={999}
        >
          <div className="image-selector__overlay"></div>
          <SvgAdd className="image-selector__button image-selector__addbutton"></SvgAdd>
        </label>
        <FileUploadMultiple
          showDialog={showUpload}
          onFileAdded={(blob) => fileAdded(blob)}
          onModalClose={() => closeModal()}
        />
      </div>
    );
  }
);

class SortableComponent extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    items: [],
  };

  componentDidUpdate(prevProps) {
    if (prevProps.items !== this.props.items) {
      this.setState({
        items: this.props.items,
      });
    }
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(
      ({ items }) => ({
        items: arrayMove(items, oldIndex, newIndex),
      }),
      () => {
        this.props.setItems(this.state.items);
      }
    );
  };

  render() {
    return (
      <React.Fragment>
        <SortableList
          items={this.state.items}
          onSortEnd={this.onSortEnd}
          distance={2}
          axis={"xy"}
          shouldShowModal={this.props.shouldShowModal}
          connection={this.props.connection}
          fileAdded={this.props.fileAdded}
          isLoading={this.props.isLoading}
          onClosed={this.props.onClosed}
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    connection: state.connection,
    useAutomaticFigureNumbering:
      state.saveComponentValue.useAutomaticFigureNumbering,
    caseId: state.currentCase.case.id,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setImageToDelete: (image) => dispatch(setImageToDelete(image)),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(FileImageComponent));
