import React, { Component } from "react";
import getComponent from "../../_Case/ComponentsList";
import { setIsRailwayWheelsetBearing } from "../../../redux/actions/isRailwayWheelsetBearingActions";
import { getBearingIndexFromName } from "../utils/BearingUtils"
class RailwayWheelsetBearingComponent extends Component {
  constructor(props) {
    super(props);
    let values = this.props.getValue(this.props.myName);
    if (values !== undefined && values != null) {
      this.state = {
        isRailwayWheelsetBearing: values.isRailwayWheelsetBearing,
        axelLoad: values.axelLoad,
        trainSpeed: values.trainSpeed,
        wheelDiameter: values.wheelDiameter,
        unsprungMass: values.unsprungMass,
      }
      this.props.dispatch(
        setIsRailwayWheelsetBearing({ isRailwayBearing: values.isRailwayWheelsetBearing, bearingIndex: getBearingIndexFromName(this.props.myName) })
      );
    } else { 
      this.state = {
        isRailwayWheelsetBearing: false,
        axelLoad: "",
        trainSpeed: "",
        wheelDiameter: "",
        unsprungMass: "",
      };
      this.props.dispatch(
        setIsRailwayWheelsetBearing({ isRailwayBearing: false, bearingIndex: getBearingIndexFromName(this.props.myName) })
      );
    }
    
  }

  
  handleChange = (value, name) => {
    const parts = name.split(".");
    const resultName = parts[parts.length - 1];
    let objectToSave = {
      isRailwayWheelsetBearing: this.state.isRailwayWheelsetBearing,
      axelLoad: this.state.axelLoad,
      trainSpeed: this.state.trainSpeed,
      wheelDiameter: this.state.wheelDiameter,
      unsprungMass: this.state.unsprungMass,
    };
    switch (resultName) {
      case "isRailwayWheelsetBearing":
        this.setState({
          isRailwayWheelsetBearing: value,
        });
        objectToSave.isRailwayWheelsetBearing = value;
        this.props.dispatch(setIsRailwayWheelsetBearing(value));
        break;
      case "axelLoad":
        this.setState({
          axelLoad: value,
        });
        objectToSave.axelLoad = value;
        break;
      case "trainSpeed":
        this.setState({
          trainSpeed: value,
        });
        objectToSave.trainSpeed = value;
        break;
      case "wheelDiameter":
        this.setState({
          wheelDiameter: value,
        });
        objectToSave.wheelDiameter = value;
        break;
      case "unsprungMass":
        this.setState({
          unsprungMass: value,
        });
        objectToSave.unsprungMass = value;
        break;
      default:
        console.log("Unexpected component: " + resultName);
    }

    this.props.setValue(objectToSave, this.props.myName);
  };

  railwayWheelsetBearing = (formComponent, index) => {
    if (formComponent !== undefined) {
      switch (formComponent.key) {
        case "isRailwayWheelsetBearing":
          return (
            <div key={index}>
              {getComponent(
                formComponent,
                `${this.props.myName}.${formComponent.key}`,
                this.handleChange,
                this.state.isRailwayWheelsetBearing
              )}
            </div>
          );
        case "axelLoad":
          if (
            this.state.isRailwayWheelsetBearing !== undefined &&
            this.state.isRailwayWheelsetBearing === true
          ) {
            return (
              <div key={index}>
                {getComponent(
                  formComponent,
                  `${this.props.myName}.${formComponent.key}`,
                  this.handleChange,
                  this.state.axelLoad
                )}
              </div>
            );
          }
          break;
        case "trainSpeed":
          if (
            this.state.isRailwayWheelsetBearing !== undefined &&
            this.state.isRailwayWheelsetBearing === true
          ) {
            return (
              <div key={index}>
                {getComponent(
                  formComponent,
                  `${this.props.myName}.${formComponent.key}`,
                  this.handleChange,
                  this.state.trainSpeed
                )}
              </div>
            );
          }
          break;
        case "wheelDiameter":
          if (
            this.state.isRailwayWheelsetBearing !== undefined &&
            this.state.isRailwayWheelsetBearing === true
          ) {
            return (
              <div key={index}>
                {getComponent(
                  formComponent,
                  `${this.props.myName}.${formComponent.key}`,
                  this.handleChange,
                  this.state.wheelDiameter
                )}
              </div>
            );
          }
          break;
        case "unsprungMass":
          if (
            this.state.isRailwayWheelsetBearing !== undefined &&
            this.state.isRailwayWheelsetBearing === true
          ) {
            return (
              <div key={index}>
                {getComponent(
                  formComponent,
                  `${this.props.myName}.${formComponent.key}`,
                  this.handleChange,
                  this.state.unsprungMass
                )}
              </div>
            );
          }
          break;
        default:
          console.log("Unexpected component: " + formComponent.key);
      }
    }
  };

  isRailwayUser = () => { 
    if (!this.props.user["custom:authorizedrailway"]) {
      return false;
    } else { 
      if (this.props.user["custom:authorizedrailway"]) {
        return true;
      } else {
        if (this.props.user["custom:authorizedrailway"].toLowerCase() === "true") {
          return true;
        } else { 
          return false;
        }
      }
    }

  }

  render() {
    if (this.isRailwayUser()) {
      return (
        <div>
          {this.props.formComponentData.args.map((formComponent, index) => {
            return this.railwayWheelsetBearing(formComponent, index);
          })}
        </div>
      );
    } else {
      return null;
    }
  }

  isRailwayUserSet(authorizedrailway) { 
    if (authorizedrailway === undefined || authorizedrailway === null) {
      return false;
    }
    if (typeof authorizedrailway === "boolean") {
      return authorizedrailway;
    }
    if (typeof authorizedrailway === "string") {
      return String(authorizedrailway).toLowerCase() === 'true';
    }
  }
}

export default RailwayWheelsetBearingComponent;
