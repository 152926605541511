
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import React, { useEffect} from "react";
import activateOnlineOfflineDetection from "./Utils/offlineDetection";
import { setConnection } from "redux/actions/connectionActions";
import syncDataForOffline from "./Utils/syncDataForOffline";

const Offline = () => { 

    const dispatch = useDispatch();
    const user = useSelector(state => state.currentUser, shallowEqual);

    activateOnlineOfflineDetection(isConnected => handleConnected(isConnected), hasBeenOffline => handleBeenOffline(hasBeenOffline));

    useEffect(() => { 
        if (user.sub) { 
            syncDataForOffline().catch(console.error);
        }
    }, [user]);

    const handleConnected = (isConnected) => { 
        console.log("OFFLINE: handleConnected", isConnected);
        dispatch(setConnection(isConnected));
    }

    const handleBeenOffline = (hasBeenOffline) => { 
        console.log("OFFLINE: handleBeenOffline", hasBeenOffline);
        dispatch(setConnection(true));
        
    }
    return null;
}

  
export default Offline;

