import React from "react";

const SvgSavingIndicator = ({ color = "#88C008", title }) => (
  <svg width={22} height={18} viewBox="0 0 22 18">
    <title>{ title }</title>
    <path
      d="M.328 9.636l2.646-3.911a.907.907 0 011.285 0l2.97 3.55a.909.909 0 11-1.395 1.165L4.538 8.889c.133 3.645 3.13 6.572 6.808 6.572a6.823 6.823 0 005.103-2.296.909.909 0 111.36 1.208 8.65 8.65 0 01-6.463 2.908c-4.54 0-8.264-3.523-8.605-7.977l-.904 1.345a.908.908 0 11-1.51-1.013M10.828 0c4.539 0 8.264 3.522 8.605 7.976l.903-1.346a.91.91 0 011.51 1.015L19.2 11.556a.91.91 0 01-1.286 0l-2.971-3.55a.91.91 0 011.395-1.167l1.295 1.551c-.131-3.644-3.13-6.57-6.806-6.57-1.95 0-3.81.835-5.105 2.295a.91.91 0 01-1.36-1.208A8.65 8.65 0 0110.828 0z"
      fill={ color }
      fillRule="evenodd"
    />
  </svg>
);

export default SvgSavingIndicator;
