import React, { Component } from "react";
import classnames from "classnames";
import ReactSelectStyles from "components/formcomponents/ReactSelectStyles";
import Select from "react-select";
import { injectIntl } from "react-intl";

class SelectValueRangeUnitComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: ""
    };
  }

  componentDidMount() {
    let values;
    if (
      this.props.parentSetValue !== undefined &&
      this.props.parentSetValue !== ""
    ) {
      values = this.props.parentSetValue;
    } else {
      values = this.props.getValue(this.props.myName);
    }

    if (values !== undefined && values !== null) {
      if (
        values.hasOwnProperty("value") &&
        values.hasOwnProperty("unitSelected")
      ) {
        let translatedUnits = this.translateOptions(this.props.formComponentData.args[0].options);

        this.setState({
          units: translatedUnits,
          value: values.value,
          unitSelected: values.unitSelected
        });
      }
    } else {
      let translatedUnits = this.translateOptions(this.props.formComponentData.args[0].options);

      this.setState({
        units: translatedUnits,
        value: "",
        unitSelected: ""
      });
    }
  }

  translateOptions = options => {
    if (options !== undefined) {
      let translatedUnits;
      return (translatedUnits = options.map(
        option => {
          return {
            value: option.value,
            label: this.props.intl.formatMessage({
              id: `${option.value}`
            })
          };
        }
      ));
    } else {
      return null;
    }
  };

  handleChange = (event, select) => {
    if (select !== undefined) {
      this.setState({
        unitSelected: event
      });

      let currentValue = {
        value: this.state.value,
        unitSelected: event
      };
      if (this.props.parentHandleSave !== undefined) {
        this.props.parentHandleSave(currentValue, this.props.myName);
      } else {
        this.props.setValue(currentValue, this.props.myName);
      }
    } else {
      this.setState({
        value: this.formatRange(event.target.value)
      });
    }
  };

  formatRange = value => {
    value = value.replace(/[^0-9+-]/g, '');
    const parts = value.split("-").filter(s => s.length > 0);
    if (parts.length > 2) {
      return parts.slice(0, 2).join("-");
    } else {
      return value;  
    }
    
  }

  _onBlur = () => {
    let currentValue = {
      value: this.state.value,
      unitSelected: this.state.unitSelected
    };
    if (this.props.parentHandleSave !== undefined) {
      this.props.parentHandleSave(currentValue, this.props.myName);
    } else {
      this.props.setValue(currentValue, this.props.myName);
    }
  };

  render() {
    return (
      <div className="input-shell">
        {this.props.formComponentData.description && (
          <label
            htmlFor={this.props.myName}
            className={classnames(
              "input-shell__label",
              "input-shell__label--dark",
              {
                "input-shell__label--required": this.props.formComponentData
                  .required
              }
            )}
          >
            {this.props.formComponentData.description}
          </label>
        )}

        <div className="input-shell__container input-shell__container--double-inputs">
          <input
            onChange={event => this.handleChange(event)}
            onBlur={this._onBlur}
            type="text"
            className="input__element"
            placeholder={
              this.props.formComponentData.hasOwnProperty("placeholder")
                ? this.props.formComponentData.placeholder
                : ""
            }
            value={this.state.value !== null ? this.state.value : ""}
          />
          <Select
            onChange={value => {
              if (value !== null) {
                this.handleChange(value, "select");
              } else {
                //is cleared
                this.setState(
                  {
                    unitSelected: "",
                    value: ""
                  },
                  this.handleChange("", "select")
                );
              }
            }}
            className="select__element"
            styles={ReactSelectStyles}
            placeholder={this.props.intl.formatMessage({id: "customComponents.select"})}
            value={this.state.unitSelected}
            options={this.state.units}
            isClearable={true}
          />
        </div>
      </div>
    );
  }
}

export default injectIntl(SelectValueRangeUnitComponent);
