import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";

import classnames from "classnames";
import generatePdf from "../ButtonActions/generatePdf";

import SvgButtonBarPdf from "../../svg/ButtonBarPdf"

class ButtonPdf extends Component {
    constructor(props) {
        super(props);

        this.state = {
            answers: [],
            case: {},
            isDownloadingPdf: false
        }
    }

    componentDidMount() {
        this.setState({
            answers: this.props.answers,
            case: this.props.currentCase,

        });
        this.setStateFromProps();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.currentCase != this.props.currentCase) {
            this.setStateFromProps();
        }
        if (prevProps.currentCase || this.props.currentCase) {
            if (prevProps.currentCase.revision_number != this.props.currentCase.revision_number) {
                this.setStateFromProps();
            }
        }
        if (prevProps.answers != this.props.answers) {
            this.setStateFromProps();
        }
        if (prevProps.excludeISOAppendix != this.props.excludeISOAppendix) {
            this.setStateFromProps();
        }
    }

    setStateFromProps() {
        this.setState({
            case: this.props.currentCase,
            answers: this.props.answers,
            revisionNumber: this.props.currentCase.revision_number,
            status: this.props.currentCase.status,
            excludeISOAppendix: this.props.excludeISOAppendix
        });
    }

    onDownloadPdf = () => {
        this.setState({ isDownloadingPdf: true })
        generatePdf(
            this.state.case.id,
            this.state.revisionNumber,
            this.state.status,
            this.props.currentFormTemplateID,
            this.state.excludeISOAppendix,
            this.props.language,
            this.state.answers,
            true,
            document.querySelector(".live-preview").outerHTML).then(() => {
                this.setState({ isDownloadingPdf: false })
            });
    }

    isLoading = () => this.state.isDownloadingPdf;
    isDisabled = () => !this.props.connection || this.props.approvingState;

    render() {
        return (
            <>
                <button
                    id="buttonDownloadPdf"
                    type="button"
                    onClick={() => this.onDownloadPdf()}
                    className={classnames("button", "button--icon-only", { "button--icon-saving": this.isLoading() }, { "button--icon-disabled": this.isDisabled() })}
                    disabled={this.isDisabled()}>
                    <SvgButtonBarPdf size={this.props.buttonSize} title={this.props.intl.formatMessage({ id: "formRenderer.createPdf" })} />
                </button>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        currentCase: state.currentCase.case,
        answers: state.saveComponentValue.components,
        connection: state.connection,
        language: state.language,
        excludeISOAppendix: state.excludeISOAppendix
    };
}

export default injectIntl(connect(mapStateToProps)(ButtonPdf));