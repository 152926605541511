export default function (value) { 
    if (!value)
        return false;
    if (value == null)
        return false;
    
    if (value.constructor === Object) {
        value = value.value;
    }
    
    let parsedValue = JSON.parse(value);
    if (!parsedValue.country)
        return false;
    
    return true;
}