import React, { useEffect, useState, useMemo } from "react";
import { useIntl } from 'react-intl';
import { useSelector, shallowEqual } from "react-redux";
import { createSelector } from 'reselect'
import classnames from "classnames";
import SectionMenu from "./SectionMenu";

const selectRelevantAnswers = () => createSelector(
    (state) => state.saveComponentValue.components,
    (_, formComponent, parentName) => (parentName ? parentName + "." : "") + formComponent.key,
    (answers, fieldName) => answers.filter(a => a.name.startsWith(fieldName))
)
  
const SectionAccordion = ({
    formComponent,
    openAccordions,
    calculateName,
    getFormComponent,
    parentName, children}) => {
    
    const [visibleComponents, setVisibleComponents] = useState([]);
    const [sectionsToAdd, setSectionsToAdd] = useState([]);
    const fieldName = (parentName ? parentName + "." : "") + formComponent.key;
    const isInvalid = useSelector(state => !state.saveComponentValue.validationErrors.filter(ve => ve.key.startsWith(fieldName)).every(ve => ve.valid), shallowEqual);
    const answerSelector = useMemo(selectRelevantAnswers, []);;
    const answers = useSelector(state => answerSelector(state, formComponent, parentName));
    const quicklinksTarget = useSelector(state => state.saveComponentValue.quicklinks.target, shallowEqual);

    const intl = useIntl();

    const isVisible = name => {
        if (visibleComponents.find(c => c.name === name && c.isVisible && !c.setAsDefault)) {
            return true;
        }
        if (answers) {
            const answer = answers.find(a => a.name === name);
            if (answer) {
                const answerValue = JSON.parse(answer.value);
                if (answerValue.observations !== "" || answerValue.imageUrls.length > 0) {
                    return true;
                }
            }
        }
        if (quicklinksTarget && quicklinksTarget.name) {
            return quicklinksTarget.name === name;
        }
        return false;
    }

    useEffect(() => {
        
        const components = formComponent.components.map(c => (
            {
                name: calculateName(c, calculateName(formComponent, parentName)),
                id: c.key,
                component: c,
            }
        )).map(c => (
            {
                ...c,
                isVisible: isVisible(c.name),
                setAsDefault : false
            }
        ));

        if (components.filter(c => c.isVisible).length == 0) {
            components[0].isVisible = true;
            components[0].setAsDefault = true;
        }

        setVisibleComponents(components);
        setSectionsToAdd(components
            .filter(c => !c.isVisible)
            .map(c => ({ label: intl.formatMessage({ id: c.id }), value: c }))
        );
    }, [answers, quicklinksTarget]);


    const addSection = value => {
        sectionsToAdd.splice(sectionsToAdd.findIndex(i => i.value.name === value.name), 1);
        visibleComponents.find(c => c.name === value.name).isVisible = true;
        console.log(visibleComponents);
        setVisibleComponents([...visibleComponents]);
        setSectionsToAdd(sectionsToAdd);
    };

    

    return (
        <div className="accordion-menu-container" data-parent-id={formComponent.key}>
            <div className="accordion accordion--bearing">
                <div className="accordion-trigger-container">
                    { children }
                    {openAccordions.includes(formComponent.key) && <SectionMenu sections={sectionsToAdd} onAddSection={addSection} />}
                </div>
                <div className={classnames("accordion__content", { "accordion__content--is-open": openAccordions.includes(formComponent.key) }, { "is-invalid": isInvalid })}>
                    <div className="accordion__content-form">
                        {visibleComponents
                            .map(c => ({...c, formComponent: getFormComponent(c.component, calculateName(formComponent, parentName)) }) )
                            .filter(c => c.formComponent !== null)
                            .map((item, index) => <div style={{ display: item.isVisible ? "block" : "none" }} key={index}>{item.formComponent}</div>)}
                    </div>
                </div>
            </div>
        </div>);
}

export default SectionAccordion;