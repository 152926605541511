import { SET_CURRENT_CASE,SET_CURRENT_CASE_STATUS, SET_CURRENT_ADD_CASE_TO_SAVE, SET_CURRENT_REMOVE_CASE_TO_SAVE } from "redux/types";
import initialState from "redux/initialState";

export default function currentCaseReducer(
  state = initialState.currentCase,
  action
) {
  switch (action.type) {
    case SET_CURRENT_CASE:
      return { case: { ...state.case, ...action.payload }, updates: state.updates};
    case SET_CURRENT_CASE_STATUS:
      return { case: { ...state.case, status: action.payload }, updates: state.updates };
    case SET_CURRENT_ADD_CASE_TO_SAVE:
      return { ...state, updates: [...state.updates, action.payload] };
    case SET_CURRENT_REMOVE_CASE_TO_SAVE:
      const updates = [...state.updates];
      updates.shift();
      return { ...state, updates: updates.map(c => ({...c, updatedAt: action.payload })) };
    default:
      return state;
  }
}
