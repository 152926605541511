import React, { useEffect, useState } from "react";
import getComponent from "../../_Case/ComponentsList";
import classnames from "classnames";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import httpService from "core/http-service";
import repository from "core/repository";
import { FormattedMessage, useIntl } from "react-intl";
import AsyncSelect from "react-select/async";
import ReactSelectStyles from "components/formcomponents/ReactSelectStyles";
import { addValidationError, removeValidationError } from "redux/actions/saveComponentValueAction";
import { isInternalUser, isUserAdmin } from "../utils/userUtils";
import config from "config";

const EndUserComponent = ({ myName, getValue, setValue, parentSetValue, parentHandleSave, formComponentData }) => { 
  const intl = useIntl();
  const dispatch = useDispatch();

  const [endUser, setEndUser] = useState(null);
  const [selectedEndUser, setSelectedEndUser] = useState(null);
  const [distributor, setDistributor] = useState(null);
  const [distributorDisplay, setDistributorDisplay] = useState(null);
  const [territoryManager, setTerritoryManager] = useState("");
  const [industrySpecialist, setIndustrySpecialist] = useState("");
  const [vendor, setVendor] = useState({});
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [vendorOptions, setVendorOptions] = useState([]);
  const [approver, setApprover] = useState(null);
  const [allusers, setAllUsers] = useState([]);
  const [endUsersForExternal, setEndUsersForExternal] = useState(null);
  const [allEndUsers, setAllEndUsers] = useState(null);

  const endUserValidation = useSelector(state => state.saveComponentValue.validationErrors.find(ve => ve.key === `${myName}.endUser`), shallowEqual);
  const currentUser = useSelector(state => state.currentUser);

  const setStateFormValue = (value) => { 
    setEndUser(value.endUser);
    setDistributor(value.distributor);
    setDistributorDisplay(value.distributor ? !value.distributor.isCustom ?
      { isLocked: true, value: `${value.distributor.name} ${value.distributor.city}, ${value.distributor.state}` } :
      { isLocked: false, value: value.distributor.value } : null);
    setTerritoryManager(value.endUser ? value.endUser.territoryManager ? value.endUser.territoryManager.name : "" : "");
    setIndustrySpecialist(value.endUser ? value.endUser.industrySpecialist ? value.endUser.industrySpecialist.name : "" : "");
    setVendor(value.vendor);
  }

  useEffect(() => {
    const loadVendors = async () => {
      const response = await httpService.get("us-inspectiondata/vendors?useremail=douglas.a.smith@skf.com");
      if (response.data !== undefined) {
        const valueLabelParams = "name+city+state+contact_name"
        const options  = response.data.map(option => ({
          value: option,
          label: valueLabelParams.split("+").map(lpart => option[lpart]).filter(part => part && part.length > 0).join(", ")
        }))
        setVendorOptions(options);
      }
    };

    const loadVendorsForExternalUser = async () => {
      const response = await httpService.get("us-inspectiondata/vendors/external");
      if (response.data) {
        const valueLabelParams = "name+city+state+contact_name"
        const options = response.data.map(option => ({
          value: option,
          label: valueLabelParams.split("+").map(lpart => option[lpart]).filter(part => part && part.length > 0).join(", ")
        }))
        setVendorOptions(options);
      }
    };
    if (!currentUser) return;
    if (!isInternalUser(currentUser['custom:external'])) {
      loadVendorsForExternalUser().catch(err => console.log(err));
      return;
    }

    loadVendors().catch(err => console.log(err));
  }, []);

  useEffect(() => {
    const getAllEndUsers = async () => {
      const response = await httpService.get("/us-inspectiondata/endusers/all");
      if (response.data) {
        setAllEndUsers(response.data.map(getEndUserOption).filter(e => e?.value?.name));
      }
    };
    const isExternalWithVendorsMatching = currentUser
      && !isInternalUser(currentUser['custom:external'])
      && !isUserAdmin(currentUser['custom:administrator'])
      && vendor?.value?.name?.includes(currentUser['custom:custom:vendor']);
    // get end users only if the current user's vendor is equal to the selected one
    if (isExternalWithVendorsMatching) {
      getAllEndUsers().catch(e => console.log(e));
    }
  }, [vendor?.value?.name]);

  useEffect(() => {
    const loadUsers = async () => { 
      const response = await repository.getListsUsers();
      if (response.data) { 
        setAllUsers(response.data);
      }
    }

    const fetchEndUsers = async () => {
      const response = await httpService.get("/us-inspectiondata/endusers");
      if (response.data) {
        const result = response?.data?.map(getEndUserOption);
        setEndUsersForExternal(result);
      }
    }

    loadUsers().catch(err => console.log(err));
    fetchEndUsers().catch(err => console.log(err));
  }, [currentUser, vendor?.value?.name]);

  useEffect(() => {
    let loadedValue = null;
    if (parentSetValue !== undefined && parentSetValue !== null) {
      loadedValue = parentSetValue;
      if (loadedValue === "") {
        loadedValue = null;
      }
    } else {
      loadedValue = getValue(myName);
    }

    if (loadedValue) {
      setStateFormValue(loadedValue);
      if (loadedValue.endUser) {
        setSelectedEndUser({ label: `${loadedValue.endUser.name}, ${loadedValue.endUser.city}`, value: loadedValue.endUser });
      }
      if (loadedValue.vendor && loadedValue.vendor.value) {
        setSelectedVendor({ label: `${loadedValue.vendor.value.name}, ${loadedValue.vendor.value.city}, ${loadedValue.vendor.value.state}, ${loadedValue.vendor.value.contact_name}`, value: loadedValue.vendor });
      }
      if (loadedValue.approver) { 
        setApprover(loadedValue.approver);
      }
    }
  }, [parentSetValue, getValue, myName]);

  const handleChange = (value, name) => {
    let resultName = name.split(".").pop();
    let objectToSave = { endUser, distributor, vendor, approver };

    if (resultName === "endUser") {
      if (value) {
        objectToSave = { ...objectToSave, endUser: value.value, distributor: distributor ? distributor.isCustom ? value.value.distributor : distributor : value.value.distributor };
        setSelectedEndUser(value);
        if (allusers && value.value.industrySpecialist && value.value.industrySpecialist.email) { 
          const foundUser = allusers.find(u => u.email.toLocaleLowerCase() === value.value.industrySpecialist.email.toLocaleLowerCase());
          objectToSave = { ...objectToSave, approver: foundUser };
          setApprover(foundUser);
        }
        dispatch(removeValidationError(`${myName}.endUser`));
      } else {
        objectToSave = { ...objectToSave, endUser: null, distributor: distributor ? !distributor.isCustom ? null : distributor : null };
        setSelectedEndUser(value);
        dispatch(addValidationError({ key: `${myName}.endUser`, valid: false, description: "End User" }));
      }
    } else if (resultName === "distributor") {
      if (value.isLocked) {
        objectToSave.distributor = endUser ? endUser.distributor : null;
      } else {
        objectToSave = { ...objectToSave, distributor: { isCustom: true, value: value.value } };
      }
      if (value.value && value.value.length > 0 || value.isLocked) {
        dispatch(removeValidationError(`${myName}.distributor`)); 
      } else {
        dispatch(addValidationError({ key: `${myName}.distributor`, valid: false, description: "Distributor" }));
      }
    } else if (resultName === "vendor") { 
      if (value) {
        objectToSave = { ...objectToSave, vendor: value };
        dispatch(removeValidationError(`${myName}.vendor`));
      } else {
        objectToSave = { ...objectToSave, vendor: null };
        dispatch(addValidationError({ key: `${myName}.vendor`, valid: false, description: "Vendor" }));
      }
      setSelectedVendor(value);
    }

    setStateFormValue(objectToSave);
    if (parentHandleSave !== undefined) {
      parentHandleSave(objectToSave, myName);
    } else {
      setValue(objectToSave, myName);
    }
  }

  const renderEndUser = formComponent => { 
    if (formComponent.key === "endUser") {
      return renderEndUserComponent(formComponent);
    } else if (formComponent.key === "territoryManager" && territoryManager !== "") {
      return getComponent(
        formComponent,
        `${myName}.${formComponent.key}`,
        handleChange,
        territoryManager
      );
    } else if (formComponent.key === "industrySpecialist" && industrySpecialist !== "") {
      return getComponent(
        formComponent,
        `${myName}.${formComponent.key}`,
        handleChange,
        industrySpecialist
      );
    } else if (formComponent.key === "distributor" && distributor !== null) {
      return getComponent(
        formComponent,
        `${myName}.${formComponent.key}`,
        handleChange,
        distributorDisplay
      );
    } else if (formComponent.key === "vendor") {
      return getComponent(
        {
          ...formComponent,
          args: [{
            options: vendorOptions
          }],
          translate: false
        },
        `${myName}.${formComponent.key}`,
        handleChange,
        selectedVendor);
    } else {
      return <></>;
    }
  };

  const renderEndUserOptions = () => {

    // for external/nam users with vendor attribute in cognito matching the one that is selected we have to show all end users
    // for external/nam users without vendor attribute in cognito we have to show only the end users matching the user's company
    if (currentUser && !isUserAdmin(currentUser['custom:administrator']) && !isInternalUser(currentUser['custom:external'])) {
      return (
        <>
          {getComponent(
            {
              key: 'endUser',
              type: 'AutoComplete',
              translate: false,
              description: 'End User',
              required: true,
              args: [{ options: vendor?.value?.name.includes(currentUser['custom:custom:vendor']) ? allEndUsers : endUsersForExternal }]
            },
            `${myName}.endUser`,
            handleChange,
            selectedEndUser
          )}
        </>
      )
    }
    // show all end users for internals and administrators
    return (
      <>
        <label className="input-shell__label input-shell__label--dark input-shell__label--required">
          <FormattedMessage id="endUser" />
        </label>
        <AsyncSelect
          onChange={event => handleChange(event, "skfDetails.endUser.endUser")}
          placeholder={intl.formatMessage({ id: "enduser.placeholder" })}
          className="select__element"
          formatOptionLabel={formatOptionLabel}
          value={selectedEndUser}
          styles={ReactSelectStyles}
          cacheOptions="true"
          loadOptions={getEndUsers}
          isClearable={true}
        />
      </>
    )
  }

  const renderEndUserComponent = formComponent => {
    //Adding to required list by getting the component
    getComponent(formComponent, `${myName}.${formComponent.key}`, null, null);

    return (
      <div className={classnames("endUser", "group", "componentContainer-linebreak", { "invalid": endUserValidation ? !endUserValidation.valid : false })}>
        <div className="input-shell">
          <div className="input-shell__container">
            {renderEndUserOptions()}
          </div>
        </div>
      </div>
    )
  };

  const getEndUsers = async inputValue => {
      if (inputValue !== undefined && inputValue !== "") {
        if (inputValue.length > 2) {
          inputValue = inputValue.toLocaleLowerCase();
          const response = await httpService.get("/us-inspectiondata/endusers");
          const result = response.data
            .filter(eu =>
                eu.name.toLocaleLowerCase().includes(inputValue) || eu.city.toLocaleLowerCase().includes(inputValue) ||
                (eu.territoryManager.name ? eu.territoryManager.name.toLocaleLowerCase().includes(inputValue) : false) ||
                (eu.industrySpecialist.name ? eu.industrySpecialist.name.toLocaleLowerCase().includes(inputValue) : false))
            .map(getEndUserOption);
          
          return result;
        }
    }
    return []
  };

  const getEndUserOption = endUser => ({
    label: `${endUser.name}, ${endUser.city}`,
    value: {
      ...endUser,
      industrySpecialist: config.US_INSPECTION_TEMPLATE_ID === 218 && endUser.industrySpecialist.email === "John.F.Della.Villa@skf.com" ? {
        name: "Tobias Linde",
        email: "tobias.linde@sigma.se"
      } : endUser.industrySpecialist
    }
  });
    
  const formatOptionLabel = ({ value, label }) => (
    <div className="option">
      <div style={value !== endUser ? { fontWeight: "bold" } : {} }>{label}</div>
      {value !== endUser ? <>
        <div className="sub">
          <span className="header">{intl.formatMessage({ id: "tm" })}:</span> {value.territoryManager.name}
        </div>
        <div className="sub">
          <span className="header">{intl.formatMessage({ id: "is" })}:</span> {value.industrySpecialist.name}
        </div>
      </> : <></>}  
    </div>
  );

  return formComponentData.args.map(formComponent => {
    return (
      <React.Fragment key={formComponent.key}>
        {renderEndUser(formComponent)}
      </React.Fragment>
    );
  });

}

export default EndUserComponent;