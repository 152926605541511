import { Auth } from "aws-amplify";
export default class FeatureUserController { 
    constructor(featureControllerDef) { 
        this.featureControllerDef = featureControllerDef;
    }

    isFeatureEnabled = async () => {
        const user = await Auth.currentAuthenticatedUser();
        return user.attributes[this.featureControllerDef.property].toLocaleString().toLocaleLowerCase() === this.featureControllerDef.equals.toLocaleString().toLocaleLowerCase();
    }
}