
import React, { useState, useEffect } from "react";
import ReactModal from "react-modal";
import BaseButton from "./../common/BaseButton";
import { injectIntl } from "react-intl";

const ConfirmModal = ({ message, isOpened, onYes, onNo, intl }) => {
    return (<ReactModal isOpen={isOpened} className="react-modal" overlayClassName="react-modal-overlay">
        <div className="react-modal__header">
          
        </div>

        <div className="react-modal__body">
          <div className="react-modal__text">
            { message }
          </div>

          <div className="react-modal__actions-container">
            <BaseButton
              text={intl.formatMessage({id : "customComponents.yes"})}
              backgroundColor="gray"
              className="react-modal__action-button"
                handleSubmit={() => { 
                    onYes();
                }}
            />

            <BaseButton
            text={intl.formatMessage({id : "customComponents.no"})}
              backgroundColor="green"
              className="react-modal__action-button"
              handleSubmit={() => {
                  onNo();
              }}
            />
          </div>
        </div>
      </ReactModal>)
}

export default injectIntl(ConfirmModal);