import { SET_CURRENT_FORMCONFIG } from "redux/types";
import initialState from "redux/initialState";

export default function currentFormConfigReducer(
  state = initialState.currentFormConfig,
  action
) {
  switch (action.type) {
    case SET_CURRENT_FORMCONFIG:
      return action.payload;
    default:
      return state;
  }
}
