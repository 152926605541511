const STATUS_DRAFT = 'Draft'
const STATUS_APPROVED = 'Approved'
const STATUS_NOT_APPROVED = 'Rejected'
const STATUS_SUBMITTED_FOR_APPROVAL = 'Submitted'
const STATUS_SUBMITTED_FOR_APPROVAL_EDITED = 'approvalEdited'

export {
  STATUS_DRAFT,
  STATUS_APPROVED,
  STATUS_NOT_APPROVED,
  STATUS_SUBMITTED_FOR_APPROVAL,
  STATUS_SUBMITTED_FOR_APPROVAL_EDITED
}
