import { fabric } from "fabric";

export default function rotateImage(file) {
    return new Promise((resolve, reject) => {
        const canvas = new fabric.Canvas();
        const imageUrl = `data:${file.type};base64,${file.body}`;
        var imgObject = new Image();
        imgObject.src = imageUrl;
        imgObject.onload = () => {
            var image = new fabric.Image(imgObject);
            canvas.setWidth(image.height);
            canvas.setHeight(image.width);
            canvas.setBackgroundImage(image, canvas.renderAll.bind(canvas), {
                angle: 90,
                left: image.height
            });

            const base64value = canvas.toDataURL({
                format: "jpeg"
            });
            resolve(getFileObject(base64value,file));
        };    
    });
}

function getFileObject(base64value, file) {
    const parts = base64value.split(";");
    const typeParts = parts[0].split(":");
    const imageDataParts = parts[1].split(",");

    return {
        ...file,
        type: typeParts[1],
        size: imageDataParts[1].length,
        body: imageDataParts[1],
        loading: false,
        deleted: false
    };
}