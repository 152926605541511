import React from 'react'

// This component could be rendered both in a Modal or in License Agreement Page.
const LicenseAgreementText = ({ renderedOn }) => (
  <div className={renderedOn === 'modal' ? 'license-text_modal' : 'license-text_page'}>
    <div>
      <p style={{ color: 'salmon', fontWeight: 'bold', textAlign: 'center', fontSize: '.9em' }}>SKF CONFIDENTIAL INFORMATION, NOT TO BE DISTRIBUTED OR COPIED</p>
      <h3 className='license-heading'>SKF BART (Bearing Analysis Report Tool) Inspection software</h3>
      <h4 className='license-subheading'>License agreement</h4>
    </div>
    <p>
      This <b>License</b> agreement <b>(“Agreement”)</b> is a legal agreement between <b>You</b> and <b>SKF</b>.
    </p>
    <p>
      The terms of this <b>Agreement</b>, including all its attachments, shall remain in full force and effect and apply to You if you access or otherwise use the Bearing Analysis Report Tool Inspection software program.
    </p>

    <h4 className='la_chapter'>1: Definitions</h4>

    <p>As used in this <b>Agreement</b>, the terms listed below shall have the following meanings:</p>

    <p>“<b>SKF</b>” shall mean the SKF company named on the facing page of this <b>Agreement</b>.</p>

    <p>“<b>You</b>” and “<b>Your</b>” shall mean the party consenting to the terms of this <b>Agreement</b>.</p>

    <p>“<b>Program</b>” shall mean the “<b>BART (Bearing Analysis Report Tool) Inspection software program,</b>”and the process described in <span className='la_attachment'>Attachment A</span>.</p>
    <p>
      “<b>Bearing inspection</b>” is the examination and documentation of a used bearing’s condition for consideration of its possible re-use, possible remanufacturing, certification for re-sale, or for purposes of understanding the conditions leading to the removal of the bearing from service in cooperation with an <b>SKF</b> analyst. A bearing inspection can be used for new bearings to verify their suitability for use. Please note that a bearing inspection conducted through the <b>BART Inspection software</b> is not a complete bearing damage analysis.
    </p>

    <p>
      “<b>Bearing damage analysis</b>” (BDA) is the technique to investigate the condition of a used bearing to determine the failure mode(s) and cause(s) of any damage and make recommendations for remedial actions to prevent reoccurrence. BDA can be a part of a root cause failure analysis (RCFA) and root cause analysis (RCA).
    </p>

    <p>
      “<b>BART (Bearing Analysis Report Tool) Inspection report</b>” a report produced by data that <b>You</b> capture using the <b>BART Inspection software</b>.
    </p>

    <p>
      “<b>BART (Bearing Analysis Report Tool) Inspection software</b>” is a <b>SKF</b> proprietary cloud based software program for generating inspection reports on new and used bearings. It reports the condition of the bearing to document if the bearing can be reused, can potentially be remanufactured for return to use, or if the bearing should be scrapped. It can also be used to document the condition of new bearings before use. <b>The BART Inspection software</b> is not to be used to a create bearing damage analysis reports.
    </p>

    <p>
      “<b>Subject Matter Personnel</b>” (SMP) is a person trained and well experienced in a particular area or topic.
    </p>

    <h4 className='la_chapter'>2: Purpose and Scope</h4>

    <p>
      <b>SKF</b> is in the business of, among other things, manufacturing, distributing, and promoting its rolling bearings, seals, lubricants and related products and services. <b>SKF</b> has a <b>Program</b> pursuant to which users can inspect bearings and make a report of their inspection using the <b>BART Inspection software</b>. This <b>Agreement</b> grants <b>You</b> direct access to a <b>SKF</b> SMP through the <b>BART Inspection software</b> for review and approval of the <b>BART Inspection report</b>. The <b>SKF</b> SMP will review your <b>BART Inspection report</b> and advise if <b>Your</b> determinations of the observations, failure mode(s) and cause(s) and the disposition of the bearing(s) are correct. The <b>SKF</b> SMP will work with you to complete the inspection report as needed. The <b>SKF</b> SMP will “Approve” the report when appropriate and assist <b>You</b> to make disposition of the bearing. The Bearing Inspection report can assist with a bearing damage analysis report by a <b>SKF</b> SMP but is not a substitute for a bearing damage analysis report. <b>SKF</b> SMP performs complete bearing damage analysis on a paid fee basis.
    </p>

    <p>
      A prerequisite for participation in the <b>Program</b> is that <b>You</b> must have a basic knowledge of rolling bearings and bearing failure modes after completion of a <b>SKF</b> bearing damage workshop training (virtual or classroom). Your use of the <b>BART Inspection software</b> to create a Bearing Inspection report represents that you have completed this training.
    </p>

    <p>
      The benefit of the <b>Program</b> to <b>You</b> is a systematic way to make professional bearing inspection reports with the assistance of a <b>SKF</b> SMP. <b>Your</b> use can potentially save <b>You</b> costs if the bearing(s) can potentially be reused, remanufactured. In the case of inspection of new bearings, it will help <b>You</b> determine whether the bearing can be reliably used. Multiple persons from <b>Your</b> company can be registered in the Program to enhance and share knowledge. The data collected for the reports is stored in an SKF database. Upon request, <b>Your</b> data (csv file) can be provided to <b>You</b> for <b>Your</b> own analytics of <b>Your</b> company’s bearing usage and inspection information.
    </p>

    <p>
      In the case of large size bearings (Bore &ge; 200mm) that are identified by the <b>BART Inspection software</b> to be a potential for remanufacture <b>You</b> can email the <b>BART Inspection report</b> directly to the local <b>SKF</b> Remanufacturing Centre (Solution Factory) for review after consulting with the <b>SKF</b> SMP.  If approved  for remanufacturing, the Remanufacturing Centre can immediately issue a return authorization for the bearing to be shipped directly the Remanufacturing Centre to speed up the process.
    </p>

    <p>The <b>Program</b> is for <b>SKF</b> and non-<b>SKF</b> bearings.</p>

    <p>
      This <b>Agreement</b> represents the entire understanding and agreement between <b>You</b> and <b>SKF</b> with respect to <b>SKF’s</b> and <b>Your</b> rights and obligations pertaining to the <b>Program</b> and the use of the <b>BART Inspection software</b>.
    </p>

    <p>
      No franchise or distributorship is intended to be created by this <b>Agreement</b>, and no fees or other consideration have been paid or provided by <b>You</b> to <b>SKF</b> for a franchise or distributorship.
    </p>

    <h4 className='la_chapter'>3: License granted</h4>

    <p>
      Subject to the terms and conditions of this <b>Agreement</b>, and for so long as <b>You</b> remain in compliance with this <b>Agreement</b>, <b>SKF</b> grants to <b>You</b> a non-exclusive and non-transferable, non-sub licensable, license to use the <b>BART Inspection software</b> relating to the <b>Program</b>.
    </p>

    <h4 className='la_chapter'>4: Requirements for license</h4>

    <p>
      <b>You</b> may use the <b>BART Inspection software</b> and resulting <b>BART Inspection reports</b> subject to this <b>Agreement</b>. However, <b>You</b> may not use any <b>SKF</b> trademarks, service marks, or other logos in any way aside from those included in the <b>BART Inspection</b> report.
    </p>

    <p>
      <b>You</b> shall not distribute the report made with the <b>BART Inspection software</b> to a third party without the expressed written permission of <b>SKF</b>, unless <b>You</b> are authorized by <b>SKF</b> to make bearing inspections for a third party(s). <b>You</b> shall inform <b>SKF</b> in writing if you intend to inspect bearings for a third party(s) and the name(s) and address(s) of the third party(s) in advance of performing any inspections. <b>SKF</b> can deny You the right to use the <b>BART Inspection software</b> to make BART Inspection reports for a certain third party(s) if by <b>You</b> making the bearing inspection causes <b>SKF</b> a business conflict.
    </p>

    <p>
      <b>You</b> may not receive payment from any third party for making a <b>BART Inspection report</b> as part of a business.
    </p>

    <p>
      The <b>BART Inspection report</b> may not be used in or for litigation with a third party(s) or <b>SKF</b>. <b>You</b> shall inform <b>SKF</b> in the event a bearing inspection is part of a health or safety claim or financial claim prior to consulting with <b>SKF</b>.
    </p>

    <p>
      <b>You</b> agree and understand that <b>SKF</b> has the right to change, at any time, the <b>Program</b> process in Attachment A, including those for obtaining and maintaining any <b>Program</b> participation, or <b>SKF</b> may change or cancel the <b>Program</b> itself with or without cause at any time. In addition, <b>SKF</b> reserves the right to revise the terms of this <b>Agreement</b> from time to time. In the event this <b>Agreement</b> is revised, Your agreement by signing or otherwise manifesting assent to a new agreement may be a condition of continued use of the <b>BART Inspection software</b>. Your continued use of the <b>BART Inspection software</b> will also be your agreement to any changes to the terms.
    </p>

    <p>
      As a further requirement under the <b>Program</b>, <b>You</b> promise to <b>SKF</b> that <b>You</b>:
    </p>

    <ul>
      <li>
        Will not engage in any deceptive, misleading, or unethical practices, and that <b>You</b> will otherwise conduct business in a manner that reflects favorably at all times on the <b>Program</b> and the reputation of SKF;
      </li>
      <li>
        Will not misrepresent <b>Your</b> relationship with <b>SKF</b> or <b>Your</b> level of skill and knowledge related to  bearing inspections, and to the best of Your ability <b>You</b> will provide correct and accurate information for the bearing(s) being inspected and how they are used and how you will use the inspection report; and
      </li>
      <li>
        Will not make any representations, warranties, or guarantees to others (third party) on behalf of <b>SKF</b> or <b>SKF’s</b> own products or services otherwise.
      </li>
    </ul>

    <p>
      In the event that <b>SKF</b> determines that <b>You</b> are not meeting acceptable levels of quality and/or integrity in its conduct or services, SKF will advise <b>You</b> and provide <b>You</b> with an opportunity to remedy such defect, provided however, that if the situation is critical in nature, as determined by <b>SKF</b>, or if <b>You</b> dispute <b>SKF’s</b> determination or fail to initiate corrective measures promptly or otherwise fail to remedy the defect within a time period determined by <b>SKF</b>, then <b>SKF</b> may immediately terminate this <b>Agreement</b> and all rights granted to <b>You</b> under this <b>Agreement</b>.
    </p>

    <h4 className='la_chapter'>5: SKF’s ownership</h4>

    <p>
      <b>SKF</b> retains all rights not expressly conveyed to <b>You</b> by this Agreement. No title to or ownership of the marks or any proprietary technology is licensed to <b>You</b>. <b>SKF</b> owns and retains all title and ownership of all intellectual property rights belonging to it, including without limitation those rights covering its products, processes, BART Inspection software and other software, and marks.
    </p>
    <p>
      <b>SKF</b> retains all rights to the data and reports collected in this program. <b>SKF</b> will not disclose <b>Your</b> participation or <b>Your</b> reports made in the <b>Program</b> to any third party without <b>Your</b> expressed written permission. <b>SKF</b> reserves the right to confidentially use <b>Your</b> data collectively with other <b>SKF</b> data to make analytics of bearings, failure modes, causes, etc.
    </p>

    <h4 className='la_chapter'>6: Termination of licensed rights</h4>

    <p>
      This <b>Agreement</b> will commence immediately upon <b>Your</b> acceptance of the terms or use of the <b>BART Inspection software</b>.
    </p>

    <h4 className='la_chapter'>7: Indemnification</h4>
    <p>
      Any technical assistance or advice provided by <b>SKF</b> either written or oral is subject to <b>SKF’s</b> General Conditions for technical assistance and advice which can be viewed using this <span className='la_attachment'>LINK</span>.
    </p>
    <p>
      <b>You</b> agree that neither <b>SKF</b> nor its directors, officers, employees or representatives shall have any liability to You, or any party claiming by or through <b>You</b>, including, for example only, any of <b>Your</b> customers under this <b>Agreement</b>. <b>You</b> agree to defend, indemnify, and hold <b>SKF</b> and its affiliates within the <b>SKF</b> Group, and their respective directors, officers, employees and representatives harmless from and against any and all loss, cost, expenses, claims, demands, and/or liabilities arising out of any claims or suits made against <b>SKF</b> (i) by reason of <b>Your</b> performance or non-performance under this <b>Agreement</b>, (ii) arising out of <b>Your</b> use of the <b>BART Inspection software</b> in any manner whatsoever except as expressly permitted by this <b>Agreement</b>, (iii) arising out of the performance, promotion, sale, or distribution of any products or services by <b>You</b>, including without limitation any personal injury, product liability or other claim arising related thereto, or (iv) <b>SKF’s</b> termination of this <b>Agreement</b> pursuant to the terms herein.
    </p>
    <p>
      In the event <b>SKF</b> seeks indemnification from <b>You</b> under this Article, <b>SKF</b> will promptly notify <b>You</b> in writing of the claim(s) brought against <b>SKF</b> for which it seeks indemnification, but the failure by <b>SKF</b> to give such notice shall not relieve <b>You</b> of <b>Your</b> obligations under this Article unless and to the extent such failure unavoidably impairs <b>Your</b> efforts at defending <b>SKF</b> against such claim. In no event may <b>You</b> enter into any third-party agreements which would in any manner whatsoever affect the rights of, or bind, SKF in any manner, without the prior written consent of <b>SKF</b>.
    </p>
    <p>
      You may not use the <b>BART Inspection software</b> in a manner that violates any national, federal, state, or local law, rule or regulation, or use the <b>BART Inspection software</b> to violate the rights of any third party, or use the <b>BART Inspection software</b> to promote pornography, hatred, or racism.
    </p>

    <h4 className='la_title'>8: Disclaimers by SKF</h4>
    <p className="bolded_paragraph">
      NOTWITHSTANDING ANYTHING IN THIS AGREEMENT TO THE CONTRARY, SKF HAS THE RIGHT NOT TO GRANT OR RENEW THIS AGREEMENT IF SKF REASONABLY DETERMINES IN GOOD FAITH THAT YOUR USE OF THE BART INSPECTION SOFTWARE WILL ADVERSELY AFFECT SKF IN ANY MANNER.
    </p>
    <p className="bolded_paragraph">
      THERE IS NO WARRANTY OF MERCHANTIBILITY, NO WARRANTY OF FITNESS FOR A PARTICULAR USE AND NO WARRANTY OF NON-INFRINGEMENT. THERE IS NO WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, REGARDING THE PROGRAM, THE BART INSPECTION SOFTWARE, OR ANY INFORMATION PROVIDED BY SKF.
    </p>
    <p className="bolded_paragraph">
      IN NO EVENT SHALL SKF BE LIABLE FOR ANY CONSEQUENTIAL, INCIDENTAL, OR SPECIAL DAMAGES (INCLUDING BUT NOT LIMITED TO LOSS OF BUSINESS PROFITS) ARISING FROM OR RELATED TO YOUR PARTICIPATION IN THIS PROGRAM OR USE OF THE BART INSPECTION SOFTWARE, OR ANY INFORMATION PROVIDED BY SKF OR ARISING FROM OR RELATED TO THE TERMINATION OF THIS PROGRAM, EVEN IF SKF HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SOME JURISDICTIONS DO NOT ALLOW LIMITATIONS OF LIABILITY SO CERTAIN OF THESE LIMITATIONS MAY NOT APPLY; HOWEVER, THEY SHALL BE MADE TO APPLY TO FURTHEST EXTENT PERMITTED BY LAW.
    </p>

    <h4 className="la_chapter" style={{ textDecoration: 'underline' }}>9: Confidentiality</h4>
    <p>
      In the course of performance of this <b>Agreement</b>, <b>SKF</b> may provide and/or <b>You</b> may receive information from <b>SKF</b> that is considered by <b>SKF</b> as confidential and/or proprietary.
    </p>
    <p>
      <b>SKF</b> confidential information, includes without limitation, any information that is (i) marked as confidential at the time of disclosure; or (ii) is orally identified as confidential at the time of disclosure; or (iii) <b>You</b> know or should know is confidential by its nature or (iv) any <b>SKF</b> software program, code, or related documentation, in whatever form, whether or not marked, including but not limited to the <b>BART Inspection software</b>; or (v) any information that is related to any technical information provided to <b>You</b> in conjunction with the <b>Program</b>.
    </p>
    <p>
      <b>You</b> acknowledge that all <b>SKF</b> confidential information are the valuable trade secrets and exclusive and proprietary property of <b>SKF</b> and are protected by <b>SKF’s</b> intellectual property rights.
    </p>
    <p>
      <b>You</b> agree that <b>You</b> will hold all <b>SKF</b> confidential information in the strictest confidence and only use it to the limited extent necessary to exercise the specific rights granted to <b>You</b> under this <b>Agreement</b>. <b>You</b> agree to protect all <b>SKF</b> confidential information from disclosure to any third party, including, specifically, competitors of <b>SKF</b>, and prevent the unauthorized use, disclosure, dissemination, or publication of <b>SKF</b> confidential information. <b>You</b> further acknowledge that the disclosure, publication, reproduction, or transmittal of <b>SKF’s</b> confidential information, in whole or in part, in any form by any means whatsoever, whether written, electronic, mechanical or verbal is strictly prohibited without the express prior written consent of <b>SKF</b>.
    </p>
    <p>
      <b>Your</b> obligations under this Article do not extend to information that: (a) is or becomes a matter of public knowledge through no fault of <b>Yours</b>; (b) is rightfully received by <b>You</b> from a third party without a duty of confidentiality being imposed on <b>You</b>; (c) is required to be disclosed by order of court or a regulatory authority, provided <b>You</b> give reasonable advance notice to <b>SKF</b> and provide <b>SKF</b> an opportunity to seek a protective order against disclosure; or (d) is disclosed by <b>You</b> with <b>SKF’s</b> express prior written approval, but only to the extent of such approved disclosure.
    </p>

    <h4 className="la_chapter">10: Force majeure</h4>

    <p>
      Neither <b>You</b> or <b>SKF</b> shall be in default or otherwise liable for any delay in or failure of its performance under this <b>Agreement</b> if such delay or failure arises by any reason beyond its reasonable control, including any act of God, any acts of the common enemy, the elements, earthquakes, floods, fires, epidemics, riots, failures or delay in transportation or communications; provided, however, that lack of funds shall not be deemed to be a reason beyond a party's reasonable control. <b>You</b> and <b>SKF</b> will promptly inform and consult with each other as to any of the above causes that in their judgement may or could be the cause of a delay in the performance of this <b>Agreement</b>.
    </p>


    <h4 className="la_chapter">11: Applicable law</h4>
    <p>
      This <b></b> shall be governed by the law of the country in which <b>You</b> have its principle place of business, notwithstanding its conflict of laws rules. The United Nations Convention for the International Sale of Goods (CISG) shall not be applicable.
    </p>
    <p>
      If a court of competent jurisdiction finds any provision of this <b>Agreement</b> invalid or unenforceable under judicial decree or decision, the remainder will remain valid and enforceable according to its terms, and the provision or part of the provision found to be invalid or unenforceable shall be modified to the minimum extent required to make the provision or its part effective and enforceable so that as nearly as possible the original intention previously expressed by such provision or part is obtained.
    </p>
    <p>
      <b>You</b> agree to comply, at <b>Your</b> own expense, with all statutes, regulations, rules, ordinances, and orders of any governmental body, department, or agency which apply to or result from <b>Your</b> rights and obligations under this <b>Agreement</b>.
    </p>

    <h4 className="la_chapter">12: Export controls</h4>
    <p>
      You shall not export or reexport, directly or indirectly, or use the <b>BART Inspection software</b> to export or reexport any information without complying with pertinent export controls imposed by any applicable country (or any future export control legislation) and the regulations promulgated thereunder.
    </p>

    <h4 className="la_chapter">13: Government end users</h4>
    <p>
      If you use the <b>BART Inspection software</b> for or on behalf of a subdivision of the U.S. federal government, this Section shall apply. The <b>BART Inspection software</b> was developed completely at private expense, is existing computer software, is a trade secret for all purposes of the Freedom of Information Act, is ''restricted computer software' and in all respects proprietary data belonging solely to or licensed by <b>SKF</b>, and all rights are reserved under the copyright laws of the United States of America. Use, duplication, or disclosure by the Government is subject to restricted rights as set forth in subparagraphs (a) through (d) of the Commercial Computer Software Restricted Rights clause at FAR 52.227-19, or for DoD units, the restrictions as set forth in subparagraph (c)(1)(ii) of the Rights in Technical Data and Computer Software clause at DFARS 252.227-7013.
    </p>

    <h4 className="la_chapter">14: General Provisions</h4>
    <p>
      (a) Complete Agreement. <b>You</b> and <b>SKF</b> agree that this <b>Agreement</b> is the complete and exclusive statement of the agreement between the Parties, which supersedes and merges all prior proposals, understandings and all other agreements, oral or written, between <b>You</b> and <b>SKF</b> relating to the use of the BART Inspection software.
    </p>
    <p>
      (b) Amendment. This <b>Agreement</b> may be amended by <b>SKF</b> and the changes become binding on <b>You</b> when <b>You</b> have approved the terms or your continued use of the BART Inspection software. In the event of a conflict between the support services policies and this <b>Agreement</b>, the terms of this <b>Agreement</b> shall control.
    </p>
    <p>
      (c) Waiver. The waiver or failure of either <b>You</b> or <b>SKF</b> to exercise in any respect any right provided for in this <b>Agreement</b> shall not be deemed a waiver of any further right under this <b>Agreement</b>.
    </p>
    <p>
      (d) Severability. If any provision of this <b>Agreement</b> is invalid, illegal or unenforceable under any applicable statute or rule of law, it is to that extent to be deemed omitted. The remainder of the <b>Agreement</b> shall be valid and enforceable to the maximum extent possible.
    </p>
    <p>
      (e) Read and Understood. <b>You</b> acknowledge that you have read and understand this <b>Agreement</b> and agree to be bound by its terms.
    </p>
    <p>
      (f) Limitations Period. No action arising under, or related to, this <b>Agreement</b> may be brought by either party against the other more than two (2) years after the cause of action accrues, unless the cause of action involves death or personal injury.
    </p>
    <p>
      (g) Attorneys' Fees. In the event any litigation is brought by either <b>You</b> or <b>SKF</b> in connection with this <b>Agreement</b>, the prevailing party in such litigation will be entitled to recover from the other party all the costs, reasonable attorneys' fees, and other expenses incurred by such prevailing party in the litigation.
    </p>
    <p>
      (h) Authorized Representative. <b>You</b>, by signing this program <b>Agreement</b> or otherwise manifesting your assent, represent and warrant that <b>You</b> are legally authorized to bind <b>Your</b> company and commit <b>Your</b> company to the terms of this <b>Agreement</b>.
    </p>
    <p>
      (i) Injunctive Relief. <b>You</b> agree that <b>SKF</b> would suffer irreparable harm as a result of a violation of this <b>Agreement</b> and that monetary damages in such event would be substantial and inadequate to compensate <b>SKF</b>. Consequently, <b>SKF</b> shall be entitled to obtain, in addition to such other monetary relief as may be recoverable at law or in equity, such injunctive other equitable relief as may be necessary to restrain any threatened, continuing, or further breach by <b>You</b> without showing or proving actual damages sustained by SKF and without posting a bond.
    </p>
    <p>
      (j) Personal data. <b>SKF</b> will collect and process certain personal data about <b>You</b> as a user of the <b>BART Inspection software</b>. More information about how <b>SKF</b> processes <b>Your</b> personal data is found in the Privacy Notice for <b>SKF</b> Engineering Tools.
    </p>
    <p>
      (k) Data Collection. Through usage of the <b>BART Inspection software</b>, <b>SKF</b> will collect certain usage data such as login details, access to the functionalities of the <b>BART Inspection software</b>, user interactions, and user defined tool settings. <b>SKF</b> will use such information for purposes of supporting <b>You</b>, compiling statistics, preventing misuse, improving the <b>BART Inspection software</b> and improving <b>SKF’s</b> offer to customers e.g. through marketing offers to <b>You</b>.
    </p>

    <h4 className="la_chapter">15: Miscellaneous</h4>
    <p>
      Failure to require compliance with a part of this <b>Agreement</b> is not a waiver of that part.
    </p>
    <p>
      This <b>Agreement</b> is made on a personal basis between <b>You</b> and <b>SKF</b>, and any attempt by <b>You</b> to transfer or assign this <b>Agreement</b> or any rights hereunder is void.
    </p>
    <p>
      <b>You</b> acknowledge and agree that <b>You</b> and <b>SKF</b> are independent and that <b>You</b> will not represent <b>Your</b>self as an agent or legal representative of <b>SKF</b>.
    </p>
    <p>
      This <b>Agreement</b> and all documents incorporated herein by reference are the parties’ complete and exclusive statement relating to their subject matter.
    </p>
    <p>
      This <b>Agreement</b> will not be supplemented or modified by any course of dealing or usage of trade.
    </p>
    <p>
      The convention or rule of contract construction or interpretation that requires construing any ambiguity against the drafting party shall not be applied to the construction or interpretation of this <b>Agreement</b>.
    </p>
    <p>
      This <b>Agreement</b> is written in English. In the event of any inconsistency between this Agreement in the English language and any translation of it into another language, the English-language <b>Agreement</b> shall control.
    </p>

    <p>
      This Agreement offered on <b>SKF’s</b> behalf under my authority:
    </p>

    <span>________________________________________</span>

    <p style={{ color: 'salmon', fontWeight: 'bold', textAlign: 'center', fontSize: '.9em' }}>SKF CONFIDENTIAL INFORMATION, NOT TO BE DISTRIBUTED OR COPIED</p>
    <h3 style={{ textDecoration: 'underline', textAlign: 'center' }}>Attachment A</h3>
    <h4 style={{ textDecoration: 'underline', textAlign: 'center' }}>SKF BART inspection program process</h4>

    <h4 style={{ margin: '0' }}>Program process</h4>
    <p>
      <b>SKF</b> will work with <b>You</b> through the process of bearing inspection. Each step is equally important and the process is in place to make sure that the <b>Program</b> brings maximum value to all participants.
    </p>
    <h4 style={{ margin: '0' }}>Bearing inspection process – You <span style={{ fontWeight: 'normal' }}>and</span> SKF</h4>

    <ol>
      <li>
        <b>You</b> clean and inspect the bearing(s). Complete the inspection checklist (Attachment B) including images of all bearing surfaces as possible. Preserve the bearing afterwards with light oil.
      </li>
      <li>
        <b>You</b> create a new <b>BART Inspection report</b>. Complete as much information in the <b>Bearing Inspection software</b> as possible, including asset details and images of the bearing components. Define <b>Your</b> analysis of failure mode(s), causes(s) and recommendations.
      </li>
      <li>
        <b>You</b> consult by telephone or text with <b>Your SKF</b> SMP, or the assigned alternate SMP, as needed. <b>You</b> “Submit” by email <b>Your</b> report to <b>Your</b> assigned <b>SKF SMP</b> for “Approval”. If the SMP agrees that the bearing(s) is a potential for remanufacturing, than it may be decided to “Submit” the report directly to the local <b>SKF</b> Remanufacturing Centre (Solution Factory).
      </li>
      <li>
        The <b>BART Inspection report</b> is complete once it is “Submitted” and “Approved” by either the <b>SKF SMP</b> or the SKF Remanufacturing Centre.
      </li>
      <li>
        If the bearing is a potential for remanufacturing, the SKF Remanufacturing Centre will issue a return authorization. <b>You</b> return the bearing to the SKF Remanufacturing Centre for further inspection. <b>SKF</b> will contact <b>You</b> with their findings.
      </li>
      <div style={{ marginLeft: '-1rem', marginBottom: '0' }}>
        <h4 style={{ marginBottom: '0' }}>SKF’s role</h4>
        <p style={{ marginTop: '0' }}>
          The <b>BART Inspection software</b> and <b>Program</b> is managed by <b>SKF</b> and carried out by <b>SKF</b>, and in some cases with the assistance of an <b>SKF</b> Authorized Distributor. Together, <b>SKF</b> sales support and its <b>SKF</b> Authorized Distributors will provide <b>You</b> with:
        </p>
      </div>
      <li>
        <b>SKF</b> SMP(s) on bearing damage analysis available during normal local working hours by telephone, text, and email.
      </li>
      <li>
        Direct connection to <b>SKF</b> Remanufacturing Centre if potential remanufacturing.
      </li>
      <li>
        IT support for the use of <b>BART Inspection software</b>.
      </li>
    </ol>

    <p style={{ textAlign: 'center' }}>***** END OF AGREEMENT*****</p>
  </div>
)

export default LicenseAgreementText
