import React from "react";

const styleRotate = { transform: "rotateY(180deg)" }

const SvgButtonExpand = ({ title, rotate, size="26px" }) => (
    <svg width={ size } height={ size } viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={rotate ? styleRotate : {}}>
        <title>{title}</title>
        <g id="Icons/icon_expand" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path d="M11.9663795,4.78791946 L16.6984467,9.30604027 C17.1005178,9.68993289 17.1005178,10.3100671 16.6984467,10.6939597 L11.9663795,15.2120805 C11.5643085,15.5959732 10.9148091,15.5959732 10.512738,15.2120805 C10.1209764,14.8281879 10.1106669,14.1982103 10.512738,13.8143177 L14.5128296,9.9950783 L10.512738,6.17583893 C10.1106669,5.79194631 10.1106669,5.17181208 10.512738,4.78791946 C10.9148091,4.40402685 11.5643085,4.40402685 11.9663795,4.78791946 Z M4.75519485,4.78791946 L9.48726201,9.30604027 C9.88933307,9.68993289 9.88933307,10.3100671 9.48726201,10.6939597 L4.75519485,15.2120805 C4.35312378,15.5959732 3.70362437,15.5959732 3.3015533,15.2120805 C2.90979175,14.8281879 2.89948223,14.1982103 3.3015533,13.8143177 L7.30164493,9.9950783 L3.3015533,6.17583893 C2.89948223,5.79194631 2.89948223,5.17181208 3.3015533,4.78791946 C3.70362437,4.40402685 4.35312378,4.40402685 4.75519485,4.78791946 Z" id="Shape" fill="#FFFFFF"></path>
        </g>
    </svg>
);

export default SvgButtonExpand;
