import React from 'react'
import NewKeyValueTable from 'components/_LivePreview/Shared/NewKeyValueTable'
import { useIntl } from "react-intl"
import { useSelector } from "react-redux";

const UsInspectionDetails = ({ config, data }) => {
    const intl = useIntl()

    const scrollTarget = useSelector(state => state.saveComponentValue.scrollTarget)
    const caseWasSaved = useSelector(state => state.saveComponentValue.caseWasSaved)

    let endUserSelected = false;
    let distributorSelected = false;
    let distributionListSelected = false;
    let vendorSelected = false;
    let vendorJobNumberSelected = false;

    const hasScrollTarget = key => {
        if (scrollTarget) {
            if (scrollTarget.fieldTarget || scrollTarget.scrollTarget) {
                return (scrollTarget.fieldTarget === key || scrollTarget.scrollTarget.includes(key)) && !caseWasSaved
            }
        }
        return false
    }

    const filterConfig = (filter, config) => {
        return config.filter(item => filter.some(f => item.key.includes(f)));
    }

    if (data["skfDetails.vendorJobNumber"]) {
        vendorJobNumberSelected = true;
    }
    if (data["skfDetails.distributionList"] && data["skfDetails.distributionList"].length > 0 || hasScrollTarget("skfDetails.distributionList")) {
        distributionListSelected = true;
    }

    if (data["skfDetails.endUser"]) {
        const endUserRoot = data["skfDetails.endUser"];
        if (endUserRoot.endUser) {
            endUserSelected = true;
        }
        if (endUserRoot.distributor) {
            const distributor = endUserRoot.distributor;
            if (distributor.isCustom) {
                distributorSelected = distributor.value && distributor.value.length > 0;
            } else {
                distributorSelected = true;
            }
        }
        if (endUserRoot.vendor && endUserRoot.vendor.label) {
            vendorSelected = true;
        }
    }

    const formatHeaderText = (translationKey, value) => {
        if (value) {
            return <>{intl.formatMessage({ id: translationKey })}<br/>{value}</>
        } else {
            return `${intl.formatMessage({ id: translationKey })}`;
        }
    }

    const headerValue = headerKeys => {
        const values = headerKeys.map(headerKey => data[headerKey]).filter(s => s);
        if (values.length > 1) {
            return <>{values[0]}<br />{values[1]}</>
        } else { 
            return values.join(", ")
        }
    }

    const renderSection = (headerTranslationKey, headerKeys, configFilter, keyId) => {
        return <div data-section-id="skfDetails.endUser">
            <div className="live-preview-images" data-key-id={keyId}>
                <h6 className="live-preview-images__headline">{formatHeaderText(headerTranslationKey, "")}</h6>
                <div className="live-preview-key-value__key">{headerValue(headerKeys)}</div>
                <NewKeyValueTable config={filterConfig(configFilter, config)} data={data} />
            </div>
        </div>
    }

    return <>
        {endUserSelected || vendorSelected || distributorSelected || distributionListSelected || vendorJobNumberSelected || hasScrollTarget("skfDetails.endUser") || hasScrollTarget("skfDetails.vendorJobNumber") ? <div className="live-preview-report-information">
            <div className="live-preview-key-values">
                <h3 className="live-preview-list-structure__header">
                    <div className="live-preview-key-value__header">{intl.formatMessage({ id: "inspectionDetails.livePreview.header" })}</div>
                </h3>
                <div className="live-preview__enduser">
                    {endUserSelected || hasScrollTarget("skfDetails.endUser") ? renderSection(
                        "enduser",
                        ["skfDetails.endUser.endUser_name", "skfDetails.endUser.endUser_cityState"],
                        ["skfDetails.endUser.endUser_contact"],
                        "skfDetails.endUser") : null}
                    {distributorSelected || hasScrollTarget("skfDetails.endUser.distributor") ? renderSection(
                        "distributor",
                        ["skfDetails.endUser.distributor_name", "skfDetails.endUser.distributor_custom", "skfDetails.endUser.distributor_cityState"],
                        ["skfDetails.endUser.distributor_contact"],
                        "skfDetails.endUser.distributor") : null}
                    {vendorSelected || vendorJobNumberSelected || hasScrollTarget("skfDetails.endUser.vendor") || hasScrollTarget("skfDetails.vendorJobNumber") ?
                        renderSection(
                            "vendor",
                            ["skfDetails.endUser.vendor_name", "skfDetails.endUser.vendor_cityState"],
                            ["skfDetails.endUser.vendor_contact", "skfDetails.vendorJobNumber"],
                            "skfDetails.endUser.vendor") : null}
                </div>
            </div>
        </div> : null}
        <div className="live-preview-list-structure">
            <NewKeyValueTable
                config={filterConfig(["reportDetails.inspectionTime", "reportDetails.travelTime"], config)}
                sectionId="reportDetails"
                data={data} />
        </div>
    </>;
}

export default UsInspectionDetails