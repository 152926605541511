const WindConfig = [
  {
    key: 'reportDetails.reportDate',
    isRequired: false,
    sortOrder: 0
  },
  {
    key: 'reportDetails.reportTitle',
    isRequired: true,
    sortOrder: 1
  },
  {
    key: 'reportDetails.skfCaseNumber',
    isRequired: false,
    sortOrder: 2
  },
  {
    key: 'reportDetails.terNumber',
    isRequired: false,
    sortOrder: 3
  },
  {
    key: 'reportAuthor',
    isRequired: false,
    sortOrder: 9
  },
  {
    key: 'coReportAuthor',
    isRequired: false,
    sortOrder: 9
  },
  {
    key: 'investigationDetails.rmaNumber',
    isRequired: false,
    sortOrder: 3
  },
  {
    key: 'investigationDetails.purpose',
    isRequired: false,
    sortOrder: 4,
  },
  {
    key: 'investigationDetails.scopeOfInvestigation',
    isRequired: false,
    isFullWidth: true,
    sortOrder: 5,
    isRichText: true
  },
  {
    key: 'investigationDetails.overallBackgroundInformation',
    isRequired: false,
    isFullWidth: true,
    sortOrder: 8,
    isRichText: true
  },
  {
    key: 'investigationDetails.numberOfBearingsInvestigated',
    isRequired: false,
    sortOrder: 9
  },
  {
    key: "investigationDetails.bearingInvestigations",
    isRequired: false,
    sortOrder: 10
  },
  {
    key: 'skfDetails.countryCompanyLocation',
    isRequired: false,
    sortOrder: 7
  },
  {
    key: 'skfDetails.countryCompanyLocation.company',
    isRequired: false,
    sortOrder: 8
  },
  {
    key: 'skfDetails.countryCompanyLocation.location',
    isRequired: false,
    sortOrder: 9
  },
  {
    key: 'skfDetails.reportAuthor',
    isRequired: false,
    sortOrder: 11
  },
  {
    key: 'skfDetails.coReportAuthor',
    isRequired: false,
    sortOrder: 11
  },
  {
    key: 'skfDetails.approver.personalInfo',
    isRequired: false,
    sortOrder: 13
  },
  {
    key: 'skfDetails.reportNeedsApproval',
    isRequired: false,
    sortOrder: 16
  },
  {
    key: 'skfDetails.approversName',
    isRequired: false,
    sortOrder: 17
  },
  {
    key: 'skfDetails.distributionListHeader',
    isRequired: false,
    sortOrder: 18
  },
  {
    key: 'skfDetails.distributionList',
    isRequired: false,
    sortOrder: 19
  },
  {
    key: 'skfDetails.delegationListHeader',
    isRequired: false,
    sortOrder: 20
  },
  {
    key: 'skfDetails.delegationList',
    isRequired: false,
    sortOrder: 21
  },
  {
    key: 'customerDetails.customer',
    isRequired: true,
    sortOrder: 22
  },
  {
    key: 'customerDetails.sectorSegment.sector',
    isRequired: false,
    sortOrder: 34
  },
  {
    key: 'customerDetails.sectorSegment.segment',
    isRequired: false,
    sortOrder: 35
  },
  {
    key: 'customerDetails.sectorSegment.industry',
    isRequired: false,
    sortOrder: 36
  },
  {
    key: 'customerDetails.customerAddress',
    isRequired: false,
    sortOrder: 23
  },
  {
    key: 'customerDetails.customerCityStateTerritory',
    isRequired: false,
    sortOrder: 24
  },
  {
    key: 'customerDetails.customerPostCode',
    isRequired: false,
    sortOrder: 25
  },
  {
    key: 'customerDetails.customerCountry',
    isRequired: false,
    sortOrder: 26
  },
  {
    key: 'customerDetails.site',
    isRequired: true,
    sortOrder: 27
  },
  {
    key: 'customerDetails.plantOrTrainOrShipName',
    isRequired: false,
    sortOrder: 28
  },
  {
    key: 'customerDetails.system',
    isRequired: false,
    sortOrder: 29
  },
  {
    key: 'customerDetails.customerContact.name',
    isRequired: false,
    sortOrder: 31
  },
  {
    key: 'customerDetails.customerContact.jobTitle',
    isRequired: false,
    sortOrder: 32
  },
  {
    key: 'customerDetails.customerContact.email',
    isRequired: false,
    sortOrder: 33
  },
  {
    key: 'customerDetails.customerContact.phone',
    isRequired: false,
    sortOrder: 34
  },

  {
    key: 'industryAssetType.windFunctionalLocation',
    isRequired: false,
    sortOrder: 35
  },
  {
    key: 'industryAssetType.windFunctionalLocation.nacelleType',
    isRequired: false,
    sortOrder: 36
  },
  {
    key: 'industryAssetType.windFunctionalLocation.nainShaftArrangementType',
    isRequired: false,
    sortOrder: 37
  },
  {
    key: 'industryAssetType.windFunctionalLocation.rotorType',
    isRequired: false,
    sortOrder: 38
  },
  {
    key: 'industryAssetType.windFunctionalLocation.towerType',
    isRequired: false,
    sortOrder: 39
  },
  {
    key: 'industryAssetType.windFunctionalLocation.electricalType',
    isRequired: false,
    sortOrder: 40
  },
  {
    key: 'assetDetails.assetDescription',
    isRequired: true,
    sortOrder: 29,
    imageKey: 'assetDetails.assetFigure'
  },
  {
    key: 'assetDetails.assetFigure',
    isRequired: true,
    sortOrder: 29,
    textKey: 'assetDetails.assetDescription'
  },
  {
    key: "assetDetails.analysisImages",
    isRequired: false,
    sortOrder: 41,
  },
  {
    key: 'assetDetails.windTurbineDesignation',
    isRequired: false,
    sortOrder: 41
  },
  {
    key: 'assetDetails.windGearBoxDesignation',
    isRequired: false,
    sortOrder: 42
  },
  {
    key: 'assetDetails.windTurbineSize',
    isRequired: false,
    sortOrder: 43
  },
  {
    key: 'assetDetails.driveTrainConcept',
    isRequired: false,
    sortOrder: 44
  },
  {
    key: 'assetDetails.turbineLocation',
    isRequired: false,
    sortOrder: 45
  },
  {
    key: 'assetDetails.rotorDiameter',
    isRequired: false,
    sortOrder: 46
  },
  {
    key: 'assetDetails.hubHeight',
    isRequired: false,
    sortOrder: 47
  },
  {
    key: 'assetDetails.turbineId',
    isRequired: false,
    sortOrder: 48
  },
  {
    key: 'assetDetails.turbineErectionDate',
    isRequired: false,
    sortOrder: 49
  },
  {
    key: 'assetDetails.turbineCommissioningDate',
    isRequired: false,
    sortOrder: 50
  },
  {
    key: 'summary.conclusions',
    isRequired: false,
    imageIsNext: true,
    sortOrder: 51,
    isRichText: true
  },
  {
    key: 'summary.conclusionImages',
    isRequired: false,
    sortOrder: 52
  },
  {
    key: 'summary.recommendations',
    isRequired: true,
    isFullWidth: true,
    sortOrder: 53,
    isRichText: true
  },
  {
    key: 'summary.recommendationsOptions',
    sortOrder: 54
  },
  {
    key: 'summary.equipmentUsed',
    sortOrder: 55
  },
  {
    key: "bearingInvestigations.bearingType.bearingTypeSelect",
    isRequired: false,
    sortOrder: 0
  },
  {
    key: "bearingInvestigations.bearingType.bearingSkfCustomerDescription",
    isRequired: false,
    sortOrder: 1,
    isRichText: true
  },
  {
    key: "bearingInvestigations.bearingType.BearingDesignationComponent",
    isRequired: false,
    sortOrder: 2
  },
  {
    key: "bearingInvestigations.bearingType.BearingDesignationComponent.manufacturer",
    isRequired: false,
    sortOrder: 0
  },
  {
    key: "bearingInvestigations.bearingType.BearingDesignationComponent.SKFManufacturer",
    isRequired: false,
    sortOrder: 3
  },
  {
    key: "bearingInvestigations.bearingType.BearingDesignationComponent.skfBearingDesignation",
    isRequired: false,
    sortOrder: 4
  },
  {
    key: "bearingInvestigations.bearingType.BearingDesignationComponent.competitorDesignationAndMarkings",
    isRequired: false,
    sortOrder: 5
  },
  {
    key: "bearingInvestigations.manufacturing.skfCompany",
    isRequired: false,
    sortOrder: 6
  },
  {
    key: "bearingInvestigations.manufacturing.skfCompany.company",
    isRequired: false,
    sortOrder: 7
  },
  {
    key: "bearingInvestigations.manufacturing.skfCompany.location",
    isRequired: false,
    sortOrder: 8
  },
  {
    key: "bearingInvestigations.manufacturing.manufacturingDateCode",
    isRequired: false,
    sortOrder: 9
  },
  {
    key: "bearingInvestigations.backgroundInformation.backgroundInformationText",
    isRequired: false,
    isFullWidth: true,
    sortOrder: 11,
    isRichText: true
  },
  {
    key: "bearingInvestigations.backgroundInformation.backgroundInformationImages",
    isRequired: false,
    sortOrder: 12,
  },
  {
    key: "bearingInvestigations.backgroundInformation.scopeOfWork",
    isRequired: false,
    sortOrder: 13,
  },
  {
    key: "bearingInvestigations.backgroundInformation.recurringFailure",
    isRequired: false,
    sortOrder: 14
  },
  {
    key: "bearingInvestigations.backgroundInformation.bearingMountingDate",
    isRequired: false,
    sortOrder: 15
  },
  {
    key: "bearingInvestigations.backgroundInformation.inspectionDate",
    isRequired: false,
    sortOrder: 16
  },
  {
    key: "bearingInvestigations.backgroundInformation.failureDetectedDate",
    isRequired: false,
    sortOrder: 17
  },
  {
    key: "bearingInvestigations.backgroundInformation.componentReplacementDate",
    isRequired: false,
    sortOrder: 18
  },
  {
    key: "bearingInvestigations.backgroundInformation.operation",
    isRequired: false,
    sortOrder: 19
  },
  {
    key: "bearingInvestigations.backgroundInformation.operation.operationNumber",
    isRequired: false,
    sortOrder: 20
  },
  {
    key: "bearingInvestigations.backgroundInformation.expectedLife",
    isRequired: false,
    sortOrder: 21
  },
  {
    key: "bearingInvestigations.backgroundInformation.costOfFailure",
    isRequired: false,
    sortOrder: 22
  },
  {
    key: "bearingInvestigations.backgroundInformation.reasonWasRemoved",
    isRequired: false,
    sortOrder: 23
  },
  {
    key: "bearingInvestigations.operatingData.rotationalSpeed",
    isRequired: false,
    sortOrder: 24
  },
  {
    key: "bearingInvestigations.operatingData.lengthOfBearingOperated",
    isRequired: false,
    sortOrder: 16
  },
  {
    key: "bearingInvestigations.operatingData.windGearBoxOilTemperature",
    isRequired: false,
    sortOrder: 25
  },
  {
    key: "bearingInvestigations.operatingData.windMainShaftBearingORTemperature",
    isRequired: false,
    sortOrder: 26
  },
  {
    key: "bearingInvestigations.operatingData.windMainShaftBearingIRTemperature",
    isRequired: false,
    sortOrder: 27
  },
  {
    key: "bearingInvestigations.operatingData.windLoadData",
    isRequired: false,
    sortOrder: 28,
    isRichText: true
  },

  {
    key: "bearingInvestigations.operatingData.specialEvents",
    isRequired: false,
    sortOrder: 29,
    isRichText: true
  },

  {
    key: "bearingInvestigations.operatingData.lubricationType",
    isRequired: false,
    sortOrder: 30
  },
  {
    key: "bearingInvestigations.operatingData.lubricationType.greaseType",
    isRequired: false,
    sortOrder: 31
  },
  {
    key: "bearingInvestigations.operatingData.lubricationType.oilType",
    isRequired: false,
    sortOrder: 32
  },
  {
    key: 'bearingInvestigations.operatingData.lubricationType.oilBathBelowShaft',
    isRequired: false,
    sortOrder: 33
  },
  {
    key: 'bearingInvestigations.operatingData.lubricationType.circulatingOilFlowRate',
    isRequired: false,
    sortOrder: 34
  },
  {
    key: "bearingInvestigations.operatingData.lubricationType.filterRating",
    isRequired: false,
    sortOrder: 35
  },
  {
    key: "bearingInvestigations.operatingData.lubricationType.greaseTypeGrams",
    isRequired: false,
    sortOrder: 36
  },
  {
    key: "bearingInvestigations.operatingData.viscosityForty",
    isRequired: false,
    sortOrder: 37
  },
  {
    key: "bearingInvestigations.operatingData.viscosityHundred",
    isRequired: false,
    sortOrder: 38
  },
  {
    key: "bearingInvestigations.operatingData.lubricantHaveAWorEPproperties",
    isRequired: false,
    sortOrder: 39
  },
  {
    key: "bearingInvestigations.operatingData.lubricantManufacturerName",
    isRequired: false,
    sortOrder: 40
  },
  {
    key: "bearingInvestigations.operatingData.lubricantModelDesignation",
    isRequired: false,
    sortOrder: 41
  },
  {
    key: "bearingInvestigations.operatingData.ringRotation",
    isRequired: false,
    sortOrder: 42
  },
  {
    key: "bearingInvestigations.operatingData.shaftOrientation",
    isRequired: false,
    sortOrder: 43
  },
  {
    key: "bearingInvestigations.bearingType.skfSerialNumber",
    isRequired: false,
    sortOrder: 44
  },
  {
    key: "bearingInvestigations.customerReferencePartNumber",
    isRequired: false,
    sortOrder: 45
  },
  {
    key: "bearingInvestigations.bearingType.customerReferencePartNumber",
    isRequired: false,
    sortOrder: 45
  },
  {
    key: "bearingInvestigations.bearingType.windFunctionalLocation",
    isRequired: false,
    sortOrder: 46
  },
  {
    key: "bearingInvestigations.bearingType.windFunctionalLocation.assetType",
    isRequired: false,
    sortOrder: 47
  },
  {
    key: "bearingInvestigations.bearingType.windFunctionalLocation.positionInAsset",
    isRequired: false,
    sortOrder: 48
  },
  {
    key: "bearingInvestigations.bearingType.allPartsAvailable",
    isRequired: false,
    sortOrder: 49
  },
  {
    key: "bearingInvestigations.componentPartsInvestigation.outerRing.racewayFailureAndCause",
    isRequired: false,
    sortOrder: 50
  },
  {
    key: "bearingInvestigations.componentPartsInvestigation.outerRing.diameterFailureAndCause",
    isRequired: false,
    sortOrder: 51
  },
  {
    key: "bearingInvestigations.componentPartsInvestigation.outerRing.sideFaceMarkedFailureAndCause",
    isRequired: false,
    sortOrder: 52
  },
  {
    key: "bearingInvestigations.componentPartsInvestigation.outerRing.outerRingFlangeFailureAndCause",
    isRequired: false,
    sortOrder: 53
  },
  {
    key: "bearingInvestigations.componentPartsInvestigation.outerRing.sideFaceOppositeFailureAndCause",
    isRequired: false,
    sortOrder: 53
  },
  {
    key: "bearingInvestigations.componentPartsInvestigation.innerRing.racewayFailureAndCause",
    isRequired: false,
    sortOrder: 54
  },
  {
    key: "bearingInvestigations.componentPartsInvestigation.innerRing.boreFailureAndCause",
    isRequired: false,
    sortOrder: 55
  },
  {
    key: "bearingInvestigations.componentPartsInvestigation.innerRing.sideFaceMarkedFailureAndCause",
    isRequired: false,
    sortOrder: 56
  },
  {
    key: "bearingInvestigations.componentPartsInvestigation.innerRing.innerRingFlangeFailureAndCause",
    isRequired: false,
    sortOrder: 57
  },
  {
    key: "bearingInvestigations.componentPartsInvestigation.innerRing.sideFaceOppositeFailureAndCause",
    isRequired: false,
    sortOrder: 57
  },
  {
    key: "bearingInvestigations.componentPartsInvestigation.rollingElements.rowOneFailureAndCause",
    isRequired: false,
    sortOrder: 58
  },
  {
    key: "bearingInvestigations.componentPartsInvestigation.rollingElements.rowTwoFailureAndCause",
    isRequired: false,
    sortOrder: 59
  },
  {
    key: "bearingInvestigations.componentPartsInvestigation.rollingElements.rowThreeFailureAndCause",
    isRequired: false,
    sortOrder: 60
  },
  {
    key: "bearingInvestigations.componentPartsInvestigation.rollingElements.rowFourFailureAndCause",
    isRequired: false,
    sortOrder: 61
  },
  {
    key: "bearingInvestigations.componentPartsInvestigation.cage.cageOneFailureAndCause",
    isRequired: false,
    sortOrder: 62
  },
  {
    key: "bearingInvestigations.componentPartsInvestigation.cage.cageTwoFailureAndCause",
    isRequired: false,
    sortOrder: 63
  },
  {
    key: "bearingInvestigations.componentPartsInvestigation.cage.cageThreeFailureAndCause",
    isRequired: false,
    sortOrder: 64
  },
  {
    key: "bearingInvestigations.componentPartsInvestigation.cage.cageFourFailureAndCause",
    isRequired: false,
    sortOrder: 65
  },
  {
    key: "bearingInvestigations.componentPartsInvestigation.guideRing.guideRingFailureAndCause",
    isRequired: false,
    sortOrder: 66
  },
  {
    key: "bearingInvestigations.componentPartsInvestigation.sealsOrShields.sealOrShieldOneFailureAndCause",
    isRequired: false,
    sortOrder: 67
  },
  {
    key: "bearingInvestigations.componentPartsInvestigation.sealsOrShields.sealOrShieldTwoFailureAndCause",
    isRequired: false,
    sortOrder: 68
  },
  {
    key: "bearingInvestigations.componentPartsInvestigation.otherComponent.otherFailureAndCause",
    isRequired: false,
    sortOrder: 69
  },
  {
    key: "bearingInvestigations.measuredInternalClearance.measuredInternalClearance",
    isRequired: false,
    sortOrder: 70
  },
  {
    key: "bearingInvestigations.analysis.analysisSummary",
    isRequired: false,
    sortOrder: 71,
    imageIsNext: true,
    isFullWidth: true,
    isRichText: true
  },
  {
    key: "bearingInvestigations.analysis.analysisImages",
    isRequired: false,
    sortOrder: 72,
  },
  {
    key: "bearingInvestigations.analysis.bearingPerformanceLevel",
    isRequired: false,
    sortOrder: 72,
  },
  {
    key: "bearingInvestigations.analysis.primaryFailureAndCause",
    isRequired: false,
    sortOrder: 73
  },
  {
    key: "bearingInvestigations.analysis.additionalFailureAndCause",
    isRequired: false,
    sortOrder: 74
  },
  {
    key: "bearingInvestigations.disposition.dispositionContainer",
    isRequired: false,
    sortOrder: 75
  },
  {
    key: "bearingInvestigations.disposition.dispositionContainer.scrapDate",
    isRequired: false,
    sortOrder: 76
  }
]
export default WindConfig
