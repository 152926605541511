import React, { useState, useEffect } from "react";
import getComponent from "../../_Case/ComponentsList";
import httpService from "core/http-service";
import { connect } from "react-redux";

const CountryLocationComponent = ({ formComponentData, myName, parentSetValue, parentHandleSave, setValue, getValue, language }) => {
    const [countries, setCountries] = useState([]);
    const [country, setCountry] = useState("");
    const [locationOptions, setLocationOptions] = useState([]);
    const [location, setLocation] = useState("")

    useEffect(() => {
        const currentData = getCurrentData();
        if (currentData) {
            setCountry(currentData.country);
            setLocation(currentData.location);
            if (currentData.country && currentData.country !== '') {
                getLocationOptions(currentData.country);
            }
        }
    }, [formComponentData, getValue]);

    useEffect(() => {
        httpService
            .get("lists/companies", { lang: language })
            .then(response => {
                setCountries(response.data);
            });
    }, []);

    const getCurrentData = () => {
        let values = getValue(myName);
        if (values !== undefined) {
            return values;
        } else if (parentSetValue) {
            return parentSetValue
        }
    }

    const saveValues = objectToSave => {
        if (parentSetValue !== undefined) {
            parentHandleSave(objectToSave, myName)
        } else {
            setValue(objectToSave, myName)
        }
    }

    const handleChange = (value, name) => {
        const parts = name.split(".");
        const resultName = parts[parts.length - 1];

        let objectToSave = getCurrentData();

        if (!objectToSave) {
            objectToSave = {
                country: country,
                location: location
            };
        }

        if (resultName === "countrySelect") {
            setCountry(value);
            setLocation('');
            objectToSave.country = value;
            objectToSave.location = '';
            saveValues(objectToSave);
            if (value === '') {
                setLocationOptions([]);
            } else {
                getLocationOptions(value);
            }
        } else if (resultName === "locationSelect") {
            setLocation(value);
            objectToSave.location = value;
            saveValues(objectToSave);
        }
    }

    const renderCountry = () => {
        if (Array.isArray(countries) && countries.length > 0) {
            return getComponent(
                {
                    key: "countrySelect",
                    type: "AutoComplete",
                    translate: false,
                    description: "Country",
                    showrequired: formComponentData.required,
                    args: [
                        {
                            options: countries.map(c => ({ label: c.country, value: c.countryCode }))
                                .sort((a, b) => a.label.localeCompare(b.label))
                        }
                    ]
                },
                `${myName}.countrySelect`,
                handleChange,
                country
            );
        } else {
            return <p>Loading</p>;
        }
    }

    const renderLocation = () => {
        if (Array.isArray(locationOptions) && locationOptions.length > 0) {
            return getComponent(
                {
                    key: "locationSelect",
                    type: "AutoComplete",
                    translate: false,
                    description: "Location",
                    args: [
                        {
                            options: locationOptions
                        }
                    ]
                },
                `${myName}.locationSelect`,
                handleChange,
                location
            );
        } else {
            return null;
        }
    }

    const getLocationOptions = (selectedCountry) => {
        let foundCountry = countries.find(country => country.countryCode === selectedCountry.value);
        if (foundCountry) {
            const locations = foundCountry.companies.flatMap(c => c.locations.map(l => ({ label: l.location, value: l.location })))
            setLocationOptions(locations);
        }
    }

    return (
        <>
            {renderCountry()}
            {renderLocation()}
        </>
    );
}


function mapStateToProps(state) {
    return {
        language: state.language,
    };
}

export default connect(mapStateToProps)(CountryLocationComponent);