import React from "react";

const SvgFeedback = props => (
  <svg width={28} height={25} viewBox="0 0 28 25" {...props}>
    <g stroke="#FFF" fill="none" fillRule="evenodd">
      <path d="M9.517 8.107H7.653a.193.193 0 00-.193.193v7.114c0 .107.087.193.193.193h1.864a.193.193 0 00.193-.193V8.3a.193.193 0 00-.193-.193zm9.505 0H14.53a.006.006 0 01-.006-.007l.433-2.172a1.305 1.305 0 00-.663-1.41l-.255-.138a.191.191 0 00-.255.075L11.724 8.2c-.01.032-.017.063-.025.095v6.368c0 .522.426.946.948.943h4.61a2.43 2.43 0 002.185-1.357l1.268-2.88a.169.169 0 000-.075v-1.5a1.691 1.691 0 00-1.688-1.688z" />
      <path
        d="M27.144 15.242a3.965 3.965 0 01-3.956 3.956h-5.019a.204.204 0 00-.163.08l-7.53 4.935c-.004.003-.01 0-.01-.005l.03-4.93a.204.204 0 00-.162-.08H5.187a3.965 3.965 0 01-3.973-3.956V5.17a3.965 3.965 0 013.957-3.957h18.017a3.965 3.965 0 013.956 3.957v10.07z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default SvgFeedback;
