import React, { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { saveAllAnswersAndValidate } from "redux/thunks/answerThunk";
import { setValidationErrors } from "redux/actions/saveComponentValueAction";
import distributeCase from "./../ButtonActions/distributeReport";
import { isUserAdmin, isUserApprover } from "./../../formcomponents/utils/userUtils";
import { setCurrentCaseStatus } from "redux/actions/currentCaseActions";
import approveCase, { hasAiAnswers } from "./../ButtonActions/approveCase";

import classnames from "classnames";

import ReactModal from "react-modal";
import BaseButton from "components/common/BaseButton";
import SvgButtonBarApprove from "./../../svg/ButtonBarApprove"
import CircleCloseFilterSvg from "components/svg/CircleCloseFilter";

const ButtonApprove = ({ buttonSize, userCanEdit, setCaseStatus, currentFormTemplateID, setApprovingState }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const history = useHistory();

    const [isApproving, setIsApproving] = useState(false);
    const [isApproveAIModalOpen, setIsApproveAIModalOpen] = useState(false);
    const [hasPressedNoApproveAI, setHasPressedNoApproveAI] = useState(false);
    const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);
    const [isApproved, setIsApproved] = useState(false);
    const [isApprover, setIsApprover] = useState(false);

    const connection = useSelector(state => state.connection, shallowEqual);
    const currentCase = useSelector(state => state.currentCase.case, shallowEqual);
    const currentCaseApprover = useSelector(state => state.currentCase.case.approver, shallowEqual);
    const currentCaseDomain = useSelector(state => state.currentCase.case.domain, shallowEqual);
    const currentUser = useSelector(state => state.currentUser);
    const currentCaseId = useSelector(state => state.currentCase.case.id, shallowEqual);
    const answers = useSelector(state => state.saveComponentValue.components, shallowEqual);
    const language = useSelector(state => state.language, shallowEqual);

    useEffect(() => {
        setApprovingState(isApproving)
    }, [isApproving])

    useEffect(() => {
        if (!answers || !currentUser || !currentCaseApprover || !currentCaseDomain) return;
        const isCurrentUserApprover = isUserApprover(
            answers,
            currentUser.email,
            currentCaseApprover,
            currentCaseDomain
        );
        setIsApprover(isCurrentUserApprover || isUserAdmin(currentUser['custom:administrator']));
    }, [answers, currentUser, currentCaseApprover, currentCaseDomain]);

    const onApprove = () => {
        if (hasAiAnswers(answers)) {
            setIsApproveAIModalOpen(true);
        } else {
            openApproveConfirmation();
        }
    }

    const openApproveConfirmation = () => {
        if (!isApproving) { 
            setIsApproving(true)
            dispatch(saveAllAnswersAndValidate(result => { 
                if (!result.validationErrors) {
                    setIsApproveModalOpen(true);
                    setIsApproved(false);
                } else { 
                    dispatch(setValidationErrors(result.requiredList));
                    setIsApproving(false)
                }
            }));
        }
    }

    const approveAIClose = () => {
        setIsApproveAIModalOpen(false);
        setHasPressedNoApproveAI(false);
    }

    const approveAIYes = () => {
        setIsApproveAIModalOpen(false);
        setHasPressedNoApproveAI(false);
        openApproveConfirmation();
    }

    const approveAINo = () => {
        setHasPressedNoApproveAI(true);
    }
    
    const closeApproveModal = () => {
        setIsApproveModalOpen(false);
        setIsApproving(false);
        if (isApproved) {
            if (connection) {
                history.push("/my-reports");
            } else {
                history.push("/dashboard");
            }
        }
    }

    const OnApproveCase = () => { 
        setIsApproving(true)
        approveCase(currentCaseId, answers, currentUser, dispatch).then(response => {
            setIsApproveAIModalOpen(false);
            setHasPressedNoApproveAI(false);
            if (!response.noApprover && !response.notValid) {
                setIsApproveModalOpen(false);
                dispatch(setCurrentCaseStatus(response.caseStatus));
                distributeCase(
                    currentCase, answers, currentFormTemplateID, language, document.querySelector(".live-preview").outerHTML, intl).then(res2 => {
                        setCaseStatus(response.caseStatus);
                        setIsApproved(true);
                        setIsApproving(false);
                    }).catch(err => {
                        console.log(err);
                        setIsApproving(false);
                    });
            }
        });
    }

    const isVisible = (currentCase.status === "submitted" || currentCase.status === "approvalEdited") && isApprover;

    return isVisible && (
        <>
            <button
                id="buttonApprove"
                type="button"
                onClick={() => onApprove()}
                className={classnames("button", "button--icon-only", { "button--icon-saving": isApproving }, { "button--icon-disabled": !connection })}
                disabled={!connection}
            >
                <SvgButtonBarApprove size={buttonSize} title={intl.formatMessage({ id: currentCase.status === "approvalEdited" ? "formRenderer.saveAsFinalReport" : "formRenderer.approve" })} />
            </button>
            <ReactModal isOpen={isApproveAIModalOpen} className="react-modal approveai-container" overlayClassName="react-modal-overlay">
                <div className="react-modal__header" />
                <div className="react-modal__body update-modal__body">
                    <h2>{hasPressedNoApproveAI ? intl.formatMessage({ id: "customComponents.failurePrediction.approveAiNo" }) : intl.formatMessage({ id: "customComponents.failurePrediction.approveAi" })}</h2>
                </div>
                <div className="react-modal__footer">
                    {hasPressedNoApproveAI
                        ? <BaseButton handleSubmit={() => approveAIClose()} text={intl.formatMessage({ id: "formRenderer.close" })} />
                        : (
                            <>
                                <BaseButton handleSubmit={() => approveAIYes()} backgroundColor="orange" text={intl.formatMessage({ id: "customComponents.yes" })} />
                                <BaseButton handleSubmit={() => approveAINo()} text={intl.formatMessage({ id: "formRenderer.no" })} />
                            </>
                        )
                    }
                </div>
            </ReactModal>
            <ReactModal isOpen={isApproveModalOpen} className="react-modal" overlayClassName="react-modal-overlay" onRequestClose={() => closeApproveModal()}>
                <div className="react-modal__header">
                    {!isApproved &&
                        <button className="react-modal__close-button" onClick={() => { closeApproveModal(); }}>
                            <CircleCloseFilterSvg />
                        </button>
                    }
                </div>
                <div className="react-modal__body">
                    <div className="react-modal__text">
                        {isApproved ? <FormattedMessage id="formRenderer.afterApproveMessage" /> : <FormattedMessage id="formRenderer.approveConfirmation" />}
                    </div>
                    <div className="react-modal__actions-container">
                        {isApproved
                            ? <BaseButton handleSubmit={() => closeApproveModal()} text={intl.formatMessage({ id: "formRenderer.close" })} backgroundColor="green" className="react-modal__action-button" />
                            : (
                                <>
                                    <BaseButton handleSubmit={() => OnApproveCase()} text={intl.formatMessage({ id: "formRenderer.approveButton" })} backgroundColor="gray" className="react-modal__action-button button--wide" />
                                    <BaseButton handleSubmit={() => closeApproveModal()} text={intl.formatMessage({ id: "formRenderer.edit.cancel" })} backgroundColor="green" className="react-modal__action-button" />
                                </>
                            )}
                    </div>
                </div>
            </ReactModal>
        </>
    );
}

export default ButtonApprove;