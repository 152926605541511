import React from 'react'
import PropTypes from 'prop-types'
import LighthouseStatus from 'components/common/LighthouseStatus'

const TableCellStatus = ({ label, value, color }) => {
  const status = label === "approvalEdited" ? "submitted" : label;
  return (
    <td className="table__body-cell">
      <div className="table__body-inset">
        <LighthouseStatus label={status} color={color} value={ value  }  />
      </div>
    </td>
  )
}

TableCellStatus.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  color: PropTypes.string
}

export default TableCellStatus
