import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getReportType } from '../utils/reportUtils';
import getComponent from 'components/_Case/ComponentsList';
import OtherManufacturersDesignations from './OtherManufacturersDesignations';
import SKFInterchanges from './SKFInterchanges';

const BearingDesignationComponent = (props) => {
  const [skfOrCompetitor, setSkfOrCompetitor] = useState('');
  const [skfBearingDesignation, setSkfBearingDesignation] = useState('');
  const [SKFManufacturer, setSKFManufacturer] = useState('');
  const [manufacturer, setManufacturer] = useState('');
  const [competitorDesignationAndMarkings, setCompetitorDesignationAndMarkings] = useState('');
  const [skfEquivalent, setSkfEquivalent] = useState(''); // skfEquivalent is now renamed to SKF Interchanges
  const [skfSN, setSkfSN] = useState('');

  const connection = useSelector(state => state.connection);
  const formtemplateId = useSelector(state => state.currentCase.case.formtemplateId);

  useEffect(() => {
    let values;
    if (props.parentSetValue) {
      values = props.parentSetValue;
    } else {
      values = props.getValue(props.myName);
    }
    if (values) {
      setSkfOrCompetitor(values.skfOrCompetitor);
      setSkfBearingDesignation(values.skfBearingDesignation);
      setSKFManufacturer(values.SKFManufacturer);
      setManufacturer(values.manufacturer);
      setCompetitorDesignationAndMarkings(values.competitorDesignationAndMarkings);
      setSkfEquivalent(values.skfEquivalent);
    }
  }, []);

  const saveToStore = (objectToSave, name) => {
    if (props.parentHandleSave !== undefined) {
      props.parentHandleSave(objectToSave, name)
    } else {
      props.setValue(objectToSave, name);
    }
  }

  const handleChange = useCallback((value, name) => {
    const parts = name.split('.');
    const resultName = parts[parts.length - 1];
    const objectToSave = {
      skfOrCompetitor,
      skfBearingDesignation,
      SKFManufacturer,
      manufacturer,
      competitorDesignationAndMarkings,
      skfEquivalent,
      skfSN,
    };

    if (resultName === 'skfOrCompetitor') {
      setSkfOrCompetitor(value);
      objectToSave.skfOrCompetitor = value;

      if (value.value === 'skf') {
        setCompetitorDesignationAndMarkings('');
        setManufacturer('');
        setSkfEquivalent('');

        objectToSave.competitorDesignationAndMarkings = '';
        objectToSave.manufacturer = '';
        objectToSave.skfEquivalent = '';
      } else if (value.value === 'competitor') {
        setSkfBearingDesignation('');
        setSKFManufacturer('');
        setSkfSN('');

        objectToSave.skfBearingDesignation = '';
        objectToSave.SKFManufacturer = '';
        objectToSave.skfSN = '';
      }

      saveToStore(objectToSave, props.myName);
    } else if (resultName === 'skfBearingDesignation') {
      setSkfBearingDesignation(value);

      objectToSave.skfBearingDesignation = value;

      saveToStore(objectToSave, props.myName);
    } else if (resultName === 'manufacturer') {
      setManufacturer(value);
      setCompetitorDesignationAndMarkings('');
      setSkfEquivalent('');

      objectToSave.manufacturer = value;
      objectToSave.competitorDesignationAndMarkings = '';
      objectToSave.skfEquivalent = '';

      saveToStore(objectToSave, props.myName);
    } else if (resultName === 'competitorDesignationAndMarkings') {
      if (value.label && designationsAndInterchangables[value.label]) {
        setSkfEquivalent(designationsAndInterchangables[value.label]);
        objectToSave.skfEquivalent = designationsAndInterchangables[value.label];
      } else {
        setSkfEquivalent('');
        objectToSave.skfEquivalent = '';
      }
      setCompetitorDesignationAndMarkings(value);
      objectToSave.competitorDesignationAndMarkings = value;
      saveToStore(objectToSave, props.myName);
    } else if (resultName === 'skfEquivalent') {
      setSkfEquivalent(value);
      objectToSave.skfEquivalent = value;
      saveToStore(objectToSave, props.myName);
    } else if (resultName === 'SKFManufacturer') {
      setSKFManufacturer(value);
      objectToSave.SKFManufacturer = value;
      saveToStore(objectToSave, props.myName);
    } else if (resultName === 'skfSN') {
      setSkfSN(value);
      objectToSave.skfSN = value;
      saveToStore(objectToSave, props.myName);
    }
  }, [SKFManufacturer, competitorDesignationAndMarkings, manufacturer, props.myName, skfBearingDesignation, skfEquivalent, skfOrCompetitor, skfSN]);

  const currentReportType = getReportType(formtemplateId);

  const renderCompetitor = () => {
    const otherManufacturersInterchangableBrans = ['NSK Ltd', 'Timken', 'FAG / Schaeffler'];

    return (
      <>
        {connection ? (
          getComponent(
            {
              'key': 'listOfManufacturers',
              'type': 'AutoCompleteCreatable',
              'description': 'Competitor manufacturer',
              'args': [
                { 'apiPath': 'lists/bearingmanufacturers' },
                {
                  'valueLabelParams': {
                    'value': 'manufacturer',
                    'label': 'manufacturer'
                  }
                }
              ]
            },
            `${props.myName}.manufacturer`,
            handleChange,
            manufacturer
          )
        ) : (
          <p>List of manufacturers disabled</p>
        )}

        {(formtemplateId &&
          currentReportType === 'NAM' &&
          manufacturer?.label &&
          otherManufacturersInterchangableBrans.includes(manufacturer.label)) ? (
          <OtherManufacturersDesignations
            manufacturer={manufacturer.label}
            competitorDesignationAndMarkings={competitorDesignationAndMarkings}
            handleChange={handleChange}
            myName={props.myName}
          />
        ) : (
          getComponent(
            {
              'key': 'competitorDesignationAndMarkings',
              'type': 'Text',
              'description': 'Competitor designation and markings'
            },
            `${props.myName}.competitorDesignationAndMarkings`,
            handleChange,
            competitorDesignationAndMarkings
          )
        )}

        {formtemplateId && (
          currentReportType === 'NAM' ||
          currentReportType === 'Inspection' ||
          currentReportType === 'BDA'
        ) && (
            <SKFInterchanges
              handleChange={handleChange}
              myName={props.myName}
              skfEquivalent={skfEquivalent}
            />
          )}
      </>
    );
  }

  const renderSKF = () => {
    return (
      <React.Fragment key={skfOrCompetitor.label}>
        {getComponent(
          {
            'key': 'SKFManufacturer',
            'type': 'AutoCompleteCreatable',
            'description': 'SKF brand',
            'args': [
              {
                'options': [
                  { 'label': 'gbcSkf', 'value': 'gbcSkf' },
                  { 'label': 'kaydonSkf', 'value': 'kaydonSkf' },
                  { 'label': 'mrcSkf', 'value': 'mrcSkf' },
                  { 'label': 'peerSkf', 'value': 'peerSkf' },
                  { 'label': 'skf', 'value': 'skf' },
                  { 'label': 'skfCooperSkf', 'value': 'skfCooperSkf' }
                ]
              },
            ]
          },
          `${props.myName}.SKFManufacturer`,
          handleChange,
          SKFManufacturer
        )}
        {getComponent(
          {
            'key': 'skfBearingDesignation',
            'type': 'AutoCompleteCreatableBigList',
            'description': 'SKF bearing designation',
            'args': [
              { 'apiPath': 'lists/json/designations' },
            ]
          }, `${props.myName}.skfBearingDesignation`, handleChange, skfBearingDesignation
        )}
        {formtemplateId && currentReportType === 'BDA' && (
          getComponent({
            key: 'skfSN',
            type: 'Text',
            description: 'SKF serial number',
          },
            `${props.myName}.skfSN`,
            handleChange,
            skfSN
          )
        )}
      </React.Fragment>
    );
  }

  const renderSKFOrCompetitor = () => {
    if (!skfOrCompetitor?.value) {
      return null;
    }
    if (skfOrCompetitor.value === 'skf') {
      return renderSKF();
    }
    return renderCompetitor();
  }

  const renderBearingDesignation = (formComponent, index) => {
    if (!formComponent) return null;

    return (
      <React.Fragment key={index}>
        {getComponent(
          formComponent,
          `${props.myName}.${formComponent.key}`,
          handleChange,
          skfOrCompetitor
        )}
      </React.Fragment>
    );
  }

  return (
    <>
      {props.formComponentData.args.map((formComponent, index) => renderBearingDesignation(formComponent, index))}
      {renderSKFOrCompetitor()}
    </>
  );
}

export default BearingDesignationComponent;

const designationsAndInterchangables = {
  '2317': '2317 M',
  '22317 EAKE4C3': '22317 EK/C3',
  'TL 23144 CAG3MKC4W507': '23144 CCK/HA3C4W33',
  '23068 CAMC3P55W509': '23068 CAC/C083W513',
  '23268 CAMKC3P55W507': '23268 CAK/C083W507',
  '23136 CKW507': '23136 CCK/C08W507',
  '23268 CAMKP55W507': '23268 CAC/C08W507',
  '230/600 CAMKC3P55W507': '230/600 CAK/C083W507',
  'TL 24156 CAG3MK30C4W507': '24156 CCK30/C4W33',
  '23248 CAMKC3P55W507': '23248 CACK/C083W507',
  '23092 CAMC3P55W507': '23092 CAK/C3W33',
  '230/560 ACAMP55W509Z': '230/560 CA/C08W509',
  '23096 CAMKC3P55W507': '23096 CAK/C083W507',
  '23260 CAG3MKP55W507': '23260 CAK/HA3W33',
  '23252 CAMKC3P55W507': '23252 CACK/C083W507',
  '239/500 CAMKC3P55W507': '239/500 CAK/C083W507',
  '23276 CAMKC3P55W507': '23276 CAK/C083W507',
  '23256 CAMKC3P55W507': '23256 CACK/C083W507',
  '23260 CAMKP55W507': '23260 CACK/C08W507',
  '23176 CAMKP55W507': '23176 CAK/C08W507',
  '23088 CAMP55W509': '23088 CA/C08W509',
  'HPS 23248 CAMKC3P55W507': '23248 CACK/C083W507',
  '23136 CE4S11': '23136 CC/W33',
  '23280 CAMKC3P55W507': '23280 CAK/C083W507',
  '22244 CAKW507B': '22244 CCK/C08W507',
  '23260 CAG3MKC4P55W507': '23260 CACK/HA3C4W33',
  '23256 CAMKP55W507': '23256 CACK/C08W507',
  '23180 ACAMC3P55W509Z': '23180 CA/C083W509',
  '22232 CDKE4S11': '22232 CCK/W33',
  '22238 CAMKC3W507': '22238 CCK/C3W33',
  '23044 CAMKW507': '23044 CCK/W33',
  '24156 CAG3MK30C4W01': '24156 CCK30/C4W33',
  '23144 CAG3MKC4W507': '23144 CCK/C4W33',
  '23292 CAG3MKW507': '23292 CACK/C08W507',
  '23144 CKC3W507': '23144 CCK/C3W33',
  'HPS 22222EAKC3W507': '22222 EK/C3',
  'HPS 23256 CAMKP55W507': '23256 CACK/C08W507',
  '23072 CAMKP55W507': '23072 CACK/C08W507',
  '22352 CAE4': '22352 CC/W33',
  '23160 CAMKC3P55W507': '23160 CACK/C083W507',
  '23136 CAE4 S11': '23136 CC/W33',
  '23164 CAE4P55S11': '23164 CAC/C08W507',
  '22352 CAMKW507': '22352 CCK/W33',
  '23248-BEA-XL-MB1-C3': '23248 CAC/C3W33',
  '23048-E1': '23048 CC/W33',
  '23248-E1A-MB1-C3': '23248 CC/C3W33',
  '23148-BEA-XL-K-MB1-T52BW': '23148 CCK/W33',
  '23276-BEA-XL-K-MB1-T52BW': '23276 CAK/C08W33',
  '22260-MB-C3': '22260 CC/C3W33',
  '23252-E1A-K-MB1-C3': '23168 CC/C3W33',
  '23960-B-K-MB-C4': '23960 CCK/C4W33',
  '23292-K-MB-T52BW': '23292 CAK/C08W507',
  '23164-K-MB-T52BW': '23164 CACK/C08W507',
  '23268-B-K-MB-T52BW-C3': '23268 CAK/C3C08W33',
  '23264-BEA-XL-K-MB1-T52BW': '23264CACK/C08W507',
  '23284-E1A-K-MB1': '23284 CAK/W33',
  '23252-BEA-XL-K-MB1-C3': '23252 CACK/C3W33',
  '23256-BEA-XL-K-MB1-T52BW-C3': '23256 CACK/C083W507',
  '23168-BK-MB-C3': '23168 CACK/C3W33',
  '23176-K-MB-T52BW': '23176 CAK/C08W507',
  '23160-B-MB-H140': '23160 CAC/C08W509',
  '23052-MB': '23052 CC/W33',
  '230/560-B-MB-H140': '230/560 CA/C08W509',
  '23172-MB-H140-C3': '23172 CAC/C083W509',
  '22328-E1-XL': '22328  CC/W33',
  'Z-568309.ZL': 'no interchange',
  '23252-BEA-XL-K-MB1-T52BW': '23252 CACK/C08W507',
  '23172-MB-H140': '23172 CAC/C08W509',
  '23144-B-K-MB': '23144 CCK/W33',
  '23092-B-MB-C3': '23092 CAC/C3W33',
  '23148-BE-XL-K-C3': '23148 CCK/C3W33',
  '23044-E1': '23044 CC/W33',
  '23072-BEA-XL-K-MB1-T52BW-C3': '23072 CACK/C083W507',
  '23080-MB-H140': '23080 CAC/C08W507 or W509',
  '23264-K-MB-T52BW': '23264 CACK/C08W507',
  '23276-B-K-MB-T52BW': '23276 CAK/C08W507',
  '23144-B': '23144 CC/W33',
  '23244-K-MB': '23244 CCK/W33',
  '23984-C3': '23984 CC/C3W33',
  '23140-BK-MB-C3': '23140 CCK/C3W33',
  '23984-MB': '23984 CC/W33',
  '23268-B-K-MB-T52BW': '23268 CAK/C08W507',
  '23972-K-MB-T52BW-C3': '23972 CCK/C083W33',
  '23972-K-MB-C3': '23972 CCK/C3W33',
  '23140 KEMBW507C08C3': '23140 CCK/C083W507',
  '231/670 KYMBW906B': '231/670 CAK/C08W507X',
  '23180 YMBW509C08': '23180 CA/C08W33',
  '23044 KYMW33C4': '23044 CCK/C4W33',
  '23996 KYMBW507C08C3': '23996 CAK/C083W507',
  '22232 KCJW33C3': '22232 CCK/C3W33',
  '23230 EMW33C3': '23230 CC/C3W33',
  '23264 KYMBW906A': '23264 CACK/C08W507',
  '23184 CA/C08W509': '23184 CA/C08W509',
  '23056 KEMBW507C08C3': '23056 CACK/C083W507',
  '23264 KYMBW906A ': '23264 CACK/C08W507',
  '23144 KEMBW507C08': '23144 CCK/C08W507',
  '230/600 KYMBW906AC3': '230/600 CACK/HA3C3W33',
  '24156 KYMBW507C08C4': '24156 CCK30/C084W33',
  '23292 KYMBW906A': '23292 CAK/C08W507',
  '230/850 KYMBW906AC3': '230/850 CAK/C083W507',
  '23180 YMBW507C08': '23180 CA/C08W507',
  '23172 KEMBW906AC4': '23172 CACK/C084W507',
  '23260 KYMBW906AC3': '23260 CACK/HA3C3W33',
  '23056 YMBW507C08C3': '23056 CACK/C083W507',
  '23080 YMBW509C08': '23080 CACK/C08W509',
  '23080 EMBW509C08': '23080 CACK/C08W509'
};
