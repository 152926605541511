import React, { useState, useEffect } from "react";
import DatePicker, {setDefaultLocale, getDefaultLocale, registerLocale} from "react-datepicker";
import CalendarSvg from 'components/svg/Calendar'
import classnames from 'classnames'
import { FormattedMessage} from "react-intl";
import { enGB, enUS, it, fr, ru, pt, zhCN, zhTW, sv, es, de, ja, cs, pl, fi, tr, vi, nl, ko } from 'date-fns/locale'
import { useSelector } from "react-redux";


registerLocale("en", enUS)
registerLocale("pt", pt)
registerLocale("zh-CN", zhCN)
registerLocale("zh-TW", zhTW)
registerLocale("it", it)
registerLocale("fr", fr)
registerLocale("es", es)
registerLocale("sv", sv)
registerLocale("de", de)
registerLocale("cs", cs);
registerLocale("pl", pl);
registerLocale("fi", fi);
registerLocale("tr", tr);
registerLocale("vi", vi);
registerLocale("ru", ru);
registerLocale("nl", nl);
registerLocale("ko", ko);
registerLocale("ja", ja);





const language = navigator.userLanguage || navigator.language;
setDefaultLocale(language)

const DatePickerComponent = ({myName, formComponentData, getValue, setValue, labelClassName, isDateRangeFilter, parentSetValue, parentHandleSave}) => {
  const [date, setDate] = useState("")
  const language = useSelector(state => state.language);

  useEffect(() => { 
    let locale = language;
    if (language === "zh") { 
      locale = "zh-CN"
    }
    if (language === "zh-HANT") { 
      locale = "zh-TW"
    }
    if (language === "se") { 
      locale = "sv"
    }
    if (language === "jp") { 
      locale = "ja"
    }
    setDefaultLocale(locale);
  }, [language])

  useEffect(() => {
    let savedDate
    if ( parentSetValue !== undefined  && parentSetValue !== null) {
      savedDate = parentSetValue
    } else {
      savedDate = getValue(myName)
    }
    if (savedDate !== undefined && savedDate !== "") {
      savedDate = new Date(savedDate.dateObject)
      if (savedDate instanceof Date && !isNaN(savedDate)) {
        setDate(savedDate)
      }
    } else {
      if (myName === "reportDetails.reportDate") {
        let now = new Date() 
        setDate(now)
      }
    }
  }, [parentSetValue, myName, getValue])

  useEffect(() => {
    if (parentSetValue !== undefined && parentSetValue.date !== undefined && parentSetValue.date!== "" && parentSetValue.hasOwnProperty("dateObject")) {
      let a = new Date(parentSetValue.dateObject)
      setDate(a)
    }
  }, [parentSetValue])

  const handleChange = (date) => {
    console.log("Date", date, myName)
    if (date !== null && date !== undefined) {
      let saveObject= { 
        date : date.toLocaleDateString(), 
        dateObject : date
      }
      if (parentHandleSave !== undefined ) {
        parentHandleSave(saveObject, myName)
        setDate(date)
      } else {
        setValue(saveObject, myName);
        setDate(date)
      }
    } else {
      if (parentHandleSave !== undefined ) {
        parentHandleSave("", myName)
        setDate("")
      } else {
        setValue("", myName);
        setDate("")
      }
    }
  }
  return (
    <div className="input-shell">
      {formComponentData.description && (<label
        htmlFor={formComponentData.name}
        className={classnames('input-shell__label', 'input-shell__label--dark', { 'input-shell__label--required': formComponentData.required }, labelClassName)}>
        { formComponentData.description }
      </label>)}
      <div
        className={(isDateRangeFilter ? "date-range-picker " : "") + "input-shell__container"}
        data-id={ myName}
      >
        <CalendarSvg className="input-shell__svg input-shell__svg--left input-shell__svg--calendar" />
        <DatePicker
          className="input__element input__element--use-icon-left"
          calendarClassName="date-picker"
          onChange={handleChange}
          selected={date}
          locale={getDefaultLocale()}
          dateFormat="P"
          previousMonthButtonLabel={<svg width="9" height="14" viewBox="0 0 9 14" xmlns="http://www.w3.org/2000/svg"><path strokeWidth="2" d="M8 1L2 7l6 6" stroke="#0F58D6" fill="none" fillRule="evenodd"/></svg>}
          nextMonthButtonLabel={<svg width="9" height="14" viewBox="0 0 9 14" xmlns="http://www.w3.org/2000/svg"><path d="M1 13l6-6-6-6" fillRule="nonzero" stroke="#0F58D6" strokeWidth="2" fill="none"/></svg>}
        />
      </div>
      {formComponentData.required && (<div className="input-shell__message">
        <div className="input-shell__message-hint">
        <FormattedMessage id="formComponents.required" />
        </div>
      </div>)}
    </div>
  )
}
export default DatePickerComponent