import React from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import PropTypes from 'prop-types'
import { isConfidential, extractConfigFor, transformSummaryData, transformAssetDetails } from "../Shared/LivepreviewHelperFunctions"

import AssetTable from 'components/_LivePreview/Shared/AssetTable'
import ReportHeader from 'components/_LivePreview/Shared/ReportHeader'
import ReportNumber from 'components/_LivePreview/Shared/ReportNumber'
import ReportGeneralText from 'components/_LivePreview/Shared/ReportGeneralText'
import BartReportInformation from 'components/_LivePreview/Bart/BartReportInformation'

import LivePreviewHeader from 'components/_LivePreview/Shared/LivePreviewHeader'
import StatusConfidential from 'components/_LivePreview/Shared/StatusConfidential'
import BartConfig from 'components/_LivePreview/Bart/BartConfig'
import BearingInvestigation from 'components/_LivePreview/Bart/BearingInvestigation'
import Summary from "../Shared/Summary";

const Bart = (props) => {
  const intl = useIntl()
  const caseId = useSelector(state => state.saveComponentValue.caseId)
  const showSummaryAtTop = useSelector(state => state.showSummaryAtTop)

  const translated = {
    customerDetailsHeader: intl.formatMessage({ id: 'customerDetails.livePreview.header' }),
    assetDetailsHeader: intl.formatMessage({ id: 'assetDetails.livePreview.header' }),
    firstSummaryHeader: intl.formatMessage({ id: 'summary.livePreview.firstHeader' }),
    summaryHeader: intl.formatMessage({ id: 'summary.livePreview.header' }),
    bdaHeader: intl.formatMessage({ id: 'case.formHeader.bearingDamageAnalysis' })
  }

  props.data["reportDetails.terNumber"] = props.data["investigationDetails.terNumber"]

  return (
    <div className="live-preview">
      <LivePreviewHeader />

      <div className="live-preview__container">
        <ReportHeader data={translated.bdaHeader} />

        <StatusConfidential caseStatus={props.caseStatus} isConfidential ={isConfidential(props.data)}/>

        <ReportNumber data={caseId} revisionNumber={props.revisionNumber} reportStatus={props.caseStatus} />

        <BartReportInformation config={{
          reportDetails: extractConfigFor('reportDetails', BartConfig),
          skfDetailsOther: extractConfigFor('skfDetails', BartConfig),
          investigationDetails: extractConfigFor('investigationDetails',BartConfig),
          customerDetails: extractConfigFor('customerDetails', BartConfig)
        }} data={props.data}
           prefixes={['skfDetails.coReportAuthor', 'skfDetails.reportAuthor']}
           isBearingTableShown= {true}
        />

        <AssetTable header={translated.assetDetailsHeader} config={extractConfigFor('assetDetails', BartConfig)} sectionId="assetDetails" data={transformAssetDetails(props.data, BartConfig)} />

        {showSummaryAtTop && <Summary showOnlyConclusionsAndRecommendations={true} header={translated.firstSummaryHeader} config={extractConfigFor('summary', BartConfig)} data={transformSummaryData(props.data, BartConfig)} />}

        <BearingInvestigation config={extractConfigFor('bearingInvestigations', BartConfig)} data={props.data} bearingsCount={props.data['bearingInvestigations.count']} />

        <Summary sectionId="summary" showOnlyConclusionsAndRecommendations={false} header={translated.summaryHeader} config={extractConfigFor('summary', BartConfig)} data={transformSummaryData(props.data, BartConfig)} />

        <ReportGeneralText/>

      </div>
    </div>
  )
}

Bart.propTypes = {
  formData: PropTypes.object,
  revisionNumber: PropTypes.any,
}

export default Bart
