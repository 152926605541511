import React, { useEffect, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { shallowEqual, useSelector } from "react-redux";
import classnames from "classnames";
import ImageSelectorItem from "./ImageSelectorItem";

const ImageSelectorComponent = ({ myName, getValue, parentSetValue, parentHandleSave, setValue, formComponentData }) => { 
    const intl = useIntl();
    const [selectedItem, setSelectedItem] = useState([]);
    const [header, setHeader] = useState("");

    const getBearingIndexFromName = name => {
        const firstSplit = name.split("[");
        if (firstSplit.length > 1) { 
            return firstSplit[1].split("]")[0];
        }
        return -1;
    }

    const bearingIndex = getBearingIndexFromName(myName);

    const images = useSelector(state => state.saveComponentValue.images.filter(image =>
        getBearingIndexFromName(image.name) === bearingIndex), shallowEqual
    );
    const imageToDelete= useSelector(state => state.saveComponentValue.imageForDeletion, shallowEqual);

    useEffect(() => {
        if (!imageToDelete) return;
        if (selectedItem.length === 0 || imageToDelete.url !== selectedItem[0].url) return;

        // unselect a photo that is being deleted
        setSelectedItem([]);

        if (parentHandleSave) {
            parentHandleSave([], myName);
        } else {
            setValue([], myName);
        }
    }, [imageToDelete]);

    useEffect(() => {
        let savedValue;
        if (parentSetValue) {
            savedValue = parentSetValue;
        } else {
            savedValue = getValue(myName);
        }
        if (savedValue) {
            setSelectedItem(savedValue);
        }
    }, [myName, getValue, parentSetValue]);

    useEffect(() => {
        let header = "";
        if (myName.includes("primaryFailureAndCause")) {
            header = intl.formatMessage({ id: "customComponents.selectFigurePrimaryIso" });
        } else if (myName.includes("additionalFailureAndCause")) {
            header = intl.formatMessage({ id: "customComponents.selectFigureAdditionalIso"});
        }
        setHeader(header);
     }, [myName, intl]);

    const getImage = (image) => image.uneditedUrl ?? image.url;
    const isSelected = image => { 
        if (selectedItem.length > 0) { 
            return getImage(selectedItem[0]) == getImage(image);
        }
        return false;
    }
 
    const selectImage = image => {
        const sameImage = selectedItem && selectedItem.length > 0 && selectedItem[0].url === image.url;

        // unselect already selected image for failure
        setSelectedItem(sameImage ? [] : [image]);

        if (parentHandleSave) {
            sameImage ? parentHandleSave([], myName) : parentHandleSave([image], myName);
        } else {
            sameImage ? setValue([], myName) : setValue([image], myName);
        }
    }
 
    return (
        <div className="input-shell">
            <label
                htmlFor={formComponentData.description}
                className={
                    classnames("input-shell__label", "input-shell__label--dark",
                        { "input-shell__label--required": formComponentData.required }
                    )}
            >
                {header}
            </label>
            <div className="image-selector">
                {images.map((image, index) =>
                    <ImageSelectorItem
                        clickHandler={() => selectImage(image)}
                        key={index}
                        imgUrl={getImage(image)}
                        itemStyle={{
                            "backgroundPosition": "50% 50%",
                            "backgroundImage": `url("${getImage(image)}")`
                        }}
                        itemClassName={classnames("image-selector__item", isSelected(image) ? "selected" : "")}
                        svgSize={36}
                        svgFillColor={!isSelected(image) ? "#6B7981" : null}
                        svgStrokeColor={!isSelected(image) ? "#86989C" : null}
                        svgClassName="image-selector__button"
                    />
                )}
            </div>
            {formComponentData.required &&
                <div className="input-shell__message-hint">
                    <FormattedMessage id='formComponents.required' />
                </div>
            }
        </div>
    );
}

export default ImageSelectorComponent;
  