import { POPULATE_CASES, TOGGLE_CASES_LOADING } from 'redux/types'

const populateCases = (cases, total) => ({
  type: POPULATE_CASES,
  cases,
  total
})

const toggleCasesLoading = isLoading => ({
  type: TOGGLE_CASES_LOADING,
  isLoading
})

export {
  populateCases,
  toggleCasesLoading
}
