import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import {isValuePresent} from "./BIHelperFunctions";
import PropTypes from "prop-types";
import renderHTML from "react-render-html";

const KeyValueTable = (props) => {
  const intl = useIntl()
  const [selectedValues, setSelectedValues] = useState([]);

  const scrollTarget = useSelector(state => state.saveComponentValue.scrollTarget)
  const caseWasSaved = useSelector(state => state.saveComponentValue.caseWasSaved)

  useEffect(() => {
    setSelectedValues(requiredFieldsAndFilledArray(props.config, props.data));
  }, [props.data]);

  const isValidAnswer = (answer) => {
    return typeof answer !== "object";
  };

  const hasScrollTarget = key => {
    if (scrollTarget) {
      if (scrollTarget.fieldTarget || scrollTarget.scrollTarget) {
        return (scrollTarget.fieldTarget === key || scrollTarget.scrollTarget.includes(key)) && !caseWasSaved;
      }
    }
    return false;
  }

  const formatValue = (value) => {
    if (value === true) {
      return intl.formatMessage({ id: "livePreview.boolean.answer.yes" });
    }

    if (value === false) {
      return intl.formatMessage({ id: "livePreview.boolean.answer.no" });
    }

    if (value === "true") {
      return intl.formatMessage({ id: "livePreview.boolean.answer.yes" });
    }

    if (value === "false") {
      return intl.formatMessage({ id: "livePreview.boolean.answer.no" });
    }

    return value;
  };

  const isBearingKey = (key) => {
    if (key.indexOf("bearingInvestigations") > -1) {
      const newKey = key.split(".");
      newKey.splice(1, 1);
      return newKey[newKey.length - 1];
    } else if (key.indexOf("sectorSegment") > -1) {
      const newKey = key.split(".");
      return "customComponents." + newKey[newKey.length - 1];
    } else {
      const newKey = key.split(".");
      return newKey[newKey.length - 1];
    }
  };

  const requiredFieldsAndFilledArray = (config, data) => {
    let hasFields = [];

    if (data !== undefined) {
      config.forEach((c) => {
        if (data[c.key] !== undefined && data[c.key] !== null && data[c.key] !== "" || hasScrollTarget(c.key)) {
          hasFields.push(c);
        }
      });
    }

    return hasFields;
  };

  const getSkipPageBreakClass = (config, content) => {
    if (content && config.isRichText) {
      return content.length > 1000 ? "skip-break" : "";
    }
    return "";
  }

  const markupToRender = () => {
    let loopIncrement = 0;
    let markup = "";

    props.config.map((cell) => {
      if (isValuePresent(props.data[cell.key]) || hasScrollTarget(cell.key)) {
        if (cell.isFullWidth) {
          let fullWidthCellValue = isValidAnswer(props.data[cell.key])
            ? formatValue(props.data[cell.key])
            : "object";

          markup += `
            <div data-key-id="${cell.key}" key="${
              cell.key
            }" className="live-preview-key-value__row ${getSkipPageBreakClass(cell, fullWidthCellValue)}">
            <div className="live-preview-key-value__cell">`;
          if (cell.noLabel !== true) {
            markup += `<div className="live-preview-key-value__key">${intl.formatMessage(
                  { id: isBearingKey(cell.key) }
                )}:</div>`;
          }
          markup += `
            <div className="live-preview-key-value__value">${
                fullWidthCellValue !== undefined ? fullWidthCellValue : ""
              }</div>
            </div>
          </div>
          `;
        } else if (
          props.data[cell.key] &&
          props.data[cell.key].hasOwnProperty("imageLayout")
        ) {
          if (props.data[cell.key].imageUrls.length === 0) return;
          let imageString;
          imageString = `<div className="live-preview-images">
          <div className="live-preview-images__container ${
            props.data[cell.key].imageLayout
              ? props.data[cell.key].imageLayout.value
              : "live-preview-images__container--3"
          }">
            ${props.data[cell.key].imageUrls
              .map((imgObj) => {
                return `
								<div>
									<div className="live-preview-images-item">
										<div className="live-preview-images-item__media">
											<div className="live-preview-images-item__media-container">
												<img src=${
                          imgObj.url
                        } alt='live preview image' className="live-preview-image-item__media-image" />
											</div>
										</div>
										<div className="live-preview-images-item__description">
											<span className="live-preview-images-item__media-figure">${(imgObj.autoNumber ? imgObj.autoNumber : imgObj.number) +
                        " - " +
                        imgObj.caption}</span>
										</div>
									</div>
								</div>`;
              })
              .join("")}
          </div>
        </div>`;

          const nextItem = selectedValues[loopIncrement + 1];
          if (
            loopIncrement % 2 == 1 ||
            loopIncrement === selectedValues.length ||
            (nextItem !== undefined && nextItem.isFullWidth) ||
            props.singleColumn
          ) {
            markup += "</div>";
          }

          markup += imageString;
        } else {

          const singleCellValue = isValidAnswer(props.data[cell.key])
            ? formatValue(props.data[cell.key])
            : "";
          
          if (loopIncrement % 2 == 0 || props.singleColumn) {
            markup += `<div className="live-preview-key-value__row ${getSkipPageBreakClass(cell, singleCellValue)}">`;
          }

          
          markup += `
            <div data-key-id="${cell.key}" key="${
              cell.key
            }" className="live-preview-key-value__cell">`;
         if (cell.noLabel !== true) {
           markup += `<div className="live-preview-key-value__key">${intl.formatMessage(
            { id: isBearingKey(cell.key) }
            )}:</div>`
          }
          markup += `<div className="live-preview-key-value__value">${
              singleCellValue !== undefined ? singleCellValue : ""
            }</div>
          </div>
          `;
          const nextItem = selectedValues[loopIncrement + 1];
          if (
            loopIncrement % 2 == 1 ||
            loopIncrement === selectedValues.length ||
            (nextItem !== undefined && nextItem.isFullWidth) ||
            props.singleColumn ||
            cell.imageIsNext
          ) {
            markup += "</div>";
          }

          loopIncrement = loopIncrement + 1;
        }
      }
    });

    return markup;
  };

  return (
    <React.Fragment>
      {selectedValues.length > 0 && (
        <div className="live-preview-key-value" data-section-id={ props.sectionId }>
          {props.header !== undefined && (
            <div className="live-preview-key-value__header">{props.header}</div>
          )}

          {props.data ? renderHTML(markupToRender()) : null}
        </div>
      )}
    </React.Fragment>
  );
};

KeyValueTable.propTypes = {
  header: PropTypes.string,
  config: PropTypes.array,
  index: PropTypes.number,
  singleColumn: PropTypes.bool,
  noBackground: PropTypes.bool,
}

export default KeyValueTable
