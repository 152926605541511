import { SET_REQUIRED_LIST, REMOVE_REQUIRED_LIST_BEARING, REMOVE_REQUIREDLIST_KEYS } from 'redux/types'

const setRequiredList = (payload) => {
  return { type: SET_REQUIRED_LIST, payload }
}

const removeRequiredListForBearing = (payload) => {
  return { type: REMOVE_REQUIRED_LIST_BEARING, payload }
}

const removeRequiredListKeys = (payload) => { 
  return { type: REMOVE_REQUIREDLIST_KEYS, payload };
}

export {
  setRequiredList,
  removeRequiredListForBearing,
  removeRequiredListKeys
}