import React, { useEffect, useState, useCallback } from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useSelector } from "react-redux";
import { Auth } from "aws-amplify";
import { getCasesInDraft } from "./Utils/syncDataForOffline"
import { getEvents } from "./Utils/syncData";
import OfflineSyncDialog from "./OfflineSyncDialog";
import WarningSvg from "components/svg/Warning";

const OfflineBar = () => { 
    const intl = useIntl();

    const connection = useSelector(state => state.connection, shallowEqual);
    const [showSyncBar, setShowSyncBar] = useState(false);
    const [showdialog, setShowDialog] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [casesLoading, setCasesLoading] = useState(false);

    const isShowingSyncbar = connection && (showSyncBar || showdialog) && isAuthenticated;

    useEffect(() => {
        Auth.currentAuthenticatedUser().then((response) => {
            setIsAuthenticated(true);
        }).catch(err => {
            setIsAuthenticated(false);
        });
    });
    
    useEffect(() => {
        if (connection) {
            fetchData().catch(err => console.log(err));
        } else {
            setShowDialog(false);
        }
    }, [connection]);

    const fetchData = async () => { 
        const events = await getEvents();
        setShowSyncBar(events.length > 0);
    };

    const getBarState = () => { 
        if (isShowingSyncbar) { 
            return "success"
        }
        return "warning";
    }

    const onCloseDialog = (hadItemsToSync) => { 
        setShowDialog(false);
        setShowSyncBar(hadItemsToSync);
    }

    const loadCasesInDraft = useCallback(async () => {
        setCasesLoading(true)
        await getCasesInDraft()
        setCasesLoading(false)
    }, [])

    const syncNowHandler = () => {
        try {
            loadCasesInDraft()
        } catch (e) {
            console.log(e)
        } finally {
            setShowDialog(true)
        }
    }
    
    const getSyncWarning = () => {
        return <>
            <WarningSvg color="#fff" className="message-bar__svg" />
            <span className="message-bar__svg">{intl.formatMessage({ id: "offline.dataToSync" })}</span>
            <button onClick={syncNowHandler} className="button button--background-green">
                <span className="button__text">{intl.formatMessage({ id: "offline.syncNow" })}</span>
            </button>
            <OfflineSyncDialog
                showdialog={showdialog}
                onCloseDialog={onCloseDialog}
                onSyncComplete={() => setShowSyncBar(false)}
                casesLoading={casesLoading}
            />
        </>;
    }

    const getOfflineWarning = () => { 
        return <>
            <WarningSvg className="message-bar__svg" />
            {intl.formatMessage({ id: "offlineMode" })}
        </>;
    }

    return !connection || isShowingSyncbar ?
        <div className={`message-bar message-bar--${getBarState()} message-bar--is-offline`}>
            <div className="message-bar__container">
                {isShowingSyncbar ? getSyncWarning() : getOfflineWarning()}
            </div>
        </div>
        : null
}

export default OfflineBar;