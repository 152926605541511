import React from "react";

export const SvgImageLayoutThree = props => {
    const computedProps = { ...props }
    delete computedProps['strokeColor']
    delete computedProps['fillColor']
    return (
        <svg width="48px" height="28px" viewBox="0 0 48 28" version="1.1">
            <title>{ props.label || "" }</title>
            <g id="icon_layout_row3_select" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect className="imageLayout-icon-bg" fill={props.fillColor || "#000000"} x="0" y="0" width="48" height="28"></rect>
                <g id="Group-3" className="imageLayout-icon-fg" transform="translate(10.000000, 8.000000)" fill={props.strokeColor || "#FFFFFF"}>
                    <rect id="Rectangle" x="7.01506774e-14" y="0" width="8.61349154" height="12" rx="1"></rect>
                    <rect id="Rectangle-Copy" x="9.69325423" y="0" width="8.61349154" height="12" rx="1"></rect>
                    <rect id="Rectangle-Copy-2" x="19.3865085" y="0" width="8.61349154" height="12" rx="1"></rect>
                </g>
            </g>
        </svg>
    )
};

export const SvgImageLayoutTwo5050 = props => {
    const computedProps = { ...props }
    delete computedProps['strokeColor']
    delete computedProps['fillColor']
    return (
        <svg width="48px" height="28px" viewBox="0 0 48 28" version="1.1">
            <title>{ props.label || "" }</title>
            <g id="icon_layout_row2_select" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect className="imageLayout-icon-bg" fill={props.fillColor || "#000000"} x="0" y="0" width="48" height="28"></rect>
                <g id="Group-4" className="imageLayout-icon-fg" transform="translate(10.000000, 8.000000)" fill={props.strokeColor || "#FFF"}>
                    <rect id="Rectangle" x="0" y="0" width="13.0909091" height="12" rx="1"></rect>
                    <rect id="Rectangle-Copy" x="14.9090909" y="0" width="13.0909091" height="12" rx="1"></rect>
                </g>
            </g>
        </svg>
    )
};

export const SvgImageLayoutTwo7030 = props => {
    const computedProps = { ...props }
    delete computedProps['strokeColor']
    delete computedProps['fillColor']
    return (
        <svg width="48px" height="28px" viewBox="0 0 48 28" version="1.1">
            <title>{props.label || ""}</title>
            <g id="icon_layout_landscape_select" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect className="imageLayout-icon-bg" fill={props.fillColor || "#000000"} x="0" y="0" width="48" height="28"></rect>
                <g id="Group-2" className="imageLayout-icon-fg" transform="translate(10.000000, 8.000000)" fill={props.strokeColor || "#FFF"}>
                    <rect id="Rectangle" x="-2.59114087e-14" y="0" width="16.3372455" height="12" rx="1"></rect>
                    <rect id="Rectangle-Copy" x="18.2821557" y="0.233333333" width="9.71784431" height="6.66666667" rx="1"></rect>
                </g>
            </g>
        </svg>
    )
};

export const SvgImageLayoutOneThree = props => {
    const computedProps = { ...props }
    delete computedProps['strokeColor']
    delete computedProps['fillColor']
    return (
        <svg width="48px" height="28px" viewBox="0 0 48 28" version="1.1">
            <title>{props.label || ""}</title>
            <g id="icon_layout_gallery_select" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect className="imageLayout-icon-bg" fill={props.fillColor || "#000000"} x="0" y="0" width="48" height="28"></rect>
                <g id="Group" className="imageLayout-icon-fg" transform="translate(10.000000, 6.000000)" fill={props.strokeColor || "#FFF"}>
                    <rect id="Rectangle" x="0" y="11.7894737" width="7" height="4.21052632" rx="1"></rect>
                    <rect id="Rectangle-Copy" x="10.5" y="11.7894737" width="7" height="4.21052632" rx="1"></rect>
                    <rect id="Rectangle-Copy-2" x="21" y="11.7894737" width="7" height="4.21052632" rx="1"></rect>
                    <rect id="Rectangle-Copy-2" x="0" y="0" width="28" height="10.1052632" rx="1"></rect>
                </g>
            </g>
        </svg>
    )
};

export const SvgImageLayoutOne = props => {
    const computedProps = { ...props }
    delete computedProps['strokeColor']
    delete computedProps['fillColor']
    return (
        <svg width="48px" height="28px" viewBox="0 0 48 28" version="1.1">
            <title>{props.label || ""}</title>
            <g id="icon_layout_single_select" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect className="imageLayout-icon-bg" fill={props.fillColor || "#000000"} x="0" y="0" width="48" height="28"></rect>
                <rect id="Rectangle-Copy-2" className="imageLayout-icon-fg" fill={props.strokeColor || "#FFFFFF"} x="10" y="6" width="28" height="16" rx="1"></rect>
            </g>
        </svg>
    )
};
