import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import IconSevere from "../svg/Severe";
import { injectIntl } from "react-intl";
import renderHTML from "react-render-html";
const ExportMessage = ({ user, intl }) => { 

    const [isExternal, setIsExternal] = useState(undefined);

    useEffect(() => { 
        if (user && "custom:external" in user) {
            if (user["custom:external"].toLocaleString().toLocaleLowerCase() === 'true') {
                setIsExternal(true);
            } else {
                setIsExternal(false);
            }
        } else { 
            setIsExternal(undefined);
        }
    }, [user]);

    const getMessage = () => {

        
        const linkText = intl.formatMessage({ id: 'dashboard.exportMessage.link' });
        const href = intl.formatMessage({ id: 'dashboard.exportMessage.href' });
        const link = `<a href=${href} target="_blank">${linkText}</a>`;
        const rootMessage = intl.formatMessage({ id: 'dashboard.exportMessage' }, { 0: link });
        
        return <>{renderHTML(rootMessage)}</>
    }
    return isExternal === false ? <div className="exportmessage">
        <div className="exportmessage__text">
            <IconSevere />
            <div>{ getMessage() }</div>
        </div>
    </div> : null;
}

const mapStateToProps = (state) => {
    return {
      user: state.currentUser,
    };
  };
  
export default connect(mapStateToProps)(injectIntl(ExportMessage));