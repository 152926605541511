import React, { Component } from "react";
import getComponent from "../../_Case/ComponentsList";
import httpService from "core/http-service";
import { connect } from "react-redux";

class CountryCompanyLocationComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countries: [],
      country: "",
      company: "",
      location: "",
      companyOptions: [],
      locationOptions: []
    };
  }

  componentDidMount() {
    let values = this.props.getValue(this.props.myName);
    if (values === undefined && this.props.user !== undefined && this.props.isUserExternal) {
      //Fallback to user details if value is not set
      values = this.getFallBackLocationValue(this.props.user);
      this.props.setValue(values, this.props.myName);
    }

    if (values) {
      this.setState({
        country: values.country,
        company: values.company,
        location: values.location
      });
    }

    httpService
      .get("lists/companies", { lang: this.props.language })
      .then(response => {
        this.setState(
          {
            countries: response.data
          },
          () => {
            if (values) {
              if (values.country) {
                this.getCompanyOptions(values.country);
                this.getLocationOptions(values.company);
              }
            }
          }
        );
      });
  }

  getFallBackLocationValue = (user) => {
    if (user !== undefined) {
      if (user['custom:externalusercountry'] !== undefined) {
        let userValues = {};
        userValues.country = { label: user['custom:externalusercountry'], value: user['custom:externalusercountry'] };
        if (user['custom:usercompany'] !== undefined) {
          userValues.company = { label: user['custom:usercompany'], value: user['custom:usercompany'] };

          if (user['custom:companylocation']) {
            userValues.location = { label: user['custom:companylocation'], value: user['custom:companylocation'] };
          }
        }
        return userValues;
      }
    }
  };

  handleChange = (value, name) => {
    const parts = name.split(".");
    const resultName = parts[parts.length - 1];

    let objectToSave = {
      country: this.state.country,
      company: this.state.company,
      location: this.state.location
    };

    if (resultName === "countrySelect") {
      this.setState({
        country: value,
        company: '',
        location: ''
      });
      objectToSave.country = value;
      objectToSave.company = '';
      objectToSave.location = '';
      this.props.setValue(objectToSave, this.props.myName);
      this.getCompanyOptions(value);
    } else if (resultName === "companySelect") {
      this.setState({
        company: value,
        location: ''
      });
      objectToSave.company = value;
      objectToSave.location = '';
      this.props.setValue(objectToSave, this.props.myName);
      this.getLocationOptions(value);
    } else if (resultName === "locationSelect") {
      this.setState({
        location: value
      });
      objectToSave.location = value;
      this.props.setValue(objectToSave, this.props.myName);
    }
  };

  getCountryOptions = () => {
    const countries = this.state.countries;
    let options = [];
    countries.forEach(country => {
      options.push({ label: country.country, value: country.countryCode });
    });
    return options.sort((a, b) => a.label.localeCompare(b.label));
  };

  getCompanyOptions = inputCountry => {
    const countries = this.state.countries;
    let options = [];
    if (inputCountry !== undefined && !this.props.isUserExternal) {
      let foundCountry = countries.find(country => country.countryCode === inputCountry.value);
      if (!foundCountry) {
        foundCountry = countries.find(country => country.country === inputCountry.value);
      }
      if (foundCountry) {
        this.inputCountry = foundCountry;
        foundCountry.companies.forEach(company => {
          options.push({
            label: company.legalUnitName,
            value: company.legalUnitName
          });
        });
      }
      this.setState({
        companyOptions: options
      });
    }
  };

  getLocationOptions = inputCompany => {
    if (this.inputCountry !== undefined && inputCompany !== undefined) {
      const companies = this.inputCountry.companies;
      let options = [];
      if (inputCompany !== undefined && inputCompany !== "" && !this.props.isUserExternal) {
        companies.forEach(company => {
          if (company.legalUnitName === inputCompany.value) {
            company.locations.forEach(location => {
              options.push({
                label: location.location,
                value: location.location
              });
            });
          }
        });
        this.setState({
          locationOptions: options
        });
      }
    }
  };

  renderCountry = () => {
    if (
      Array.isArray(this.state.countries) &&
      this.state.countries.length > 0
    ) {
      return (
        <div>
          {getComponent(
            {
              key: "countrySelect",
              type: "AutoComplete",
              translate: false,
              description: "Country",
              showrequired: this.props.formComponentData.required,
              args: [
                {
                  options: this.getCountryOptions()
                }
              ]
            },
            `${this.props.myName}.countrySelect`,
            this.handleChange,
            this.state.country
          )}
        </div>
      );
    } else return <p>Loading</p>;
  };

  renderCompany = () => {
    if (
      Array.isArray(this.state.companyOptions) &&
      this.state.companyOptions.length > 0
    ) {
      return (
        <div key={this.state.country.value}>
          {getComponent(
            {
              key: "companySelect",
              type: "AutoComplete",
              translate: false,
              description: "Company",
              args: [
                {
                  options: this.state.companyOptions
                }
              ]
            },
            `${this.props.myName}.companySelect`,
            this.handleChange,
            this.state.company
          )}
        </div>
      );
    } else if (this.props.isUserExternal) {
      return (
        <div>
          {getComponent(
            {
              key: "companySelect",
              type: "Text",
              description: "Company",
              isDisabled: true
            },
            '',
            this.handleChange,
            this.props.user['custom:usercompany']
          )}
        </div>
      );
    } else return null;
  };

  renderLocation = () => {
    if (
      Array.isArray(this.state.locationOptions) &&
      this.state.locationOptions.length > 0
    ) {
      return (
        <div key={this.state.company.value}>
          {getComponent(
            {
              key: "locationSelect",
              type: "AutoComplete",
              translate: false,
              description: "Location",
              args: [
                {
                  options: this.state.locationOptions
                }
              ]
            },
            `${this.props.myName}.locationSelect`,
            this.handleChange,
            this.state.location
          )}
        </div>
      );
    } else if (this.props.isUserExternal) {
      return (
        <div>
          {getComponent(
            {
              key: "locationSelect",
              type: "Text",
              description: "Location",
              isDisabled: true
            },
            '',
            this.handleChange,
            this.props.user['custom:companylocation']
          )}
        </div>
      );
    } else return null;
  };

  render() {
    return (
      <React.Fragment>
        {this.renderCountry()}
        {this.renderCompany()}
        {this.renderLocation()}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    language: state.language
  };
}

export default connect(mapStateToProps)(CountryCompanyLocationComponent);
