import React, { useState, useEffect } from "react";
import MainContainer from "components/common/MainContainer";
import WelcomeInformation from "components/_Dashboard/WelcomeInformation";
import PageHeader from "components/common/PageHeader";
import ContentContainer from "components/common/ContentContainer";
import repository from "core/repository";
import { Auth } from "aws-amplify";
import { connect } from "react-redux";
import CasesTable from "components/common/CasesTable";
import OfflineBaseTable from "components/_Offline/OfflineBaseTable";
import OfflineBaseTableMobile from "components/_Offline/OfflineBaseTableMobile";

import DashboardSearch from "./DashboardSearch";
import ExportMessage from "../common/ExportMessage";
import config from "config";
import _ from "lodash";
import localforage from "localforage";
import { useMediaQuery } from "react-responsive";
import { TO_SMALL } from "core/ui/breakpoints";
import { FormattedMessage } from "react-intl";
import BaseButton from "components/common/BaseButton";

const Dashboard = ({ loading, connection, ...props }) => {
  const [userName, setUserName] = useState("");
  const [offlineCasesReady, setOfflineCasesReady] = useState(false);
  const [offlineCases, setOfflineCases] = useState([]);


  useEffect(() => {
    Auth.currentAuthenticatedUser().then((response) => {
      setUserName(response.attributes.name);
    });
  }, []);

  useEffect(() => {
    getOfflineCases();
  }, [connection]);

  const isMobile = useMediaQuery({
    query: TO_SMALL,
  });

  function addOneCase() {
    repository
      .putCase(config.BART_TEMPLATE_ID)
      .then((response) => {
        props.history.push("/case/" + response.data.id);
      })
      .catch((error) => {
        alert(error);
      });
  }

  function getOfflineCases() {
    let offlineCases = [];

    localforage
      .iterate((value, key) => {
        let res = key.split("-");

        if (key.indexOf("case/") > -1) {
          offlineCases.unshift(value.data);
        }
      })
      .then(() => {
        setOfflineCasesReady(true);
        setOfflineCases(formatReportDates(offlineCases.reverse()));
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function formatReportDates(cases) {
    return cases.map((report) => {
      const reportDate = new Date(report.updatedAt);
      let updated = "N/A";
      if (typeof reportDate.getMonth === "function") {
        const date = ("0" + reportDate.getDate()).slice(-2); // Double digit date instead of single digit
        const month = ("0" + (reportDate.getMonth() + 1)).slice(-2); // Since getMonth() returns month from 0-11 not 1-12
        const year = reportDate.getFullYear();
        updated = `${year}-${month}-${date}`;
      }
      return {
        ...report,
        updated,
      };
    });
  }

  function renderComponents(isMobile, connection, loading) {
    if (!isMobile && connection) {
      return <CasesTable loading={loading} showPagination={false} />;
    } else if (!isMobile && !connection) {
      return (
        <OfflineBaseTable
          className={
            loading ? "element--is-loading element--is-loading-after" : ""
          }
          tableData={offlineCasesReady ? offlineCases : []}
        />
      );
    } else if (isMobile && !connection) {
      return (
        <OfflineBaseTableMobile
          className={
            loading ? "element--is-loading element--is-loading-after" : ""
          }
          tableData={offlineCasesReady ? offlineCases : []}
        />
      );
    } else if (isMobile && connection) {
      return <CasesTable loading={loading} showPagination={false} />;
    } else {
      return null;
    }
  }

  return (
    <MainContainer
      header={
        <React.Fragment>
          <DashboardSearch isUserExternal={props.isUserExternal} />
          <WelcomeInformation
            userName={userName}
            isUserExternal={props.isUserExternal}
          />
        </React.Fragment>
      }
    >
      {/* This is removed for now according to Jira ticket BART-207 */}

      {/* <PageHeader header="Recent reports" additional={ 
        <BaseButton 
          handleSubmit={addOneCase}
          text="Create new report" 
          iconComponent={<CirclePlus className="svg--45" /> } />
      /> } */}
      <PageHeader header={<FormattedMessage id="dashboard.recentReports" />} />
      <ContentContainer useFrame={true}>
        {renderComponents(isMobile, connection, loading)}
      </ContentContainer>
      <ExportMessage />
    </MainContainer>
  );
};

const mapStateToProps = (state) => ({
  loading: state.caseRelated.loading,
  connection: state.connection
});

export default connect(mapStateToProps)(Dashboard);
