import React, { useEffect, useState } from "react";
import getComponent from "../../_Case/ComponentsList";
import { removeRequiredListKeys } from "redux/actions/requiredListActions";
import { connect, useDispatch } from "react-redux";
import _ from "lodash";

const InspectionApprovedByComponent = ({ myName, getValue, setValue, formComponentData }) => {

  const [diameterOverTwoHundred, setDiameterOverTwoHundred] = useState("");
  const [approversName, setApproversName] = useState("");
  const [managerApprover, setManagerApprover] = useState("");
  const [solutionFactoryName, setSolutionFactoryName] = useState("");
  const [solutionFactoryCoordinator, setSolutionFactoryCoordinator] = useState("");
  const solutionFactory = formComponentData.args.find(a => a.key === 'solutionFactory')

  const dispatch = useDispatch();

  useEffect(() => {
    const values = getValue(myName);
    if (values) {
      setDiameterOverTwoHundred(values.diameterOverTwoHundred);
      setApproversName(values.approversName);
      setManagerApprover(values.managerApprover);
      setSolutionFactoryName(values.solutionFactoryName);
      setSolutionFactoryCoordinator(values.solutionFactoryCoordinator);
    }
  }, [getValue, myName]);

  const handleChange = (value, name) => {
    var resultName = name.split(".").pop();
    let objectToSave = { diameterOverTwoHundred, managerApprover, solutionFactoryName, approversName, solutionFactoryCoordinator };

    if (resultName === "diameterOverTwoHundred") {
      if (value === "true") {
        setManagerApprover("");
        setApproversName("");
        objectToSave = { ...objectToSave, managerApprover: "", approversName: "" };
      } else {
        setSolutionFactoryName("");
        setSolutionFactoryCoordinator("");
        setApproversName("");
        objectToSave = { ...objectToSave, solutionFactoryName: "", solutionFactoryCoordinator: "", approversName: "" };
        objectToSave.solutionFactoryName = "";
        objectToSave.approversName = "";
      }
      removeFromRequiredList(value);
      setDiameterOverTwoHundred(value);
      objectToSave = { ...objectToSave, diameterOverTwoHundred: value };
      setValue(objectToSave, myName);
    } else if (resultName === "managerApprover") {
      setManagerApprover(value);
      setApproversName(value);
      objectToSave = { ...objectToSave, managerApprover: value, approversName: value };
      setValue(objectToSave, myName);
    } else if (resultName === "solutionFactoryName") {
      setSolutionFactoryName(value);
      setSolutionFactoryCoordinator(value.value ? value.value.email : "");
      setApproversName(value.value ? value.value.email : "");
      objectToSave = { ...objectToSave, solutionFactoryName: value, solutionFactoryCoordinator: value.value ? value.value.email : "", approversName: value.value ? value.value.email : "" };
      setValue(objectToSave, myName);
    }
  }

  const removeFromRequiredList = (value) => {
    if (value === "true") {
      dispatch(removeRequiredListKeys(`${myName}.managerApprover`));
    } else {
      dispatch(removeRequiredListKeys(`${myName}.solutionFactoryName`));
    }
  };

  const renderYesOrNo = () => {
    if (diameterOverTwoHundred === "true") {
      return renderYes();
    } else if (diameterOverTwoHundred === "false") {
      return renderNo();
    } else {
      return null;
    }
  };

  const renderYes = () => {
    return (
      <>
        {getComponent(
          solutionFactory,
          `${myName}.solutionFactoryName`, handleChange, solutionFactoryName
        )}
        {getComponent(
          {
            type: "Text",
            key: "solutionFactoryCoordinator",
            description: "E-mail address to coordinator",
            isDisabled: true,
          },
          `${myName}.solutionFactoryCoordinator`, handleChange, solutionFactoryCoordinator
        )}
      </>
    );
  };

  const renderNo = () => {
    return (
      <>
        {getComponent(
          {
            type: "AutoComplete",
            key: "managerApproverExternal",
            description: "Report Approved By (Report by Manager)",
            args: [
              { apiPath: "lists/approvers" },
              {
                valueLabelParams: {
                  value: "email",
                  label: "name",
                },
              },
            ],
            required: true,
          },
          `${myName}.managerApprover`, handleChange, managerApprover
        )}
      </>
    );
  };



  const renderComponents = (formComponent, index) => {
    if (formComponent) {
      if (formComponent.key === "diameterOverTwoHundred") {
        return (
          <div key={index}>
            {getComponent(
              formComponent,
              `${myName}.${formComponent.key}`,
              handleChange,
              diameterOverTwoHundred
            )}
          </div>
        );
      }
    }
  };

  return <>
    {formComponentData.args.map((formComponent, index) => {
      return renderComponents(formComponent, index);
    })}
    {renderYesOrNo()}
  </>;

}

export default InspectionApprovedByComponent;