import React, { useState, useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { recordQuickLinkPosition, triggerSaveOnAccordian, selectQuickLink, setLivePreviewTarget } from "./../../redux/actions/saveComponentValueAction";
import { setIsBearingsExpanded } from "./../../redux/actions/isBearingsExpandedActions";
import getComponent from "./ComponentsList";

import _ from "lodash";
import classnames from "classnames";

import AccordionTrigger from "./Accordion/AccordionTrigger";
import SectionAccordion from "./Accordion/SectionAccordion";

const FormComponent = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [openAccordions, setOpenAccordions] = useState([]);
  const [scrollTarget, setScrollTarget] = useState(null);
  const [scrollSection, setScrollSection] = useState(null);

  const previousScrollTarget = usePrevious({ scrollTarget });
  const previousScrollSection = usePrevious({ scrollSection });

  const disableAutoScroll = useSelector(state => state.saveComponentValue.disableAutoScroll, shallowEqual);
  const isBearingsExpanded = useSelector(state => state.bearingsExpanded.isBearingsExpanded, shallowEqual);
  const bearingExpandedState = useSelector(state => state.bearingsExpanded.bearingsExpandedState, shallowEqual);
  const quickLinksTarget = useSelector(state => state.saveComponentValue.quicklinks.target, shallowEqual);
  const livePreviewTarget = useSelector(state => state.saveComponentValue.livePreviewTarget);
  const previousValue = usePrevious({ quickLinksTarget: quickLinksTarget });

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  useEffect(() => {
    if (previousValue && previousValue.quickLinksTarget) {
      if (previousValue.quickLinksTarget.name !== quickLinksTarget.name) {
        toggleOnQuicklinksTarget(quickLinksTarget);
      }
    } else {
      toggleOnQuicklinksTarget(quickLinksTarget);
    }
  }, [quickLinksTarget]);

  useEffect(() => {
    if (!livePreviewTarget || !quickLinksTarget) return

    toggleOnBearingAccordion()
  }, [livePreviewTarget, quickLinksTarget])

  useEffect(() => {
    if (previousScrollTarget && previousScrollTarget.scrollTarget && scrollTarget !== null) {
      if (previousScrollTarget.scrollTarget.name !== scrollTarget.name) {
        scrollToTarget(scrollTarget);
      }
    } else {
      scrollToTarget(scrollTarget);
    }
  }, [scrollTarget])

  useEffect(() => {
    if (previousScrollSection && previousScrollSection.scrollSection && scrollSection !== null) {
      if (previousScrollSection.scrollSection.name !== scrollSection.name) {
        scrollToSection(scrollSection);
      }
    } else {
      scrollToSection(scrollSection);
    }
  }, [scrollSection])

  useEffect(() => {
    if (!livePreviewTarget) return;

    const parts = livePreviewTarget.split('.');
    scrollToTarget({ name: parts[parts.length - 1] });
  }, [openAccordions])

  function translateAccordionLabel(formComponent) {
    if (formComponent.hasOwnProperty("accordion") && formComponent.accordion.label) {
      if (intl.messages.hasOwnProperty(formComponent.accordion.label)) {
        //Check alternate label when user is external
        if (props.isUserExternal && formComponent.accordion.hasOwnProperty('externallabel') && intl.messages.hasOwnProperty(formComponent.accordion.externallabel)) {
          return intl.formatMessage({ id: formComponent.accordion.externallabel });
        } else {
          return intl.formatMessage({ id: formComponent.accordion.label });
        }
      } else {
        return formComponent.accordion.label;
      }
    }
  }

  function calculateName(formComponent, parentName) {
    if (formComponent.skipParentName) {
      return parentName;
    }
    if (parentName !== undefined) {
      if (
        formComponent.hasOwnProperty("args") &&
        formComponent.args.hasOwnProperty("isList") &&
        formComponent.args.isList
      ) {
        return `${parentName}.${formComponent.key}[0]`;
      } else {
        return `${parentName}.${formComponent.key}`;
      }
    } else if (
      formComponent.hasOwnProperty("args") &&
      formComponent.args.hasOwnProperty("isList") &&
      formComponent.args.isList
    ) {
      return `${formComponent.key}[0]`;
    } else {
      return formComponent.key;
    }
  }

  const toggleOnBearingAccordion = () => {
    if (!livePreviewTarget || !quickLinksTarget) return
    // Triggered when Bearing section is clicked
    // toggling the sections to the currently clicked bearing that were opened in the previous one
    if (livePreviewTarget.includes('componentPartsInvestigation')) {
      setOpenAccordions(['componentPartsInvestigation'])
    } else {
      setOpenAccordions([quickLinksTarget.accordionTarget])
    }
    if (props?.parentName?.includes('componentPartsInvestigation')) {
      setOpenAccordions([quickLinksTarget.accordionTarget])
    }
    scrollToTarget(quickLinksTarget)
  }

  const toggleOnQuicklinksTarget = target => {
    // It is only intreseting to do this for bearings clicking in the quicklinks bearing menu
    // toggling the sections to the currently clicked bearing that were open in the previous one
    if (target && target.name && props.parentName && props.parentName.includes("bearingInvestigations.bearingInvestigation[") && props.formConfig) {
      if (target.name.includes(props.parentName) || props.parentName.includes(target.name)) {
        if (livePreviewTarget?.includes('componentPartsInvestigation')) {
          setOpenAccordions(['componentPartsInvestigation'])
          setScrollTarget({ name: target.name, accordionTarget: 'componentPartsInvestigation' });
        } else {
          setOpenAccordions([quickLinksTarget.accordionTarget])
          setScrollTarget(target);
        }
        if (props.parentName.includes('componentPartsInvestigation')) {
          setOpenAccordions([quickLinksTarget.accordionTarget])
          setScrollTarget(target);
        }
      }
    } else {
      setOpenAccordions([]);
    }
  };

  const toggleAccordion = (id) => {
    dispatch(triggerSaveOnAccordian(id));
    const index = getIndexValue(props.parentName)
    if (openAccordions?.includes(id)) {
      setOpenAccordions([]);
      dispatch(selectQuickLink({ index, accordionTarget: props.parentName }))
    } else {
      setOpenAccordions([id]);
      setScrollTarget({ name: id });
      setScrollSection({ name: id });
      if (!(props.parentName && props.parentName.includes("bearingInvestigations.bearingInvestigation["))) {
        dispatch(setIsBearingsExpanded(false));
      } else if (props.parentName && index !== '-1') {
        dispatch(selectQuickLink({ index, accordionTarget: id }))
        dispatch(setLivePreviewTarget(`${props.parentName}.${id}`))
      };
    }
  };

  const getIndexValue = (parentName) => {
    const match = parentName?.match(/\[(.*?)\]/)
    return match ? match[1] : '-1'
  }

  const scrollToTarget = target => {
    scrollTo("data-parent-id", target, window, -40);
    setScrollTarget(null);
  };

  const scrollToSection = target => {
    if (!target) return

    if (props.parentName && props.parentName.includes("bearingInvestigations.bearingInvestigation[")) {
      target.name = props.parentName + "." + target.name;
    }
    scrollTo("data-section-id", target, document.querySelector('.page-layout__content--live-preview'));
    setScrollSection(null);
  }

  const scrollTo = (key, target, scrollContainer, offset = 0) => {
    if (!target || !key || disableAutoScroll) return;

    const newTarget = target.accordionTarget ? target.accordionTarget : target.name;

    const allTargetElements = Array.from(document.querySelectorAll(`[${key}="${newTarget}"]`)).length > 0
      ? Array.from(document.querySelectorAll(`[${key}="${newTarget}"]`))
      // if no such section found, we are scrolled to the top of the header of the bearing in the live preview
      : Array.from(document.querySelectorAll(`[${key}="${target.name?.split('.')[1]}"]`))

    const element = allTargetElements.find(el => el.offsetTop > 0);

    if (element && element.offsetTop !== 0) {
      scrollContainer.scrollTo({
        top: element.offsetTop + offset,
        behavior: "smooth"
      });
    }
  }

  const isFirst = formComponent => {
    const found = props.formConfig.find(fc => fc.key === formComponent.key);
    if (found) {
      return props.formConfig.indexOf(found) === 0;
    }
  }
  const isLast = formComponent => {
    const found = props.formConfig.find(fc => fc.key === formComponent.key);
    if (found) {
      return props.formConfig.indexOf(found) === props.formConfig.length - 1;
    }
  }

  const isOpened = formComponent => {
    return openAccordions?.includes(formComponent.key);
  }

  const isPreviousOpened = formComponent => {
    if (openAccordions?.length > 0) {
      const opened = openAccordions[0];

      const openedIndex = props.formConfig.findIndex(fc => fc.key === opened);
      if (openedIndex === -1) return false;

      const currentIndex = props.formConfig.indexOf(formComponent);

      return openedIndex === currentIndex - 1;
    }
    if (isBearingsExpanded) {
      const currentIndex = props.formConfig.indexOf(formComponent);
      if (currentIndex > 0) {
        const potentialBearings = props.formConfig[currentIndex - 1];
        return potentialBearings.key === "bearingInvestigations";
      }
    }
    return false;
  }

  const isExpanded = formComponent => {
    return openAccordions?.includes(formComponent.key) && formComponent.components?.filter(fc => fc?.type !== "nested")?.length > 0;
  }

  const isPreviousOrphaned = formComponent => {
    if (isBearingsExpanded && bearingExpandedState === "lastChildOpened") {
      const currentIndex = props.formConfig.findIndex(fc => fc.key === formComponent.key);
      const bearingIndex = props.formConfig.findIndex(fc => fc.key === "bearingInvestigations");
      if (bearingIndex !== -1 && currentIndex !== -1) {
        return bearingIndex === currentIndex - 1;
      }
    }
  }

  const isOrphaned = formComponent => {
    if (props.parentName && props.parentName.includes("bearingInvestigation[") && props.parentName.endsWith("]") && bearingExpandedState === "lastChildOpened") {
      const index = props.formConfig.indexOf(formComponent);
      return index === props.formConfig.length - 1;
    }
  }

  const isPreviousExpanded = formComponent => {
    if (!isPreviousOpened(formComponent)) {
      return false
    }

    const opened = openAccordions[0];
    if (opened) {
      const openedFormComponent = props.formConfig.find(fc => fc.key === opened);
      if (openedFormComponent && openedFormComponent.components) {
        //If the last is an accordion
        const last = openedFormComponent.components[openedFormComponent.components.length - 1];
        if (last && last.type === "nested") {
          return false;
        }

        return openedFormComponent.components.filter(fc => fc.type !== "nested").length > 0;
      }
    }
  }

  function recordPosition(name, formComponent, event) {
    if (dispatch) {
      dispatch(recordQuickLinkPosition({ name: name }));
    } else {
      console.log("Missing dispatch for", name);
    }
  }

  const getGroupedFormAndAccordions = () => {
    const groups = [];
    let currentGroup = null;
    for (let index = 0; index < props.formConfig.length; index++) {
      const formComponent = props.formConfig[index];
      if (formComponent.accordion) {
        if (currentGroup === null || currentGroup.type !== "accordion") {
          currentGroup = {
            type: "accordion",
            items: []
          };
          groups.push(currentGroup);
        }
      } else {
        if (currentGroup === null || currentGroup.type !== "form") {
          currentGroup = {
            type: "form",
            items: []
          };
          groups.push(currentGroup);
        }
      }
      currentGroup.items.push({ formComponent, index });
    }
    return groups;
  }

  const isBearingInvestigationForm = items => items.length === 1 && items[0].formComponent.key === "bearingInvestigations";

  const getFormComponent = (formComponent, parentName) => {

    if (!formComponent.hidden) {
      return getComponent(
        formComponent,
        calculateName(formComponent, parentName),
        undefined,
        undefined,
        undefined,
        props.isDisabled,
        props.isUserExternal,
        props.user,
        recordPosition
      );
    } else {
      return null;
    }
  }

  const renderForms = (items, parentIndex, parentName) => {
    let currentSkip = -1;
    const components = items.map(({ formComponent, index }) => {
      if (formComponent.groupWithNext) {
        currentSkip = index + 1;
        return {
          type: "group",
          items: [getFormComponent(formComponent, parentName), ...items.slice(index + 1, index + 2).map(i => getFormComponent(i.formComponent, parentName))]
        };
      } else if (currentSkip !== index) {
        return getFormComponent(formComponent, parentName);
      } else {
        return null;
      }
    }).filter(c => c !== null);

    if (components.length > 0) {
      if (isBearingInvestigationForm(items)) {
        return components.map((item, index) => {
          if (item.type && item.type === "group") {
            return (
              <div className="group" key={index}>
                {item.items.map((innerItem, innerIndex) =>
                  <React.Fragment key={innerIndex}>{innerItem}</React.Fragment>
                )}
              </div>
            )
          }
          return <React.Fragment key={index}>{item}</React.Fragment>;
        });
      }
      return (
        <div key={parentIndex} className="accordion__content-form">
          {components.map((item, index) => {
            if (item.type && item.type === "group") {
              return (
                <div className="group" key={index}>
                  {item.items.map((innerItem, innerIndex) =>
                    <React.Fragment key={innerIndex}>{innerItem}</React.Fragment>
                  )}
                </div>
              )
            }
            return <React.Fragment key={index}>{item}</React.Fragment>;
          })}
        </div>
      );
    }
  }

  const renderFormComponent = formComponent => {
    const name = calculateName(formComponent, props.parentName);
    return (
      <FormComponent
        formConfig={formComponent.components}
        parentName={name}
        isDisabled={props.isDisabled}
        isUserExternal={props.isUserExternal}
        user={props.user}
        isRoot={false}
      />
    );
  }

  const renderAccordion = items => {
    return items.map(({ formComponent, index }) => (
      formComponent.renderType === "Sections" ? (
        <SectionAccordion key={index}
          formComponent={formComponent}
          openAccordions={openAccordions}
          calculateName={calculateName}
          getFormComponent={getFormComponent}
          parentName={props.parentName}
        >
          <AccordionTrigger
            isRoot={props.isRoot}
            isOpened={isOpened(formComponent)}
            isExpanded={isExpanded(formComponent)}
            isLast={isLast(formComponent)}
            isFirst={isFirst(formComponent)}
            isPreviousExpanded={isPreviousExpanded(formComponent)}
            isPreviousOpened={isPreviousOpened(formComponent)}
            isPreviousOrphaned={isPreviousOrphaned(formComponent)}
            isOrphaned={isOrphaned(formComponent)}
            toggleAccordion={() => toggleAccordion(formComponent.key)}
            label={translateAccordionLabel(formComponent, props.intl)}
            fieldName={(props.parentName ? props.parentName + "." : "") + formComponent.key}
          />
        </SectionAccordion>
      ) : (
        <div key={index} className="accordion" data-parent-id={formComponent.key}>
          <AccordionTrigger
            isRoot={props.isRoot}
            isOpened={isOpened(formComponent)}
            isExpanded={isExpanded(formComponent)}
            isLast={isLast(formComponent)}
            isFirst={isFirst(formComponent)}
            isPreviousExpanded={isPreviousExpanded(formComponent)}
            isPreviousOpened={isPreviousOpened(formComponent)}
            isPreviousOrphaned={isPreviousOrphaned(formComponent)}
            isOrphaned={isOrphaned(formComponent)}
            toggleAccordion={() => toggleAccordion(formComponent.key)}
            label={translateAccordionLabel(formComponent, props.intl)}
            fieldName={(props.parentName ? props.parentName + "." : "") + formComponent.key}
          />
          <div className={classnames("accordion__content", { "accordion__content--is-open": openAccordions?.includes(formComponent.key) })}>
            {renderFormComponent(formComponent)}
          </div>
        </div>
      )));
  }

  return getGroupedFormAndAccordions().map((group, index) => {
    if (group.type === "form") {
      return renderForms(group.items, index, props.parentName);
    }
    return renderAccordion(group.items);
  })
};

export default FormComponent;

