import React from "react";
import { setRequiredList } from "redux/actions/requiredListActions";
import { store } from "index.js";
import classnames from "classnames";

import _ from "lodash";

import TextFieldComponent from "components/formcomponents/TextFieldComponent.js";
import FileComponent from "components/formcomponents/FileComponent.js";
import AppendicesComponent from "components/formcomponents/AppendicesComponent";
import HeaderComponent from "components/formcomponents/HeaderComponent.js";
import AutoCompleteComponent from "components/formcomponents/AutoCompleteComponent.js";
import DatePickerComponent from "components/formcomponents/DatePickerComponent.js";
import AutoCompleteCreatableComponent from "components/formcomponents/AutoCompleteCreatableComponent";
import SelectValueUnitComponent from "components/formcomponents/customformcomponents/SelectValueUnitComponent.js";
import SelectValueRangeUnitComponent from "components/formcomponents/customformcomponents/SelectRangeValueUnitComponent.js";
import CheckBoxComponent from "components/formcomponents/customformcomponents/CheckboxComponent.js";
import SubHeaderComponent from "../formcomponents/SubHeaderComponent";
import BearingInvestigationsComponent from "components/formcomponents/customformcomponents/BearingInvestigationsComponent.js";
import FileImageComponent from "components/formcomponents/customformcomponents/FileImageComponent.js";
import Customer from "components/formcomponents/customformcomponents/CustomerComponent";
import CustomerValidator from "components/formcomponents/validators/CustomerValidator";
import SectorSegmentIndustry from "components/formcomponents/customformcomponents/SectorSegmentIndustryComponent";
import SkfCompanyComponent from "components/formcomponents/customformcomponents/SkfCompanyComponent.js";
import UserPickerComponent from "components/formcomponents/customformcomponents/UserPickerComponent.js";
import AutoCompleteUserComponent from "components/formcomponents/customformcomponents/AutoCompleteUserComponent.js";
import AutoCompleteCreatableMultipleComponent from "components/formcomponents/customformcomponents/AutoCompleteCreatableMultipleComponent.js";
import LubricationTypeComponent from "components/formcomponents/customformcomponents/LubricationTypeComponent";
import WindLubricationTypeComponent from "components/formcomponents/customformcomponents/WindLubricationTypeComponent";
import OperationComponent from "components/formcomponents/customformcomponents/OperationComponent";
import FailureAndCauseComponent from "components/formcomponents/customformcomponents/FailureAndCauseComponent";
import DispositionComponent from "components/formcomponents/customformcomponents/DispositionComponent";
import BearingDesignationComponent from "components/formcomponents/customformcomponents/BearingDesignationComponent";
import CountryCompanyLocationComponent from "components/formcomponents/customformcomponents/CountryCompanyLocationComponent";
import OtherInvestigationDetailsComponent from "components/formcomponents/customformcomponents/OtherInvestigationDetailsComponent";
import OtherBearingInvestigationComponent from "components/formcomponents/customformcomponents/OtherBearingInvestigationComponent";
import TERInvestigationDetailsComponent from "components/formcomponents/customformcomponents/TERInvestigationDetailsComponent";
import TERBearingInvestigationComponent from "components/formcomponents/customformcomponents/TERBearingInvestigationComponent";
import AMFRBearingInvestigationComponent from "components/formcomponents/customformcomponents/AMFRBearingInvestigationComponent";
import AMFRInvestigationDetailsComponent from "components/formcomponents/customformcomponents/AMFRInvestigationDetailsComponent";
import TERorAMFRorOtherComponent from "components/formcomponents/customformcomponents/TERorAMFRorOtherComponent";
import RadioCheckboxComponent from "components/formcomponents/customformcomponents/RadioCheckboxComponent";
import ApprovedByComponent from "components/formcomponents/customformcomponents/ApprovedByComponent";
import InspectionApprovedByComponent from "components/formcomponents/customformcomponents/InspectionApprovedByComponent";
import SelectCreatableValueUnitComponent from "components/formcomponents/customformcomponents/SelectCreatableValueUnitComponent";
import PositionOfBearingInWindMachineComponent from "components/formcomponents/customformcomponents/PositionOfBearingInWindMachineComponent";
import AutoCompleteCreatableBigListComponent from "components/formcomponents/customformcomponents/AutoCompleteCreatableBigListComponent";
import ShaftMaterialComponent from "components/formcomponents/customformcomponents/ShaftMaterialComponent";
import WindFunctionalLocationComponent from "components/formcomponents/customformcomponents/WindFunctionalLocationComponent";
import WindFunctionalLocationValidator from "components/formcomponents/validators/WindFunctionalLocationValidator";
import { withSetAndGet } from "components/formcomponents/withSetAndGet";
import InfoMessage from "components/formcomponents/InfoMessageComponent";
import RailwayWheelsetBearingComponent from "../formcomponents/customformcomponents/RailwayWheelsetBearingComponent";
import ImageSelectorComponent from "../formcomponents/customformcomponents/ImageSelectorComponent";
import FailurePredictionComponent from "../formcomponents/customformcomponents/FailurePredictionComponent";
import RichTextComponent from "components/formcomponents/RichTextComponent";
import ValueUnitValidator from "components/formcomponents/validators/ValueUnitValidator";
import CountryCompanyLocationValidator from "components/formcomponents/validators/CountryCompanyLocationValidator";
import EndUserComponent from "components/formcomponents/customformcomponents/EndUserComponent";
import UnlockableTextFieldComponent from "components/formcomponents/UnlockableTextFieldComponent";
import EndUserValidator from "components/formcomponents/validators/EndUserValidator";
import ObservationComponent from "components/formcomponents/customformcomponents/ObservationComponent";
import TrafficLightComponent from "components/formcomponents/customformcomponents/TrafficLightComponent";
import CountryLocationComponent from "components/formcomponents/customformcomponents/CountryLocationComponent";
import BearingDesignationValidator from "components/formcomponents/validators/BearingDesignationValidator";
import Table from "components/formcomponents/customformcomponents/TableComponent";
import ManufacturingDateCode from "components/formcomponents/customformcomponents/ManufacturingDateCodeComponent";
import FunctionalAreaComponent from "components/formcomponents/customformcomponents/FunctionalAreaComponent";
import MachineNumberComponent from "components/formcomponents/customformcomponents/MachineNumberComponent";
import SystemNameComponent from "components/formcomponents/customformcomponents/SystemNameComponent";

export const validators = {
  Customer: CustomerValidator,
  WindFunctionalLocationValidator: WindFunctionalLocationValidator,
  BearingDesignationValidator: BearingDesignationValidator,
  ValueUnit: ValueUnitValidator,
  CountryCompanyLocation: CountryCompanyLocationValidator,
  EndUserValidator: EndUserValidator
}

export const formComponents = {
  Text: withSetAndGet(TextFieldComponent),
  ValueUnit: withSetAndGet(SelectValueUnitComponent),
  RangeValueUnit: withSetAndGet(SelectValueRangeUnitComponent),
  AutoComplete: withSetAndGet(AutoCompleteComponent),
  BearingInvestigations: withSetAndGet(BearingInvestigationsComponent),
  Date: withSetAndGet(DatePickerComponent),
  AutoCompleteCreatable: withSetAndGet(AutoCompleteCreatableComponent),
  AutoCompleteUser: withSetAndGet(AutoCompleteUserComponent),
  AutoCompleteCreatableMultiple: withSetAndGet(
    AutoCompleteCreatableMultipleComponent
  ),
  CheckBox: withSetAndGet(CheckBoxComponent),
  Customer: withSetAndGet(Customer),
  SkfCompany: withSetAndGet(SkfCompanyComponent),
  UserPicker: withSetAndGet(UserPickerComponent),
  SectorSegmentIndustry: withSetAndGet(SectorSegmentIndustry),
  LubricationType: withSetAndGet(LubricationTypeComponent),
  WindLubricationType: withSetAndGet(WindLubricationTypeComponent),
  Operation: withSetAndGet(OperationComponent),
  FailureAndCause: withSetAndGet(FailureAndCauseComponent),
  BearingDesignation: withSetAndGet(BearingDesignationComponent),
  Disposition: withSetAndGet(DispositionComponent),
  FileImage: withSetAndGet(FileImageComponent),
  CountryCompanyLocation: withSetAndGet(CountryCompanyLocationComponent),
  TERInvestigationDetails: withSetAndGet(TERInvestigationDetailsComponent),
  OtherInvestigationDetails: withSetAndGet(OtherInvestigationDetailsComponent),
  OtherBearingInvestigation: withSetAndGet(OtherBearingInvestigationComponent),
  TERBearingInvestigation: withSetAndGet(TERBearingInvestigationComponent),
  AMFRBearingInvestigation: withSetAndGet(AMFRBearingInvestigationComponent),
  AMFRInvestigationDetails: withSetAndGet(AMFRInvestigationDetailsComponent),
  TERorAMFRorOther: withSetAndGet(TERorAMFRorOtherComponent),
  RadioCheckbox: withSetAndGet(RadioCheckboxComponent),
  ApprovedBy: withSetAndGet(ApprovedByComponent),
  InspectionApprovedBy: withSetAndGet(InspectionApprovedByComponent),
  SelectCreatableValueUnit: withSetAndGet(SelectCreatableValueUnitComponent),
  PositionOfBearingInWindMachine: withSetAndGet(
    PositionOfBearingInWindMachineComponent
  ),
  AutoCompleteCreatableBigList: withSetAndGet(
    AutoCompleteCreatableBigListComponent
  ),
  File: withSetAndGet(FileComponent),
  Appendices: withSetAndGet(AppendicesComponent),
  ShaftMaterial: withSetAndGet(ShaftMaterialComponent),
  WindFunctionalLocation: withSetAndGet(WindFunctionalLocationComponent),

  Header: HeaderComponent,
  SubHeader: withSetAndGet(SubHeaderComponent),
  RailwayWheelsetBearing: withSetAndGet(RailwayWheelsetBearingComponent),
  ImageSelector: withSetAndGet(ImageSelectorComponent),
  FailurePrediction: withSetAndGet(FailurePredictionComponent),
  RichText: withSetAndGet(RichTextComponent),
  EndUser: withSetAndGet(EndUserComponent),
  UnlockableText: withSetAndGet(UnlockableTextFieldComponent),
  Observation: withSetAndGet(ObservationComponent),
  TrafficLight: withSetAndGet(TrafficLightComponent),
  CountryLocation: withSetAndGet(CountryLocationComponent),
  Table: withSetAndGet(Table),
  ManufacturingDateCode: withSetAndGet(ManufacturingDateCode),
  FunctionalArea: withSetAndGet(FunctionalAreaComponent),
  MachineNumber: withSetAndGet(MachineNumberComponent),
  SystemName: withSetAndGet(SystemNameComponent),
};

function addComponentToRequiredList(formComponent, myName) {
  let requiredFields = store.getState().requiredList.slice(0);
  let oldRequiredFields = requiredFields.slice(0);

  if (!requiredFields.some((component) => component.key === myName)) {
    let tempObject = {};
    tempObject.key = myName;
    
    tempObject.description = formComponent.description;
    if (formComponent.validator !== undefined) {
      tempObject.isValid = validators[formComponent.validator];
    } else {
      tempObject.isValid = defaultValidator;
    }
    requiredFields.push(tempObject);
  }
  if (!_.isEqual(oldRequiredFields, requiredFields)) {
    store.dispatch(setRequiredList([...requiredFields]));
  }
}

function defaultValidator(value) {
  return value !== undefined && value != null && value != '' && value !== "\"\"" &&
    (
      value.constructor === String ||
      (value.constructor === Object && Object.keys(value).length > 0) ||
      (value.constructor === Array && value.length > 0)
  )
};

export default function getComponent(
  formComponent,
  myName,
  parentHandleSave,
  parentSetValue,
  loopIndex,
  isDisabled,
  isUserExternal,
  user,
  onClick
) {
 
  if (formComponent.required) {
    addComponentToRequiredList(formComponent, myName);
  }
  let Component = "";
  if (formComponent.renderType !== undefined) {
    Component = formComponents[formComponent.renderType];
  } else {
    Component = formComponents[formComponent.type];
  }
  if (Component !== undefined) {
    const component = <>
      <Component
        formComponentData={formComponent}
        myName={myName}
        parentHandleSave={parentHandleSave}
        parentSetValue={parentSetValue}
        key={loopIndex}
        isDisabled={isDisabled}
        isUserExternal={isUserExternal}
        user={user}
        
      />
      {formComponent.infoMessage &&
        <InfoMessage textId={formComponent.infoMessage} />
      }
    </>;
    if (formComponent.responsive && formComponent.responsive === "skipcontainer") {
      return component;
    } else {
      return (
        <div onClick={event => onClick ? onClick(myName, formComponent, event) : null} data-parent-id={myName} className={classnames("componentContainer", getForResponsive(formComponent)) }>
          {component}
        </div>
      );  
    }
    
  } else {
    return null;
  }
}

function getForResponsive(formComponent) {
  if (formComponent.responsive) {
    if (formComponent.responsive === "linebreak-full") {
      return "componentContainer-linebreak-full";
    }
    if (formComponent.responsive === "linebreak") {
      return "componentContainer-linebreak";
    }
    if (formComponent.responsive === "linebreak-full-nospace") {
      return "componentContainer-linebreak-full-nospace";
    }
    if (formComponent.responsive === "linebreak-nospace") {
      return "componentContainer-linebreak-nospace";
    }
  }
  return "";
}
