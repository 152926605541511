import React, { useEffect } from 'react'
import LicenseAgreementText from '../_LicenseAgreement/LicenseAgreementText'

const LicenseAgreementPage = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'instant',
    })
  }, [])

  return <LicenseAgreementText />
}

export default LicenseAgreementPage
