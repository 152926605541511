import React from "react";

const SvgSilo = props => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path
      d="M24 1H0l8.82 8.476V23l6.425-3.958V9.476z"
      stroke="#FFF"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgSilo;
