import React, { useCallback, useEffect, useState } from "react";
import getComponent from "../../_Case/ComponentsList";
import httpService from "core/http-service";
import { removeRequiredListKeys } from "redux/actions/requiredListActions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { getBearingIndexFromName } from "../utils/BearingUtils"

const FailureAndCauseComponent = ({ myName, getValue, setValue, parentSetValue, parentHandleSave, formComponentData }) => {


  const bearingIndex = getBearingIndexFromName(myName);

  const [isRailwayBearing, setIsRailwayBearing] = useState(false);
  const [imageUrls, setImageUrls] = useState([]);
  const [imageLayout, setImageLayout] = useState("");
  const [prediction, setPrediction] = useState(null);
  const [observations, setObservations] = useState("");
  const [failureCode, setFailureCode] = useState("");
  const [cause, setCause] = useState("");
  const [failureAndCauses, setFailureAndCauses] = useState([]);
  const [failureOptions, setFailureOptions] = useState([]);
  const [causeOptions, setCauseOptions] = useState();
  const [showCause, setShowCause] = useState(false);

  const language = useSelector(state => state.language, shallowEqual);
  const railwayWheelsetBearing = useSelector(state => state.isRailwayWheelsetBearing.find(bi => bi.bearingIndex === bearingIndex), shallowEqual);
  const dispatch = useDispatch();

  useEffect(() => {
    if (railwayWheelsetBearing && railwayWheelsetBearing.isRailwayBearing) {
      setIsRailwayBearing(true);
    } else {
      setIsRailwayBearing(false);
    }
  }, [railwayWheelsetBearing]);

  useEffect(() => {
    let values = getValue(myName);
    if (!values && parentSetValue) {
      values = parentSetValue;
    }
    if (values) {
      setImageUrls(values.imageUrls);
      setImageLayout(values.imageLayout);
      setPrediction(values.prediction ? values.prediction : "");
      setObservations(values.observations);
      setFailureCode(formComponentData.failureNeeded ? { label: values.failureCode.label, value: values.failureCode.value } : "");
      setCause(formComponentData.failureNeeded ? values.cause : "");
    }

  }, [getValue, myName, parentSetValue, formComponentData.failureNeeded]);

  useEffect(() => {
    if (formComponentData.failureNeeded) {
      fetchFailureCodes(undefined);
    }
  }, [formComponentData.failureNeeded, railwayWheelsetBearing]);



  useEffect(() => {
    if (failureCode && failureCode.value) {
      setShowCause(!(failureCode.value === "M000" || failureCode.value === "M001"));
    } else {
      setShowCause(false);
    }
  }, [failureCode]);

  useEffect(() => {
    if (formComponentData.failureNeeded && failureCode && failureAndCauses.length > 0) {
      const foundFailure = failureAndCauses.find(failure => failure.failureCode === failureCode.value);
      if (foundFailure) {
        setCauseOptions(Object.keys(foundFailure.causes).map(key => ({ label: foundFailure.causes[key], value: key })));
      }
    }
  }, [formComponentData.failureNeeded, failureAndCauses, failureCode]);

  const fetchFailureCodes = useCallback(async (failureCode) => {
    if (failureAndCauses.length === 0) {
      const failureModeType = formComponentData.iso15243 ? 'ISO15243' : 'NORMAL';
      const response = await httpService.get("lists/failuremodesandcauses", { lang: language, type: failureModeType });
      setFailureAndCauses(response.data);
      setFailureOptions(response.data.map(failure => ({ value: failure.failureCode, label: failure.failure })));
    }
  }, []);

  const handleChange = (value, name) => {

    var resultName = name.split(".").pop();
    let objectToSave = { observations, imageUrls, imageLayout, prediction };
    if (formComponentData.failureNeeded) {
      objectToSave = { ...objectToSave, failureCode, cause };
    }

    switch (resultName) {
      case "failureMode":
        setFailureCode(value);
        setCause("");
        objectToSave = { ...objectToSave, failureCode: value, cause: "" };
        if (value.value === "M000" || value.value === "M001") {
          delete objectToSave.imageUrls;
          dispatch(removeRequiredListKeys([`${myName}.cause`, `${myName}.failureImage`]));
        }
        break;
      case "cause":
        setCause(value);
        objectToSave = { ...objectToSave, cause: decorateValueWithCauseKey(value) };
        break;
      case "failureImage":
        setImageUrls(value);
        objectToSave = { ...objectToSave, imageUrls: value };
        break;
      case "observations":
        setObservations(value);
        objectToSave = { ...objectToSave, observations: value };
        break;
      case "imageLayout":
        setImageLayout(value);
        objectToSave = { ...objectToSave, imageLayout: value };
        break;
      case "failurePrediction":
        setPrediction(value);
        objectToSave = { ...objectToSave, prediction: value };
        break;
      default:
        break;
    }
    if (parentSetValue !== undefined) {
      parentHandleSave(objectToSave, myName)
    } else {
      setValue(objectToSave, myName)
    }
  }

  const decorateValueWithCauseKey = (value) => {
    if (failureCode && failureCode.value) {
      const failure = failureAndCauses.find(f => f.failureCode === failureCode.value);
      if (failure) {
        const causekey = Object.keys(failure.causes).find(key => failure.causes[key] === value.value);
        if (causekey) {
          value["key"] = causekey;
        } else {
          if (typeof value === 'object') {
            value["key"] = "";
          }
        }
      }
    }
    return value;
  };

  const renderFileImage = (formComponent, index) => {
    return (
      <React.Fragment key={index}>
        {getComponent(
          formComponent,
          `${myName}.${formComponent.key}`,
          handleChange,
          { urlItems: imageUrls, imageLayout: imageLayout }
        )}
      </React.Fragment>
    );
  }

  const renderSubHeader = (formComponent, index) => {
    const shouldRender = formComponentData.iso15243 ?
      isRailwayBearing :
      formComponentData.failureNeeded ? !isRailwayBearing : true;

    if (shouldRender) {
      return <React.Fragment key={index}>
        {getComponent(
          formComponent,
          `${myName}.${formComponent.key}`
        )}
      </React.Fragment>
    } else {
      return null;
    }
  }

  const renderRichText = (formComponent, index) => {
    const shouldRenderFailureAndCause = formComponentData.iso15243 ?
      isRailwayBearing :
      formComponentData.failureNeeded ? !isRailwayBearing : false;
    const shouldRenderText = !formComponentData.iso15243 && !formComponentData.failureNeeded;

    if (shouldRenderFailureAndCause) {
      return renderFailureAndCause(formComponent, index);
    } else if (shouldRenderText) {
      return renderText(formComponent, index);
    }
  }

  const renderText = (formComponent, index) => {

    return (<React.Fragment key={index}>
      {getComponent(
        formComponent,
        `${myName}.${formComponent.key}`,
        handleChange,
        observations
      )}
    </React.Fragment>)
  }

  const renderFailureAndCause = (formComponent, index) => {
    return <React.Fragment key={index}>
      {getComponent(
        formComponent,
        `${myName}.${formComponent.key}`,
        handleChange,
        observations
      )}
      {renderImageSelector()}
      {renderFailurePrediction()}
      {renderFailure()}
      {showCause && renderCause()}
    </React.Fragment>
  }

  const renderImageSelector = () => {
    return <React.Fragment key={failureCode.label}>
      {getComponent(
        {
          key: "failureImage",
          type: "ImageSelector",
          required: isRequiredNeeded("image"),
          description: "Failure image"
        },
        `${myName}.failureImage`, handleChange, imageUrls)}
    </React.Fragment>
  };

  const renderFailurePrediction = () => {
    return (
      <React.Fragment>
        {getComponent(
          {
            key: "failurePrediction",
            type: "FailurePrediction",
            required: false,
            description: "Failure prediction",
            imageUrl: imageUrls,
            prediction: prediction,
            failureOptions: failureOptions
          },
          `${myName}.failurePrediction`,
          handleChange
        )}
      </React.Fragment>
    );
  }

  const renderFailure = () => {
    return (
      <React.Fragment>
        {getComponent(
          {
            key: "failureMode",
            type: "AutoComplete",
            description: "Failure mode",
            translate: false,
            required: isRequiredNeeded("failureMode"),
            args: [
              {
                options: failureOptions
              }
            ]
          },
          `${myName}.failureMode`, handleChange, failureCode)}
      </React.Fragment>
    );
  }

  const renderCause = () => {
    if (showCause && causeOptions) {
      return <React.Fragment key={failureCode.value}>
        {getComponent(
          {
            key: "failureCause",
            type: "AutoCompleteCreatable",
            translate: false,
            required: isRequiredNeeded("cause"),
            description: "Cause",
            args: [
              {
                options: causeOptions
              }
            ]
          },
          `${myName}.cause`,
          handleChange,
          cause)}
      </React.Fragment>;
    } else {
      return null;
    }
  }

  const isRequiredNeeded = name => {
    if (formComponentData.key === "primaryFailureAndCause") {
      if (name === "image" || name == "cause") {
        if (failureCode.label !== undefined) {
          let value = failureCode.value;
          if (value === "M000" || value === "M001") {
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }
      } else {
        return true;
      }
    } else if (
      formComponentData.key === "additionalFailureAndCause"
    ) {
      return false;
    }
  };

  const renderParts = (formComponent, index) => {
    if (formComponent) {
      switch (formComponent.type) {
        case "FileImage":
          return renderFileImage(formComponent, index);
        case "SubHeader":
          return renderSubHeader(formComponent, index);
        case "RichText":
          return renderRichText(formComponent, index);
        default:
          return null;
      }
    } else {
      return null;
    }
  }

  return formComponentData.args.map((formComponent, index) => {
    return renderParts(formComponent, index);
  });

}
export default FailureAndCauseComponent;
