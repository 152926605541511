import React from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from "react-intl"
import { isConfidential, extractConfigFor, transformAssetDetails} from "../Shared/LivepreviewHelperFunctions"
import { transformUsInspectionData } from "./UsInspectionData";

import _ from 'lodash'
import PropTypes from 'prop-types'

import ReportHeader from 'components/_LivePreview/Shared/ReportHeader'
import AssetTable from 'components/_LivePreview/Shared/AssetTable'
import ReportNumber from 'components/_LivePreview/Shared/ReportNumber'
import ReportGeneralText from 'components/_LivePreview/Shared/ReportGeneralText'
import UsInspectionReportInformation from './UsInspectionReportInformation';
import LivePreviewHeader from 'components/_LivePreview/Shared/LivePreviewHeader'
import StatusConfidential from 'components/_LivePreview/Shared/StatusConfidential'
import UsInspectionConfig from 'components/_LivePreview/UsInspection/UsInspectionConfig'
import UsInspectionBearingInvestigation from 'components/_LivePreview/UsInspection/UsInspectionBearingInvestigation'
import UsInspectionSummary from 'components/_LivePreview/UsInspection/UsInspectionSummary';
import UsInspectionDetails from './UsInspectionDetails'

const UsInspection = ({ data, isUserExternal, caseStatus, revisionNumber }) => {
  const intl = useIntl()

  const caseId = useSelector(state => state.saveComponentValue.caseId)
  const showSummaryAtTop = useSelector(state => state.showSummaryAtTop)

  const usInspectionData = transformUsInspectionData(data);

  const translated = {
    customerDetailsHeader: intl.formatMessage({ id: 'customerDetails.livePreview.header' }),
    skfDetailsHeader: (isUserExternal ? intl.formatMessage({ id: 'skfDetailsExternal' }) : intl.formatMessage({ id: 'skfDetails.livePreview.header' })),
    assetDetailsHeader: intl.formatMessage({ id: 'assetDetails.livePreview.header' }),
    firstSummaryHeader: intl.formatMessage({ id: 'firstSummaryInspection' }),
    inspectionHeader: intl.formatMessage({ id: 'usBearingInspection.livePreview.header' }),
  }

  const subtractFromConfig = (key, config) => { 
    const configToReturn = config.filter(c => !c.key.includes(key));
    return configToReturn;
  }

  const subtractKeysFromConfig = (keys, config) => { 
    const configToReturn = config.filter(c => !keys.some(k => c.key.includes(k)));
    return configToReturn;
  }

  const renderAssetHeader = () => {
    const assetDetailsKeys = _.pickBy(usInspectionData, (value, key) => _.includes(key, 'assetDetails'))

    const showHeader = shouldShowHeader(assetDetailsKeys)
    if (!showHeader) return null

    let extraHeader = "";
    if (usInspectionData["assetDetails.assetTypeOrFunctionalAreaOrSystem.machineOrAssetCode"]) {
      const value = usInspectionData["assetDetails.assetTypeOrFunctionalAreaOrSystem.machineOrAssetCode"];
      if (value.label) {
        extraHeader = value.label;
      }
    }

    return (
      <div className="live-preview-key-value__header live-preview-key-value__bearingheader">
        <span className="first">{translated.assetDetailsHeader}</span>
        {extraHeader ? <span className="last">{`${intl.formatMessage({ id: "assetType" })} - ${extraHeader}`}</span> : null}
      </div>
    )
  }

  return (
    <div className="live-preview">
      <LivePreviewHeader />

      <div className="live-preview__container">
        <ReportHeader data={translated.inspectionHeader} />

        <StatusConfidential caseStatus={caseStatus} isConfidential={isConfidential(data)} />

        <ReportNumber data={caseId} revisionNumber={revisionNumber} reportStatus={caseStatus} />

        <UsInspectionReportInformation config={{
          reportDetails: subtractKeysFromConfig(["inspectionTime", "travelTime"], extractConfigFor('reportDetails', UsInspectionConfig)),
          skfDetails: extractConfigFor('skfDetails', UsInspectionConfig)
        }} data={usInspectionData}
          prefixes={["reportDetails.reportApprovedBy", "reportDetails.inspectionMadeBy"]}
          isbearingTableShown={false}
          isUserExternal={isUserExternal}
        />

        {!showSummaryAtTop && <UsInspectionSummary data={usInspectionData} />}
        
        <UsInspectionDetails config={UsInspectionConfig} data={usInspectionData}/>

        { renderAssetHeader() }
        <AssetTable config={subtractFromConfig("machineOrAssetCode", extractConfigFor('assetDetails', UsInspectionConfig))} sectionId="assetDetails" data={transformAssetDetails(data, UsInspectionConfig)} />

        <UsInspectionBearingInvestigation config={extractConfigFor('bearingInvestigations', UsInspectionConfig)} data={data} bearingsCount={data['bearingInvestigations.count']} />

        <UsInspectionSummary data={usInspectionData} />

        <ReportGeneralText />

      </div>
    </div>
  )
}

const shouldShowHeader = (assetDetailsKeys) => {
  let hasValue
  Object.entries(assetDetailsKeys).forEach(([key, value]) => {
    if (assetHeaderRequiredKeys.some(e => e === key)) {
      if (typeof value === 'object') {
        if (_.some(value, e => !_.isEmpty(e))) {
          hasValue = true
        }
      } else {
        if (value) hasValue = true
      }
    }
  })
  return hasValue
}

const assetHeaderRequiredKeys = [
  'assetDetails.assetTypeOrFunctionalAreaOrSystem.machineOrAssetCode',
  'assetDetails.assetTypeOrFunctionalAreaOrSystem.assetDescription',
  'assetDetails.assetTypeOrFunctionalAreaOrSystem.assetFigure',
  "assetDetails.assetTypeOrFunctionalAreaOrSystem.functionalArea",
  "assetDetails.assetTypeOrFunctionalAreaOrSystem.systemNameMachineNumber",
  "assetDetails.assetTypeOrFunctionalAreaOrSystem.endUserTagNumber",
  "assetDetails.assetTypeOrFunctionalAreaOrSystem.purposeOfAsset",
]

UsInspection.propTypes = {
  formData: PropTypes.object,
  caseId: PropTypes.any,
  revisionNumber: PropTypes.any
}

export default UsInspection
