const TO_SMALL = "( max-width:  766px )"
const SMALL = "( min-width:  767px )"
const TO_MEDIUM = "( max-width:  991px )"
const MEDIUM = "( min-width:  992px )"
const TO_LARGE = "( max-width: 1199px )"
const LARGE = "( min-width: 1200px )"
const TO_X_LARGE = "( max-width: 1599px )"
const X_LARGE = "( min-width: 1600px )"
const TO_NAVIGATION = "( max-width: 1339px )"
const NAVIGATION = "( min-width: 1340px )"

export {
  TO_SMALL,
  SMALL,
  TO_MEDIUM,
  MEDIUM,
  TO_LARGE,
  LARGE,
  TO_X_LARGE,
  X_LARGE,
  TO_NAVIGATION,
  NAVIGATION
}
