import metaDataList from "./metaDataList.json";
import config from "config";

export default function getCaseMetadataForAnswer(name, value, currentFormTemplateId) { 
    const acc = {};
    const metadata = metaDataList.find(i => i.answerInStore === name);
    if (metadata) { 
        switch (metadata.key) {
            case "SKFCompanyName":
                acc["skf_company"] = JSON.parse(value).company.label
                break;
            case "customerCompanyName":
                getForCustomerCompanyName("customer", value, acc)
                break;
            case "customerCompanyNameTER":
                getForCustomerCompanyNameGBI("customer", value, acc);
                break;
            case "customerCompanyNameAMFR":
                getForCustomerCompanyNameGBI("customer", value, acc);
                break;
            case "customerCompanyNameOther":
                getForCustomerCompanyNameOther("customer", value, acc);
                break;
            case "customerCompanyNameEndUser":
                getForCustomerCompanyNameForEndUser("customer", value, acc);
                break;
            case "confidential":
                acc["confidential"] = JSON.parse(value);
                break;
            case "approver":
                getForApprover(metadata.key, value, acc);
                break;
            case "reportApprovedBy":
                getForReportApprovedBy(value, acc);
                break;    
            case "delegationList":
                getForDelegationList("delegations", value, acc);
                break;
            case "reportCoAuthor":
                getForCoAuthor("coauthor", value, acc);
                break;
            case "isoFailureMode":
                acc["iso_failure_mode"] = JSON.parse(value).failureCode.label;
                break;
            case "assetType":
                acc["asset_type"] = JSON.parse(value).label;
                break;
            case "customerContactName":
                acc["customer_contact"] = JSON.parse(value);
                break;
            case "reportTitle":
                acc["title"] = JSON.parse(value);
                break;
            case "terReferenceNumber":
                acc["ter_reference"] = JSON.parse(value);
                break;
            default:
                acc[metadata.key] = JSON.parse(value);
                break;
        };
    }
    addReportType(acc, currentFormTemplateId);

    return acc;
}

function getForDelegationList(key, value, metadata) {
    let delegationListParsed = JSON.parse(value);
    if (delegationListParsed && delegationListParsed.constructor.name === "Array") {
        metadata[key] = delegationListParsed.map(p => p.value).join(" ,")
    }
}

export function addReportType(metadata, currentFormTemplateId) {
    switch (currentFormTemplateId) {
        case config.INSPECTION_TEMPLATE_ID:
            metadata.typeOfReport = "Inspection";
            break;
        case config.US_INSPECTION_TEMPLATE_ID:
            metadata.typeOfReport = "NAMInspection";
            break;
        case config.WIND_TEMPLATE_ID:
            metadata.typeOfReport = "Wind";
            break;
        case config.BART_TEMPLATE_ID:
            metadata.typeOfReport = "BDA";
            break;
        case config.GBI_TEMPLATE_ID:
            metadata.typeOfReport = "GBI";
            break;
        default:
            break;
    }
}

function getForCoAuthor(key, value, metadata) {
    if (!metadata[key]) {
        metadata[key] = "";
    }
    const existingAuthors = metadata[key].split(";");
    existingAuthors.push(JSON.parse(value));
    metadata[key] = existingAuthors.join(";");
}
function getForApprover(key, value, metadata) {
    let approver = JSON.parse(value).approversName;
    if (approver.hasOwnProperty("value")) {
        approver = approver.value;
    }
    if (approver) {
        metadata[key] = approver;
    }
}

function getForReportApprovedBy(value, metadata) {
    metadata.approver = value.slice(1, -1)
}

function getForCustomerCompanyNameForEndUser(key, value, metadata) {
    let parsedAnswer = JSON.parse(value);
    if (parsedAnswer && !parsedAnswer.endUser) {
        metadata[key] = null;
    }
    if (parsedAnswer && parsedAnswer.endUser) {
        metadata[key] = `${parsedAnswer.endUser.name} - ${parsedAnswer.endUser.city}`;
    }
}

function getForCustomerCompanyNameOther(key, value, metadata) {
    let parsedAnswer = JSON.parse(value);
    if (parsedAnswer.customer && parsedAnswer.customer.companyName && parsedAnswer.customer.companyName.value) {
        metadata[key] = parsedAnswer.customer.companyName.value.CompanyName;
    }
}

function getForCustomerCompanyNameGBI(key, value, metadata) {
    let parsedAnswer = JSON.parse(value);
    if (parsedAnswer.customerName !== "" && parsedAnswer.customerName !== null) {
        metadata[key] = parsedAnswer.customerName;
    }
}


function getForCustomerCompanyName(key, value, metadata) {
    let parsedAnswer = JSON.parse(value);
    if (parsedAnswer.companyName !== "") {
        metadata[key] = parsedAnswer.companyName.value.AddressLine2;
    }
    if (parsedAnswer.companyPath !== undefined && parsedAnswer.companyPath !== "") {
        let codesSplitted = parsedAnswer.companyPath.split("/");
        if (codesSplitted[1] !== "-") {
            metadata["sectorCode"] = codesSplitted[1];
        }
        if (codesSplitted[2] !== "-") {
            metadata["segmentCode"] = codesSplitted[2];
        }
        if (codesSplitted[3] !== "-") {
            metadata["industryCode"] = codesSplitted[3];
        }
    }
}