import React, { useState, useEffect, useRef } from "react";
import classnames from "classnames";
import Feedback from "components/svg/Feedback";
import { FormattedMessage } from "react-intl";

const ApplicationFeedback = (props) => {
  const applicationFeedbackRef = useRef(null);
  const [activeSubNavigation, setActiveSubNavigation] = useState("");

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  const toggleSubItems = (parentName, isSubItem) => {
    setActiveSubNavigation(
      activeSubNavigation === parentName ? "" : parentName
    );
  };

  const handleClickOutside = (event) => {
    if (
      applicationFeedbackRef.current &&
      !applicationFeedbackRef.current.contains(event.target)
    ) {
      setActiveSubNavigation("");
    }
  };

  return (
    <div className="application-feedback" ref={applicationFeedbackRef}>
      <button
        className={classnames("application-feedback__toggler", {
          "application-feedback__toggler--active":
            activeSubNavigation === "feedback",
        })}
        onClick={() => toggleSubItems("feedback")}
      >
        <Feedback />
      </button>

      {activeSubNavigation === "feedback" && (
        <div className="application-feedback__navigation-items">
          <a
            href="https://skf.com/websupport"
            className="application-feedback__link"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => toggleSubItems("feedback", true)}
          >
            <FormattedMessage id="incident" />
          </a>
          {!props.isUserExternal ? (
            <a
              href="https://skf.com/websupport"
              className="application-feedback__link"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => toggleSubItems("feedback", true)}
            >
              <FormattedMessage id="requestForChange" />
            </a>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default ApplicationFeedback;
