import React,  { useCallback, useState, useEffect } from "react";
import ReactModal from "react-modal";
import classnames from "classnames";
import { injectIntl } from "react-intl";

import BaseButton from "components/common/BaseButton";
import CircleCloseFilterSvg from "components/svg/CircleCloseFilter";
import ConfirmDialog from './../ConfirmModal';
import DropZone from "./DropZone";
import resizeImage from "./ResizeImage";
import rotateImage from "./RotateImage";

const maxImageWidth = 1920;
const maxImageHeight = 1080;

const FileUploadMultiple = ({ showDialog, onModalClose, onFileAdded, intl }) => {

    const [files, setFiles] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const [addingFilesCount, setAddingFilesCount] = useState(0);
    const [isFilesDone, setIsFileDone] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);

    useEffect(() => {
        setIsFileDone(files.filter(f => !f.deleted && !f.isuploaded).length === 0 && files.length > 0);
    }, [files]);

    const setFileState = (file,onSet) => {
        setFiles(existing => existing.map(ef => {
            if (ef.id === file.id && !ef.deleted) {
                return onSet(ef);
            } else {
                return ef;
            }
        }))
    }

    const generateId = () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }
      

    const onUpload = (file, body) => {
        setAddingFilesCount(prevState => prevState + 1);
        
        file.id = generateId();
        setFiles(existing => [...existing, {
            ...file,
            loading: true
        }]);
        resizeImage(file, body, maxImageWidth, maxImageHeight).then(({ file, result }) => {
            setFileState(file, f => ({
                ...f,
                type: "image/jpeg",
                size: result.length,
                body: result,
                loading: false
            }));
            setAddingFilesCount(prevState => prevState - 1);
        })
    };

    const remove = (file, event) => {
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();

        setFileState(file, f => ({
            ...f,
            type: "image/jpeg",
            size: 0,
            body: "",
            loading: false,
            deleted: true,
        }));
    };

    const rotate = (file, event) => {
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
        rotateImage(file).then(result => {
            setFileState(file, f => ({
                ...f,
                type: result.type,
                size: result.length,
                body: result.body,
                loading: false
            }));
        });
    };

    const attemptCloseDialog = () => {
        if (files.filter(f => !f.deleted && !f.isuploaded).length > 0) {
            setShowConfirm(true);
        } else {
            closeDialog();
        }
    }

    const closeDialog = () => {
        setShowConfirm(false);
        setFiles([]);
        onModalClose();
    }

    const uploadFiles = async () => {
        if (isFilesDone) {
            closeDialog();
        } else {
            setIsUploading(true);
            const promise = async () => { 
                const filesToUpload = files.filter(f => !f.deleted && !f.isuploaded && !f.isuploading);
                
                for (const file of filesToUpload) {
                    setFileState(file, f => ({
                        ...f,
                        isuploading: true
                    }));    
                    await onFileAdded(`data:${file.type};base64,${file.body}`);
                    setFileState(file, f => ({
                        ...f,
                        isuploading: false,
                        isuploaded: true
                    }));    
                }
            };
            promise().then(() => {
                setIsUploading(false);
            });
        }
    }
    
    return (
        <ReactModal
            isOpen={showDialog}
            className="react-modal upload-modal-container"
            overlayClassName="react-modal-overlay">
            <div className="react-modal__header upload-modal__header">
                <h2>{ intl.formatMessage({id : "fileuploadmultiple.header"}) }</h2>
                <button className="react-modal__close-button" onClick={attemptCloseDialog}>
                    <CircleCloseFilterSvg />
                </button>
            </div>
            <div className="react-modal__body upload-modal__body">
                <DropZone onUpload={onUpload} files={files} onRemove={remove} onRotate={rotate} uploadMessage={ intl.formatMessage({id : "fileuploadmultiple.uploadmessage"}) } />
            </div>
            <div className="react-modal__footer upload-modal__footer">
                <BaseButton handleSubmit={uploadFiles} id="save"
                    text={isFilesDone ? intl.formatMessage({id : "formRenderer.close"}) : intl.formatMessage({id : "fileuploadmultiple.uploadbutton"})}
                    disabled={addingFilesCount > 0 || files.length === 0}
                    className={classnames(
                        "react-modal__action-button react-modal__action-button--wide",
                        {
                            "element--is-loading element--is-loading-after": isUploading
                        },
                        {
                            "button--disabled": addingFilesCount > 0 || files.length === 0
                        }
                    )}
                ></BaseButton>
            </div>
            <ConfirmDialog isOpened={showConfirm} onClose={() => setShowConfirm(false)} onNo={() => setShowConfirm(false)} onYes={closeDialog} message={ intl.formatMessage({id : "fileuploadmultiple.closeconfirmation"}) } />
        </ReactModal>);
};

export default injectIntl(FileUploadMultiple);