import { TOGGLE_NAVIGATION } from 'redux/types'
import initialState from 'redux/initialState'

export default function uiReducer(state = initialState.ui, action) {
  switch (action.type) {
    case TOGGLE_NAVIGATION:
      return Object.assign({}, state, {
        isNavigationOpen: action.payload ? action.payload : !state.isNavigationOpen
      })

    default:
      return state
  }
}
