import React from 'react'
import ImageContainer from "components/_LivePreview/Shared/ImageContainer";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import {
    getImageObjects,
    getImageLayoutObject,
  } from "./BIHelperFunctions";

const ImageRow = (props) => {
  
    const hasScrollTarget = key => {
        if (props.scrollTarget) {
          if (props.scrollTarget.fieldTarget || props.scrollTarget.scrollTarget) {
            return props.scrollTarget.fieldTarget === key || props.scrollTarget.scrollTarget.includes(key);
          }
        }
        return false;
      }
 
    const renderFigure = (figureKey, figureTextKey) => { 
        const renderedFigureRow = renderFigureRow(figureKey, figureTextKey);
    
        return (renderedFigureRow != null ? 
          (<div className="live-preview-list-structure" data-key-id={figureKey} key={figureKey}>
            {renderedFigureRow}
          </div>) : null
        );
    }

    const renderFigureRow = (figureKey, figureTextKey) => {
       
         const images = {
          imageObjects: getImageObjects(figureKey, props.data),
          imageLayouts: getImageLayoutObject(props.data[figureKey])
        };
   
        return (
            images.imageObjects.length > 0 || props.data[figureTextKey] || hasScrollTarget(figureKey) || hasScrollTarget(figureTextKey) ? 
            (
                <div className="live-preview-images">
                  <div data-key-id={figureTextKey} key={figureTextKey} className="live-preview-key-value__row">
                      <div className="live-preview-key-value__cell">            
                          <div className="live-preview-key-value__key">
                            {props.intl.formatMessage({id: figureTextKey.split('.').at(-1)})}    
                          </div>
                          <div className="live-preview-key-value__value" dangerouslySetInnerHTML={{ __html: props.data[figureTextKey] }}></div>
                      </div>
                  </div>
                    <ImageContainer
                          datakey={figureKey + ".failureImage"}
                          imageObjects={images.imageObjects}
                          imageLayout={images.imageLayouts} />
                  </div>
              ) : null)
      };

    return (
        <div>
            { renderFigure(props.figureKey, props.figureTextKey) }
        </div>
    );
  };
    
  const mapStateToProps = (state) => {
    return {
      scrollTarget: state.saveComponentValue.scrollTarget
    };
  };
  
  export default connect(mapStateToProps)(injectIntl(ImageRow));