import React from 'react'
import { useIntl } from "react-intl"
import {
    getBearingDesignationGBI,
    getBearingData,
    renderCustomerPartReferenceNumber,
    renderCustomerPartReferenceNumberGBI
} from "../Shared/GetAndRenderBearingDesignation";
import { useSelector } from 'react-redux';

const BearingTable = ({ numberOfBearings, isGBI, data, isRecommendationShown, isUSInspection }) => {
    const intl = useIntl()
    const isInspection = useSelector(state => state.currentCase.case.domain === 'Inspection')

    const getBearingTableData = () => {
        const headers = [
            intl.formatMessage({ id: "bearingInvestigations.table.bearingNumber" }),
            intl.formatMessage({ id: "bearingInvestigations.table.bearingDesignationNumber" }),
            isRecommendationShown ? intl.formatMessage({ id: "recommendations" }) : null,
            isReferenceNumberShown() ? intl.formatMessage({ id: isUSInspection ? "customerReferenceNumber" : "customerReferencePartNumber" }) : null,
            isSkfEquivalentShown(data) ? intl.formatMessage({ id: "skfEquivalent" }) : null,
        ].filter(s => s !== null);

        const rows = [];
        for (var i = 0; i < numberOfBearings; i++) {
            rows.push([
                i + 1,
                getDesignation(i),
                isRecommendationShown ? getRecommendation(i) : null,
                isReferenceNumberShown() ? getReferenceNumber(i) : null,
                isSkfEquivalentShown(data) ? getSkfEquivalent(i) : null,
            ].filter(v => v !== null));
        }

        return {
            headers,
            rows
        }
    };

    const getDesignation = (index) => {
        const bearingData = !isGBI ? getBearingData(index, data, false) : getBearingDesignationGBI(index, data, false);
        const result = Object.keys(bearingData).filter(k => k !== 'skfEquivalent').map(k => bearingData[k]).join(" ");
        if (result) {
            return result;
        } else {
            return "";
        }
    }

    const getRecommendation = (index) => {
        if (data[`bearingInvestigations.bearingInvestigation[${index}].recommendations.recommendations`] !== undefined) {
            let recommendations = data[`bearingInvestigations.bearingInvestigation[${index}].recommendations.recommendations`].label
            if (recommendations) {
                return recommendations;
            }
        }
        return "";
    }

    const isReferenceNumberShown = () => {
        const numbers = [];
        for (var i = 0; i < numberOfBearings; i++) {
            numbers.push(getReferenceNumber(i))
        }
        return numbers.filter(v => v !== null).length > 0;
    }

    const getReferenceNumber = (index) => {
        return isGBI ? renderCustomerPartReferenceNumberGBI(index, data) : renderCustomerPartReferenceNumber(index, data)
    }

    const getSkfEquivalent = (index) => {
        if (!isUSInspection && !isInspection) return

        const bearingData = getBearingData(index, data, false);
        return bearingData['skfEquivalent'];
    }

    const isSkfEquivalentShown = (data) => {
        // NAM and Inspection reports only
        if ((!isUSInspection && !isInspection) || !data) return false

        const skfEquivalentData = [];
        for (let i = 0; i < numberOfBearings; i++) {
            const skfManufacturer = data[`bearingInvestigations.bearingInvestigation[${i}].bearingType.BearingDesignationComponent`]?.skfOrCompetitor?.value;
            if (!skfManufacturer || skfManufacturer !== 'competitor') continue; // skip if manufacturer !== 'skf'

            const currSkfEquivalent = getSkfEquivalent(i);
            skfEquivalentData.push(currSkfEquivalent);
        }
        return skfEquivalentData.filter(e => e !== null).length > 0;
    }

    const tableData = getBearingTableData();

    return (
        <div className="bearing-table_container">
            <table>
                <thead>
                    <tr>
                        {tableData.headers.map((header, i) => <th key={i}><div className="live-preview-key-value__key">{header}</div></th>)}
                    </tr>
                </thead>
                <tbody>
                    {tableData.rows.map((row, index) =>
                        <tr key={index}>
                            {row.map((value, i) => (
                                <td key={i}>
                                    <div className="live-preview-key-value__value">{value}</div>
                                </td>
                            ))}
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
}

export default BearingTable
