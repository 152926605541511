import config from "config";
import { addAnswerToSave } from "./answersToSaveEffects";
import _ from 'lodash'

export function setReportTitle(formtemplateId, components, componentsToSave, payload, intl) {
    if (formtemplateId === config.US_INSPECTION_TEMPLATE_ID) {
        if (shouldSetTitle(payload) || isTitleAndIsEmpty(payload)) {
            const generatedTitle = getGeneratedTitle(components, componentsToSave, payload, intl);
            return addOrUpdateAnswer(
                componentsToSave,
                components,
                { name: "reportDetails.reportTitle", value: JSON.stringify(generatedTitle) }
            );
        }   
    }
    return componentsToSave;
}

export function addOrUpdateAnswer(componentsToSave, components, component) { 
    const existingAnswer = components.find(c => c.name === component.name);
    if (existingAnswer) { 
        component = {
            ...existingAnswer,
            value: component.value
        };
    }
    return addAnswerToSave(componentsToSave, component);
}

const titleFields = [
    {
        name: "skfDetails.endUser", getTitle: (parsedValue, intl) => {
            return parsedValue.endUser ? `${parsedValue.endUser.name}, ${parsedValue.endUser.city}` : "";
        }
    },
    {
        name: "skfDetails.vendorJobNumber", getTitle: (parsedValue, intl) => {
            return `${intl ? intl.formatMessage({ id: "job" }) : ""} ${parsedValue}`;
        }
    },
    {
        name: "assetDetails.assetTypeOrFunctionalAreaOrSystem.systemName", getTitle: (parsedValue, intl) => parsedValue?.value ? parsedValue.value : parsedValue ? parsedValue : ''
    },
    {
        name: "assetDetails.assetTypeOrFunctionalAreaOrSystem.machineNumber", getTitle: (parsedValue, intl) => {
            return parsedValue
        }
    },
    {
        name: "assetDetails.assetTypeOrFunctionalAreaOrSystem.machineOrAssetCode", getTitle: (parsedValue, intl) => {
            return parsedValue.label;
        }
    },
    {
        name: "assetDetails.assetTypeOrFunctionalAreaOrSystem.endUserTagNumber", getTitle: (parsedValue, intl) => {
            return `${intl ? intl.formatMessage({ id: "tag" }) : ""} ${parsedValue}`;
        }
    }
];

function isTitleAndIsEmpty(payload) {
    // '""' is considered truthy value
    if (payload.name === "reportDetails.reportTitle" && !payload.value) return true;
    return payload.name === "reportDetails.reportTitle" && JSON.parse(payload.value).length === 0;
}

function shouldSetTitle(payload) {
    return titleFields.some(f => f.name === payload.name);
}

function extractMachineNumber(components, componentsToSave) {
    let component = componentsToSave.find(c => c.answer.name.endsWith('machineNumber'))
    if (component?.answer?.value) {
        const parsedValue = JSON.parse(component.answer.value)
        if (component) {
            return parsedValue?.value ? parsedValue.value : ''
        }
    }
    component = components.find(c => c.name.endsWith('machineNumber'))
    if (component?.value) {
        const parsedValue = JSON.parse(component.value)
        return parsedValue?.value ? parsedValue.value : ''
    }
}

function getGeneratedTitle(components, componentsToSave, payload, intl) {

    const titleParts = titleFields.map(f => {
        const isSystemName = f.name.endsWith('systemName')
        if (f.name.endsWith('machineNumber')) return

        let component = componentsToSave.find(c => c.answer.name === f.name)
        const parsedValue = component?.answer?.value ? JSON.parse(component.answer.value) : null
        if (component) {
            if (isSystemName) {
                const tmpSystemName = f.getTitle(parsedValue)
                const tmpMachineNum = extractMachineNumber(components, componentsToSave)

                if (!tmpSystemName) return ''
                return tmpMachineNum ? `${tmpSystemName} ${tmpMachineNum}` : tmpSystemName
            }
            return _.isEmpty(parsedValue) ? null : f.getTitle(parsedValue, intl)
        }

        component = components.find(c => c.name === f.name);
        if (component) {
            const parsedValue = JSON.parse(component.value);
            if (parsedValue) {
                if (isSystemName) {
                    const tmpSystemName = f.getTitle(parsedValue)
                    const tmpMachineNum = extractMachineNumber(components, componentsToSave)

                    if (!tmpSystemName) return ''
                    return tmpMachineNum ? `${tmpSystemName} ${tmpMachineNum}` : tmpSystemName
                }
                return f.getTitle(parsedValue, intl);
            }
            if (parsedValue === "") { 
                return parsedValue;
            }
        }
        
    }).filter(p => p);
    
    return titleParts.join(" - ");
}