import React, { Component } from "react";
import getComponent from "../../_Case/ComponentsList";

class DispositionComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disposition: "",
      scrapDate: ""
    };
  }

  componentDidMount() {
    let values = this.props.getValue(this.props.myName);
    if (values !== undefined) {
      this.setState({
        disposition: values.disposition,
        scrapDate: values.scrapDate
      });
    }
  }

  handleChange = (value, name) => {
    var parts = name.split(".");
    var resultName = parts[parts.length - 1];
    let objectToSave = { disposition: this.state.disposition };
    if (resultName === "dispositionSelect") {
      this.setState({
        disposition: value
      });
      objectToSave.disposition = value;
      this.props.setValue(objectToSave, this.props.myName);
    } else if (resultName === "scrapDate") {
      this.setState({
        scrapDate: value
      });
      objectToSave.scrapDate = value;
      this.props.setValue(objectToSave, this.props.myName);
    }
  };

  renderDispositionOptions = () => {
    if (this.state.disposition.value === "scrap") {
      return getComponent(
        {
          key: "scrapDate",
          type: "Date",
          description: "Scrap date"
        },
        `${this.props.myName}.scrapDate`,
        this.handleChange,
        this.state.scrapDate
      );
    } else {
      return null;
    }
  };

  renderDisposition = (formComponent, index) => {
    if (formComponent !== undefined) {
      return (
        <React.Fragment key={index}>
          {getComponent(
            formComponent,
            `${this.props.myName}.${formComponent.key}`,
            this.handleChange,
            this.state.disposition
          )}
        </React.Fragment>
      );
    }
  };

  render() {
    return (
      <React.Fragment>
        {this.props.formComponentData.args.map((formComponent, index) => {
          return this.renderDisposition(formComponent, index);
        })}
        {this.renderDispositionOptions()}
      </React.Fragment>
    );
  }
}

export default DispositionComponent;
