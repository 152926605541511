import React from 'react'
import {
  getImageObjects,
  getImageLayoutObject,
} from "../Shared/BIHelperFunctions";
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import PropTypes from "prop-types";
import ImageContainer from "components/_LivePreview/Shared/ImageContainer";
import KeyValueTable from "components/_LivePreview/Shared/KeyValueTable";

const AssetTable = (props) => {
  const intl = useIntl()

  const scrollTarget = useSelector(state => state.saveComponentValue.scrollTarget)
  const caseWasSaved = useSelector(state => state.saveComponentValue.caseWasSaved)

  var isFirstActive = true
  const imageKey = "assetFigure";
  const assetDescriptionKey = props.config.filter(c => c.key.includes("assetDescription"))[0].key
  const assetFigureKey = props.config.filter(c => c.key.includes("Figure"))[0].key

  const hasScrollTarget = key => {
    if (scrollTarget) {
      if (scrollTarget.fieldTarget || scrollTarget.scrollTarget) {
        return (scrollTarget.fieldTarget === key || scrollTarget.scrollTarget.includes(key)) && !caseWasSaved
      }
    }
    return false;
  }

  const filterConfig = (config, isFirstPart) => {
    var filteredConfig = []
    config.map((c) => {
      if (props.data[assetFigureKey] === undefined && props.data[assetDescriptionKey] === undefined) {
        if (isFirstPart) {
          filteredConfig = config.filter(c => !c.key.includes("assetDescription"))
        }
      } else {
        if (isFirstPart) {
          if (config.findIndex(con => con.key === c.key) < config.findIndex(c => c.key.includes(assetDescriptionKey))) {
            isFirstActive = true
            filteredConfig.push(c)
          }
        } else {
          if (config.findIndex(con => con.key === c.key) > config.findIndex(c => c.key.includes(imageKey))) {
            filteredConfig.push(c)
          }
        }
      }
    })
    return filteredConfig
  }

  const renderHeader = () => {
    if (props.data[assetFigureKey] !== undefined) {
      if (props.data[assetFigureKey].imageUrls.length < 1) {
        delete props.data[assetFigureKey]
      }
    }
    if (props.header) {
      for (let c of props.config) {
        if (props.data[c.key]) {
          return <div className="live-preview-key-value__header">{props.header}</div>
        }
      }
    }
  }

  const renderAnalysis = () => {
    const renderedAnalysis = renderAnalysisImages(0);

    return renderedAnalysis ? (
      <div className="live-preview-list-structure" data-key-id={assetFigureKey} key={assetFigureKey}>
        {renderedAnalysis}
      </div>
    ) : null
  }

  const renderAnalysisImages = i => {
    const assetDescription = props.data[assetDescriptionKey]

    const analysisImages = {
      imageObjects: getImageObjects(assetFigureKey, props.data),
      imageLayouts: getImageLayoutObject(props.data[assetFigureKey])
    }

    return (
      analysisImages.imageObjects.length > 0 || assetDescription || hasScrollTarget(assetFigureKey) || hasScrollTarget(assetFigureKey) ? (
        <div className="live-preview-key-value">
          <div className="live-preview-images">
            <div data-key-id={assetDescriptionKey} key={assetDescriptionKey} className="live-preview-key-value__row">
              <div className="live-preview-key-value__cell">
                <div className="live-preview-key-value__key">
                  {intl.formatMessage({ id: "assetDescription" })}
                </div>
                <div className="live-preview-key-value__value" dangerouslySetInnerHTML={{ __html: assetDescription }}></div>
              </div>
            </div>

            <ImageContainer
              datakey={assetFigureKey + ".failureImage"}
              imageObjects={analysisImages.imageObjects}
              imageLayout={analysisImages.imageLayouts} />
          </div>
        </div>
      ) : null)
  }

  return (
    <div>
      <div className="live-preview-report-information">
        {renderHeader()}
        <KeyValueTable config={filterConfig(props.config, true)} sectionId={props.sectionId} data={props.data} />
        {renderAnalysis()}
        <KeyValueTable config={filterConfig(props.config, false)} sectionId={props.sectionId} data={props.data} />
      </div>
    </div>
  )
}

KeyValueTable.propTypes = {
  header: PropTypes.string,
  config: PropTypes.array,
  index: PropTypes.number,
  singleColumn: PropTypes.bool,
  noBackground: PropTypes.bool,
}

export default AssetTable