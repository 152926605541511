import { getCaseDataRepository } from "core/caseRepository/caseRepositoryFactory";
import { setCurrentFormConfig } from "redux/actions/currentFormConfigActions";
import { setCurrentFormTemplate } from "redux/actions/formTemplateActions";
import { createConfiguration } from "Configs/config-builder";

import gbiConfig from "Configs/GBI/root.json";
import bartConfig from "Configs/BearingDamageAnalysis/root.json";
import inspectionConfig from "Configs/Inspection/root.json";
import usInspectionConfig from "Configs/USInspection/root.json";
import windConfig from "Configs/Wind/root.json";

import bartBearingInvestigationConfig from "Configs/BearingDamageAnalysis/BearingInvestigation.json";
import gbiBearingInvestigationConfig from "Configs/GBI/GBI-BearingInvestigation.json";
import inspectionBearingInvestigationConfig from "Configs/Inspection/Inspection-BearingInvestigation.json";
import usInspectionBearingInvestigationConfig from "Configs/USInspection/USInspection-BearingInvestigation.json";
import windBearingInvestigationConfig from "Configs/Wind/Wind-BearingInvestigation.json";

import config from "config";
import { setCurrentCaseFormTemplate } from "redux/actions/saveComponentValueAction";

export const loadFormTemplate = (id) => async (dispatch, getState) => { 
    if (process.env.REACT_APP_CONFIG_LOCAL !== "local") {
        const formTemplateReponse = await getCaseDataRepository(getState()).getFormTemplate(id);
        let translatedFormConfig = changeKeyValueInFormConfig(formTemplateReponse.data.formTemplateData);
        dispatch(setCurrentFormTemplate(id));
        dispatch(setCurrentCaseFormTemplate(id));
        dispatch(setCurrentFormConfig(translatedFormConfig));
    } else { 
        console.log("Using local form config")
        getFormTemplatesForLocalDevelopment(id, dispatch);
    }
    
}

export const resetFormTemplate = () => async (dispatch, getState) => { 
    dispatch(setCurrentFormConfig(null));
}

const getFormTemplatesForLocalDevelopment = (id, dispatch) => { 
    dispatch(setCurrentFormTemplate(id));
    dispatch(setCurrentCaseFormTemplate(id));
    dispatch(setCurrentFormConfig(createLocalFormConfig(id)));
}

const createLocalFormConfig = (id) => { 
    switch (id) {
        case config.GBI_TEMPLATE_ID:
            return createConfiguration(gbiConfig);
        case config.INSPECTION_TEMPLATE_ID:
            return createConfiguration(inspectionConfig);
        case config.US_INSPECTION_TEMPLATE_ID:
            return createConfiguration(usInspectionConfig);
        case config.WIND_TEMPLATE_ID:
            return createConfiguration(windConfig);
        default:
            return createConfiguration(bartConfig);
    }
}

export const createBIFormConfig = (id) => { 
    switch (id) {
        case config.GBI_TEMPLATE_ID:
            return createConfiguration(gbiBearingInvestigationConfig);
        case config.INSPECTION_TEMPLATE_ID:
            return createConfiguration(inspectionBearingInvestigationConfig);
        case config.US_INSPECTION_TEMPLATE_ID:
            return createConfiguration(usInspectionBearingInvestigationConfig);
        case config.WIND_TEMPLATE_ID:
            return createConfiguration(windBearingInvestigationConfig);
        default:
            return createConfiguration(bartBearingInvestigationConfig);
    }
}

const changeKeyValueInFormConfig = (formConfig) => {
    let string = JSON.stringify(formConfig);
    string = string.replace(/"name":/g, '"key":');
    let object = JSON.parse(string);
    return object;
};