import React, { Component } from "react";
import { Storage } from "aws-amplify";
import classnames from "classnames";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";

class FileComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFile: null,
      files: [],
      isLoading: false
    };
  }

  componentDidMount() {
    if (this.props.connection) {
      this.listAttachments();
    }
  }

  addAttachment = () => {
    this.setState({
      isLoading: true
    });
    const file = this.state.selectedFile;
    const name = file.name;
    Storage.put(`${this.props.caseId}/` + name, file, {
      level: "public"
    }).then(() => {
      this.listAttachments();
      this.setState({
        isLoading: false
      });
    });
  };

  getAttachment = key => {
    Storage.get(`${this.props.caseId}/${key}`, {
      level: "public"
    }).then(result => (window.location = result));
  };

  listAttachments = () => {
    Storage.list(this.props.caseId, {
      level: "public"
    }).then(result => {
      let filtered = result.filter( (filename) => !filename.key.includes("appendix"))
      this.setState({ files: filtered.map(x => x.key.split("/")[1]) });
    });
  };

  onChangeHandler = event => {
    this.setState({
      selectedFile: event.target.files[0],
      loaded: 0
    });
  };

  render() {
    return (
      <div className="input-shell">
        {this.props.formComponentData.description && (
          <label
            htmlFor={this.props.myName}
            className={classnames(
              "input-shell__label",
              "input-shell__label--dark",
              {
                "input-shell__label--required": this.props.formComponentData
                  .required
              }
            )}
          >
            {this.props.formComponentData.description}
          </label>
        )}

        <div className="input-shell__container input-shell__container--image-container">
          <label
            htmlFor={this.props.myName}
            className={classnames("button button--background-gray", {
              "button--disabled": this.props.connection === false
            })}
          >
            <FormattedMessage id="formComponents.chooseAttachment" />
          </label>

          <input
            type="file"
            id={this.props.myName}
            name={this.props.formComponentData.name}
            onChange={this.onChangeHandler}
            disabled={!this.props.connection}
          />

          <button
            type="button"
            onClick={this.addAttachment}
            disabled={!this.state.selectedFile}
            className={classnames("button button--background-green", {
              "element--is-loading element--is-loading-after": this.state.isLoading
            })}
          >
            <FormattedMessage id="formComponents.upload" />
          </button>
        </div>

        <ul className="input-files-list">
          {this.state.files.map((item, i) => (
            <li
              className="input-files-list__item"
              key={i}
              onClick={e => this.getAttachment(item)}
            >
              {i + 1}: {item}
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    caseId: state.saveComponentValue.caseId,
    connection: state.connection
  };
}
export default connect(mapStateToProps)(FileComponent);
