import React, { useEffect, useRef } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import SvgCheck from "components/svg/Check";

const ImageSelectorItem = (props) => {
  const {
    clickHandler: itemClickHandler,
    imgUrl,
    itemStyle,
    itemClassName,
    svgSize,
    svgFillColor,
    svgStrokeColor,
    svgClassName,
  } = props;

  const connection = useSelector(state => state.connection, shallowEqual);
  const itemRef = useRef(itemStyle);

  useEffect(() => {
    if (connection) {
      itemRef.current.style.backgroundImage = `url(${imgUrl}?${Date.now()})`;
    }
  }, [connection]);

  return (
    <figure
      ref={itemRef}
      style={itemStyle}
      onClick={itemClickHandler}
      className={itemClassName}
    >
      <SvgCheck
        size={svgSize}
        fillColor={svgFillColor}
        strokeColor={svgStrokeColor}
        className={svgClassName}
      />
    </figure>
  );
}

export default ImageSelectorItem;