export class CaseDataOfflineRepositoryEmpty { 
    getCase(caseId) { 
        return this.default()    
    }
    async addCase(templateId, answers, confidential = false, language = 'en') { 
        return this.default()    
    }
    async cloneCase(caseId) { 
        return this.default()   
    }
    async getCaseData(caseId) { 
        return this.default()    
    }
    async setCaseData(caseId, answers) { 
        return this.default()    
    }
    async getCaseAccess(caseId) {
        return this.default()    
    }
    async setCaseAccess(caseId, access) { 
        return this.default()    
    }
    async addAnswer(caseId, answer) { 
        return this.default()    
    }
    async updateAnswer(caseId, answerId, answer) { 
        return this.default()    
    }
    async updateCaseData(caseId, caseData) { 
        return this.default()    
    }
    async addOrUpdateCase(caseId, caseData) { 
        return this.default()    
    }
    async addOrUpdateAnswer(caseId, answerId, answer) { 
        return this.default()    
    }
    getCasesDraftForUser(numberOfCases) { 
        return this.default()    
    }
    async getFormTemplate(templateId) { 
        return this.default()    
    }
    getFormTemplates(templateIds) { 
        return this.default()    
    }
    async moveBearing(caseId, currentIndex, newIndex, count) { 
        return this.default()    
    }
    async removeBearing(caseId, bearingIndex, newCount) { 
        return this.default()    
    }
    async duplicateBearing(caseId, bearingIndex, newCount) { 
        return this.default()    
    }
    getList(endpoint) { 
        return this.default()    
    }
    default() { 
        return new Promise(res => res());
    }
}