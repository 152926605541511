
import config from "config";
import { getBearingIndexFromName } from "./../../formcomponents/utils/BearingUtils";

export default function validateAnswers(answers, requiredList, currentFormTemplateID) {
    const answersWithAdditions = [...answers];
    setApprover(answers, answersWithAdditions);
    addAnswersForReportTypes(answersWithAdditions, currentFormTemplateID);
    return compareRequiredListAndSavedAnswers(requiredList, answersWithAdditions);
}

function compareRequiredListAndSavedAnswers(requiredList, savedAnswers) {
    if (requiredList && savedAnswers) {
        const newList = requiredList.map(requiredAnswer => {
            const answer = savedAnswers.find(a => a.name === requiredAnswer.key);
            if (answer) {
                return { ...requiredAnswer, valid: requiredAnswer.isValid(answer.value) };
            } else {
                return { ...requiredAnswer, valid: false };
            }
        });
        return { requiredList: newList, valid: newList.every(ra => ra.valid) };
    } else {
        return { valid: true };
    }
}

function setApprover(answers, answersWithAdditions) {
    const approver = answers.find(a => a.name === "skfDetails.reportNeedsApproval");
    if (approver) {
        let approverParsed = JSON.parse(approver.value).approversName;
        if (approverParsed.hasOwnProperty("value")) {
            approverParsed = approverParsed.value;
        }
        answersWithAdditions.push({
            name: "skfDetails.reportNeedsApproval.approversName",
            value: approverParsed,
        });
    }
}

function addAnswersForReportTypes(answersWithAdditions, currentFormTemplateID) {
    switch (currentFormTemplateID) {
        case config.GBI_TEMPLATE_ID:
            addCustomerToAnswers(answersWithAdditions);
            break;
        case config.INSPECTION_TEMPLATE_ID:
            addInspectionApprover(answersWithAdditions);
            break;
        case config.US_INSPECTION_TEMPLATE_ID:
            addInspectionApprover(answersWithAdditions);
            addEndUser(answersWithAdditions);
            break;
        case config.WIND_TEMPLATE_ID:
            addFunctionalLocation(answersWithAdditions);
            break;
        default:
            break;
    }
    addRequiresApproval(answersWithAdditions);
    addPrimaryFailureModeAnswers(answersWithAdditions);
}

function addCustomerToAnswers(answers) {
    const terOrAmfrOrOther = answers.find(a => a.name === "TERorAMFRorOther");
    if (terOrAmfrOrOther) {
        let value = JSON.parse(terOrAmfrOrOther.value);
        if (value.GBIReportType === undefined) return
        if (value.GBIReportType.value === "Other") {
            let customerOther = answers.find(a => a.name === "investigationDetails.OtherInvestigationDetails");
            if (customerOther) {
                const customer = JSON.parse(customerOther.value).customer;
                if (customer) {
                    answers.push({
                        name: "investigationDetails.OtherInvestigationDetails.customer",
                        value: customerOther,
                    });
                }
            }
        }
    }
}

function addEndUser(answers) {
    const endUser = answers.find(a => a.name === "skfDetails.endUser");
    if (endUser) {
        const value = JSON.parse(endUser.value);
        if (value.hasOwnProperty("endUser")) {
            answers.push({
                name: "skfDetails.endUser.endUser",
                value: value.endUser,
            });
        }
        if (value.hasOwnProperty("distributor")) {
            if (value.distributor && value.distributor.isCustom) {
                answers.push({
                    name: "skfDetails.endUser.distributor",
                    value: value.distributor.value,
                });
            } else {
                answers.push({
                    name: "skfDetails.endUser.distributor",
                    value: value.distributor,
                });
            }
        }
        if (value.hasOwnProperty("vendor")) {
            answers.push({
                name: "skfDetails.endUser.vendor",
                value: value.vendor,
            });
        }
    }
}

function addInspectionApprover(answers) {
    const reportNeedsApproval = answers.find(a => a.name === "skfDetails.reportNeedsApproval");
    if (reportNeedsApproval) {
        
        const value = JSON.parse(reportNeedsApproval.value);
        if (value.managerApprover !== "") {
            answers.push({
                name: "skfDetails.reportNeedsApproval.managerApprover",
                value: value.managerApprover,
            });
            answers.push({
                name: "skfDetails.reportNeedsApproval.diameterOverTwoHundred",
                value: "true"
            });
        }
        if (value.solutionFactoryName !== "") {
            answers.push({
                name: "skfDetails.reportNeedsApproval.solutionFactoryName",
                value: value.solutionFactoryName,
            });
            answers.push({
                name: "skfDetails.reportNeedsApproval.diameterOverTwoHundred",
                value: "true"
            });
        }
    }
}

function addFunctionalLocation(answers) {
    answers.filter(a => a.name.includes("windFunctionalLocation")).forEach(a => {
        const answerValue = JSON.parse(a.value);
        if (answerValue) {
            if (answerValue.hasOwnProperty("functionalLocation")) {
                answers.push({
                    name: `${a.name}.functionalLocation`,
                    value: answerValue.functionalLocation,
                });
            }
            if (answerValue.hasOwnProperty("assetType")) {
                answers.push({
                    name: `${a.name}.assetType`,
                    value: answerValue.assetType,
                });
            }
            if (answerValue.hasOwnProperty("positionInAsset")) {
                answers.push({
                    name: `${a.name}.positionInAsset`,
                    value: answerValue.positionInAsset,
                });
            }
        }
    });
}

function addRequiresApproval(answers) {
    const answer = answers.find(a => a.name === "skfDetails.reportNeedsApproval");
    if (answer) {
        const answerValue = JSON.parse(answer.value);
        if (answerValue.needsApproval || answerValue.needsApproval === "false") {
            answers.push({
                name: "skfDetails.reportNeedsApproval.needsApproval",
                value: "true"
            });
        }
    }
}

function addPrimaryFailureModeAnswers(answers) { 
    answers.filter(a => a.name.includes("primaryFailureAndCause")).forEach(a => {
        let answerValue = JSON.parse(a.value);
        if (answerValue) {
            if (answerValue.failureCode !== "") {
                answers.push({
                    name: `${a.name}.failureMode`,
                    value: answerValue.failureCode,
                });
            }

            if (answerValue.failureCode.value === "M001" || answerValue.failureCode.value === "M000") {
                answers.push({
                    name: `${a.name}.failureImage`,
                    value: "empty",
                });
            };

            if (Array.isArray(answerValue.imageUrls) && answerValue.imageUrls.length > 0) {
                answers.push({
                    name: `${a.name}.failureImage`,
                    value: answerValue.imageUrls,
                });
            }
            if (answerValue.cause !== "") {
                answers.push({
                    name: `${a.name}.cause`,
                    value: answerValue.cause,
                });
            }
        }
    });

    answers.filter(a => a.name.includes("railwayWheelsetBearing")).forEach(a => {
        let answerValue = JSON.parse(a.value);
        if (answerValue) {
            if (answerValue.isRailwayWheelsetBearing) {
                const index = getBearingIndexFromName(a.name);
                const failureKey = `bearingInvestigations.bearingInvestigation[${index}].analysis.primaryFailureAndCause`;
                answers.push({
                    name: `${failureKey}.failureMode`,
                    value: "value",
                });
                answers.push({
                    name: `${failureKey}.failureImage`,
                    value: "value",
                });
                answers.push({
                    name: `${failureKey}.cause`,
                    value: "value",
                });
            }
        }
    });
}


