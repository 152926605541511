import React from "react";

const SvgCircleClearSearch = props => (
  <svg width={20} height={20} viewBox="0 0 20 20" {...props}>
    <g fill="none" fillRule="evenodd">
      <circle fill="#DCE0E1" cx={10} cy={10} r={10} />
      <path
        d="M6 6l7.542 7.542m0-7.542L6 13.542"
        fillRule="nonzero"
        stroke="#485A64"
        strokeWidth={2}
      />
    </g>
  </svg>
);

export default SvgCircleClearSearch;
