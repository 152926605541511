import httpService from 'core/http-service'
import endpoints from 'core/endpoints'
import localforage from "localforage";

function getEndpoint(endpoint) {
  return httpService.getEndpoint(endpoint)
}

function getCaseSearch(params) {
  return httpService.get(endpoints.CASESEARCH, params || {})
}

function getCaseQCSearch(params) {
  return httpService.get(endpoints.QC_CASESEARCH, params || {})
}


function postPdf(data, queryString) {
  if (queryString !== undefined ) {
    return httpService.post(`${endpoints.PDF_BART}${queryString}`, data)
  } else {
    return httpService.post(`${endpoints.PDF_BART}`, data)
  }
}

function postPublishPdf(data, queryString) {
  return httpService.post(`${endpoints.PUBLISH_PDF_BART}${queryString}`, data)
}

function postImage(id, file) {
  return httpService.post(`case/${id}/image`, { file })
}

function getListsUsers() {
  return httpService.get(`${endpoints.LISTS_USERS}`)
}

function getListsCustomers() {
  return httpService.get(`${endpoints.LISTS_CUSTOMERS}`)
}

function getDataExport() {
  return httpService.get(`${endpoints.DATAEXPORT}`)
}

function getPrediction(resourceId) { 
  return httpService.get(`${endpoints.AIPREDICTION}prediction/${resourceId}`);
}

function createPrediction(imageUrl) { 
  return httpService.put(`${endpoints.AIPREDICTION}prediction/`, { "imageUrl": imageUrl });
}

function addUpdatePredictionFeedback(resourceId, feedback) { 
  return httpService.put(`${endpoints.AIPREDICTION}prediction/${resourceId}/feedback`, feedback);
}

function approveFeedback(resourceId, failureMode, user) { 
  return httpService.put(`${endpoints.AIPREDICTION}prediction/${resourceId}/approve`, {
    approvalUser: user,
    approvedFailureMode: failureMode
  });
}

function getUserProperties() {
  return localforage.getItem("userdetails");
}

function updateUserProperties(keyvaluepair) {
  return new Promise((resolve, reject) => {
    getUserProperties().then(props => {
      if (props) {
        props = { ...props, ...keyvaluepair };
      } else {
        props = { ...{}, ...keyvaluepair };
      }
      localforage.setItem("userdetails", props).then(res => resolve(res));
    }).catch(err => reject(err));
  });
}

export default {
  getEndpoint,
  getDataExport,
  getCaseSearch,
  getCaseQCSearch,
  getListsUsers,
  getListsCustomers,
  postPdf,
  postImage,
  getPrediction,
  createPrediction,
  addUpdatePredictionFeedback,
  getUserProperties,
  updateUserProperties,
  approveFeedback,
  postPublishPdf
}
