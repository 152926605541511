import Pica from 'pica';

export default function resizeImage(file, body, maxImageWidth, maxImageHeight) {
    const outputCanvas = document.createElement('canvas');

        return new Promise(resolve => {
            const img = new Image();
            img.src = `data:${file.type};base64,${body}`;
            img.onload = () => {
                const aspectRatio = img.width / img.height;
                if (aspectRatio > 1) {
                    //Landscape
                    if (img.width > maxImageWidth) {
                        //Do resize
                        outputCanvas.width = maxImageWidth;
                        outputCanvas.height = maxImageWidth / aspectRatio;
                        resolve(resizeImageWithScaledCanvasAndImage(file, outputCanvas, img));
                    } else {
                        resolve(skipResizing(file, body));
                    }
                } else {
                    //Portrait
                    if (img.height > maxImageHeight) {
                         //Do resize
                        outputCanvas.height = maxImageHeight;
                        outputCanvas.width = maxImageHeight * aspectRatio;
                        resolve(resizeImageWithScaledCanvasAndImage(file, outputCanvas, img));
                    } else {
                        resolve(skipResizing(file, body));
                    }
                }
            };
        });
}

async function resizeImageWithScaledCanvasAndImage (file, outputCanvas, img) {
    const pica = Pica();
    const result = await pica.resize(img, outputCanvas, {
        unsharpAmount: 80,
        unsharpRadius: 0.6,
        unsharpThreshold: 2
    });
    const blob = await pica.toBlob(result, 'image/jpeg', 0.7);
    const blobResult = await convertBlobToBinaryString(blob);
    return ({ file, result: btoa(blobResult) });
}

function skipResizing (file, body) {
    return new Promise(resolve => resolve({ file, result: body }));
}

function convertBlobToBinaryString(blob) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
    
        reader.onload = () => {
            resolve(reader.result);
        }
    
        reader.onabort = () => {
            reject(new Error('Reading blob aborted'));
        }
    
        reader.onerror = () => {
            reject(new Error('Error reading blob'));
        }
    
        reader.readAsBinaryString(blob);
    });
}