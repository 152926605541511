import React, { Component } from "react";
import getComponent from "../../_Case/ComponentsList";

class OperationComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      continuousOrIntermittent: null,
      numberOfHours: null
    };
  }

  componentDidMount() {
    let values;
    if (
      this.props.parentSetValue !== undefined &&
      this.props.parentSetValue !== null
    ) {
      values = this.props.parentSetValue;
    } else {
      values = this.props.getValue(this.props.myName);
    }
    if (values !== undefined) {
      this.setState({
        continuousOrIntermittent: values.continuousOrIntermittent,
        numberOfHours: values.numberOfHours
      });
    }
  }

  saveToStore(objectToSave, name) {
    if (this.props.parentHandleSave !== undefined) {
      this.props.parentHandleSave(objectToSave, name);
    } else {
      this.props.setValue(objectToSave, name);
    }
  }

  handleChange = (value, name) => {
    var parts = name.split(".");
    var resultName = parts[parts.length - 1];
    let objectToSave = {
      continuousOrIntermittent: this.state.continuousOrIntermittent,
      numberOfHours: this.state.numberOfHours
    };

    if (resultName === "continuousOrIntermittent") {
      this.setState({
        continuousOrIntermittent: value
      });
      objectToSave.continuousOrIntermittent = value;
      this.saveToStore(objectToSave, this.props.myName);
    } else if (resultName === "numberOfHours") {
      this.setState({
        numberOfHours: value
      });
      objectToSave.numberOfHours = value;
      this.saveToStore(objectToSave, this.props.myName);
    }
  };

  renderIntermittent = continuousOrIntermittent => {
    if (
      continuousOrIntermittent !== null &&
      continuousOrIntermittent !== undefined &&
      continuousOrIntermittent.value === "Intermittent"
    ) {
      return (
        <React.Fragment>
          {getComponent(
            {
              key: "numberOfHours",
              type: "ValueUnit",
              description: "If intermittent, number of hours",
              args: [
                {
                  options: [
                    { label: "perDay", value: "perDay" },
                    { label: "perWeek", value: "perWeek" },
                    { label: "perYear", value: "perYear" }
                  ]
                }
              ]
            },
            `${this.props.myName}.numberOfHours`,
            this.handleChange,
            this.state.numberOfHours
          )}
        </React.Fragment>
      );
    } else {
      return null;
    }
  };

  renderOperation = (formComponent, index) => {
    if (formComponent !== undefined) {
      return (
        <React.Fragment key={index}>
          {getComponent(
            formComponent,
            `${this.props.myName}.${formComponent.key}`,
            this.handleChange,
            this.state.continuousOrIntermittent,
            index
          )}
        </React.Fragment>
      );
    }
  };

  render() {
    return (
      <React.Fragment>
        {this.props.formComponentData.args.map((formComponent, index) => {
          return this.renderOperation(formComponent, index);
        })}
        {this.renderIntermittent(this.state.continuousOrIntermittent)}
      </React.Fragment>
    );
  }
}

export default OperationComponent;
