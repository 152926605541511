export default function (value) {
    if (!value)
        return true;
    if (value == null)
        return true;
    
    const parsedValue = JSON.parse(value);

    if (parsedValue) {
        if (parsedValue.skfOrCompetitor && parsedValue.skfOrCompetitor.value && parsedValue.skfOrCompetitor.value === "skf") {
            if (parsedValue.skfBearingDesignation !== undefined && parsedValue.skfBearingDesignation.value !== undefined && parsedValue.skfBearingDesignation.value.length > 0) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
     } else {
        return true;
    }
}