import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ReportHeader from 'components/_LivePreview/Shared/ReportHeader'
import AssetTable from 'components/_LivePreview/Shared/AssetTable'
import ReportNumber from 'components/_LivePreview/Shared/ReportNumber'
import ReportGeneralText from 'components/_LivePreview/Shared/ReportGeneralText'
import BartReportInformation from 'components/_LivePreview/Bart/BartReportInformation'
import KeyValueTable from 'components/_LivePreview/Shared/KeyValueTable'
import LivePreviewHeader from 'components/_LivePreview/Shared/LivePreviewHeader'
import StatusConfidential from 'components/_LivePreview/Shared/StatusConfidential'
import InspectionConfig from 'components/_LivePreview/Inspection/InspectionConfig'
import InspectionBearingInvestigation from 'components/_LivePreview/Inspection/InspectionBearingInvestigation'
import { injectIntl } from "react-intl"
import { isConfidential, extractConfigFor, transformSummaryData,
    transformAssetDetails, transformCustomerDetailsData, getOtherSKFdetailsData} from "../Shared/LivepreviewHelperFunctions"
import BearingTable from 'components/_LivePreview/Shared/BearingTable'
import { checkIfAnyBearingHasDesignationBrandOrRecommendations, } from "../Shared/GetAndRenderBearingDesignation";

const Inspection = (props) => {
  var bearingTable;
  var bearingInspectionHeader;
  
  const translated = {
    customerDetailsHeader: props.intl.formatMessage({ id: 'customerDetails.livePreview.header' }),
    skfDetailsHeader: (props.isUserExternal ? props.intl.formatMessage({ id: 'skfDetailsExternal' }) : props.intl.formatMessage({ id: 'skfDetails.livePreview.header' })),
    assetDetailsHeader: props.intl.formatMessage({ id: 'assetDetails.livePreview.header' }),
    summaryHeader: props.intl.formatMessage({ id: 'summaryInspection' }),
    firstSummaryHeader: props.intl.formatMessage({ id: 'firstSummaryInspection' }),
    inspectionHeader: props.intl.formatMessage({ id: 'bearingInspection.livePreview.header' }),
  }

    if (checkIfAnyBearingHasDesignationBrandOrRecommendations(props.data)) {
      bearingTable = <BearingTable 
                        data={props.data}
                        numberOfBearings= {props.data["bearingInvestigations.count"]}
                        isGBI= {false}
                        isRecommendationShown={true}
                      />
      bearingInspectionHeader =  <div className="live-preview-key-value__header">{props.intl.formatMessage({ id: "bearingInspection.livePreview.header"})}</div>          
    }

  props.data["reportDetails.terNumber"] = props.data["investigationDetails.terNumber"]
  
  return (
    <div className="live-preview">
      <LivePreviewHeader />

      <div className="live-preview__container">
        <ReportHeader data={translated.inspectionHeader} />

        <StatusConfidential caseStatus={props.caseStatus} isConfidential ={isConfidential(props.data)}/>

        <ReportNumber data={props.caseId} revisionNumber={props.revisionNumber} reportStatus={props.caseStatus} />

        <BartReportInformation config={{
          reportDetails: extractConfigFor('reportDetails', InspectionConfig),
          investigationDetails: extractConfigFor('investigationDetails', InspectionConfig),
          skfDetailsOther: extractConfigFor('skfDetails', InspectionConfig),
          customerDetails: extractConfigFor('customerDetails', InspectionConfig)
        }} data={props.data}
           prefixes={['skfDetails.coReportAuthor', 'skfDetails.reportAuthor']}
           isbearingTableShown= {false}
           isUserExternal={props.isUserExternal}
        />

        <AssetTable header={translated.assetDetailsHeader} config={extractConfigFor('assetDetails', InspectionConfig)} sectionId="assetDetails" data={transformAssetDetails(props.data, InspectionConfig)} />
        
        {bearingInspectionHeader}
        {bearingTable}

        {props.showSummaryAtTop && <KeyValueTable header={translated.firstSummaryHeader} config={extractConfigFor('summary', InspectionConfig)} sectionId="summary" data={transformSummaryData(props.data, InspectionConfig)} />}
        <InspectionBearingInvestigation
          config={extractConfigFor('bearingInvestigations', InspectionConfig)}
          data={props.data}
          bearingsCount={props.data['bearingInvestigations.count']}
        />

        <KeyValueTable header={translated.summaryHeader} config={extractConfigFor('summary', InspectionConfig)} sectionId="summary" data={transformSummaryData(props.data, InspectionConfig)} />

        <ReportGeneralText/>

      </div>
    </div>
  )
}

Inspection.propTypes = {
  formData: PropTypes.object,
  caseId: PropTypes.any,
  revisionNumber: PropTypes.any
}

const mapStateToProps = (state) => {
  return {
    caseId: state.saveComponentValue.caseId,
    showSummaryAtTop: state.showSummaryAtTop
  };
}

export default connect(mapStateToProps)(injectIntl(Inspection))
