import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import classnames from "classnames";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import SvgButtonBarApproveWithEdits from "./../../svg/ButtonBarApproveWithEdits"
import approveWithEdits from "./../ButtonActions/approveWithEdits";
import { setActiveTab } from "redux/actions/activeTabActions";
import { isUserAdmin, isUserApprover } from "./../../formcomponents/utils/userUtils";

const ButtonApproveWithEdits = ({ buttonSize, setCaseStatus }) => { 
    const intl = useIntl();
    const dispatch = useDispatch();

    const [isApprovingWithEdits, setIsApprovingWithEdits] = useState();
    const [isApprover, setIsApprover] = useState(false);

    const currentCaseStatus = useSelector(state => state.currentCase.case.status, shallowEqual);
    const currentCaseId = useSelector(state => state.currentCase.case.id, shallowEqual);
    const currentCaseDomain = useSelector(state => state.currentCase.case.domain, shallowEqual);
    const connection = useSelector(state => state.connection, shallowEqual);
    const currentCaseApprover = useSelector(state => state.currentCase.case.approver, shallowEqual);
    const currentUser = useSelector(state => state.currentUser);
    const answers = useSelector(state => state.saveComponentValue.components, shallowEqual);

    useEffect(() => {
        if (!answers || !currentUser || !currentCaseApprover || !currentCaseDomain) return;
        const isCurrentUserApprover = isUserApprover(
            answers,
            currentUser.email,
            currentCaseApprover,
            currentCaseDomain
        );
        setIsApprover(isCurrentUserApprover || isUserAdmin(currentUser['custom:administrator']));
    }, [answers, currentUser, currentCaseApprover, currentCaseDomain]);

    const onEdit = () => { 
        setIsApprovingWithEdits(true);
        approveWithEdits(currentCaseId, dispatch).then(response => {
            setIsApprovingWithEdits(false);
            setCaseStatus(response.caseStatus);
            dispatch(setActiveTab(0));
        });
    }

    const isVisible = () => currentCaseStatus === "submitted" && isApprover;
    const isLoading = () => isApprovingWithEdits;
    const isDisabled = () => !connection;

    return isVisible() && <button
        id="buttonApprove"
        type="button"
        onClick={() => onEdit()}
        className={classnames("button", "button--icon-only", { "button--icon-saving": isLoading() }, { "button--icon-disabled": isDisabled() })}
        disabled={isDisabled()}>
        <SvgButtonBarApproveWithEdits size={buttonSize} title={intl.formatMessage({ id: "formRenderer.approveWithEdits" })} />
    </button>;

}

export default ButtonApproveWithEdits;