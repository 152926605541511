import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

function BaseCheckbox({ isFor, value, onChange, labelText, labelTextDark }) {
  return (
    <div className="checkbox">
      <input
        id={isFor}
        name={isFor}
        checked={value}
        type="checkbox"
        className="checkbox__hidden"
        onChange={onChange} />

      <label
        htmlFor={isFor}
        className={classnames('checkbox__label', { 'checkbox__label--dark': labelTextDark })}
        tabIndex="0">
        <span className="checkbox__label-span">{ labelText }</span>
      </label>
    </div>
  )
}

BaseCheckbox.propTypes = {
  value: PropTypes.bool,
  isFor: PropTypes.string,
  labelText: PropTypes.string,
  labelTextDark: PropTypes.bool
}

export default BaseCheckbox
