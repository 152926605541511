import React, { Component } from "react";

export default class CacheBuster extends Component { 
    constructor(props) {
        super(props);
    }

    componentDidMount() { 
        if (this.props.isconnected) { 
            const getCached = fetch("/asset-manifest.json");
            const getUnCached = fetch("/asset-manifest.json", { mode: "no-cors", cache: "no-cache"});
            Promise.all([getCached, getUnCached]).then(res => {
                const documents = res.map(r => r.json());
                return Promise.all(documents);
            }).then(documents => {
                console.log('fetched documents', documents)
                const versions = documents.map(d => Object.keys(d.files).find(n => n.includes("precache-manifest"))).filter(v => v !== undefined);
                if (versions.length > 1) {
                    if (versions[0] !== versions[1]) {
                        console.log("Updated");
                        this.updateApp();
                    } else {
                        console.log("Not updated");
                    }
                    
                } else {
                    console.log("Could not determine version");
                }
            }).catch(err => {
                console.log("No network, skip versioning");
             });
        }   
    }

    updateApp = () => {
        console.log('Clearing cache and hard reloading...')
        //caches object provided by service worker
        if (caches) {
          // Service worker cache should be cleared with caches.delete()
            caches.keys().then(names =>
                Promise.all(names.map(name => caches.delete(name)))
                    .then(() => window.location.reload(true)));
        } else {
            // delete browser cache and hard reload
            window.location.reload(true)
        }
    }

    render() { 
        return (<>{this.props.children}</>);
    }
}