import React from "react";

const SvgImageEdit = props => (
    <svg width="28px" height="28px" viewBox="0 0 28 28" version="1.1" style={{ background: "transparent" }} {...props}>
        <g id="icon_check" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <rect fill="transparent" x="0" y="0" width="28" height="28"></rect>
            <path d="M5,-1.77635684e-15 L23,-1.77635684e-15 C25.7614237,1.69014829e-16 28,2.23857625 28,5 L28,23 C28,25.7614237 25.7614237,28 23,28 L5,28 C2.23857625,28 8.11988438e-15,25.7614237 5.32907052e-15,23 L5.32907052e-15,5 C4.99089364e-15,2.23857625 2.23857625,-1.26909153e-15 5,-1.77635684e-15 Z" id="Rectangle-5" fill="#88C008"></path>
            <g id="Icons/icon_edit" transform="translate(5, 4)" fill="#FFFFFF" fillRule="nonzero">
                <path d="M1,15.4625 L1,18.5025 C1,18.7825 1.22,19.0025 1.5,19.0025 L4.54,19.0025 C4.67,19.0025 4.8,18.9525 4.89,18.8525 L15.81,7.9425 L12.06,4.1925 L1.15,15.1025 C1.05,15.2025 1,15.3225 1,15.4625 Z M18.71,5.0425 C19.1,4.6525 19.1,4.0225 18.71,3.6325 L16.37,1.2925 C15.98,0.9025 15.35,0.9025 14.96,1.2925 L13.13,3.1225 L16.88,6.8725 L18.71,5.0425 Z" id="Shape" fill="#F8F8F8"></path>
            </g>
        </g>
    </svg>
);

export default SvgImageEdit;