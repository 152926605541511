import JSZip from "jszip";
import { saveAs } from 'file-saver';
import config from "../../../config";

export default async function shareImages(answers, caseData, intl) {
  const imageUrls = getImageUrlsFromAnswers(answers);
  const autoNumbering = isUsingAutoNumbering(answers);
  const filePrefix = intl.formatMessage({ id: 'formRenderer.downloadImages.figure' });
  const zip = new JSZip();

  imageUrls
    .map(imageObj => ({ ...imageObj, downloadUrl: imageObj.uneditedUrl ? imageObj.uneditedUrl : imageObj.url }))
    .map((imageObj, index) => ({
      promise: fetch(imageObj.downloadUrl + "?1").then(res => res.blob()),
      filename: getFileName(imageObj, autoNumbering, index, filePrefix)
    }))
    .forEach(b => zip.file(b.filename, b.promise));

  const zipFile = await zip.generateAsync({ type: "blob" });

  const filename = cleanFileName(`${caseData.id} ${getCaseDomain(caseData, intl)} ${intl.formatMessage({ id: 'formRenderer.downloadImages.figures' })}`);
  saveAs(zipFile, `${filename}.zip`);

  return zipFile;
}

function getFileName(imageObj, isUsingAutoNumbering, index, filePrefix) {
  let number = isUsingAutoNumbering ? imageObj.autoNumber : imageObj.number;
  if (!number) {
    number = index + 1;
  } else {
    number = cleanFileName(number.toString());
  }
  const extension = imageObj.downloadUrl.split(".").pop();
  return `${filePrefix} ${number}.${extension}`;
}

function cleanFileName(filename) {
  return filename.replace(/[/\\?%*:|"<>]/g, '')
}

export function getImageUrlsFromAnswers(answers) {
  return answers
    .reduce((acc, item) => {
      const urls = getImageUrlsFromAnswer(item.value, item.name).filter(imageToAdd => !acc.find(image => image.url === imageToAdd.url))
      return [...acc, ...urls];
    }, []);
}

function getImageUrlsFromAnswer(answervalue, answername) {
  if (answervalue?.includes("imageUrls")) {
    const value = JSON.parse(answervalue);
    if (value.imageUrls) {
      return value.imageUrls.filter(image => image.url && image.url !== "").map(image => ({ ...image, name: answername }));
    }
  }
  return [];
}

function isUsingAutoNumbering(answers) {
  const answer = answers.find(a => a.name === "reportDetails.AutoFigureNumbering");
  return answer && answer.value === "true";
}

function getCaseDomain(caseData, intl) {
  const languageKey = getLanguageKey(caseData);
  if (languageKey) {
    return intl.formatMessage({ id: languageKey })
  } else {
    return caseData.domain;
  }
}

function getLanguageKey(caseData) {
  // checking three ids for each report
  // because there is a specific id for earch report type on dev/staging/prod
  switch (caseData.formtemplateId) {
    case 56:
    case 44:
    case 20:
      return "case.formHeader.bearingDamageAnalysis";
    case 133:
    case 32:
    case 46:
      return "navigation.gbi";
    case 170:
    case 87:
    case 73:
      return "bearingInspection.livePreview.header";
    case 218:
    case 131:
    case 128:
      return "usBearingInspection.livePreview.header";
    case 200:
    case 99:
    case 101:
      return "case.formHeader.wind";
    default:
      return undefined;
  }
}