import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { renderBearingDesignationHeader } from '../Shared/GetAndRenderBearingDesignation';
import { extractConfigFor, formatRollingElementsHeader } from "../Shared/LivepreviewHelperFunctions";
import {
  fixBearingConfig,
  getImageLayoutObject,
  getImageObjects,
  imageAnalysisNames,
  imageSectionNames,
  isImageEmpty,
  isValuePresent,
  transformBearingInvestigationData,
  transformBearingInvestigationKeys
} from "../Shared/BIHelperFunctions";

import _ from "lodash";
import classnames from "classnames";
import PropTypes from "prop-types";

import ImageContainer from "components/_LivePreview/Shared/ImageContainer";
import KeyValueTable from "components/_LivePreview/Shared/KeyValueTable";
import ObservationsAndFailuremode from "components/_LivePreview/Shared/ObservationsAndFailuremode";
import BackgroundInformation from "./BackgroundInformation";

const BearingInvestigation = (props) => {
  const intl = useIntl()

  const [bearingsConfig, setBearingConfig] = useState([]);

  const pageBreakEachBearing = useSelector(state => state.pageBreakEachBearing)
  const scrollTarget = useSelector(state => state.saveComponentValue.scrollTarget)
  const caseWasSaved = useSelector(state => state.saveComponentValue.caseWasSaved)

  const extractConfigsFor = (values, config) => {
    return config.filter(item => { return values.some(v => item.key.includes(v)) });
  }

  const filterConfig = (values, key) => {
    return values.filter(v => !v.key.endsWith(key));
  }

  const filterConfigMultiple = (values, keys) => {
    return values.filter(v => !keys.some(k => v.key.endsWith(k)));
  }

  const hasScrollTarget = key => {
    if (scrollTarget) {
      if (scrollTarget.fieldTarget || scrollTarget.scrollTarget) {
        return (scrollTarget.fieldTarget === key || scrollTarget.scrollTarget.includes(key)) && !caseWasSaved
      }
    }
    return false;
  }

  useEffect(() => {
    const configs = fixBearingConfig(props.config, props.bearingsCount);
    setBearingConfig(configs);
  }, [props.data]);

  const renderComponentPartInspection = (i, bearingsConfig) => {
    let currentName = "";

    return (
      <div data-section-id={`bearingInvestigations.bearingInvestigation[${i}].componentPartsInvestigation`}>
        {imageSectionNames(i, bearingsConfig).map((name) => {
          let key = name.key;
          let partsOfName = key.split(".");
          let scrollHook = "";
          if (currentName !== partsOfName[3]) {
            currentName = partsOfName[3];
            scrollHook = `${partsOfName[0]}.${partsOfName[1]}.${partsOfName[2]}.${currentName}`;
          }
          let headerName = intl.formatMessage({ id: `${partsOfName[4]}` });

          if (key.includes("innerRing") || key.includes("outerRing")) {
            headerName = `${intl.formatMessage({
              id: `${partsOfName[3]}`,
            })} - ${intl.formatMessage({ id: `${partsOfName[4]}` })}`;
          }

          if (isImageEmpty(props.data[key])) {
            if (!hasScrollTarget(key)) {
              return;
            }
          }

          headerName = formatRollingElementsHeader(props.data, i, headerName, scrollTarget)

          return props.data[key] || hasScrollTarget(key) ? (
            <div key={key + ".whole"} data-section-id={scrollHook}>
              <div className="live-preview-images" data-key-id={key}>
                <h6 className="live-preview-images__headline">{headerName}</h6>
                <ObservationsAndFailuremode
                  answer={props.data[key]}
                  configKey={key}
                />
                <ImageContainer
                  imageObjects={getImageObjects(key, props.data)}
                  imageLayout={getImageLayoutObject(props.data[key])}
                />
              </div>
            </div>
          ) : null
        })
        }
      </div>
    )
  }

  const renderAnalysis = (i, bearingsConfig) => {
    const renderedFailures = renderFailureImages(i, bearingsConfig);
    const renderedAnalysis = renderAnalysisImages(i);

    const shouldRenderAnalysis = renderedAnalysis != null || renderedFailures.some(f => f !== null)

    return shouldRenderAnalysis ? (
      <div className="live-preview-list-structure" data-section-id={`bearingInvestigations.bearingInvestigation[${i}].analysis`}>
        <div className="live-preview-key-value">
          <div className="live-preview-key-value__header__without_background_color">{intl.formatMessage({ id: "analysis" })}</div>
        </div>
        {renderedAnalysis}
        {renderedFailures}
      </div>
    ) : null
  }

  const renderAnalysisImages = i => {
    const imageObjectKey = "bearingInvestigations.bearingInvestigation[" + i + "].analysis.analysisImages";
    const imageLayoutKey = "bearingInvestigations.bearingInvestigation[" + i + "].analysis.analysisImages";
    const summaryKey = "bearingInvestigations.bearingInvestigation[" + i + "].analysis.analysisSummary";
    const analysisImages = {
      imageObjects: getImageObjects(imageObjectKey, props.data),
      imageLayouts: getImageLayoutObject(props.data[imageLayoutKey])
    };
    const analysisSummary = props.data[summaryKey];

    return (
      isValuePresent(analysisSummary) || analysisImages.imageObjects.length > 0 || hasScrollTarget(summaryKey) || hasScrollTarget(imageLayoutKey) || hasScrollTarget(imageObjectKey) ? (
        <div className="live-preview-key-value">
          <div className="live-preview-images">
            <div data-key-id={summaryKey} key={"bearingInvestigations.bearingInvestigation[" + i + "].analysis.analysisSummary"} className="live-preview-key-value__row">
              <div className="live-preview-key-value__cell">
                <div className="live-preview-key-value__key">
                  {intl.formatMessage({ id: "analysisSummary" })}
                </div>
                <div className="live-preview-key-value__value" dangerouslySetInnerHTML={{ __html: analysisSummary }}></div>
              </div>
            </div>
            <ImageContainer
              datakey={imageObjectKey + ".failureImage"}
              imageObjects={analysisImages.imageObjects}
              imageLayout={analysisImages.imageLayouts} />
          </div>
        </div>
      ) : null
    )
  }

  const showPredictionImage = data => {
    if (data && data.prediction && data.prediction.showBoundryBox && data.prediction.boundryBoxImage) {
      return true;
    } else {
      return false;
    }
  }

  const showAnalysis = data => {
    if (data) {
      if (data.cause || !_.isEmpty(data.failureCode) || data.imageLayout || data?.imageUrls?.length > 0 || data.observations || showPredictionImage(data)) {
        return true;
      }
    }
    return false;
  }

  const renderFailureImages = (i, bearingsConfig) => {
    const rail = props.data['bearingInvestigations.bearingInvestigation[' + i + '].operatingData.railwayWheelsetBearing'];
    const isRailway = rail !== undefined ? rail.isRailwayWheelsetBearing : false;
    const analysisNames = imageAnalysisNames(i, bearingsConfig, isRailway)

    const res = analysisNames.map((name) => {
      let key = name.key;
      const analysisOrScrollTarget = showAnalysis(props.data[key]) || hasScrollTarget(key)
      return analysisOrScrollTarget ? (
        <React.Fragment key={key + ".whole"}>
          <div className="live-preview-images">
            <ObservationsAndFailuremode
              answer={props.data[key]}
              configKey={key}
            />
            {showPredictionImage(props.data[key]) && (
              <ImageContainer
                datakey={name.key + ".predictionImage"}
                imageObjects={[props.data[key].prediction.boundryBoxImage]}
                imageLayout={{ value: "live-preview-images__container--one-figure" }}
              />
            )}
          </div>
        </React.Fragment>
      ) : null
    })
    return res
  }

  return (
    <div className="live-preview-key-values live-preview-key-value--bearing" data-section-id="bearingInvestigations">

      {bearingsConfig.map((row, i) => (
        <div key={`something-${i}`} className="live-preview-list-structure">
          <h3 className="live-preview-list-structure__header" data-section-id={`bearingInvestigation[${i}]`}>
            <div className={classnames("live-preview-key-value__header live-preview-key-value__bearingheader",
              { "live-preview-key-value-with-page-break-if-print__header": pageBreakEachBearing })}>
              {renderBearingDesignationHeader(intl.formatMessage({ id: "bearingNumber" }), intl.formatMessage({ id: "customerReferenceNumber.header" }), i, props.data)}
            </div>
          </h3>

          <KeyValueTable
            config={filterConfigMultiple(extractConfigFor("bearingType", row), ["BearingDesignationComponent"])}
            data={transformBearingInvestigationData(
              row,
              ["bearingType"],
              props.data
            )}
          />



          <KeyValueTable
            config={transformBearingInvestigationKeys(extractConfigsFor(["manufacturing", "operatingData", "interface", "environment", "drives"], row))}
            data={transformBearingInvestigationData(row, ["manufacturing", "operatingData", "interface", "environment", "drives"], props.data)}
          />
          <BackgroundInformation config={extractConfigFor('backgroundInformation', row)} data={transformBearingInvestigationData(
            row,
            [
              "backgroundInformation"
            ],
            props.data
          )} />
          {renderComponentPartInspection(i, bearingsConfig)}
          <KeyValueTable
            config={extractConfigsFor(["measuredInternalClearance"], row)}
            data={transformBearingInvestigationData(
              row,
              ["customerReferencePartNumber", "measuredInternalClearance"],
              props.data
            )} />

          {renderAnalysis(i, bearingsConfig)}
          <KeyValueTable
            config={extractConfigsFor(["dispositionContainer", "scrapDate"], row)}
            data={transformBearingInvestigationData(
              row,
              ["dispositionContainer", "scrapDate"],
              props.data
            )}
          />
        </div>
      ))}
    </div>
  )
}

BearingInvestigation.propTypes = {
  header: PropTypes.string,
  config: PropTypes.array,
  extract: PropTypes.func,
}
export default BearingInvestigation
