import getComponent from "components/_Case/ComponentsList"
import React from "react"
import { useIntl } from "react-intl"

const SKFInterchanges = ({
  myName,
  handleChange,
  skfEquivalent,
}) => {
  const intl = useIntl()

  return (
    <>
      {getComponent({
        'key': 'skfEquivalent',
        'type': 'Text',
        'description': 'SKF Intechanges',
      },
        myName + '.skfEquivalent',
        handleChange,
        skfEquivalent
      )}
      <p>
        <a
          href='https://croesus.skf.com/'
          target='_blank'
          rel='noopener noreferrer'
        >
          {intl.formatMessage({ id: 'getSkfEquivalent' })}
        </a>
      </p>
    </>
  )
}

export default SKFInterchanges
