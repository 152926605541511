import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import AssetTable from 'components/_LivePreview/Shared/AssetTable'
import ReportHeader from 'components/_LivePreview/Shared/ReportHeader'
import ReportNumber from 'components/_LivePreview/Shared/ReportNumber'
import ReportGeneralText from 'components/_LivePreview/Shared/ReportGeneralText'
import BartReportInformation from 'components/_LivePreview/Bart/BartReportInformation'
import KeyValueTable from 'components/_LivePreview/Shared/KeyValueTable'
import NewKeyValueTable from 'components/_LivePreview/Shared/NewKeyValueTable'
import LivePreviewHeader from 'components/_LivePreview/Shared/LivePreviewHeader'
import StatusConfidential from 'components/_LivePreview/Shared/StatusConfidential'
import WindConfig from 'components/_LivePreview/Wind/WindConfig'
import WindBearingInvestigation from 'components/_LivePreview/Wind/WindBearingInvestigation'
import Summary from "../Shared/Summary";
import { injectIntl } from "react-intl"
import { extractConfigFor, transformSummaryData,
  transformAssetDetails, transformCustomerDetailsData, transformIndustryAssetTypeData, getOtherSKFdetailsData} from "../Shared/LivepreviewHelperFunctions"

const Wind = (props) => {
  const translated = {
    customerDetailsHeader: props.intl.formatMessage({ id: 'customerDetails.livePreview.header' }),
    assetDetailsHeader: props.intl.formatMessage({ id: 'assetDetails.livePreview.header' }),
    summaryHeader: props.intl.formatMessage({ id: 'summary.livePreview.header' }),
    firstSummaryHeader: props.intl.formatMessage({ id: 'summary.livePreview.firstHeader' }),
    industryAssetTypeHeader: props.intl.formatMessage({ id: 'industryAssetTypeHeader.livePreview.header' }),
    windHeader: props.intl.formatMessage({ id: 'case.formHeader.wind' })
  }


  props.data["reportDetails.terNumber"] = props.data["investigationDetails.terNumber"]

  const isConfidential = () => {
    let answer = props.data["reportDetails.PublicCheckBox"]
    if (answer !== undefined) {
      if ( answer === true) {
        return false
      } else {
        return true
      }
    } else {
      return true
    }
  }
  
  return (
    <div className="live-preview">
      <LivePreviewHeader />

      <div className="live-preview__container">
        <ReportHeader data={translated.windHeader} />

        <StatusConfidential caseStatus={props.caseStatus} isConfidential ={isConfidential(props.data)}/>
        <ReportNumber data={props.caseId} revisionNumber={props.revisionNumber} reportStatus={props.caseStatus} />

        <BartReportInformation config={{
          reportDetails: extractConfigFor('reportDetails', WindConfig),
          skfDetailsOther: extractConfigFor('skfDetails', WindConfig),
          investigationDetails: extractConfigFor('investigationDetails', WindConfig),
          customerDetails: extractConfigFor('customerDetails', WindConfig)
        }}
          data={props.data}
          prefixes={['skfDetails.coReportAuthor', 'skfDetails.reportAuthor']}
          isBearingTableShown= {true}
        />

        <NewKeyValueTable header={translated.assetDetailsHeader} config={extractConfigFor('assetDetails', WindConfig)} sectionId="assetDetails" data={transformAssetDetails(props.data, WindConfig)} />

        {props.showSummaryAtTop && <Summary showOnlyConclusionsAndRecommendations={true} header={translated.firstSummaryHeader} sectionId="summary" config={extractConfigFor('summary', WindConfig)} data={transformSummaryData(props.data, WindConfig)} />}

        <WindBearingInvestigation
          config={extractConfigFor('bearingInvestigations', WindConfig)}
          data={props.data}
          bearingsCount={props.data['bearingInvestigations.count']}
        />

        <Summary showOnlyConclusionsAndRecommendations={false} header={translated.summaryHeader} sectionId="summary" config={extractConfigFor('summary', WindConfig)} data={transformSummaryData(props.data, WindConfig)} />

        <ReportGeneralText/>

      </div>
    </div>
  )
}

Wind.propTypes = {
  formData: PropTypes.object,
  caseId: PropTypes.any,
  revisionNumber: PropTypes.any
}

const mapStateToProps = (state) => {
  return {
    caseId: state.saveComponentValue.caseId,
    showSummaryAtTop: state.showSummaryAtTop
  };
}

export default connect(mapStateToProps)(injectIntl(Wind))
